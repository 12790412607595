import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import MainNavigation from "../Navigation/MainNavigation";

import { useContext } from "react";

import { RoutingContext } from "../../Context/Routing.Context";
import Loader from "../../Shared/Components/UI/Loaders/Loader";

import ScrollToTopButton from "../../Shared/Components/UI/ScrollToTopButton/ScrollToTopButton"
import { logPageView } from '../../Utils/google-analytics'
import ReactGA from 'react-ga4';

import WelcomeBar from "../../Shared/Components/UI/WelcomeBar/WelcomeBar";
import FlashMessage from "../../Shared/Components/UI/FlashMessage/FlashMessage";
const RoutingHeader = () => {
  const { isRoutingState, setIsRoutingState } = useContext(RoutingContext)

  const location = useLocation();
  
  ReactGA.initialize('G-4QM3SEEVKS')
  
  useEffect(() => {
    // initializeGA();
    logPageView(location.pathname);
  }, [location]);

    useEffect(() => {
    // window.scrollTo(0, 0);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [location]);


  // TOTOPBUTTON
  useEffect(() => {
    const handleScroll = () => {
        if (window.pageYOffset > 800) {
            setIsRoutingState(prevState => ({...prevState, isShowScrollToTopButton:true}))
        } else {
            setIsRoutingState(prevState => ({...prevState, isShowScrollToTopButton:false}))
        }
    };
    
    window.addEventListener("scroll", handleScroll);
    
    return () => {
        window.removeEventListener("scroll", handleScroll);
    };
}, [location]);



  return (
    <>
      {isRoutingState.isLoading && <Loader />}
      {isRoutingState.isShowScrollToTopButton &&  <ScrollToTopButton/>}
      <>
     
        <FlashMessage/>
        {/* <WelcomeBar /> */}
      
        <MainNavigation />
         
        
        <Footer />
      </>
    </>
  );
};

export default RoutingHeader;
