// vid 59
import { useReducer, useEffect } from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { validate } from "../../../../../Utils/validators";


import styles from './Input.module.css'

const inputReducer = (state, action) => {
    switch (action.type) {
        case 'SFW_CHANGE':
            return {
                ...state,
                value: action.sfwVal,
                isValid: validate(action.sfwVal, action.validators)
            };
        case 'SFW_TOUCH':
            return {
                ...state,
                isTouched: true
            }
        default:
            return state;
    }
};


const Input = (props) => {

    const [inputState, dispatch] = useReducer(inputReducer,
        {
            value: props.initialValue || '',
            isValid: props.initialIsValid || false,
            isTouched: false
        });

    const { id, onInput } = props;
    const { value, isValid } = inputState;

    useEffect(() => {
        onInput(id, value, isValid)
    }, [id, value, isValid, onInput]);


    // BIND HANDLERS TO INPUT
    const changeHandler = (event) => {
        dispatch({
            type: 'SFW_CHANGE',
            sfwVal: event.target.value,
            validators: props.validators
        });
    };
    const touchHandler = () => {
        dispatch({
            type: 'SFW_TOUCH',
        });
    };

    //ANIMATION
    const inputVariants = {
        initial: { opacity: 0, x: -20 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 20 },
    };

    const element = props.element === 'input' ? (
        <>
            <div className={styles.textInputContainer}>

                <input
                    icon={props.icon}
                    // iconColor={props.iconColor}
                    onIconClick={props.onIconClick}
                    id={props.id}
                    type={props.type}
                    placeholder={props.placeholder}
                    onChange={changeHandler}
                    onBlur={touchHandler}
                    value={inputState.value}
                    style={props.icon ? { paddingRight: "35px" } : {}}
                    className={`${styles.input}${!inputState.isValid && inputState.isTouched && ` ${styles["inputInvalid"]}`}`}
                // className={`input${!inputState.isValid && inputState.isTouched && " input-invalid"}`}
                />

                {props.icon && (
                    <FontAwesomeIcon
                        icon={props.icon}
                        className={`${styles["inputIcon"]}${props.onIconClick ? ` ${styles.clickable}` : ""}`}
                        onClick={props.onIconClick ? props.onIconClick : undefined}
                        style={{ color: props.iconColor }}

                    />
                )}
            </div>

        </>
    ) : (
        <div className={styles.textareaInputContainer}>
            <textarea
                id={props.id}
                rows={props.rows || 2}
                onChange={changeHandler}
                onBlur={touchHandler}
                value={inputState.value}
                placeholder={props.placeholder}
                className={`${styles.input}${!inputState.isValid && inputState.isTouched && ` ${styles["inputInvalid"]}`}`}
            />
            {!inputState.isValid && inputState.isTouched && props.element === 'textarea' &&
                <motion.p
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={inputVariants}
                    className={styles.errorText}
                >
                    {props.errorText}
                </motion.p>
            }
        </div>
    );


    return (
        <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={inputVariants}
            className={styles.textInputWrapper}
        >
            <label htmlFor={props.id} className={styles.labelWrapper}>
                <p className={styles.labelText} style={{ color: props.labelColor }}>
                    {props.label}
                    {props.requireAsterix ? <span className={styles.requiredAsterix}>*</span> : ''}
                </p>

                <div className={styles.inputElementBox}>
                    {element}
                </div>

                {!inputState.isValid && inputState.isTouched && props.element === 'input' &&
                    <motion.p
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={inputVariants}
                        className={styles.errorText}
                    >
                        {props.errorText}
                    </motion.p>
                }
            </label>





        </motion.div>
    );
};

export default Input;