import  ReactDOM  from "react-dom";
import { usePopUpAnimation } from "../../../../Hooks/AnimationHook/UseAnimiationHook";
import { motion } from "framer-motion";
import styles from "./TheFullBackdrop.module.css";

const TheFullBackdrop = props => { 
    const animationProps = usePopUpAnimation();
    return ReactDOM.createPortal(
  
        <motion.div {...animationProps} className={styles.modalContainer} onClick={props.onClick}></motion.div>,
        document.getElementById('The-Backdrop-Hook')
    );
   
   
};

export default TheFullBackdrop;