import { useParams } from "react-router-dom";
import { useContext } from "react";
import { LanguageContext } from "../../../../Context/Language.Context";
import { RetailerContext } from "../../../../Context/Retailer.Context";
import PageWrapperTemplate from "../../../Components/UI/Templates/PageWrapperTemplate";
import HeroContainer from "../../../Components/UI/HeroContainer/HeroContainer";
import BodyContainer from "../../../Components/UI/BodyContainer/BodyContainer";

//LG CHARTS

//COOKING
import lgCooking1EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-cooking-step-en-1.webp";
import lgCooking2EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-cooking-step-en-2.webp";
import lgCooking3EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-cooking-step-en-3.webp";
import lgCooking4EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-cooking-step-en-4.webp";
import lgCooking5EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-cooking-step-en-5.webp";
import lgCooking6EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-cooking-step-en-6.webp";
import lgCooking7EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-cooking-step-en-7.webp";
import lgCooking8EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-cooking-step-en-8.webp";

import lgCooking1FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-cooking-step-fr-1.webp";
import lgCooking2FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-cooking-step-fr-2.webp";
import lgCooking3FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-cooking-step-fr-3.webp";
import lgCooking4FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-cooking-step-fr-4.webp";
import lgCooking5FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-cooking-step-fr-5.webp";
import lgCooking6FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-cooking-step-fr-6.webp";
import lgCooking7FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-cooking-step-fr-7.webp";
import lgCooking8FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-cooking-step-fr-8.webp";

//DISHWASHER
import lgDish1EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-dish-step-en.webp";

import lgDish1FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-dish-step-fr.webp";

//REFRIGERATION
import lgFridge1EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-en-1.webp";
import lgFridge2EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-en-2.webp";
import lgFridge3EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-en-3.webp";
import lgFridge4EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-en-4.webp";
import lgFridge5EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-en-5.webp";
import lgFridge6EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-en-6.webp";
import lgFridge7EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-en-7.webp";
import lgFridge8EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-en-8.webp";
import lgFridge9EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-en-9.webp";
import lgFridge10EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-en-10.webp";
import lgFridge11EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-en-11.webp";

import lgFridge1FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-fr-1.webp";
import lgFridge2FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-fr-2.webp";
import lgFridge3FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-fr-3.webp";
import lgFridge4FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-fr-4.webp";
import lgFridge5FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-fr-5.webp";
import lgFridge6FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-fr-6.webp";
import lgFridge7FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-fr-7.webp";
import lgFridge8FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-fr-8.webp";
import lgFridge9FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-fr-9.webp";
import lgFridge10FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-fr-10.webp";
import lgFridge11FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-fridge-step-fr-11.webp";

//LAUNDRY
import lgLaundry1EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-laundry-step-en-1.webp";
import lgLaundry2EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-laundry-step-en-2.webp";
import lgLaundry3EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-laundry-step-en-3.webp";
import lgLaundry4EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-laundry-step-en-4.webp";
import lgLaundry5EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-laundry-step-en-5.webp";
import lgLaundry6EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-laundry-step-en-6.webp";
import lgLaundry7EN from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-laundry-step-en-7.webp";

import lgLaundry1FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-laundry-step-fr-1.webp";
import lgLaundry2FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-laundry-step-fr-2.webp";
import lgLaundry3FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-laundry-step-fr-3.webp";
import lgLaundry4FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-laundry-step-fr-4.webp";
import lgLaundry5FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-laundry-step-fr-5.webp";
import lgLaundry6FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-laundry-step-fr-6.webp";
import lgLaundry7FR from "../../../Theme/Resources/CategoryComparisonImages/Generic/lg-laundry-step-fr-7.webp";




//HOMEDEPOT CHARTS

//COOKING
import hdCooking1EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-cooking-step-en-1.webp";
import hdCooking2EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-cooking-step-en-2.webp";
import hdCooking3EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-cooking-step-en-3.webp";
import hdCooking4EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-cooking-step-en-4.webp";
import hdCooking5EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-cooking-step-en-5.webp";
import hdCooking6EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-cooking-step-en-6.webp";
import hdCooking7EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-cooking-step-en-7.webp";
import hdCooking8EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-cooking-step-en-8.webp";

import hdCooking1FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-cooking-step-fr-1.webp";
import hdCooking2FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-cooking-step-fr-2.webp";
import hdCooking3FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-cooking-step-fr-3.webp";
import hdCooking4FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-cooking-step-fr-4.webp";
import hdCooking5FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-cooking-step-fr-5.webp";
import hdCooking6FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-cooking-step-fr-6.webp";
import hdCooking7FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-cooking-step-fr-7.webp";
import hdCooking8FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-cooking-step-fr-8.webp";

//DISHWASHER
import hdDish1EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-dish-step-en.webp";

import hdDish1FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-dish-step-fr.webp";

//REFRIGERATION
import hdFridge1EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-en-1.webp";
import hdFridge2EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-en-2.webp";
import hdFridge3EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-en-3.webp";
import hdFridge4EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-en-4.webp";
import hdFridge5EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-en-5.webp";
import hdFridge6EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-en-6.webp";
import hdFridge7EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-en-7.webp";
import hdFridge8EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-en-8.webp";
import hdFridge9EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-en-9.webp";
import hdFridge10EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-en-10.webp";
import hdFridge11EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-en-11.webp";

import hdFridge1FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-fr-1.webp";
import hdFridge2FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-fr-2.webp";
import hdFridge3FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-fr-3.webp";
import hdFridge4FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-fr-4.webp";
import hdFridge5FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-fr-5.webp";
import hdFridge6FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-fr-6.webp";
import hdFridge7FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-fr-7.webp";
import hdFridge8FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-fr-8.webp";
import hdFridge9FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-fr-9.webp";
import hdFridge10FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-fr-10.webp";
import hdFridge11FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-fridge-step-fr-11.webp";

//LAUNDRY
import hdLaundry1EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-laundry-step-en-1.webp";
import hdLaundry2EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-laundry-step-en-2.webp";
import hdLaundry3EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-laundry-step-en-3.webp";
import hdLaundry4EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-laundry-step-en-4.webp";
import hdLaundry5EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-laundry-step-en-5.webp";
import hdLaundry6EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-laundry-step-en-6.webp";
import hdLaundry7EN from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-laundry-step-en-7.webp";

import hdLaundry1FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-laundry-step-fr-1.webp";
import hdLaundry2FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-laundry-step-fr-2.webp";
import hdLaundry3FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-laundry-step-fr-3.webp";
import hdLaundry4FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-laundry-step-fr-4.webp";
import hdLaundry5FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-laundry-step-fr-5.webp";
import hdLaundry6FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-laundry-step-fr-6.webp";
import hdLaundry7FR from "../../../Theme/Resources/CategoryComparisonImages/HomeDepot/lg-hd-laundry-step-fr-7.webp";

//HERO IMAGES
import dishHeroImage from '../../../Theme/Resources/StepUpChartHero/dishwasher.webp'
import cookingHeroImage from '../../../Theme/Resources/StepUpChartHero/cooking.webp'
import fridgeHeroImage from '../../../Theme/Resources/StepUpChartHero/fridge.webp'
import laundryHeroImage from '../../../Theme/Resources/StepUpChartHero/laundry.webp'

import styles from './CategoryCompare.module.css'
import SectionContainer from "../../../Components/UI/SectionContainer/SectionContainer";

const CategoryComparePage = () => {

    const { category } = useParams();
    const { language } = useContext(LanguageContext);
    const { isHomeDepotApp } = useContext(RetailerContext)

    const compareCategoriesImages = [
        {
            name: "Laundry", //DONE
            // linkText: "Compare Laundry",
            linkTextEN: "Laundry step up chart",
            linkTextFR: "Lessive tableau de progression",
            comparisonImagesEN: [lgLaundry1EN, lgLaundry2EN, lgLaundry3EN, lgLaundry4EN, lgLaundry5EN, lgLaundry6EN, lgLaundry7EN],
            comparisonImagesFR: [lgLaundry1FR, lgLaundry2FR, lgLaundry3FR, lgLaundry4FR, lgLaundry5FR, lgLaundry6FR, lgLaundry7FR],
            comparisonHeroImage: [laundryHeroImage],
            backgroundPosition: "center"
        },
        {
            name: "Refrigeration",//DONE
            linkTextEN: "Refrigeration step up chart",
            linkTextFR: "Réfrigérateur tableau de progression",
            comparisonImagesEN: [lgFridge1EN, lgFridge2EN, lgFridge3EN, lgFridge4EN, lgFridge5EN, lgFridge6EN, lgFridge7EN, lgFridge8EN, lgFridge9EN, lgFridge10EN, lgFridge11EN],
            comparisonImagesFR: [lgFridge1FR, lgFridge2FR, lgFridge3FR, lgFridge4FR, lgFridge5FR, lgFridge6FR, lgFridge7FR, lgFridge8FR, lgFridge9FR, lgFridge10FR, lgFridge11FR],
            comparisonHeroImage: [fridgeHeroImage],
            backgroundPosition: "center"
        },
        {
            name: "Cooking", //DONE
            linkTextEN: "Cooking step up chart",
            linkTextFR: "Cuisson tableau de progression",
            comparisonImagesEN: [lgCooking1EN, lgCooking2EN, lgCooking3EN, lgCooking4EN, lgCooking5EN, lgCooking6EN, lgCooking7EN, lgCooking8EN],
            comparisonImagesFR: [lgCooking1FR, lgCooking2FR, lgCooking3FR, lgCooking4FR, lgCooking5FR, lgCooking6FR, lgCooking7FR, lgCooking8FR],
            comparisonHeroImage: [cookingHeroImage],
            backgroundPosition: "center"
        },
        {
            name: "Dishwasher", //DONE
            linkTextEN: "Dishwasher step up chart",
            linkTextFR: "Lave-vaisselle tableau de progression",
            comparisonImagesEN: [lgDish1EN],
            comparisonImagesFR: [lgDish1FR],
            comparisonHeroImage: [dishHeroImage],
            backgroundPosition: "center"
        }
    ];

    const compareCategoriesImagesHD = [
        {
            name: "Laundry", //DONE
            // linkText: "Compare Laundry",
            linkTextEN: "Laundry step up chart",
            linkTextFR: "Lessive tableau de progression",
            comparisonImagesEN: [hdLaundry1EN, hdLaundry2EN, hdLaundry3EN, hdLaundry4EN, hdLaundry5EN, hdLaundry6EN, hdLaundry7EN],
            comparisonImagesFR: [hdLaundry1FR, hdLaundry2FR, hdLaundry3FR, hdLaundry4FR, hdLaundry5FR, hdLaundry6FR, hdLaundry7FR],
            comparisonHeroImage: [laundryHeroImage],
            backgroundPosition: "center"
        },
        {
            name: "Refrigeration",//DONE
            linkTextEN: "Refrigeration step up chart",
            linkTextFR: "Réfrigérateur tableau de progression",
            comparisonImagesEN: [hdFridge1EN, hdFridge2EN, hdFridge3EN, hdFridge4EN, hdFridge5EN, hdFridge6EN, hdFridge7EN, hdFridge8EN, hdFridge9EN, hdFridge10EN, hdFridge11EN],
            comparisonImagesFR: [hdFridge1FR, hdFridge2FR, hdFridge3FR, hdFridge4FR, hdFridge5FR, hdFridge6FR, hdFridge7FR, hdFridge8FR, hdFridge9FR, hdFridge10FR, hdFridge11FR],
            comparisonHeroImage: [fridgeHeroImage],
            backgroundPosition: "center"
        },
        {
            name: "Cooking", //DONE
            linkTextEN: "Cooking step up chart",
            linkTextFR: "Cuisson tableau de progression",
            comparisonImagesEN: [hdCooking1EN, hdCooking2EN, hdCooking3EN, hdCooking4EN, hdCooking5EN, hdCooking6EN, hdCooking7EN, hdCooking8EN],
            comparisonImagesFR: [hdCooking1FR, hdCooking2FR, hdCooking3FR, hdCooking4FR, hdCooking5FR, hdCooking6FR, hdCooking7FR, hdCooking8FR],
            comparisonHeroImage: [cookingHeroImage],
            backgroundPosition: "center"
        },
        {
            name: "Dishwasher", //DONE
            linkTextEN: "Dishwasher step up chart",
            linkTextFR: "Lave-vaisselle tableau de progression",
            comparisonImagesEN: [hdDish1EN],
            comparisonImagesFR: [hdDish1FR],
            comparisonHeroImage: [dishHeroImage],
            backgroundPosition: "center"
        }
    ];


    const currentCategoryBase = compareCategoriesImages.find(categoryObj => categoryObj.name === category);
    const currentCategoryHD = compareCategoriesImagesHD.find(categoryObj => categoryObj.name === category);

    const comparisonImages = language === "EN"
        ? (isHomeDepotApp && isHomeDepotApp.isHomeDepotActive ? currentCategoryHD.comparisonImagesEN : currentCategoryBase.comparisonImagesEN)
        : (isHomeDepotApp && isHomeDepotApp.isHomeDepotActive ? currentCategoryHD.comparisonImagesFR : currentCategoryBase.comparisonImagesFR);

    const heroImage = currentCategoryBase.comparisonHeroImage[0]; // Assuming it's always an array with one image
    const linkText = language === "FR" ? currentCategoryBase.linkTextFR : currentCategoryBase.linkTextEN;



    return (
        <>
            <PageWrapperTemplate>

                <BodyContainer>
                    <HeroContainer
                        backgroundImage={heroImage}
                        title={linkText}
                    />
                    <SectionContainer>
                        <div className={styles.mainContainer}>
                            <main className={styles.mainBody}>
                                <div className={styles.imageBox}>
                                    {comparisonImages.map((imageSrc, index) => (
                                        <img src={imageSrc} alt={`Comparison ${index + 1}`} key={index} />
                                    ))}
                                </div>
                            </main>

                        </div>
                    </SectionContainer>
                </BodyContainer>
            </PageWrapperTemplate>
        </>
    );
};

export default CategoryComparePage;