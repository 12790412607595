import styles from './SearchPreview.module.css'
import { motion, AnimatePresence } from 'framer-motion';
import { useContext } from 'react';
import { SFWBuilderContext } from '../../../../Context/BuilderContext';
import { LanguageContext } from '../../../../Context/Language.Context';
import { RoutingContext } from '../../../../Context/Routing.Context';
import TheButton from '../../../Components/UI/TheButton/TheButton';
import {logEvent} from "../../../../Utils/google-analytics"


const SearchPreview = ({ products }) => {
    
    const { language } = useContext(LanguageContext);
    const { setIsFlashMessageState } = useContext(RoutingContext);

    const {
        addItemToBuilderList,
        removeItemFromBuilderList,
        builderListItems
    } = useContext(SFWBuilderContext);


    const handleAddToList = (product) => {

        const productAlreadyInList = builderListItems.find(item => item._id === product._id);
        let message;
        if (productAlreadyInList) {
            removeItemFromBuilderList(product);
            message = language === "EN" ? `Product ${product.title} removed from list!` : `Product ${product.title} retiré de la liste!`;
            setIsFlashMessageState(prevState => ({
                ...prevState,
                show: true,
                message: message,
                iconType: 'subtracted',
                styleName:'subtracted'
            }))
         

        } else {
            addItemToBuilderList(product);
            message = language === "EN" ? `Product ${product.title} added to list!` : `Product ${product.title} ajouté à la liste!`;
            setIsFlashMessageState(prevState => ({
                ...prevState,
                show: true,
                message: message,
                iconType: 'checkmark',
                styleName:'checkmark'
            }))
            logEvent('User Interaction', 'Product_Added_From_Search', `Product Title: ${product.title}`);
        }
    }


    return (
        <div className={styles.searchPreviewContainer}>
            <div className={styles.searchPreviewDropdown}>
                <AnimatePresence>
                    {
                        products &&
                        products.map((product) => {
                            const categoryText = language === "EN" ? product.categoryEN : product.categoryFR;
                            const availabilityText = language === "EN" ? product.availabilityEN : product.availabilityFR;
                            const subtitleText = language === "EN" ? product.subtitleEN : product.subtitleFR;
                            return (
                                <motion.section
                                    initial={{ opacity: 0, x: 50 }}
                                    animate={{
                                        opacity: 1,
                                        x: 0,
                                        transition: {
                                            duration: .3,
                                            ease: [0.75, 0, 0, 1],
                                        },
                                    }}
                                    exit={{
                                        opacity: 0,
                                        x: 50,
                                        transition: {
                                            duration: .3,
                                            ease: [0.75, 0, 0, 1],
                                        },
                                    }}
                                    key={product._id} className={styles.searchItem}>

                                    <div onClick={() => handleAddToList(product)} className={styles.searchPreviewImageBox}>
                                        <img src={`${process.env.REACT_APP_AWS_URL}/` + product.image} alt={product.title} />
                                    </div>
                                    <div onClick={() => handleAddToList(product)} className={styles.searchPreviewTextBox}>
                                        <p className={styles.previewCategoryText}>{categoryText}</p>
                                        <p className={styles.previewTitleText} >{product.title}</p>
                                        <p className={styles.previewSubtitleText} >{subtitleText}</p>
                                        <p className={styles.previewAvailabilityText} >{availabilityText}</p>
                                    </div>
                                    <div className={styles.searchPreviewBtnBox}>
                                            <span onClick={() => handleAddToList(product)}>
                                                <TheButton
                                                animated="true"
                                                    icon 
                                                    buttonIcon="true"
                                                    iconName="plus"
                                                    styleName="lightIconButton"
                                                    // buttonTracking
                                                    // interactionName={`search_add_${product.title}`}
                                                />
                                            </span>
                                    </div>
                                </motion.section>
                            )})}
                </AnimatePresence>
            </div>
        </div>
    )
}

export default SearchPreview;
