import ReactDOM from "react-dom";
import styles from "./TheModal.module.css"
// import TheBackdrop from "../Backdrop";
import { motion } from "framer-motion";
import TheButton from "../TheButton/TheButton";
import TheFullBackdrop from "../TheBackdrops/TheFullBackdrop";
import { usePopUpAnimation } from "../../../../Hooks/AnimationHook/UseAnimiationHook";
// import { CSSTransition } from "react-transition-group"

const TheModalOverlay = props => {
    const animationProps = usePopUpAnimation();
    const backdropClickHandler = props.closeOnBackdropClick !== false ? props.onCancel : undefined;
    const content = (
        <>
            {/* <TheBackdrop onClick={props.onCancel} /> */}
            {/* <TheBackdrop /> */}
            <TheFullBackdrop  onClick={backdropClickHandler} />
            {/* <CSSTransition in={props.show} timeout={200} classNames="slide-in-left" mountOnEnter unmountOnExit> */}
                <motion.div 
              

                
                className={styles.modalContainer}   >
                    <header className={styles.modalHeaderWrapper}>

                        <p className={styles.titleText}>{props.title}</p>
                        {props.icon &&
                            <span onClick={props.iconOnClick}>
                                <TheButton
                                    icon
                                    buttonIcon="true"
                                    iconName={props.iconName}
                                    styleName={props.styleName}
                                    animated='true'
                                />
                            </span>
                        }

                    </header>
                    <div className={styles.modalMessageWrapper}>
                        <p className={styles.bodyText}>{props.message}</p>
                        {props.errorList ? (
                            <>
                                <div className={styles.errorListWrapper}>
                                    <ul className={styles.errorList}>
                                        {props.errorList.map((error, index) => {
                                            return (
                                                <li key={index}>{error}</li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </>
                        ) : null}
                    </div>

                    <div className={styles.modalActionBoxWrapper}>
                        {props.actionBox} {props.children}
                    </div>
                    <div className={styles.emailFormWrapper}>
                        {props.emailform}
                    </div>

                    {
                        props.onConfirm && props.onCancel ?
                            (
                                <div className={styles.modalConfirmationBoxWrapper}>

                                    <span onClick={props.onConfirm}>
                                        <TheButton
                                            styleName="red"
                                            animated="true"
                                        >Confirm
                                        </TheButton>
                                    </span>
                                    <span onClick={props.onCancel}>
                                        <TheButton

                                            animated="true"
                                        >Cancel
                                        </TheButton>
                                    </span>

                                </div>

                            ) : null
                    }


                </motion.div>
            {/* </CSSTransition> */}
        </>
    );

    return ReactDOM.createPortal(content, document.getElementById('The-Modal-Hook'))
};

const Modal = props => {
    return (
        <>
            {props.show && <TheModalOverlay {...props} />}
        </>
    );
};
export default Modal;