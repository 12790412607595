import styles from "./PortalFormSectionElementsBox.module.css"
const PortalFormSectionElementsBox = ({ key, label, children }) => {
    return (
        <div className={styles.portalFormSectionElementsBoxContainer} key={key}>
            {/* <p className={styles.labelText}>{label}<span className={styles.requiredAsterix}>*</span></p> */}
            <div className={styles.elementsWrapper}>
                {children}
            </div>

        </div>
    )
}
export default PortalFormSectionElementsBox;