import styles from "./TheButton.module.css";
import { useState, useEffect } from "react";
// import { AnalyticsContext } from "../../../../Context/AnalyticsContext";

import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";

import TheIcon from "../TheIcon/TheIcon";

const TheButton = ({
    children,
    styleName = 'default',
    animated = false,
    link = false,
    linkText,
    // buttonTracking = false,
    buttonIcon = false,
    // interactionName,
    iconName,
    iconText = false,
    buttonOnClick,
    iconOnClick,
    ...otherProps
}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // const { storeButtonInteraction } = useContext(AnalyticsContext);
    const ButtonElement = animated ? motion.button : 'button';
    // const buttonStyles = `${styles.button} ${styles[styleName]}`;
    const buttonStyles = `${styles[styleName]}`;
    let hoverEffect = {};

    if (animated) {
        if (!isMobile) {
            hoverEffect = {
                whileHover: {
                    scale: 1.1,
                    transition: {
                        duration: 0.2
                    }
                },
                whileTap: {
                    scale: 1,
                    transition: {
                        duration: 0.3
                    }
                }
            };
        }
    }

    return (
        link ?
        <span className={styles.buttonLinks}>
            <NavLink to={link}>
                <ButtonElement
                    className={buttonStyles}
                    {...hoverEffect}
                    {...otherProps}
                    // onClick={buttonTracking ? () => storeButtonInteraction(`${interactionName}`) : null}
                    onClick={buttonOnClick}
                >
                    {linkText}
                    {
                        buttonIcon &&
                        <span onClick={iconOnClick} className={styles.buttonIconWrapper}>
                            <TheIcon name={iconName} />
                        </span>
                    }
                    {!link && buttonIcon && <span>{iconText}</span>}
                    <span>{children}</span>
                </ButtonElement>
            </NavLink></span>
            :

            <ButtonElement
                className={buttonStyles}
                {...hoverEffect}
                {...otherProps}
                // onClick={buttonTracking ? () => storeButtonInteraction(`${interactionName}`) : null}
                onClick={buttonOnClick}
            >
                {/* {!buttonIcon && link && <NavLink to={link}>{linkText}</NavLink>} */}
                {
                    buttonIcon &&
                    <span onClick={iconOnClick} className={styles.buttonIconWrapper}>
                        <TheIcon name={iconName} />
                    </span>
                }
                {!link && buttonIcon && <span>{iconText}</span>}
                <span>{children}</span>
            </ButtonElement>



    );
};
//     const ButtonContent = () => (
//         <>
//             {buttonIcon && (
//                 <span onClick={iconOnClick} className={styles.buttonIconWrapper}>
//                     <TheIcon name={iconName} />
//                 </span>
//             )}
//             {!link && buttonIcon && <span>{iconText}</span>}
//             <span>{children}</span>
//         </>
//     );

//     if (link) {
//         return (
//             <ButtonElement
//                 className={buttonStyles}
//                 {...otherProps}
//                 {...hoverEffect}
//                 onClick={e => {
//                     if (buttonTracking) {
//                         storeButtonInteraction(`${interactionName}`);
//                         e.preventDefault();  // prevent default to allow analytics to finish
//                     }
//                 }}
//                 as={NavLink}
//                 to={link}
//                 {...otherProps}
//             >
//                 <ButtonContent />
//             </ButtonElement>
//         );
//     }

//     return (
//         <ButtonElement
//             className={buttonStyles}
//             {...hoverEffect}
//             onClick={buttonTracking ? () => storeButtonInteraction(`${interactionName}`) : null}
//             {...otherProps}
//         >
//             <ButtonContent />
//         </ButtonElement>
//     );
// };
export default TheButton;