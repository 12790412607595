
import { createContext, useState, useEffect, useContext } from 'react';

// -----------------------------------------------------
// LOGIC TO CREATE TOOL: ADD ITEMS TO BROCHURE BUILDER 
// -----------------------------------------------------

const addingItemToBuilderList = (builderListItems, productToAddToBuilderListArg) => {
    // WHAT WE DO WHEN PRODUCT IS FIRST ADDED: - SEE IF ITS ALREADY IN CART
    const detectedExistingProductInBuilderList = builderListItems.find(
        (singleListItem) => singleListItem.title === productToAddToBuilderListArg.title
    );
    // WHAT WE DO WHEN PRODUCT IS FIRST ADDED: - SEE IF ITS ALREADY IN CART
    if (detectedExistingProductInBuilderList) {
        return builderListItems.map((preExistingListItem) =>
            // SAME AS if(preExistingListItem.title === productToAddToBuilderListArg.title)
            preExistingListItem.title === productToAddToBuilderListArg.title
                ? { ...preExistingListItem, quantity: preExistingListItem.quantity + 1 } // returning a new object that contains everything from the preExisting plus an increment qty of 1
                // ? {...builderListItems, quantity: preExistingListItem.quantity + 1}
                : preExistingListItem
        );
    }
    // WHAT WE DO WHEN ITS A NEW PRODUCT NOT ALREADY IN LIST
    return [...builderListItems, { ...productToAddToBuilderListArg, quantity: 1 }];
    // return [ ...builderListItems, {...productToAddToBuilderListArg}];
};

// -----------------------------------------------------
// LOGIC TO CREATE TOOL: REMOVE ITEM FROM BROCHURE BUILDER
// -----------------------------------------------------

const removingItemFromBuilderList = (builderListItems, productToRemoveFromBuilderListArg) => {

    const detectedExistingProductInBuilderList = builderListItems.find(
        (singleListItem) => singleListItem.title === productToRemoveFromBuilderListArg.title
    );


    if (detectedExistingProductInBuilderList.quantity === 1) {
        return (
            builderListItems.filter((listItem) => listItem.title !== productToRemoveFromBuilderListArg.title)
        );
    }

    return builderListItems.map((listItem) =>
        listItem.title === productToRemoveFromBuilderListArg.title
            ? { ...listItem, quantity: listItem.quantity - 1 }
            : listItem
    );
}


// -----------------------------------------------------
// LOGIC TO CREATE TOOL: REMOVE ALL ITEMS FROM BROCHURE BUILDER
// -----------------------------------------------------

const removingAllItemsFromBuilderList = (builderListItems, builderListToEmptyArg) => {
    return (
        // builderListItems.filter((builderListItem) => builderListItem.title === builderListToEmptyArg.title)
        builderListItems.filter((builderListItem) => builderListItem.title === builderListToEmptyArg.title)
    )
}

// -----------------------------------------------------
// LOGIC TO CREATE TOOL: REMOVE ALL ITEMS FROM SAVED LIST
// -----------------------------------------------------

const removingAllItemsFromSavedList = (savedListItems, savedListToEmptyArg) => {
    return (
        savedListItems.filter((savedListItem) => savedListItem.title === savedListToEmptyArg.title)
    )
}

// -----------------------------------------------------
// CONTEXTS 
// -----------------------------------------------------

export const SFWBuilderContext = createContext({
    // isBuilderDropdownOpen: false,
    // setIsBuilderDropdownOpen: () => { },

    // isMobileNavDropdownOpen: false,
    // setIsMobileNavDropdownOpen: () => {},

    builderListCount: 0,
    builderListItems: [],

    addItemToBuilderList: () => { },
    removeItemFromBuilderList: () => { },
    removeAllItemsFromBuilderList: () => { },

    isEditingMode: false,
    savedListItems: [],
    setSavedListItems: ()=>{},
    savedListCount: [],
    addItemToSavedList: () => { },
    removeItemFromSavedList: () => { },
    removeAllItemsFromSavedList: () => { },

    initialSavedList: [],
    uploadInitialSavedList: () => { },

    // isEmailFormOpen: false,
    // setIsEmailFormOpen: ()=>{},

    // isSaveListFormOpen: false,
    // setIsSaveListFormOpen: ()=>{},


    //ONE PULL
    userSavedList:[],
    setUserSavedList:()=>{},


loadedListForm:'',
setLoadedListForm:()=>{},

})


// STATE
// -----------------------------------------------------
export const SFWBuilderProvider = ({ children }) => {
    // const [isBuilderDropdownOpen, setIsBuilderDropdownOpen] = useState(false);
    // const [isMobileNavDropdownOpen, setIsMobileNavDropdownOpen]=useState(false)
    const [builderListItems, setBuilderListItems] = useState([]);
    const [builderListCount, setBuilderListCount] = useState(0)

    const [isEditingMode, setIsEditingMode] = useState(false);
    const [savedListItems, setSavedListItems] = useState([]);
    const [savedListCount, setSavedListCount] = useState(0)

    const [initialSavedList, setInitialSavedList] = useState([])
const [loadedListForm, setLoadedListForm]=useState()
    // const [isEmailFormOpen, setIsEmailFormOpen] = useState(false)
    const [isEditEmailFormOpen, setIsEditEmailFormOpen] = useState(false)
    // const [isSaveListFormOpen, setIsSaveListFormOpen] = useState(false)

    //ONE PULL
    const [userSavedList, setUserSavedList] = useState([])

    // USE EFFECTS: RECOUNTS ON EACH BUILDER LIST ITEM CHANGE 
    // -----------------------------------------------------

    useEffect(() => {
        const newBuilderListCount = builderListItems.reduce(
            (builderListTotal, currentBuildertListItem) => builderListTotal + currentBuildertListItem.quantity, 0)
        setBuilderListCount(newBuilderListCount)
    }, [builderListItems]);

    // USE EFFECTS: RECOUNTS ON EACH SAVED LIST ITEM CHANGE 
    // -----------------------------------------------------

    useEffect(() => {
        const newSavedListCount = savedListItems.reduce(
            (savedListTotal, currentSavedListItem) => savedListTotal + currentSavedListItem.quantity, 0)
        setSavedListCount(newSavedListCount)
    }, [savedListItems]);


    //  TOOL: ADD ITEMS TO BROCHURE BUILDER
    // -----------------------------------------------------

    // ADD THIS TO ONCLICKS YOU WANT TO ADD ITEMS TO BUILDER E.G SEARCH, PRODUCT CARDS, CAT PRODUCTS 
    const addItemToBuilderList = (productToAddToBuilderListArg) => {
        setBuilderListItems(addingItemToBuilderList(builderListItems, productToAddToBuilderListArg)); //FUNCTION CALLING ANOTHER FUNCTION
      
    }

    // SAVED LIST
    //  TOOL: UPLOAD INITIAL SAVED LIST _GOOOD
    // -----------------------------------------------------
    const uploadInitialSavedList = (uploadedList) => {
        setInitialSavedList(uploadedList)
    }
    //  TOOL: ADD ITEM TO SAVED LIST _GOOOD
    // -----------------------------------------------------
    const addItemToSavedList = (productToAddToSavedListArg) => {
        // console.log('context p2+', productToAddToSavedListArg)
        // Check if the product already exists in the list
        const doesExist = initialSavedList.some(item => item._id === productToAddToSavedListArg._id);
        if (!doesExist) {
            setInitialSavedList(prevState => [...prevState, productToAddToSavedListArg]);
        } else {
            alert('Product is already in the list');
        }
    }
    //  TOOL: REMOVE ITEM FROM SAVED LIST _GOOOD
    // -----------------------------------------------------
    const removeItemFromSavedList = (productToRemove) => {
        setInitialSavedList(prevState => prevState.filter(item => item._id !== productToRemove._id));
    }




    //  TOOL: REMOVE ITEMS TO BROCHURE BUILDER
    // -----------------------------------------------------
    const removeItemFromBuilderList = (productToRemoveFromBuilderListArg) => {
        setBuilderListItems(removingItemFromBuilderList(builderListItems, productToRemoveFromBuilderListArg));

    }

    //  TOOL: REMOVE ALL ITEMS FROM BROCHURE BUILDER
    // -----------------------------------------------------

    const removeAllItemsFromBuilderList = (builderListToEmptyArg) => {
        setBuilderListItems(removingAllItemsFromBuilderList(builderListItems, builderListToEmptyArg));
    }
    //  TOOL: REMOVE ALL ITEMS FROM SAVED LIST
    // -----------------------------------------------------

    const removeAllItemsFromSavedList = (savedListToEmptyArg) => {
        setInitialSavedList(removingAllItemsFromSavedList(savedListItems, savedListToEmptyArg));
    }

    // CONTEXTS VALUE
    // -----------------------------------------------------
    const value = {
        // isBuilderDropdownOpen,
        // setIsBuilderDropdownOpen,

        // isMobileNavDropdownOpen,
        // setIsMobileNavDropdownOpen,

        addItemToBuilderList,
        builderListItems,
        builderListCount,
        removeItemFromBuilderList,
        removeAllItemsFromBuilderList,

        isEditingMode,
        setIsEditingMode,
        addItemToSavedList,

        savedListItems,
        setSavedListItems,

        savedListCount,
        removeItemFromSavedList,
        removeAllItemsFromSavedList,

        initialSavedList,
        setInitialSavedList,
        uploadInitialSavedList,

        // isEmailFormOpen,
        // setIsEmailFormOpen,
       

        // isSaveListFormOpen,
        // setIsSaveListFormOpen,

        //ONE PULL

        userSavedList,
        setUserSavedList,

        loadedListForm,
        setLoadedListForm

    }

    return (
        <SFWBuilderContext.Provider value={value}>
            {children}
        </SFWBuilderContext.Provider>
    )
};