import styles from "./CustomIcons.module.css";
import Icon from "./Icon";

const CustomIcon = ({ iconStyle, iconColor, iconName,  ...otherProps }) => {

    const iconClass = `${styles[iconStyle]}`;
    const iconControl = `${styles[iconColor]} svg`;

    return (
        <div className={iconClass} 
        {...otherProps}
        >
            <div className={iconControl} >
                <Icon name={iconName} />
            </div>

        </div>
    )
}

export default CustomIcon;