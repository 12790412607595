import styles from "./PurchaseProUserContainer.module.css"

const PurchaseProUserContainer = ({ firstName, lastName, retailer }) => {
    return (
        <div className={styles.purchaseProUserContainer}>
            <div className={styles.userRow}>
                <p>
                    Employee
                </p>
                <div className={styles.dataInRow}>
                <span>{firstName}</span>
                    <span>{lastName}</span>
                </div>
            </div>
            <div className={styles.userRow}>
                <p>Retailer</p>
                <div>
                <span>{retailer}</span>
                </div>

            </div>

        </div>
    )
}

export default PurchaseProUserContainer;