import styles from "./Home.module.css";

import { useContext } from "react";

import { RetailerContext } from "../../../Context/Retailer.Context";

import { motion } from "framer-motion";


import { useScrollFadeIn, usePopUpAnimation } from "../../../Hooks/AnimationHook/UseAnimiationHook";


import TheButton from "../../Components/UI/TheButton/TheButton";
import TheIcon from "../../Components/UI/TheIcon/TheIcon";

import { LanguageContext } from "../../../Context/Language.Context";
import PageWrapperTemplate from "../../Components/UI/Templates/PageWrapperTemplate";


import BodyContainer from "../../Components/UI/BodyContainer/BodyContainer";

const HomePage = () => {

    const { translatedText } = useContext(LanguageContext)
    const animationProps = usePopUpAnimation();

    const {isHomeDepotApp} = useContext(RetailerContext);


    const [cardRef0, cardInView0] = useScrollFadeIn({
        threshold: 0.2,
        triggerOnce: true,
    });
    const [cardRef1, cardInView1] = useScrollFadeIn({
        threshold: 0.2,
        triggerOnce: true,
    });

    const [cardRef2, cardInView2] = useScrollFadeIn({
        threshold: 0.2,
        triggerOnce: true,
    });

    const [cardRef3, cardInView3] = useScrollFadeIn({
        threshold: 0.2,
        triggerOnce: true,
    });


    return (
        <PageWrapperTemplate>
            <section className={styles.heroSection}>
                <BodyContainer>
                    <div className={styles.textWrapper}>
                        <p className={styles.topTitleText}>{translatedText.expInnovation}</p>
                        <p className={styles.mainTitleText}>{translatedText.lgPg}</p>
                        {/* <p className={styles.subtitleText}>{translatedText.haSalesTool}</p> */}
                        <div className={styles.buttonBoxWrapper}>
                            {/* <TheButton
                                styleName="red"
                                // linkText="Purchase Pro"
                                linkText={translatedText.pL}
                                link={`${homeDepotPrefix}/purchase-pro`}
                                animated="true"
                            /> */}
                            <TheButton
                                linkText= {translatedText.exploreHA}
                                link={`${isHomeDepotApp.isHomeDepotPrefix}/categories`}
                                animated="true"
                                styleName="red"
                            />
                        </div>
                    </div>

                </BodyContainer>

            </section>
            <BodyContainer>
                {/* <SectionTextContainer
                    titleText="Welcome to the LG Product Guide"
                    headlineText="Your total-solution sales tool covering the complete line-up of LG Home Appliances"
                /> */}
                <div className={styles.introTextBox}>
                    <p id={styles.darkTitleText} className={styles.sectionTitleText}>{translatedText.welcomePG}</p>
                    <p id={styles.darkSubtitleText} className={styles.subtitleText}>{translatedText.totSales}</p>
                </div>

                <section className={styles.featureSection}>
                    <motion.div
                        className={styles.card}
                        ref={cardRef1}
                        animate={{ opacity: cardInView1 ? 1 : 0, y: cardInView1 ? 0 : 50 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className={styles.cardTextBox}>

                            <p className={styles.cardTitleText}>{translatedText.homeAppliances}</p>
                            <p className={styles.bodyText}>{translatedText.brHACard}</p>
                            <div className={styles.optionsBox}>
                                <TheButton link={`${isHomeDepotApp.isHomeDepotPrefix}/categories/Laundry`} linkText={translatedText.laundry} animated="true" />
                                <TheButton link={`${isHomeDepotApp.isHomeDepotPrefix}/categories/Refrigeration`} linkText={translatedText.refrigeration} animated="true" />
                                <TheButton link={`${isHomeDepotApp.isHomeDepotPrefix}/categories/Cooking`} linkText={translatedText.cooking} animated="true" />
                                <TheButton link={`${isHomeDepotApp.isHomeDepotPrefix}/categories/Dishwasher`} linkText={translatedText.dishwasher} animated="true" />
                                <TheButton link={`${isHomeDepotApp.isHomeDepotPrefix}/categories/LG Studio`} linkText="LG Studio" animated="true" />
                            </div>
                        </div>
                        <div className={styles.cardImageBox}>
                            <TheIcon name="shoppingTag" />
                        </div>
                    </motion.div>
                    <motion.div
                        className={styles.card}
                        ref={cardRef2}
                        animate={{ opacity: cardInView2 ? 1 : 0, y: cardInView2 ? 0 : 50 }}
                        transition={{ duration: 0.5 }}
                    >

                        <div className={styles.cardTextBox}>
                            <p className={styles.cardTitleText}>{translatedText.stepUpChart}</p>
                            <p className={styles.bodyText}>{translatedText.brSUCard}</p>
                            <div className={styles.optionsBox}>
                                <TheButton link={`${isHomeDepotApp.isHomeDepotPrefix}/compare/Laundry`} linkText={translatedText.laundry} animated="true" />
                                <TheButton link={`${isHomeDepotApp.isHomeDepotPrefix}/compare/Refrigeration`} linkText={translatedText.refrigeration} animated="true" />
                                <TheButton link={`${isHomeDepotApp.isHomeDepotPrefix}/compare/Cooking`} linkText={translatedText.cooking} animated="true" />
                                <TheButton link={`${isHomeDepotApp.isHomeDepotPrefix}/compare/Dishwasher`} linkText={translatedText.dishwasher} animated="true" />
                            </div>
                        </div>
                        <div className={styles.cardImageBox}>
                            {/* <Icon name="searchFile" /> */}
                            <TheIcon name="compareBoard" />
                        </div>
                    </motion.div>
                    <motion.div
                        className={styles.card}
                        ref={cardRef3}
                        animate={{ opacity: cardInView3 ? 1 : 0, y: cardInView3 ? 0 : 50 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className={styles.cardTextBox}>
                            <p className={styles.cardTitleText} >{translatedText.resources}</p>
                            <p className={styles.bodyText}>{translatedText.brRECard}</p>
                            <div className={styles.optionsBox}>
                                <TheButton link={`${isHomeDepotApp.isHomeDepotPrefix}/resources/definitions`} linkText={translatedText.featureDefinitions} animated="true" />
                                <TheButton link={`${isHomeDepotApp.isHomeDepotPrefix}/resources/warranties`} linkText={translatedText.limitedWarranties} animated="true" />
                                <TheButton link={`${isHomeDepotApp.isHomeDepotPrefix}/resources/transitions`} linkText={translatedText.modelTransitions} animated="true" />
                            </div>
                        </div>
                        <div className={styles.cardImageBox}>
                            {/* <Icon name="list" /> */}
                            <TheIcon name="squareInfo" />
                        </div>
                    </motion.div>


                </section>

            </BodyContainer>

            {/* <div className={styles.purchaseProSectionWrapper}>
                <BodyContainer>
              
                    <section className={styles.purchaseProSection}>
                        <motion.div
                            ref={cardRef0}
                            animate={{ opacity: cardInView0 ? 1 : 0, y: cardInView0 ? 0 : 50 }}
                            transition={{ duration: 0.5 }}
                            id={styles.purchaseProTextBox}
                            className={styles.textBox}>

                            <p id={styles.darkSubtitleText} className={styles.topTitleText}>{translatedText.exclusiveOffers}</p>
                            <p className={styles.sectionTitleText}>{translatedText.introPL}</p>
                            <p id={styles.darkSubtitleText} className={styles.subtitleText}>{translatedText.dySalesTL}</p>
                            <div className={styles.benefitsBox}>
                                <p className={styles.purchaseProBodyText}>{translatedText.descrPL}</p>
                            </div>
                            <div div className={styles.buttonBox}>
                                <TheButton
                                    styleName="red"
                                    linkText={translatedText.tryPLBtn}
                                    link={`${isHomeDepotApp.isHomeDepotPrefix}/product-list`}
                                   
                                    animated="true"
                                />

                            </div>
                        </motion.div>
                    
                    </section>
           
                </BodyContainer>

            </div> */}
        </PageWrapperTemplate>


    )
}

export default HomePage