import styles from './QRCodeImage.module.css';
import { LanguageContext } from '../../../../../Context/Language.Context';
import { useContext } from 'react';


const QRCodeImage = ({ QRCodeImage }) => {

    const { language } = useContext(LanguageContext);

    // console.log(QRCodeImage);
    return (
        QRCodeImage.qrCodeSpecSheetEN || QRCodeImage.qrCodeSpecSheetEN ?
            <div className={styles.qrCodeImageContainer}>
                {language === "EN" ?
                    <>
                        

                        <div className={styles.imageWrapper}>
                            <div className={styles.imageItem}>
                                <div className={styles.productImageContainer}>
                                    <img src={`${process.env.REACT_APP_AWS_URL}/` + QRCodeImage.qrCodeSpecSheetEN} alt={QRCodeImage.qrCodeSpecSheetEN} />
                                </div>
                                <p className={styles.title}>Scan QR Code</p>
                                <p className={styles.itemText}>Specification Sheet</p>
                               
                            </div>
                            
                        </div>
                    </> :
                    <>
                       

                        <div className={styles.imageWrapper}>
                            
                            <div className={styles.imageItem}>
                                <div className={styles.productImageContainer}>
                                    <img src={`${process.env.REACT_APP_AWS_URL}/` + QRCodeImage.qrCodeSpecSheetFR} alt={QRCodeImage.qrCodeSpecSheetFR} />
                                </div>
                                <p className={styles.title}>Scanner le code QR</p>
                                <p className={styles.itemText}>Fiche technique</p>
                            </div>
                        </div>
                    </>

                }

            </div>
            : null
    );
}

export default QRCodeImage;