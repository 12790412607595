import styles from '../ResourcesPages.module.css'

import TransitionImage from "../../../Theme/Resources/TransitionsImages/lg-product-transitions-en.webp"
import TransitionImageFR from "../../../Theme/Resources/TransitionsImages/lg-product-transitions-fr.webp"

import { useContext } from 'react';
import { LanguageContext } from '../../../../Context/Language.Context';


import TransitionPageBackgroundImage from "../../../Theme/Product/SubcategoryImages/fridge-14.webp"
import HeroContainer from '../../../Components/UI/HeroContainer/HeroContainer';

import BodyContainer from '../../../Components/UI/BodyContainer/BodyContainer';
import PageWrapperTemplate from '../../../Components/UI/Templates/PageWrapperTemplate';

import SectionTextContainer from '../../../Components/UI/SectionTextContainer/SectionTextContainer';
import SectionContainer from '../../../Components/UI/SectionContainer/SectionContainer';
const TransitionsPage = () => {


   const { translatedText, language } = useContext(LanguageContext)
   const transitionPageImage = language === "EN" ? TransitionImage : TransitionImageFR;

   return (
      <>

            <PageWrapperTemplate>
               <BodyContainer>
               <HeroContainer
               backgroundImage={TransitionPageBackgroundImage}
               title={translatedText.modelTransitions}
               backgroundPosition="center"
               />
             <SectionContainer>
               <SectionTextContainer
                subtitleText={translatedText.resources}
                        titleText={translatedText.exModelTransitions}
                        descriptionText={translatedText.describeModelTransitions}
               //  descriptionText2="From built-in cooktops to laundry machines and more, we'll keep you informed about what's new on our new models versus the model it's replacing."
               //  descriptionText3="Prepare to impress customers with enhanced energy efficiency, sleek designs, and innovative technologies that will elevate their home experience."
               />
             
                  <div className={styles.transitionImgWrapper}>
                     <img src={transitionPageImage} />
                  </div>
               </SectionContainer>
               </BodyContainer>
            </PageWrapperTemplate>

           
         {/* </PageTemplate> */}
      </>
   );
};

export default TransitionsPage;
