
import { useContext } from "react";
import { AdminContext } from "../../../Context/Admin.Context";

import LeaderBoard from "./DisplayDataElements/LeaderBoards/LeaderBoard";  // assuming you saved the component in the same directory

import styles from "../DisplayData/DisplayDataElements/LeaderBoards/LeaderBoard.module.css"

const ProductGuideCityUsageLeaderBoard = () => {
    
    const { isFilteredGoogleAnalyticsData } = useContext(AdminContext);
    const citiesData = {};
    
    isFilteredGoogleAnalyticsData?.pageDataMetrics?.forEach(entry => {
        // console.log('entry', entry)
        // citiesData[entry.city] = (citiesData[entry.city] || 0) + parseInt(entry.activeUsers, 10);
        citiesData[entry.city] = (citiesData[entry.city] || 0) + parseInt(entry.newUsers, 10);
    });
    
    const sortedCities = Object.entries(citiesData)
    .sort(([, a], [, b]) => b - a)
    .slice(0, 10)
    .map(([city, totalUsers]) => ({ city, totalUsers }));
    
    const cityFormatter = (cityData, index) => (
        <div className={styles.leaderRow} key={cityData.city}>
            <p className={styles.rank}>Rank <span className={styles.rankNumber}>{index + 1}</span></p>
            <p className={styles.leaderPoint}>{cityData.city}</p>
            <p className={styles.leaderPoint}>{cityData.totalUsers} visitors</p>
        </div>
    );

    return (
        <LeaderBoard
            // title="Top Cities"
            // subtitle="By Visitor Traffic"
            data={sortedCities}  
            formatter={cityFormatter}
        />
    );
}

export default ProductGuideCityUsageLeaderBoard;

