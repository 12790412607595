import styles from './TheTableHeader.module.css';
import { motion } from "framer-motion";

//ANIMATION
const tableVariants = {
    initial: { opacity: 0, x: -20 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 20 },
};

const TheTableHeader = ({title, subtitle, headline}) => {
    return (
        <motion.div
            className={styles.tableHeaderContainer}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={tableVariants}
        >
            <p className={styles.subtitleText}>
                {subtitle}
            </p>
            <p className={styles.titleText}>
                {title}
            </p>
            <p className={styles.headlineText}>
                {headline}
            </p>

        </motion.div>
    )
}

export default TheTableHeader;