import TheTableHeader from "../TableElements/TheTableHeader";
import styles from "./PortalForm.module.css"



const PortalForm = ({
    onSubmit,
    title,
    subtitle,
    headline,

    children

}) => {
 
    return (
        <div className={styles.portalFormContainer}>
            {/* <SectionTextContainer
            titleText={title}
            subtitleText={subtitle}
            headlineText={headline}
            /> */}
            <TheTableHeader
                title={title}
                subtitle={subtitle}
                headline={headline}
            />
            <form
                className={styles.portalFormWrapper}
                onSubmit={onSubmit}
            >
              
                {children}
            </form>
        </div>

    )
}

export default PortalForm;