import { motion } from "framer-motion";

import styles from "./ScrollToTopButton.module.css"

import TheButton from "../TheButton/TheButton";


const ScrollToTopButton = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (


        <motion.div
            whileHover={{
                scale: 1.05,
                transition: {
                    duration: 1,
                }
            }}
            whileTap={{
                scale: 1,
                transition: {
                    duration: .3,
                }
            }}

            initial={{ opacity: 0, x: 50 }}
            animate={{
                opacity: 1,
                x: 0,
                transition: {
                    duration: .3,
                    ease: [0.75, 0, 0, 1],
                },
            }}
            exit={{
                opacity: 0,
                x: 50,
                transition: {
                    duration: .3,
                    ease: [0.75, 0, 0, 1],
                },
            }}
            onClick={scrollToTop}
            className={styles.container}
        >
          
                <TheButton
                    animated="true"
                    icon
                    buttonIcon ="true"
                    iconName="caretUp"
                    styleName="lightIconButton"
                  
                />
                {/* <Icon name="arrow-up" /> */}

   
        </motion.div>
    )
}

export default ScrollToTopButton;