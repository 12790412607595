import styles from "./PortalFormImageUploadSection.module.css"
const PortalFormImageUploadSection = ({ label,  note, children }) => {
    return (
        <>
            <div className={styles.textBox}>
                <p className={styles.sectionLabelText}>{label}</p>
                <p className={styles.noteText} >{note} </p>
            </div>

            <div >
                <div>
                   
                    {children}
                </div>

            </div>
        </>

        // <div className={styles.portalFormImageUploadSectionWrapper}>
        //     <div className={styles.imageUploadRow}>
        //         <div className={styles.textBox}>
        //             <p className={styles.sectionLabelText}>{label}</p>
        //             <p className={styles.noteText} >{note} </p>
        //         </div>
        //         <div className={styles.imagePreviewBox}>
        //             {children}
        //         </div>
        //     </div>
        // </div>
    )
}

export default PortalFormImageUploadSection;