import styles from './ListModal.module.css'
import { useContext } from 'react'
import { SFWBuilderContext } from '../../../../../Context/BuilderContext'
import { RoutingContext } from '../../../../../Context/Routing.Context'
import { RetailerContext } from '../../../../../Context/Retailer.Context'
import { LanguageContext } from '../../../../../Context/Language.Context'
import { NavLink } from 'react-router-dom'
// import CustomIcon from '../../../../Components/IconElements/CustomIcons'
import TheButton from '../../../../Components/UI/TheButton/TheButton'
import DropdownWrapper from '../../../../Components/UI/DropdownWrapper/DropdownWrapper'

const ListModal = (props) => {
    const { builderListItems } = useContext(SFWBuilderContext);
    const { isHomeDepotApp } = useContext(RetailerContext);

    const { translatedText } = useContext(LanguageContext)

    const { setIsRoutingState } = useContext(RoutingContext)

    const closeProductProNavList = () => {
        setIsRoutingState(prevState => ({ ...prevState, isBuilderDropdownOpen: false, }))
    }
     const handleDesktopListDropdownMouseLeave = () => {
        setIsRoutingState(prevState => ({
            ...prevState,
            isBuilderDropdownOpen: false
        }))
    }

    return (
        <DropdownWrapper>

            <div className={styles.backdrop} />
            <div className={styles.modal}  onMouseLeave={handleDesktopListDropdownMouseLeave}>
                <div className={styles.mainBuilderListContainer}>
                    <div className={styles.closeBuilderBox}>
                        <div className={styles.headTextBox}>
                            <div className={styles.textTitleCountBox}>
                                <div className={styles.countTextBox}>
                                    <p className={styles.subtitleText}>{translatedText.products}</p>
                                    <p className={styles.productCountNumber}>{builderListItems.length}</p>
                                </div>
                                <p className={styles.titleText}>{translatedText.yourProductList}</p>
                            </div>
                            <span
                                className={styles.closeDropdownButtonBox}
                                onClick={closeProductProNavList}>
                                <TheButton
                                    icon
                                    buttonIcon
                                    iconName="close"
                                    styleName="lightIconButton"
                                    animated='true'
                                    buttonTracking
                                />
                                {/* <CustomIcon
                                    iconName="close"
                                    iconStyle="circularGray04Icon"
                                    iconColor="white"
                                /> */}
                            </span>
                        </div>
                    </div>
                    {<div className={styles.bodyWrapper}>{
                          props.children
                    // props.children ? props.children : <p>No Products</p>
                    
                    }</div>}

                    <div className={styles.modalButtonBox}>
                        {/* <NavLink to={`${isHomeDepotApp.isHomeDepotPrefix}/purchase-pro`}> */}
                        <NavLink to={`${isHomeDepotApp.isHomeDepotPrefix}/product-list`}>
                            <span onClick={closeProductProNavList}>
                                <TheButton
                                 buttonIcon="true"
                                 iconName="forward"
                             
                                styleName="blackIconButton"
                                    // link={`${isHomeDepotApp.isHomeDepotPrefix}/purchase-pro`}
                                    link={`${isHomeDepotApp.isHomeDepotPrefix}/product-list`}
                                   
                                    linkText={translatedText.pL}
                                />
                            </span>
                        </NavLink>
                    </div>
                </div>
            </div>

        </DropdownWrapper>
    )
}

export default ListModal;