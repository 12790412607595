import styles from '../ResourcesPages.module.css'
import WarrantiesPageBackgroundImage from "../../../Theme/Product/SubcategoryImages/dish-5.webp"
import { useContext } from "react";
import { LanguageContext } from "../../../../Context/Language.Context";

import PageWrapperTemplate from '../../../Components/UI/Templates/PageWrapperTemplate';
import HeroContainer from '../../../Components/UI/HeroContainer/HeroContainer';
import SectionTextContainer from '../../../Components/UI/SectionTextContainer/SectionTextContainer';
import BodyContainer from '../../../Components/UI/BodyContainer/BodyContainer';
import { motion } from 'framer-motion';
import SectionContainer from '../../../Components/UI/SectionContainer/SectionContainer';

const warrantyEN = [
  {
    type: "LG Studio",
    items: ["2 Years Parts and Labour"]
  },
  {
    type: "Washers",
    items: [
      "1 Year Parts & Labour",
      "3 Years STS Drum (Parts Only)",
      "10 Years DD Motor (Parts Only)",
    ]
  },
  {
    type: "Dryers",
    items: [
      "1 Year Parts & Labour",
      "3 Years Dryer Drum (Parts Only)",
      "10 Years Dual Inverter Compressor (Parts Only)",
      "10 Years Motor (Parts Only)",
    ]
  },
  {
    type: "Styler",
    items: [
      "1 Year Parts & Labour",
      "10 Years Inverter Compressor",
    ]
  },
  {
    type: "Refrigerators",
    items: [
      "1 Year Parts & Labour",
      "7 Years Sealed System (Parts Only)*",
      "10 Years Compressor (Parts Only)*",
      "*Depending on model, some conditions may apply.",
    ]
  },
  {
    type: "Ranges, Cooktops, Wall Ovens:",
    items: [
      "1 Year Parts and Labour",
    ]
  },
  {
    type: "Dishwashers:",
    items: [
      "1 Year on Parts & Labour",
      "2 Years (Parts Only)",
      "10 Years Part Only for Direct Drive Motor (Parts Only)",
    ]
  },
  {
    type: "Microwave Ovens (Over-the-Range)",
    items: [
      "1 Year Parts & Labour",
      "5 Years on the Magnetron (Part Only)",
    ]
  },
  {
    type: "Microwave Ovens (Countertop)",
    items: [
      "1 Year Parts & Labour",
      "5 Years on the Magnetron (Part Only)",
    ]
  },
  {
    type: "Vacuum Cleaners",
    items: [
      "LG Cord Zero A906SM",
      "1 Year Parts and Labour",
      "10 Year Parts Only Warranty on Smart Inverter Motor (Suction Motor)",
    ]
  },
]

const warrantyFR = [
  {
    type: "LG Studio",
    items: ["2 ans sur les pièces et la main-d’oeuvre"]
  },
  {
    type: "Laveuses",
    items: [
      "1 an sur les pièces et la main-d’oeuvre",
      "3 ans sur le tambour en acier inoxydable (pièces seulement)",
      "10 ans sur le moteur DirectDrive (pièces seulement)",
    ]
  },
  {
    type: "Sécheuses",
    items: [
      "1 an sur les pièces et la main-d’oeuvre",
      "3 ans sur le tambour de la sécheuse (pièces seulement)",
      "10 ans sur le compresseur à double onduleur (pièces seulement)",
      "10 ans sur le moteur (pièces seulement)",
    ]
  },
  {
    type: "Styler",
    items: [
      "1 an sur les pièces et la main-d’oeuvre",
      "10 ans sur le compresseur à inversion",
    ]
  },
  {
    type: "Réfrigérateurs",
    items: [
      "1 an sur les pièces et la main-d’oeuvre",
      "7 ans sur le système scellé (pièces seulement)*",
      "10 ans sur le compresseur (pièces seulement)*",
      "*Des conditions peuvent s’appliquer selon le modèle,",
    ]
  },
  {
    type: "Cuisinières, surfaces de cuisson, fours muraux",
    items: [
      "1 an sur les pièces et la main-d’oeuvre",
    ]
  },
  {
    type: "Lave-vaisselle",
    items: [
      "1 an sur les pièces et la main-d’oeuvre",
      "2 ans (pièces seulement)",
      "10 ans sur le moteur DirectDrive (pièces seulement)",
    ]
  },
  {
    type: "Fours à micro-ondes (four-hotte)",
    items: [
      "1 an sur les pièces et la main-d’oeuvre",
      "5 ans sur le magnétron (pièce seulement)",
    ]
  },
  {
    type: "Fours à micro-ondes (de comptoir)",
    items: [
      "Garantie de remplacement de 1 an",
      "5 ans sur le magnétron (pièce seulement)",
    ]
  },
  {
    type: "Aspirateurs",
    items: [
      "Cord Zero de LG, modèle A906SM",
      "1 an sur les pièces et la main-d’oeuvre",
      "10 ans (pièces seulement) sur le moteur à inversion intelligent",
    ]
  },
]
const WarrantiesPage = () => {

  const { language, translatedText } = useContext(LanguageContext);
  const warranty = language === "EN" ? warrantyEN : warrantyFR;

  return (
    <>
      {/* <PageTemplate> */}

      <PageWrapperTemplate>
        <BodyContainer>
          <HeroContainer
            title={translatedText.warranties}
            backgroundImage={WarrantiesPageBackgroundImage}
            backgroundPosition="bottom"
          />
          <SectionContainer>
            <SectionTextContainer
              subtitleText={translatedText.resources}
              titleText={translatedText.exLimitedWarranties}
              descriptionText={translatedText.describeLimitedWarranties}
            // descriptionText2="From refrigerators to dishwashers and more, you can confidently present the specific warranty details to customers to build trust and loyalty, knowing that LG Home Appliances are protected and reliable."
            />
          </SectionContainer>
          {/* <TextBox
           subtitle="Resources"
           title="Explore Limited Warranties"
           bodyText=" We understand the significance of providing customers with peace of mind, which is why we offer tailored warranties for each product category, covering both parts and labour. From refrigerators to dishwashers and more, you can confidently present the specific warranty details to customers to build trust and loyalty, knowing that LG Home Appliances are protected and reliable."
         />  */}

          {/* <BodyContainer> */}
          <div className={styles.warrantyContainer}>
            {
              warranty.map((e, idx) => {
                return (
                  <>
                    <div className={styles.warrantyItem}>
                      <div key={idx} >
                        <motion.p className={styles.productTypeText}
                          initial={{ opacity: 0, x: 20 }}
                          animate={{
                            opacity: 1,
                            x: 0,
                            transition: {
                              duration: .3,
                              ease: [0.75, 0, 0, 1],
                            },
                          }}
                          exit={{
                            opacity: 0,
                            x: 50,
                            transition: {
                              duration: .3,
                              ease: [0.75, 0, 0, 1],
                            },
                          }}
                        > {e.type}
                        </motion.p>
                      </div>
                      <ul className={styles.itemList}

                      >
                        {e.items.map((i, idx) => {
                          return (
                            <motion.li key={idx} className={styles.definitionText}
                              initial={{ opacity: 0, x: 20 }}
                              animate={{
                                opacity: 1,
                                x: 0,
                                transition: {
                                  duration: .3,
                                  ease: [0.75, 0, 0, 1],
                                },
                              }}
                              exit={{
                                opacity: 0,
                                x: 50,
                                transition: {
                                  duration: .3,
                                  ease: [0.75, 0, 0, 1],
                                },
                              }}
                            >{i}</motion.li>
                          )
                        })}
                      </ul>
                    </div>
                  </>
                )
              })
            }
          </div>
        </BodyContainer>
      </PageWrapperTemplate>

      {/* </PageTemplate> */}
    </>
  );
};

export default WarrantiesPage;
