import TheTable from "../TableElements/TheTable";
import TheTablePagination from "../TableElements/TheTablePagination";
import TheButton from "../../../Shared/Components/UI/TheButton/TheButton";
import { AdminContext } from "../../../Context/Admin.Context";
import { useContext, useMemo, useState } from "react";

const LGAppTrafficTables = () => {

    const { isFilteredGoogleAnalyticsData } = useContext(AdminContext);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const numberedData = useMemo(() => {
        const sortedData = isFilteredGoogleAnalyticsData?.pageDataMetrics?.sort((a, b) => b.date.localeCompare(a.date)) || [];
        const lgTraffic = sortedData.filter(item =>
            // item.pagePath.includes('/home-depot/product-details/') && !item.pagePath.includes('/home-depot')
            !item.pagePath.includes('/home-depot') && !item.pagePath.includes('/home-depot/')
        ) || [];

// console.log(lgTraffic)
// console.log(sortedData)
        return lgTraffic.map((row, index) => ({
            ...row,
            rowNumber: index + 1,
            screenPageViewsPerSession: Number(row.screenPageViews).toFixed(2),
            averageSessionDuration: (Number(row.averageSessionDuration) / 60).toFixed(2),
            userEngagementDuration: (Number(row.userEngagementDuration) / 60).toFixed(2)
        }));
    }, [isFilteredGoogleAnalyticsData]);
    // Enhance data with row numbers
    // const numberedData = useMemo(() => {
    //     const lgTraffic = isGoogleAnalytics?.pageData?.filter(item =>
    //         !item.pagePath.includes('/home-depot') && !item.pagePath.includes('/home-depot/')
    //     ) || [];

    //     return lgTraffic?.map((row, index) => ({
    //         ...row,
    //         rowNumber: index + 1  // Adding row number starting from 1
    //     })) || [];
    // }, [isGoogleAnalytics]);


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const componentData = numberedData.slice(indexOfFirstItem, indexOfLastItem);


    // TABLE DATA
    const columns = [
        { key: 'rowNumber', title: '#' },  // 
        { key: 'pagePath', title: 'Page URL' },
        { key: 'activeUsers', title: 'Active users' },
        { key: 'screenPageViews', title: 'Page Views' },
        { key: 'userEngagementDuration', title: 'Time on Page' }
    ];
    while (componentData.length < itemsPerPage) {
        componentData.push({
            rowNumber: '',
            pagePath: '',
            activeUsers: '',
            screenPageViews: '',
            userEngagementDuration: ''
        });
    }

    return (
        <>
            <TheTable columns={columns}
                data={numberedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)} />
            <TheTablePagination
                itemsPerPage={itemsPerPage}
                // tableData={products}
                tableData={numberedData}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
            />
        </>
    )

}

export default LGAppTrafficTables;