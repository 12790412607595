import { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { SFWBuilderContext } from "../../../../Context/BuilderContext";
import { SFWProductsContext } from "../../../../Context/CategoryContext";

import { AuthContext } from "../../../../Context/Auth.Context";
import { LanguageContext } from "../../../../Context/Language.Context";

import { RetailerContext } from "../../../../Context/Retailer.Context";
import { useForm } from "../../../../Hooks/FormHook/form-hook";
import { useHttpClient } from "../../../../Hooks/HttpHook/http-hook";
import { VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH, VALIDATOR_EMAIL } from "../../../../Utils/validators";
import EditModeListUtilitiesBar from "../../Components/ListUtilitiesBar/EditModeListUtilitiesBar";
import EditModeSearchHeader from "../../Components/SearchHeader/EditModeSearchHeader";
import TheButton from "../../../Components/UI/TheButton/TheButton";
import PurchaseProUserContainer from "../../Components/PurchaseProUserContainer/PurchaseProUserContainer";
import PortalForm from "../../../../Portal/PortalComponents/FormElements/PortalForm";
import PortalFormRow from "../../../../Portal/PortalComponents/FormElements/PortalFormRow";

import Input from "../../../Components/UI/FormElements/Input/Input";
import jwt_decode from "jwt-decode";
import EmptyList from "../../Components/EmptyList/EmptyList";
import EditModeSearchResult from "../../Components/SearchResult/EditModeSearchResult";
import Modal from "../../../Components/UI/TheModals/TheModal";
import BodyContainer from "../../../Components/UI/BodyContainer/BodyContainer"
import styles from "../ThePurchaseProPage/ThePurchaseProPage.module.css"

import EditPurchaseProPrintScreen from "../../Components/PrintScreen/EditPurchaseProPrintScreen";
import SectionTextContainer from "../../../Components/UI/SectionTextContainer/SectionTextContainer";
import { logEvent } from "../../../../Utils/google-analytics";


const TheEditModePurchaseProPage = () => {

    const listId = useParams().listId;

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [matchedProducts, setMatchedProducts] = useState([]);
    // const [loadedListForm, setLoadedListForm] = useState()
    const [loadedListProducts, setLoadedListProducts] = useState()
    const [userInfo, setUserInfo] = useState();

    const redirect = useNavigate();

    const { sendRequest } = useHttpClient();
    const { translatedText } = useContext(LanguageContext);

    const { token } = useContext(AuthContext);
    const decodedToken = token ? jwt_decode(token) : null;

    const { isHomeDepotApp } = useContext(RetailerContext);
    const { filteredProducts } = useContext(SFWProductsContext)

    const {
        setLoadedListForm, //NEW 
        loadedListForm, // NEW
        removeAllItemsFromSavedList,
        setInitialSavedList,
        initialSavedList,
    } = useContext(SFWBuilderContext)

    const [formState, inputHandler, setFormData] = useForm({
        listName: {
            value: '',
            isValid: false
        },
        listEmail: {
            value: '',
            isValid: false
        },
        listPrice: {
            value: '',
            isValid: false
        },
        listNotes: {
            value: '',
            isValid: false
        },
    }, false
    )
    const defaultModal = {
        show: false,
        title: '',
        message: '',
        errorList: [],
        actionText: 'Close',
        wasSuccessful: false,
        onConfirm: null,
        onCancel: null,
        onClose: null,
        cancelText: '',
        confirmText: '',
    }
    const [modalInfo, setModalInfo] = useState(defaultModal);

    useEffect(() => {
        const fetchRequest = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-list/${listId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                })
                if (!response) {
                    // console.log({ "failed fetch response": response })
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                // const data = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/user-list/${listId}`)
                setLoadedListForm(data.userLists)

                setLoadedListProducts(data.userLists.builderList)

                setInitialSavedList(data.userLists.builderList)
                setUserInfo(data.user)

                setFormData({
                    listName: {
                        value: data.userLists.listName,
                        isValid: true
                    },
                    listEmail: {
                        value: data.userLists.listEmail,
                        isValid: true
                    },
                    // listPrice: {
                    //     value: data.userLists.listPrice,
                    //     isValid: true

                    // },
                    listNotes: {
                        value: data.userLists.listNotes,
                        isValid: true
                    },
                },
                    true
                );
            } catch (err) { }

        }
        fetchRequest();
    }, [sendRequest, listId, setFormData]);
    // console.log(matchedProducts.length)

    // })
    // MATCH PRODUCTS BY ID IN CONTEXT + STORE IN STATE 
    useEffect(() => {
        if (initialSavedList && initialSavedList.length > 0 && filteredProducts.length > 0) { //fil
            const newMatchedProducts = initialSavedList.map(item => {
                const matchedProduct = filteredProducts.find(product => product._id === item._id);
                return matchedProduct;
            });
            setMatchedProducts(newMatchedProducts);
        } else {
            setMatchedProducts([]);
        }
    }, [initialSavedList, filteredProducts]);

    // console.log(matchedProducts)

    const requireUpdateHandler = () => {
        // e.preventDefault();

        const hasChanges = (
            formState.inputs.listName.value !== loadedListForm.listName ||
            formState.inputs.listEmail.value !== loadedListForm.listEmail ||
            formState.inputs.listNotes.value !== loadedListForm.listNotes ||
            loadedListProducts !== initialSavedList
        )
        // console.log({ loadedListProducts, loadedListForm })

        if (!hasChanges) {
            setModalInfo({
                show: true,
                title: 'Nothing to update',
                message: 'Your profile information has not been changed. There is nothing to update.',
                cancelText: "Close",
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
                onCancel: () => setModalInfo({ ...modalInfo, show: false }),
            });
            return;
        } else {
            setModalInfo({
                show: true,
                title: 'Confirm update',
                message: 'You are about to edit the information in your saved Product List. Please confirm to proceed.',
                cancelText: "Close",
                confirmText: "Update",
                onConfirm: listUpdateHandler,
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
                onCancel: () => setModalInfo({ ...modalInfo, show: false }),
            });
            logEvent('User Interaction', 'Click_Update', 'Update Button Click');
            // listUpdateHandler();
        }

    }


    const listUpdateHandler = async (event) => {
        // console.log('click upate')
        // event.preventDefault();



        try {
            const response = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/edit-list/${listId}`,
                'PATCH',
                JSON.stringify({
                    listName: formState.inputs.listName.value,
                    listEmail: formState.inputs.listEmail.value,
                    listNotes: formState.inputs.listNotes.value,
                    //   listPrice: formState.inputs.listPrice.value,
                    // builderList: loadedListProducts
                    builderList: initialSavedList
                }),

                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }

            );
            if (response.message === "User list") {
                setModalInfo({
                    show: true,
                    wasSuccessful: true,
                    title: 'List updated',
                    message: 'Your list has been updated successfully!',
                    errorList: [],
                    confirmText: 'View all',
                    cancelText: 'Go back',



                    onConfirm: () => redirect(`${isHomeDepotApp.isHomeDepotPrefix}/user-lists/${decodedToken._userId}`),
                    onCancel: () => setModalInfo({ ...modalInfo, show: false }),
                    onClose: () => setModalInfo({ ...modalInfo, show: false }),
                });
                logEvent('User Interaction', 'Click_Update_Complete', 'Update Complete Button Click');

            }
            // redirect(`${homeDepotPrefix}/user-lists/${decodedToken._userId}`)

        } catch (err) { }

    };
    return (
        <>
            <span className={styles.printBlock}>
                <EditModeSearchHeader />
                <EditModeListUtilitiesBar
                    // handleListUpdate={listUpdateHandler} 
                    // handleListUpdateFromParent={listUpdateHandler}
                    handleListUpdateFromParent={requireUpdateHandler}
                >
                    <PurchaseProUserContainer
                        firstName={userInfo?.firstName}
                        lastName={userInfo?.lastName}
                        retailer={userInfo?.store}
                    />

                </EditModeListUtilitiesBar>
                <BodyContainer>

                    {
                        loadedListForm &&
                    
                        <form
                        className={styles.purchaseProFormContainer}
                        onSubmit={requireUpdateHandler}
                        >
                            <p className={styles.subtitleText}>Details</p>
                            <p className={styles.titleText}>Add or remove details</p>
                            
                            {/* <PortalForm
                                title="Update details"
                                subtitle="List Details"
                                // onSubmit={listUpdateHandler}
                                onSubmit={requireUpdateHandler}
                            > */}
                                <PortalFormRow>
                                    <Input
                                        id="listName"
                                        type="text"
                                        element="input"
                                        label="List Name"
                                        validators={[VALIDATOR_REQUIRE]}
                                        errorText="Please enter a list name"
                                        onInput={inputHandler}
                                        initialValue={loadedListForm?.listName}
                                        initialIsValid={true}
                                    />
                                    <Input
                                        id="listEmail"
                                        type="email"
                                        element="input"
                                        label="List Email"
                                        validators={[VALIDATOR_EMAIL()]}
                                        errorText="Please enter a valid email"
                                        onInput={inputHandler}
                                        initialValue={loadedListForm?.listEmail}
                                        initialIsValid={true}
                                    />
                                </PortalFormRow>
                                <PortalFormRow>
                                    {/* <Input
                                        id="listEmail"
                                        type="email"
                                        element="input"
                                        label="List Email"
                                        validators={[VALIDATOR_EMAIL()]}
                                        errorText="Please enter a valid email"
                                        onInput={inputHandler}
                                        initialValue={loadedListForm?.listEmail}
                                        initialIsValid={true}
                                    /> */}
                                </PortalFormRow>
                                <PortalFormRow>
                                    <Input
                                        id="listNotes"
                                        type="number"
                                        element="textarea"
                                        rows="5"
                                        label="List Notes"
                                        validators={[VALIDATOR_MINLENGTH(5)]}
                                        errorText="Notes must be a least 5 characters in length"
                                        onInput={inputHandler}
                                        initialValue={loadedListForm?.listNotes}
                                        initialIsValid={true}
                                    />
                                </PortalFormRow>

                            {/* </PortalForm> */}
                            </form>


                    }

                    {
                        matchedProducts && matchedProducts.length !== 0 ?
                            (
                                <>
                                    {/* <SectionTextContainer
                                        titleText="Update products"
                                        subtitleText="List products"
                                    /> */}
                                    {/* <TitleIntroContainer
                                        
                                    /> */}
                                    <div>
                                    <p className={styles.subtitleText}>Products</p>
                            <p className={styles.titleText}>Add or remove products</p>
                                    </div>
                                    {matchedProducts.map((product) => {
                                        if (!product) {
                                            return null;
                                        }
                                        return (
                                            <EditModeSearchResult product={product} />
                                        );
                                    })}
                                    {
                                        matchedProducts.filter(product => !product).length > 0 &&
                                        <div>
                                            {`${matchedProducts.filter(product => !product).length} Product(s) no longer available. Clear your list to remove all products.`}
                                        </div>
                                    }

                                </>
                            )
                            : (<EmptyList />)
                    }


                </BodyContainer>
            </span>

            <div className={styles.printScreenContainer}>
                {/* <PurchaseProPrintScreen /> */}
                <EditPurchaseProPrintScreen
                    products={matchedProducts}
                    listDetails={loadedListForm}
                    userInfo={userInfo}
                />
            </div>
            <Modal
                icon="true"
                iconName="close"
                styleName="baseIconButton"
                iconOnClick={modalInfo.onClose}
                show={modalInfo.show}
                title={modalInfo.title}
                message={modalInfo.message}
                errorList={modalInfo.errorList}
                closeOnBackdropClick={false}
                actionText={modalInfo.actionText}
                actionBox={
                    <>
                        {modalInfo.onConfirm && (
                            <span onClick={modalInfo.onConfirm}>
                                <TheButton styleName="red" animated="true">
                                    {modalInfo.confirmText}
                                </TheButton>
                            </span>
                        )}
                        {modalInfo.onCancel && (
                            <span onClick={modalInfo.onCancel}>
                                <TheButton styleName="black" animated="true">
                                    {modalInfo.cancelText}
                                </TheButton>
                            </span>
                        )}
                    </>
                }
            />
            <Modal

                show={showConfirmationModal}
                title={translatedText.areYouSureTitle}
                message={translatedText.clearListMessage}
                onConfirm={() => {
                    removeAllItemsFromSavedList(initialSavedList);
                    setShowConfirmationModal(false);
                }}
                onCancel={() => setShowConfirmationModal(false)}

            />
        </>


    )
}

export default TheEditModePurchaseProPage;

