import styles from "./UserPage.module.css";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../Context/Auth.Context";
import { RoutingContext } from "../../../Context/Routing.Context";
import { RetailerContext } from "../../../Context/Retailer.Context";
import { useHttpClient } from "../../../Hooks/HttpHook/http-hook"
import { useForm } from "../../../Hooks/FormHook/form-hook";
import Input from "../../../Shared/Components/UI/FormElements/Input/Input";
import { VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH } from "../../../Utils/validators";
import { NavLink, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import TheButton from "../../../Shared/Components/UI/TheButton/TheButton";
import Modal from "../../../Shared/Components/UI/TheModals/TheModal";
import PageWrapperTemplate from "../../../Shared/Components/UI/Templates/PageWrapperTemplate"
import BodyContainer from "../../../Shared/Components/UI/BodyContainer/BodyContainer"
import PortalFormRow from "../../PortalComponents/FormElements/PortalFormRow"
const UserPage = () => {
   const redirect = useNavigate();
   const { setIsRoutingState } = useContext(RoutingContext);
   const { isHomeDepotApp } = useContext(RetailerContext);
   const { token, currentUser, logout, setLoggedInUser, loggedInUser } = useContext(AuthContext);
   const decodedToken = token ? jwt_decode(token) : null;
   const { sendRequest } = useHttpClient();
   const [loadedUser, setLoadedUser] = useState();
   const [updateTheUser, setUpdateTheUser] = useState(false);

   const defaultModal = {
      show: false,
      title: '',
      message: '',
      errorList: [],
      actionText: 'Close',
      wasSuccessful: false,
      onConfirm: null,
      onCancel: null,
      onClose: null,
      cancelText: '',
      confirmText: '',
   }
   const [modalInfo, setModalInfo] = useState(defaultModal);

   const [formState, inputHandler, setFormData] = useForm({
      store: {
         value: '',
         isValid: false
      },
      address: {
         value: '',
         isValid: false
      },

      firstName: {
         value: '',
         isValid: false
      },
      lastName: {
         value: '',
         isValid: false
      },
   },
      false
   );

   useEffect(() => {
      setIsRoutingState(prevState => ({
         ...prevState,
         isLoading: true
      }))
      const fetchRequest = async () => {
         // setIsRoutingState(prevState => ({
         //    ...prevState,
         //    isLoading: true
         // }))
         try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-profile/${decodedToken.userId}`, {
               headers: { 'Authorization': `Bearer ${token}` }
            })

            if (!response) {
               setIsRoutingState(prevState => ({
                  ...prevState,
                  isLoading: false
               }))
               setModalInfo({
                  show: true,
                  title: 'Account Error',
                  message: 'There appears to be an error. We could not locate your account. Please contact an administrator.',
                  errorList: [],
                  actionText: 'Exit',
                  onConfirm: handleAccountFetchErrorModal,
                  confirmText: "Close",
                  onClose: handleAccountFetchErrorModal,
               });
               // console.log({ "failed fetch response": response })
               // throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            // console.log(data.user)
            setLoadedUser(data.user)
            setFormData({
               store: {
                  value: data.user.store,
                  isValid: true
               },
               address: {
                  value: data.user.address,
                  isValid: true
               },
               firstName: {
                  value: data.user.firstName,
                  isValid: true
               },
               lastName: {
                  value: data.user.lastName,
                  isValid: true
               },
            },
               true
            );
            setIsRoutingState(prevState => ({
               ...prevState,
               isLoading: false
            }))
         } catch (err) {
            setIsRoutingState(prevState => ({
               ...prevState,
               isLoading: false
            }))
            setModalInfo({
               show: true,
               title: 'Account Error',
               message: 'Something went wrong. Please contact an administrator.',
               errorList: [],
               actionText: 'Exit'
            });
         }
      }
      fetchRequest();
   }, [sendRequest, token, setLoadedUser, setFormData, updateTheUser])


   const handleUpdateMyUserAccont = (event) => {

      event.preventDefault();
      // setIsRoutingState(prevState => ({
      //    ...prevState,
      //    isLoading: true
      // }))
      const hasChanges = (
         formState.inputs.firstName.value !== loadedUser.firstName ||
         formState.inputs.lastName.value !== loadedUser.lastName ||
         formState.inputs.store.value !== loadedUser.store ||
         formState.inputs.address.value !== loadedUser.address
      );

      if (!hasChanges) {
         // setIsRoutingState(prevState => ({
         //    ...prevState,
         //    isLoading: false
         // }))
         setModalInfo({
            show: true,
            title: 'Nothing to update',
            message: 'Your profile information has not been changed. There is nothing to update.',
            cancelText: "Close",
            onClose: () => setModalInfo({ ...modalInfo, show: false }),
            onCancel: () => setModalInfo({ ...modalInfo, show: false }),
         });
         return;
      }
      setModalInfo({
         show: true,
         title: 'Are you sure you want to make changes?',
         message: 'This action will change your profile information. Please confirm.',
         confirmText: "Update",
         onConfirm: userUpdateHandler,
         cancelText: "Cancel",
         onCancel: () => setModalInfo({ ...modalInfo, show: false }),
         onClose: () => setModalInfo({ ...modalInfo, show: false }),
      });
      // setShowConfirmationModal(true);
   };

   const userUpdateHandler = async (e) => {
      e.preventDefault();

      setIsRoutingState(prevState => ({
         ...prevState,
         isLoading: true
      }))
      try {
         const response = await sendRequest(
            // `${process.env.REACT_APP_BACKEND_URL}/edit-profile/${loadedUser._id}`,
            `${process.env.REACT_APP_BACKEND_URL}/edit-profile/${decodedToken.userId}`,
            'PATCH',
            JSON.stringify({
               // username: formState.inputs.username.value,
               firstName: formState.inputs.firstName.value,
               lastName: formState.inputs.lastName.value,
               store: formState.inputs.store.value,
               address: formState.inputs.address.value,
            }),
            {
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${token}`
            });


         if (response.message === "User updated") {

            setIsRoutingState(prevState => ({
               ...prevState,
               isLoading: false
            }))
            setModalInfo({
               show: true,
               title: 'Account updated',
               message: 'Your account has been updated successfully!',
               errorList: [],

               confirmText: 'Close',
               wasSuccessful: true,
               onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
               onClose: () => setModalInfo({ ...modalInfo, show: false }),
            });
            setUpdateTheUser(prevState => !prevState);
         }
         if (!response.message === "User updated") {
            setIsRoutingState(prevState => ({
               ...prevState,
               isLoading: false
            }))
            setModalInfo({
               show: true,
               title: 'Update failed',
               message: 'Your account could not be updated. Please contact an administrator.',
               errorList: [],
               confirmText: 'Try again',
               onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
               onClose: () => setModalInfo({ ...modalInfo, show: false }),
            });
         }
      } catch (err) {
         setIsRoutingState(prevState => ({
            ...prevState,
            isLoading: false
         }))
         setModalInfo({
            show: true,
            title: 'Something went wrong',
            message: 'There appears to problem with the account. Please contact an administrator.',
            errorList: [],
            confirmText: 'Try again',
            onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
            onClose: () => setModalInfo({ ...modalInfo, show: false }),
         });
         // console.log(err)
      }
   }

   const handleDeleteMyUserAccount = () => {
      setModalInfo({
         show: true,
         title: 'Are you sure you want to delete your profile?',
         message: 'This action will delete your profile forever. Please confirm',
         onConfirm: deleteAccount,
         confirmText: "Delete Account",
         onCancel: () => setModalInfo({ ...modalInfo, show: false }),
         onClose: () => setModalInfo({ ...modalInfo, show: false }),
         cancelText: 'Cancel'
      });
   }
   const deleteAccount = async (e) => {
      e.preventDefault();
      setIsRoutingState(prevState => ({
         ...prevState,
         isLoading: true
      }))
      // setShowDeleteAccountConfirmationModal(false);
      try {
         const response = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/delete-user/${decodedToken.userId}`,
            'DELETE',
            JSON.stringify({ adminId: decodedToken.userId, }),
            { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
         );
         if (response.message === "User deleted!") {
            setIsRoutingState(prevState => ({
               ...prevState,
               isLoading: false
            }))

            setModalInfo({
               show: true,
               title: 'Account deleted',
               message: 'Your account has been deleted successfully!',
               errorList: [],
               confirmText: 'Close',
               wasSuccessful: true,
               onConfirm: closeDeleteConfirmationModal,
               onClose: closeDeleteConfirmationModal,
            });

         }

         if (!response.message === "User deleted!") {
            setIsRoutingState(prevState => ({
               ...prevState,
               isLoading: false
            }))
            setModalInfo({
               show: true,
               title: 'Deletion failed',
               message: 'Your account could not be deleted. Please contact an administrator.',
               errorList: [],
               confirmText: 'Try again',
               onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
               onClose: () => setModalInfo({ ...modalInfo, show: false }),
            });
         }

      } catch (err) {
         setIsRoutingState(prevState => ({
            ...prevState,
            isLoading: false
         }))
         setModalInfo({
            show: true,
            title: 'Something went wrong',
            message: 'There appears to problem with the account. Please contact an administrator.',
            errorList: [],
            confirmText: 'Try again',
            onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
            onClose: () => setModalInfo({ ...modalInfo, show: false }),
         });

      }
      setIsRoutingState(prevState => ({
         ...prevState,
         isLoading: false
      }))
      // setAllUsers(prevUsers => prevUsers.filter(allUsers => allUsers._id !== userId));
   };

   const closeDeleteConfirmationModal = () => {
      setModalInfo({ ...modalInfo, show: false });
      setLoggedInUser(null);
      logout(currentUser);
      setUpdateTheUser(prevState => !prevState);
      redirect(`${isHomeDepotApp.isHomeDepotPrefix}/sign-up`);
   }

   const handleAccountFetchErrorModal = () => {
      setModalInfo({ ...modalInfo, show: false });
      if (loggedInUser) {
         setLoggedInUser(null);
         logout(currentUser);
         setUpdateTheUser(prevState => !prevState);
         redirect(`${isHomeDepotApp.isHomeDepotPrefix}/sign-up`);
      } else {
         setModalInfo({ ...modalInfo, show: false });
      }
   }
   return (
      <>
         <Modal
            icon="true"
            iconName="close"
            styleName="lightIconButton"
            iconOnClick={modalInfo.onClose}
            show={modalInfo.show}
            title={modalInfo.title}
            message={modalInfo.message}
            errorList={modalInfo.errorList}
            closeOnBackdropClick={false}
            actionText={modalInfo.actionText}
            actionBox={
               <>
                  {modalInfo.onConfirm && (
                     <span onClick={modalInfo.onConfirm}>
                        <TheButton styleName="black" animated="true">
                           {modalInfo.confirmText}
                        </TheButton>
                     </span>
                  )}
                  {modalInfo.onCancel && (
                     <span onClick={modalInfo.onCancel}>
                        <TheButton styleName="white" animated="true">
                           {modalInfo.cancelText}
                        </TheButton>
                     </span>
                  )}
               </>
            }
         />
       <div className={styles.templateWrapper} >

     
            <BodyContainer>
             
               <div className={styles.headerContainer}>
                  <div className={styles.textBox}>
                     <p className={styles.titleText}>Your Account</p>
                     <p className={styles.subTitleText}>Personal information and contact details</p>
                  </div>
               </div>
               <div className={styles.sectionWrapper}>
                  <div className={styles.textBox}>
                     <div className={styles.textGroup}>
                        <span className={styles.textGroupLabel}>Account Type</span> {
                           decodedToken &&
                              decodedToken?.role === "superAdmin"
                              ? <p className={styles.textGroupInfo}>Super Administrator</p> :
                              decodedToken?.role === "admin"
                                 ? <p className={styles.textGroupInfo}>Administrator</p> :
                                 <p className={styles.textGroupInfo}>User</p>
                        }
                     </div>
                     <div className={styles.textGroup}>
                        <span className={styles.textGroupLabel}>Email</span>
                        <p className={styles.textGroupInfo}>{loadedUser?.email}</p>
                     </div>
                  </div>

               </div>

               {
                  loadedUser &&
                  <form
                     className={styles.userFormContainer}
                     onSubmit={handleUpdateMyUserAccont}>
                    
                        <Input
                           id="firstName"
                           type="text"
                           element="input"
                           label="First Name"
                           validators={[VALIDATOR_MINLENGTH(2)]}
                           errorText="Please enter a valid title of at least 5 characters"
                           onInput={inputHandler}
                           initialValue={loadedUser?.firstName}
                           initialIsValid={true}
                        />
                       
                    
                       
                        <Input
                           id="lastName"
                           type="text"
                           element="input"
                           label="Last Name"
                           validators={[VALIDATOR_MINLENGTH(2)]}
                           errorText="Please enter a valid title of at least 5 characters"
                           onInput={inputHandler}
                           initialValue={loadedUser?.lastName}
                           initialIsValid={true}
                        />
                 
                        <Input
                           id="store"
                           type="text"
                           element="input"
                           label="Store"
                           validators={[VALIDATOR_REQUIRE()]}
                           errorText="Retailer name required."
                           onInput={inputHandler}
                           initialValue={loadedUser?.store}
                           initialIsValid={true}
                        />
                        
                    
                       
                        <Input
                           id="address"
                           type="text"
                           element="input"
                           label="Address"
                           validators={[VALIDATOR_REQUIRE()]}
                           errorText="Address cannot be blank"
                           onInput={inputHandler}
                           initialValue={loadedUser?.address}
                           initialIsValid={true}
                        />
                    
                     <div className={styles.updateProfileButtonBox}>
                        <TheButton
                           styleName="red"
                           animated="true"
                        >
                           Update Profile
                        </TheButton>
                     </div>
                  </form>
               }
               <div className={styles.sectionWrapper}>
                  <div className={styles.textBox}>
                     <p className={styles.sectionTitleText}>Security</p>
                     <p className={styles.sectionSubtitleText}>Update your password and protect your account</p>
                     <div className={styles.buttonBox}>
                     <TheButton 
                     linkText='Reset Password'
                     link="/forgot-password"
                     styleName="white" 
                     animated="true"
                     />
                     </div>
                  </div>
                  <div className={styles.textBox}>
                     <p className={styles.sectionTitleText}>Account</p>
                     <p className={styles.sectionSubtitleText}>Delete Your Product Guide account</p>
                     <div className={styles.buttonBox} onClick={handleDeleteMyUserAccount}>
                     <TheButton 
                    
                     styleName="white" 
                     animated="true"
                     >Delete Account</TheButton>
                     </div>
                  </div>
               </div>
              
               {/* </div>
            </section> */}
            </BodyContainer>
            </div> 
        
      </>
   )
}

export default UserPage;