import styles from './ProductImage.module.css';

const ProductImage = ({product, styleName} ) => {
    const buttonStyles = `${styles.productImageContainer} ${styles[styleName]}`;
    return (
        // <div className={styles.productImageContainer}>
        <div  className={buttonStyles}>
             <img src={`${process.env.REACT_APP_AWS_URL}/` + product.image} alt={product.title} />
           {/* <img src={`${process.env.REACT_APP_BACKEND_URL}/` + product.image} alt={product.subtitleEN} /> */}
        </div>
    )
}

export default ProductImage;