import styles from "./FlashMessageWrapper.module.css"
import TheButton from "../TheButton/TheButton"
import TheIcon from "../TheIcon/TheIcon"
const FlashMessageWrapper = ({
    message,
    onClick,
    buttonIcons
}) => {
    return (
        <div className={styles.flashMessageContainer}>
            <div className={styles.messageTextBox}>
            <span className={styles.messageIconWrapper} onClick={onClick}>
                    {buttonIcons}
                </span>
                <p className={styles.messageText}>{message}</p>
               
            </div>
        </div>

    )
}

export default FlashMessageWrapper