// import HomeDepotLogoSVG from "../../../Theme/Branding/lg-hd-logos.svg"
import HomeDepotLogoSVG from "../../../Theme/Branding/lg-hd-logos.svg"
import styles from "../BrandElement.styles.module.css"


const HomeDepotLogo = () => {
    return(
        <div className={styles.logoContainer}>
            <img
            src={HomeDepotLogoSVG}
            />
        </div>
    )
}

export default HomeDepotLogo;