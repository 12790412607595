import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router";




export const RetailerContext = createContext({

   
   
    isHomeDepotApp: {
        isHomeDepotActive: false,
        isHomeDepotPrefix: null,
    },
    setIsHomeDepotApp: () => { },

   
});

export const RetailerProvider = ({ children }) => {

   
    const initiaHomeDepotState = {
        isHomeDepotActive: false,
        isHomeDepotPrefix: null,
    }
    
    const [isHomeDepotApp, setIsHomeDepotApp] = useState(initiaHomeDepotState);
    const location = useLocation();
    const isHomeDepot = location.pathname.startsWith('/home-depot');
    const homeDepotPrefix = isHomeDepot ? '/home-depot' : '';
    
    
    useEffect(() => {
        // HANDLING HOME DEPOT SWAP
        setIsHomeDepotApp({
            isHomeDepotActive: isHomeDepot,
            isHomeDepotPrefix: homeDepotPrefix
        });

    }, [location, isHomeDepot, homeDepotPrefix])

   

    return (
        <RetailerContext.Provider
            value={{
               
                isHomeDepotApp,
                setIsHomeDepotApp,
            
            }}
        >
            {children}
        </RetailerContext.Provider>
    );
}
