import styles from "./TheTable.module.css"

const TheTable = ({ columns, data }) => {


 const hasData = Array.isArray(data) && data.length > 0;
  return (
    !hasData ? <h1>No Data</h1> :
    <div className={styles.tableWrapper}>
      <table className={styles.table}>
        <thead className={styles.columnHeader}>
          <tr className={styles.tableRows}>
            {columns.map(column => (
              <th key={column.key}>{column.title}</th>
            ))}
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {data.map(row => (
            <tr className={styles.tableRows} key={row.id}>
              {columns.map(column => (
                <td className={styles.tableData} key={column.key}>
                  {column.render ? column.render(row) : row[column.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

    </div>


  )
}

export default TheTable;