import { useContext } from "react";
import { SFWProductsContext } from "../../../Context/CategoryContext";
import GraphContainer from "./DisplayDataElements/Graphs/GraphContainer";
import ProductCategoriesGraph from "./DisplayDataElements/SingleElements/ProductCategoriesGraph";


const ProductInventoryGraphs = () => {
    const { products } = useContext(SFWProductsContext);
    const HDProducts = products.filter(product => product.store === "HD");
    const LGProducts = products.filter(product => product.store === "LG");

    return (

        <GraphContainer>
              <ProductCategoriesGraph
                products={HDProducts}
                chartTitle="The Home Depot"
                chartSubtitle=" Product Categories"
            />
            <ProductCategoriesGraph
                products={LGProducts}
                chartTitle="LG Home Appliances"
                chartSubtitle=" Product Categories"
            />
        </GraphContainer>
        


    )

}

export default ProductInventoryGraphs;