import styles from "./Sidebar.module.css";
import TheIcon from "../../../../Shared/Components/UI/TheIcon/TheIcon";
import Icon from "../../../../Shared/Components/IconElements/Icon";
import { useState, useContext } from "react";
import { AdminContext } from "../../../../Context/Admin.Context";
import { AuthContext } from "../../../../Context/Auth.Context";
import { RoutingContext } from "../../../../Context/Routing.Context";
import { NavLink } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import NavigationLinkBox from "../../../../Shared/Components/UI/NavigationElements/NavigationLinkBox";
import MenuRowToggleBox from "../../../../Shared/Components/UI/NavigationElements/MenuRowToggleBox";
import jwt_decode from "jwt-decode";

const SideBar = () => {

    const [showManageProductLinks, setShowManageProductLinks] = useState(false);
    const [showManageUserLinks, setShowManageUserLinks] = useState(false);
    const [showProductGuideLinks, setShowProductGuideLinks] = useState(false);
    const [showAdministratorLinks, setShowAdministratorLinks] = useState(false);
    const [showAnalyticsLinks, setShowAnalyticsLinks] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);

    const { token } = useContext(AuthContext);
    const decodedToken = token ? jwt_decode(token) : null;
const {setIsRoutingState}=useContext(RoutingContext)
    const { setIsActiveSection, isActiveSection } = useContext(AdminContext)

    const handleShowManageProductLinks = () => {
        setShowManageProductLinks(!showManageProductLinks);
    }

    const handleShowManageUserLinks = () => {
        setShowManageUserLinks(!showManageUserLinks);
    }
    // const handleShowProductGuideLinks = () => {
    //     setShowProductGuideLinks(!showProductGuideLinks);
    // }

    const handleShowAdministratorLinks = () => {
        setShowAdministratorLinks(!showAdministratorLinks);
    }

    const handleShowAnalyticsLinks = () => {
        setShowAnalyticsLinks(!showAnalyticsLinks);
    }

    const handleSectionClick = (sectionNumber) => {
         setShowSidebar(false)
        setIsActiveSection(sectionNumber);
        // console.log('section set to ', sectionNumber)
        setIsRoutingState(prevState =>({...prevState, isNavLinkedClicked:true}))
       
    }

    return (
        <div className={styles.sidebarWrapper}>
            <div className={styles.tabColumn}>
                {showSidebar
                    // ? (<div onClick={() => setShowSidebar(!showSidebar)}><TheIcon name="close" /></div>)
                    // : (<div onClick={() => setShowSidebar(!showSidebar)} ><TheIcon name="caretRight" /></div>)
                    ? (<div onClick={() => setShowSidebar(!showSidebar)}><TheIcon name="close" /></div>)
                    : (<div onClick={() => setShowSidebar(!showSidebar)} ><TheIcon name="rxdash" /></div>)
                }
            </div>
            <AnimatePresence>
                {showSidebar &&
                    <motion.div
                        className={styles.sidebarMenuWrapper}
                        initial={{ opacity: 0, x: 50 }} // Change from y to x
                        animate={{
                            opacity: 1,
                            x: 0, // Change from y to x
                            transition: {
                                duration: .3, // Update duration to 1500ms
                                ease: [0.75, 0, 0, 1], // Custom cubic-bezier easing function
                            },
                        }}
                        exit={{
                            opacity: 0,
                            x: 50, // Change from y to x
                            transition: {
                                duration: .3, // Update duration to 1500ms
                                ease: [0.75, 0, 0, 1], // Custom cubic-bezier easing function for closing
                            },
                        }}
                    >
                        <div className={styles.menuRow} id={styles.menuRowTopBox}>
                            <div  onClick={() => handleSectionClick(1)} className={styles.rowHeaderBox}>
                            <TheIcon name="rxdash" />
                                <p className={styles.rowHeaderTitle}>Dashboard</p>
                            </div>
                        </div>

                        {
                            token && decodedToken?.role === 'superAdmin' ?

                                <div className={styles.menuRow} >
                                    <span className={styles.menuHeaderShade}>
                                        <MenuRowToggleBox
                                        styleName="pillMenuRowToggleBoxContainer"
                                        themeIcon="true"
                                        iconName="admin"
                                            onClick={handleShowAdministratorLinks}
                                            clickText="Admin Tools"
                                            condition={showAdministratorLinks}
                                        />
                                    </span>
                                    {
                                        showAdministratorLinks &&
                                        <div className={styles.rowDropdownBox}>
                                            <p onClick={() => handleSectionClick(8)}>Create Admin</p>
                                            <p onClick={() => handleSectionClick(6)}>All Admins</p>
                                        </div>
                                    }

                                </div>
                                : null
                        }

                        <div className={styles.menuRow} >

                            <MenuRowToggleBox
                             styleName="pillMenuRowToggleBoxContainer"
                                onClick={handleShowManageProductLinks}
                                clickText="Product Tools"
                                condition={showManageProductLinks}
                                themeIcon="true"
                                        iconName="inventory"
                            />
                            {
                                showManageProductLinks &&
                                <div className={styles.rowDropdownBox}>
                                    <p className={isActiveSection === 3 ? styles.active : undefined} onClick={() => handleSectionClick(3)}> Add Product</p>
                                    <p onClick={() => handleSectionClick(2)} >All Products</p>
                                </div>
                            }
                        </div>

                        <div className={styles.menuRow}>
                            <MenuRowToggleBox
                             styleName="pillMenuRowToggleBoxContainer"
                                onClick={handleShowManageUserLinks}
                                clickText="User Tools"
                                condition={showManageUserLinks}
                                themeIcon="true"
                                iconName="users"
                            />

                            {
                                showManageUserLinks &&
                                <div className={styles.rowDropdownBox}>
                                    <p onClick={() => handleSectionClick(5)}>
                                        All Users
                                    </p>
                                </div>
                            }

                        </div>

                        {/* <div className={styles.menuRow}  >
                            <MenuRowToggleBox
                                onClick={handleShowProductGuideLinks}
                                clickText="Other Product Guides"
                                condition={showProductGuideLinks}
                            />
                            {
                                showProductGuideLinks &&
                                <div className={styles.rowDropdownBox}>
                                    <p>
                                        <NavLink to='/home-depot' >
                                            Home Depot
                                        </NavLink>
                                    </p>

                                </div>
                            }
                        </div> */}

                        <div className={styles.menuRow} onClick={handleShowAnalyticsLinks}>
                            <MenuRowToggleBox
                             styleName="pillMenuRowToggleBoxContainer"
                                onClick={handleShowAnalyticsLinks}
                                clickText="Analytics"
                                condition={showAnalyticsLinks}
                                themeIcon="true"
                                iconName="dashboard"
                            />
                            {
                                showAnalyticsLinks &&
                                <div className={styles.rowDropdownBox}>
                                    <p onClick={
                                        
                                        () => handleSectionClick(10)
                                        } >Website Activity</p>
                                </div>
                            }
                        </div>
                    </motion.div>


                }
            </AnimatePresence>

        </div>
    );
}
export default SideBar;