import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useContext, useState } from 'react';
import { SFWBuilderContext } from '../../../../Context/BuilderContext';
import { LanguageContext } from '../../../../Context/Language.Context';
import styles from './SearchResult.module.css'
import Icon from '../../../Components/IconElements/Icon';
import TheIcon from '../../../Components/UI/TheIcon/TheIcon';
import { motion } from 'framer-motion';
const EditModeSearchResult = ({ product }) => {
    const [showSpecColumn, setShowSpecColumn] = useState({});

    // if(title){
    //      const { title } = product;
    // }
   
    const { language } = useContext(LanguageContext);

    // const availabilityText = language === "EN" ? product.availabilityEN : product.availabilityFR;
    
    // const subtitleText = language === "EN" ? product.subtitleEN : product.subtitleFR;
    // const subtitleText = language === "EN" ? product.subtitleEN : product.subtitleFR;

    // const specListGroup1 = language === "EN" ? product.specList1EN : product.specList1FR;
    // const specTitle1 = language === "EN" ? product.specTitle1EN : product.specTitle1FR;
    // const specListGroup2 = language === "EN" ? product.specList2EN : product.specList2FR;
    // const specTitle2 = language === "EN" ? product.specTitle2EN : product.specTitle2FR;
    // const specListGroup3 = language === "EN" ? product.specList3EN : product.specList3FR;
    // const specTitle3 = language === "EN" ? product.specTitle3EN : product.specTitle3FR;
    // const specListGroup4 = language === "EN" ? product.specList4EN : product.specList4FR;
    // const specTitle4 = language === "EN" ? product.specTitle4EN : product.specTitle4FR;

    // const upcCodeText = language === "EN" ? product.upcCodeEN : product.upcCodeFR;
    const subtitleText = language === "EN" ? product?.subtitleEN : product?.subtitleFR;

const specListGroup1 = language === "EN" ? product?.specList1EN : product?.specList1FR;
const specTitle1 = language === "EN" ? product?.specTitle1EN : product?.specTitle1FR;
const specListGroup2 = language === "EN" ? product?.specList2EN : product?.specList2FR;
const specTitle2 = language === "EN" ? product?.specTitle2EN : product?.specTitle2FR;
const specListGroup3 = language === "EN" ? product?.specList3EN : product?.specList3FR;
const specTitle3 = language === "EN" ? product?.specTitle3EN : product?.specTitle3FR;
const specListGroup4 = language === "EN" ? product?.specList4EN : product?.specList4FR;
const specTitle4 = language === "EN" ? product?.specTitle4EN : product?.specTitle4FR;

const upcCodeText = language === "EN" ? product?.upcCodeEN : product?.upcCodeFR;

    const { removeItemFromSavedList } = useContext(SFWBuilderContext)

    return (
        <main className={styles.productContainer}>
            <div className={styles.productWrapper}>
                <section className={classNames(styles.iconBox, styles.itemTrash)}>
                    <span className={styles.circleCloseIcon} onClick={() => removeItemFromSavedList(product)}>
                        <Icon name="close" />
                    </span>
                </section>
                <section className={classNames(styles.productHeaderContainer, styles.itemHead)}>
                    <div className={styles.productTitleBox}>
                        <p className={styles.availabilityText}>{product?.availability}</p>
                        <p className={styles.productTitleText}>{product?.title}</p>
                        <p className={styles.subtitleText}>{subtitleText}</p>
                    </div>
                </section>
                <div className={classNames(styles.productImageContainerAII, styles.itemImage)}>
                    <Link className={styles.linkTagStyles} to={`/product-details/${product?.title}`}>
                        {
                            product &&
                            <img src={`${process.env.REACT_APP_AWS_URL}/` + product.image} alt={product?.subtitle} />
                        }
                    
                        {/* <img src={`${process.env.REACT_APP_BACKEND_URL}/` + product.image} alt={product.subtitle} /> */}
                    </Link>
                </div>
                <div className={styles.itemSpecsBox}>

                    {/* SECTION - SPEC 1 */}
                    {specListGroup1 && specListGroup1.length > 1 && (
                        <section className={classNames(styles.specificationsColoumn, styles.itemspecCol1)}>
                            <motion.div
                                initial={{ opacity: 0, x: 20 }}
                                animate={{
                                    opacity: 1,
                                    x: 0,
                                    transition: {
                                        duration: .3,
                                        ease: [0.75, 0, 0, 1],
                                    },
                                }}
                                exit={{
                                    opacity: 0,
                                    x: 50,
                                    transition: {
                                        duration: .3,
                                        ease: [0.75, 0, 0, 1],
                                    },
                                }}
                                className={styles.specTitleToolTipGroup}
                                >
                                <p className={styles.specListTitleText}>{specTitle1}</p>
                                <span
                                    className={styles.expandIcon}
                                    onClick={() => setShowSpecColumn(prevState => ({
                                        ...prevState,
                                        spec1: !prevState.spec1
                                    }))}>
                                    {showSpecColumn.spec1 ? <TheIcon name="close" /> : <TheIcon name="expand" />}
                                </span>
                            </motion.div>
                            <div className={styles.specListItemBox}>
                                {showSpecColumn.spec1 && specListGroup1.map((item, index) => (
                                    <p className={styles.specListItemText} key={index}>{item}</p>
                                ))}
                            </div>
                        </section>
                    )}
                    {/* SECTION - SPEC 2 */}
                    {specListGroup2 && specListGroup2.length > 1 && (
                        <section className={classNames(styles.specificationsColoumn, styles.itemspecCol2)}>
                            <div className={styles.specTitleToolTipGroup}>
                                <p className={styles.specListTitleText}>{specTitle2}</p>
                                <span
                                    className={styles.expandIcon}
                                    onClick={() => setShowSpecColumn(prevState => ({
                                        ...prevState,
                                        spec2: !prevState.spec2
                                    }))}>
                                    {showSpecColumn.spec2 ? <Icon name="close" /> : <Icon name="expand" />}
                                </span>
                            </div>
                            <div className={styles.specListItemBox}>
                                {showSpecColumn.spec2 && specListGroup2.map((item, index) => (
                                    <p className={styles.specListItemText} key={index}>{item}</p>
                                ))}
                            </div>
                        </section>
                    )}
                    {/* SECTION - SPEC 3 */}
                    {specListGroup3 && specListGroup3.length > 1 && (
                        <section className={classNames(styles.specificationsColoumn, styles.itemspecCol3)}>
                            <div className={styles.specTitleToolTipGroup}>
                                <p className={styles.specListTitleText}>{specTitle3}</p>
                                <span
                                    className={styles.expandIcon}
                                    onClick={() => setShowSpecColumn(prevState => ({
                                        ...prevState,
                                        spec3: !prevState.spec3
                                    }))}>
                                    {showSpecColumn.spec3 ? <Icon name="close" /> : <Icon name="expand" />}
                                </span>
                            </div>
                            <div className={styles.specListItemBox}>
                                {showSpecColumn.spec3 && specListGroup3.map((item, index) => (
                                    <p className={styles.specListItemText} key={index}>{item}</p>
                                ))}
                            </div>
                        </section>
                    )}
                    {/* SECTION - SPEC 4 */}
                    {specListGroup4 && specListGroup4.length > 1 && (
                        <section className={classNames(styles.specificationsColoumn, styles.itemspecCol4)}>
                            <div className={styles.specTitleToolTipGroup}>
                                <p className={styles.specListTitleText}>{specTitle4}</p>
                                <span
                                    className={styles.expandIcon}
                                    onClick={() => setShowSpecColumn(prevState => ({
                                        ...prevState,
                                        spec4: !prevState.spec4
                                    }))}>
                                    {showSpecColumn.spec4 ? <Icon name="close" /> : <Icon name="expand" />}
                                </span>
                            </div>
                            <div className={styles.specListItemBox}>
                                {showSpecColumn.spec4 && specListGroup4.map((item, index) => (
                                    <p className={styles.specListItemText} key={index}>{item}</p>
                                ))}
                            </div>
                        </section>
                    )}
                </div>

            </div>
        </main>
    );
};

export default EditModeSearchResult;
