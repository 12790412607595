import styles from "./TheTablePagination.module.css";

const TheTablePagination = ({
    itemsPerPage,
    tableData,
    setCurrentPage,
    currentPage
}) => {

    if (!Array.isArray(tableData) || tableData.length === 0) {
        return <div>No records available</div>;
    }


    const totalPages = itemsPerPage ? Math.ceil(tableData.length / itemsPerPage) : 0;
    const startingItem = (currentPage - 1) * itemsPerPage + 1;
    const endingItem = Math.min(currentPage * itemsPerPage, tableData.length);

    if (totalPages <= 1) {
        return <div className={styles.pagination}><div className={styles.infoText2}>Showing all {tableData.length} records</div></div>;
    }

    // Function to generate the list of pages to show in the pagination component.
    // const generatePageNumbers = () => {
    //     const range = 3; // Determines how many pages to show around the current page.
    //     let start = Math.max(currentPage - range, 1);
    //     let end = Math.min(currentPage + range, totalPages);

    //     if (currentPage <= range + 1) {
    //         end = start + 2 * range;
    //     } else if (currentPage >= totalPages - range) {
    //         start = end - 2 * range;
    //     }

    //     const pages = [];
    //     for (let i = start; i <= end; i++) {
    //         pages.push(i);
    //     }

    //     return pages;
    // };
    const generatePageNumbers = () => {
        const range = 3;
        let start = Math.max(currentPage - range, 1);
        let end = Math.min(currentPage + range, totalPages);

        if (totalPages <= 2 * range + 1) {
            start = 1;
            end = totalPages;
        } else if (currentPage <= range + 1) {
            end = start + 2 * range;
        } else if (currentPage >= totalPages - range) {
            start = end - 2 * range;
        }

        const pages = [];
        for (let i = start; i <= end; i++) {
            pages.push(i);
        }

        return pages;
    };

    const pageNumbers = generatePageNumbers();

    return (
        <>
            <div className={styles.pagination}>
                <button
                    onClick={() => setCurrentPage(1)}
                    disabled={currentPage === 1}
                >
                    First
                </button>
                <button
                    onClick={() => setCurrentPage(prevPage => prevPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>

                {pageNumbers[0] !== 1 && <>...</>}

                {pageNumbers.map(number => (
                    <span
                        key={number}
                        className={`${styles.pagNum} ${number === currentPage ? styles.activePage : ""}`}
                        onClick={() => setCurrentPage(number)}
                    >
                        {number}
                    </span>
                ))}
                {pageNumbers[pageNumbers.length - 1] !== totalPages && <><p className={styles.pagNum}>...</p></>}
                {/* <p> | Page {currentPage} of {totalPages}</p> */}
                <button
                    onClick={() => setCurrentPage(prevPage => prevPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
                <button
                    onClick={() => setCurrentPage(totalPages)}
                    disabled={currentPage === totalPages}
                >
                    Last
                </button>

            </div>
            <div className={styles.infoText}>
                Showing {startingItem} to {endingItem} of {tableData.length} records
            </div>
        </>

    );
};


export default TheTablePagination;
