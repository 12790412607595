import { useContext } from "react";


import { AdminContext } from "../../../Context/Admin.Context";
import Tile from "./DisplayDataElements/Tiles/Tile"
import TileContainer from "./DisplayDataElements/Tiles/TileContainer";

const LGAppDataTiles = () => {
    const { isFilteredGoogleAnalyticsData } = useContext(AdminContext)

    // Filter data that doesn't include /home-depot in pagePath
    const lgTrafficData = isFilteredGoogleAnalyticsData?.pageDataMetrics?.filter(item =>
        !item.pagePath.includes('/home-depot') && !item.pagePath.includes('/home-depot/')
    ) || [];


    const totalActiveUsers = lgTrafficData.reduce((sum, entry) => sum + parseInt(entry.activeUsers, 10), 0);
    const totalNewUsers = lgTrafficData.reduce((sum, entry) => sum + parseInt(entry.newUsers, 10), 0);
    const totalSessions = lgTrafficData.reduce((sum, entry) => sum + parseInt(entry.sessions, 10), 0);
    const totalScreenPageViews = lgTrafficData.reduce((sum, entry) => sum + parseInt(entry.screenPageViews, 10), 0);
    // const totalUserEngagementTime = lgTrafficData.reduce((sum, entry) => sum + parseInt(entry.userEngagementDuration, 10), 0);
    const totalUserEngagementTimeInSeconds = lgTrafficData.reduce((sum, entry) => sum + parseInt(entry.userEngagementDuration, 10), 0);
    const totalUserEngagementTime = (totalUserEngagementTimeInSeconds / 60).toFixed(2);

    return (
        <TileContainer>
            <Tile
                title="All Visitors"
                data={totalActiveUsers}
            />
            <Tile
                title="New Visitors"
                data={totalNewUsers}
            />
            <Tile
                title="Total Sessions"
                data={totalSessions}
            />
           <Tile
                title="Total Page Screen Views"
                data={totalScreenPageViews}
            />
             <Tile
                title="Total Time on Page (mins)"
                data={totalUserEngagementTime}
            />

</TileContainer>

    )
}

export default LGAppDataTiles;