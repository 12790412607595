// REACT LIBS
import {
  useContext,
  useEffect

} from 'react';
import {
  Routes, Route,
  useLocation
} from 'react-router-dom';
// OTHER 
import jwt_decode from "jwt-decode";

// CONTEXT
import { AuthContext } from './Context/Auth.Context';

// CUSTOM COMPONENTS
import RoutingHeader from './Routes/RoutingHeader/RoutingHeader';

// PAGE COMPONENTS
import HomePage from './Shared/Pages/Home/HomePage';
import DefinitionsPage from './Shared/Pages/Resources/Definitions/DefinitionsPage';
import TransitionsPage from './Shared/Pages/Resources/Transitions/TransitionsPage';
import WarrantiesPage from './Shared/Pages/Resources/Warranties/WarrantiesPage';

import CategoriesPage from './Shared/Pages/ProductGuide/Categories/CategoriesPage';
import CategoryPage from './Shared/Pages/ProductGuide/Category/CategoryPage';
import CategoryComparePage from './Shared/Pages/ProductGuide/Compare/CategoryComparePage';
import DetailsPage from "./Shared/Pages/ProductGuide/ProductDetails/DetailsPage";

// import PurchaseProPage from './Shared/PurchasePro/Pages/PurchaseProPage';
import ThePurchaseProPage from './Shared/PurchasePro/Pages/ThePurchaseProPage/ThePurchaseProPage';

import TheEditModePurchaseProPage from './Shared/PurchasePro/Pages/TheEditPurchaseProPage/TheEditModePurchaseProPage';

import SignInPage from './Shared/Auth/Pages/SigninPage/SignInPage';
import SignUpPage from './Shared/Auth/Pages/SignupPage/SignUpPage';

import ForgotPasswordPage from './Shared/Auth/Pages/ForgotPasswordPage/ForgotPasswordPage';
import PasswordResetPage from './Shared/Auth/Pages/PasswordResetPage/PasswordResetPage';

import PrivacyPage from './Shared/Pages/ProductGuide/Legal/PrivacyPage';
import TermsPage from './Shared/Pages/ProductGuide/Legal/TermsPage';
//VERIFICATION REQUIRED

import UserPage from "./Portal/RegisteredUserPortalPages/UserProfilePage/UserPage";


import UserSavedListsTablePage from './Portal/RegisteredUserPortalPages/UserSavedListsTablePage/UserSavedListsTablePage';
//AUTHENTICATION REQUIRED


//ERRORS PAGE
import NotFoundPage from './Shared/Pages/NotFoundPage/NotFoundPage';
import PortalHomePage from './Portal/PortalPages/Home/PortalHomePage';





const App = () => {

  const { currentUser, token } = useContext(AuthContext)
  const decodedToken = token ? jwt_decode(token) : null;



  const renderRoutes = () => (
    <>
      <Route index element={<HomePage />} />
      <Route path='resources/definitions' element={<DefinitionsPage />} />
      <Route path='resources/transitions' element={<TransitionsPage />} />
      <Route path='resources/warranties' element={<WarrantiesPage />} />

      <Route path='categories' element={<CategoriesPage />} />
      <Route path='categories/:category' element={<CategoryPage />} />
      <Route path='compare/:category' element={<CategoryComparePage />} />
      <Route path='product-details/:productTitle' element={<DetailsPage />} />

      {/* <Route path='purchase-pro' element={<ThePurchaseProPage />} /> */}
      <Route path='product-list' element={<ThePurchaseProPage />} />
      <Route path='sign-in' element={<SignInPage />} />
      <Route path='sign-up' element={<SignUpPage />} />

      <Route path='privacy' element={<PrivacyPage />} />
      <Route path='terms' element={<TermsPage />} />

      <Route path='forgot-password' element={<ForgotPasswordPage />} />
      <Route path='reset/:token' element={<PasswordResetPage />} />

      {/* {currentUser ? ( */}
      {/* {token && currentUser ? ( */}
      {token ? (
        <>
          {(decodedToken && (decodedToken?.role === 'admin' || decodedToken?.role === 'superAdmin')) ? (
            <>
             <Route path='admin' element={<PortalHomePage />} />
              <Route path='user/:userId' element={<UserPage />} />           
              <Route path='user-lists/:userId' element={<UserSavedListsTablePage />} />
              <Route path='edit-list/:listId' element={<TheEditModePurchaseProPage />} />
            </>
          ) : (
            <>
              <Route path='user-lists/:userId' element={<UserSavedListsTablePage />} />
              <Route path='user/:userId' element={<UserPage />} />
              <Route path='edit-list/:listId' element={<TheEditModePurchaseProPage />} />
            </>
          )}
        </>
      ) : (
        // null
        <Route path='sign-up' element={<SignUpPage />} />
      )}
      <Route path="*" element={<NotFoundPage />} />
    </>
  );

  return (
    <Routes>
      <Route path="/" element={<RoutingHeader />}>
        {renderRoutes()}
      </Route>
      <Route path="/home-depot/*" element={<RoutingHeader />}>
        {renderRoutes()}
      </Route>
    </Routes>
  );
};

export default App;


