import styles from "./NoSearchResultsTextContainer.module.css"
import { motion } from "framer-motion";

const inputVariants = {
    initial: { opacity: 0, x: -20 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 20 },
};

const NoSearchResultsTextContainer = () => {
    return (
        <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={inputVariants}
            className={styles.noResultPreviewText}
        >
            No Results!
        </motion.div>
    );
};

export default NoSearchResultsTextContainer;