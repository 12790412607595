/* DONE */

import styles from './UPCCodes.module.css'
import { useContext } from 'react';
import { LanguageContext } from '../../../../../Context/Language.Context';
import BodyContainer from '../../../UI/BodyContainer/BodyContainer';

const UPCCodes = ({ product }) => {

    const { language } = useContext(LanguageContext)

    const upcCodeText = language === "EN" ? product.upcCodeEN : product.upcCodeFR;
    return (
        <div className={styles.upcCodesSectionWrapper}>
            <BodyContainer>
                <section className={styles.upcCodesContainer}>
                    <div className={styles.titleBox}>
                        <p className={styles.titleText}>UPC Codes</p>
                    </div>
                    <div className={styles.upcCodeWrapper}>
                        {
                            upcCodeText.map((selectedUpcCode, index) => {
                                return (

                                    <p key={index} className={styles.upcCodeText}>{selectedUpcCode}</p>

                                );
                            })
                        }
                    </div>

                </section>
            </BodyContainer>
        </div>
    );
};

export default UPCCodes;