import styles from "./PageWrapperTemplate.module.css"
const PageWrapperTemplate = ({ children }) => {
    // return<div className={styles.pageWrapperTemplateContainer}>{children}</div>
    return (
        <>

            <div className={styles.pageWrapperTemplateContainer}>
                <div className={styles.gutter1} />
                <div className={styles.pageWrapperContent}>
                    {children}
                </div>
                <div className={styles.gutter1} />
            </div>

        </>
    )
}

export default PageWrapperTemplate;