// import Button from '../../Buttons/Buttons';
import TheButton from '../../UI/TheButton/TheButton';
import styles from './ConfirmationModal.module.css';

const ConfirmationModal = ({ show, title, message, confirmText, cancelText, onConfirm, onCancel }) => {
  if (!show) {
    return null;
  }

  return (

    <div className={styles['confirmation-modal-container']}>
      <div className={styles['confirmation-modal']}>
        <header className={styles.header}>
          <h2>{title}</h2>

        </header>
        <div className={styles.content}>
          <p>{message}</p>
        </div>
        <footer className={styles.actions}>
          <span
           onClick={onConfirm}
          >
            <TheButton>
            Confirm
            </TheButton>
          </span>
          {/* <Button
            onClick={onConfirm}
            styleName="black"
          >
            Confirm

          </Button> */}
           <span
           onClick={onCancel}
          >
            <TheButton>
            Cancel
            </TheButton>
          </span>
          {/* <Button
            onClick={onCancel}
            styleName="white"
          >
            Cancel

          </Button> */}
          {/* <button className={`${styles['confirm-btn']}`} onClick={onConfirm}>
            {confirmText}
          </button>
          <button className={`${styles['cancel-btn']}`} onClick={onCancel}>
            {cancelText}
          </button> */}
        </footer>
      </div>
    </div>
  );
};

export default ConfirmationModal;