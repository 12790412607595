import styles from './EmptyList.module.css';
import { useContext } from 'react';
import LookAroundGif from "../../../Theme/Gifs/ha-look.gif";
import { motion } from 'framer-motion';
import { LanguageContext } from '../../../../Context/Language.Context';
import { useScrollFadeIn } from '../../../../Hooks/AnimationHook/UseAnimiationHook';
import BodyContainer from "../../../Components/UI/BodyContainer/BodyContainer"
import PageWrapperTemplate from '../../../Components/UI/Templates/PageWrapperTemplate';


const EmptyList = () => {

    const { translatedText } = useContext(LanguageContext)


    const [cardRef1, cardInView1] = useScrollFadeIn({
        threshold: 0.2,
        triggerOnce: false,
    });

    const [cardRef2, cardInView2] = useScrollFadeIn({
        threshold: 0.2,
        triggerOnce: false,
    });

    return (

        <div className={styles.containerWrapper}>
            <BodyContainer>


                <div className={styles.container}>
                    {/* <div className={styles.imageWrapper}>
                <img src={LookAroundGif} />
            </div> */}
                    <div className={styles.textBox}>
                        <motion.div

                            className={styles.imageWrapper}
                        >
                            <img
                                src={LookAroundGif} />
                        </motion.div>
                        <motion.p
                            className={styles.titleText}
                            ref={cardRef1}
                            animate={{ opacity: cardInView1 ? 1 : 0, y: cardInView1 ? 0 : 50 }}
                            transition={{ duration: 0.5 }}
                        >
                            {translatedText.yourProductListIsEmpty}
                            {/* Your product list is empty */}
                        </motion.p>

                        <motion.p
                            className={styles.bodyText}
                            ref={cardRef2}
                            animate={{ opacity: cardInView2 ? 1 : 0, y: cardInView2 ? 0 : 50 }}
                            transition={{ duration: 0.5 }}
                        >
                            Use Product List Search to find and add products

                        </motion.p>


                    </div>


                </div>

            </BodyContainer>
        </div>

    )
}

export default EmptyList;