import styles from "./GraphContainer.module.css"

const  GraphContainer = ({ children, dataGraphRowTitle, dataGraphRowSubtitle }) => {
    return (
        <div className={styles.dataGraphRowContainer}>
            <p className={styles.dataGraphRowTitle}>{dataGraphRowTitle}</p>
            <p className={styles.dataGraphRowSubtitle}>{dataGraphRowSubtitle}</p>
            <div className={styles.dataGraphRowWrapper}>
                {children}
            </div>
        </div>
    )
}

export default GraphContainer;