import SearchPreview from "./SearchPreview"
import NoSearchResultsTextContainer from "./NoSearchResultsTextContainer"
import styles from "./SearchHeaderPreviewContainer.module.css"


const SearchHeaderPreviewContainer = ({ show, results }) => {
    return (
        <div className={styles.searchHeaderPreviewWrapper}>
            {
                show &&
                (
                    results?.length > 0 ?
                        (<SearchPreview products={results} />)
                        : results?.length === 0 ?
                            (<NoSearchResultsTextContainer />)
                            : null
                )
            }
        </div>
    );
};

export default SearchHeaderPreviewContainer