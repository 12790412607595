import { useContext } from 'react';
import { LanguageContext } from '../../../../../Context/Language.Context';
import GoogleLogo from "../../../../Theme/Product/TechLogos/google-en.svg"


import AmazonLogo from '../../../../Theme/Product/TechLogos/amazon-en.svg'
import AsthmaLogo from '../../../../Theme/Product/TechLogos/asthma-en.svg'
import NSFLogo from '../../../../Theme/Product/TechLogos/nsf-en.svg'
import ThinQLogo from '../../../../Theme/Product/TechLogos/thinq-en.svg'
import EnergyStarLogo from '../../../../Theme/Product/TechLogos/energystar-en.svg'
import InstaViewLogo from '../../../../Theme/Product/TechLogos/instaview-en.svg'
import WashTowerLogo from '../../../../Theme/Product/TechLogos/washtower-en.svg'
import DiDCraftIceLogo from '../../../../Theme/Product/TechLogos/did-craft-ice-en.svg'
import CounterDepthMaxLogo from '../../../../Theme/Product/TechLogos/CounterDepthMax-en.svg'
import CraftIceLogo from '../../../../Theme/Product/TechLogos/craft-ice-en.svg'

import InnitLogo from '../../../../Theme/Product/TechLogos/innit-logo.png'
import SideChefLogo from '../../../../Theme/Product/TechLogos/sidechef-logo.png'

import QuadWashProLogo from '../../../../Theme/Product/TechLogos/quadwash-en.svg'

import OneHourCompleteWashDryLogo from '../../../../Theme/Product/TechLogos/1-Hour-Complete-Wash-Dry.webp'
import TwoHourWashDryLogo from '../../../../Theme/Product/TechLogos/two-hour-wash-dry.webp';
import ProBakeConvectionLogo from '../../../../Theme/Product/TechLogos/pro-bake-convection.webp';
import LGDynamicHeatDryLogo from '../../../../Theme/Product/TechLogos/lg-dynamic-heat-dry.webp';
import InstaViewMyColorLogo from '../../../../Theme/Product/TechLogos/instaView-MyColor.webp';
import FourWayAgitatorLogo from '../../../../Theme/Product/TechLogos/4-Way-Agitator.webp';
import SixMotionDirectDriveLogo from '../../../../Theme/Product/TechLogos/6Motion-Direct-Drive-Horz.webp';
import RefStandardMaxLogo from '../../../../Theme/Product/TechLogos/REF-Standard-Depth-MAX.webp';
import CanadaReliabilityBadgeLogo from '../../../../Theme/Product/TechLogos/Canada-Reliability-Badge.webp';
import CertifiedAsthmaLogo from '../../../../Theme/Product/TechLogos/Certified-Asthma.webp';
import EasyCleanLogo from '../../../../Theme/Product/TechLogos/EasyClean-Logo.webp';
import QuadWashLogo from '../../../../Theme/Product/TechLogos/QuadWash.webp';

import SlimSpacePlusLogo from '../../../../Theme/Product/TechLogos/SlimSpacePlus-Logo.webp';
import SmartDiagnosisLogo from '../../../../Theme/Product/TechLogos/Smart-Diagnosis.webp';
import  SmartInverterCompressor10YearWarrantyLogo from '../../../../Theme/Product/TechLogos/Smart-Inverter-Compressor-10-Year-Warranty.webp';
import TrueBalanceLogo from '../../../../Theme/Product/TechLogos/TrueBalance.webp';
import TrueSteamLogo from '../../../../Theme/Product/TechLogos/TrueSteam.webp';
import TurboSteamLogo from '../../../../Theme/Product/TechLogos/turbosteam.webp';
import TurboWash3DLogo from '../../../../Theme/Product/TechLogos/TurboWash3D.webp';
import WashComboLogo from '../../../../Theme/Product/TechLogos/WashCombo.webp';


import styles from './FeatureLogos.module.css'
import BodyContainer from '../../../UI/BodyContainer/BodyContainer';

const FeatureLogos = ({ product }) => {
    const { language } = useContext(LanguageContext);

    // const techLogos = language === "EN" ? product.logosEN : product.logosFR;

    return (
        <>
            <BodyContainer>
                <div className={styles.featureLogoSectionWrapper}>
                    <div className={styles.featureLogoContainer}>
                        {/* <div className={styles.titleBox}>
                        <p className={styles.titleText}>Technology</p>
                    </div> */}
                        <div className={styles.logoWrapper}>
                            {/* {techLogos && */}
                            {product.logos &&
                                product.logos.map((selectedLogos, index) => {
                                    let logoImage;
                                    switch (selectedLogos) {
                                        case 'Google':
                                            logoImage = GoogleLogo;
                                            break;
                                        case 'Amazon':
                                            logoImage = AmazonLogo;
                                            break;
                                        // case 'Asmtha':
                                        //     logoImage = AsthmaLogo;
                                        //     break;
                                        case 'Asthma': //asthma was spelled wrong
                                            logoImage = AsthmaLogo;
                                            break;
                                        case 'NSF':
                                            logoImage = NSFLogo;
                                            break;
                                        // case 'EnergyStar':
                                        case 'EnergyStar':
                                            logoImage = EnergyStarLogo;
                                            break;
                                        case 'ThinQ':
                                            logoImage = ThinQLogo;
                                            break;
                                        case 'InstaView':
                                            logoImage = InstaViewLogo;
                                            break;
                                        case 'WashTower':
                                            logoImage = WashTowerLogo;
                                            break;
                                        case 'CraftIce':
                                            logoImage = CraftIceLogo;
                                            break;
                                        case 'DiDCraftIce': //Door in Door with Craft Ice
                                            logoImage = DiDCraftIceLogo;
                                            break;
                                        case 'CounterDepthMax': //new image
                                            logoImage = CounterDepthMaxLogo;
                                            break;
                                        case 'Innit':
                                            logoImage = InnitLogo;
                                            break;
                                        case 'SideChef':
                                            //no svg available - it's PNG
                                            logoImage = SideChefLogo;
                                            break;
                                        case 'QuadWashPro':
                                            logoImage = QuadWashProLogo;
                                            break;
                                        // NEW
                                        case '1HrCompleteWashDry':
                                            logoImage = OneHourCompleteWashDryLogo;
                                            break;

                                        case '2HrWashDry':
                                            logoImage = TwoHourWashDryLogo;
                                            break;
                                        case '4WayAgitator':
                                            logoImage = FourWayAgitatorLogo;
                                            break;

                                        case '6MotionDirectDrive':
                                            logoImage = SixMotionDirectDriveLogo;
                                            break;

                                        case 'RefStandardMax':
                                            logoImage = RefStandardMaxLogo;
                                            break;

                                        case 'InstaViewMyColor':
                                            logoImage = InstaViewMyColorLogo;
                                            break;
                                        case 'CanadaReliabilityBadge':
                                            logoImage = CanadaReliabilityBadgeLogo;
                                            break;

                                        case 'CertifiedAsthma':
                                            logoImage = CertifiedAsthmaLogo;
                                            break;
                                        case 'EasyClean':
                                            logoImage = EasyCleanLogo;
                                            break;
                                        case 'LGDynamicHeatDry':
                                            logoImage = LGDynamicHeatDryLogo;
                                            break;
                                        case 'ProBakeConvection':
                                            logoImage = ProBakeConvectionLogo;
                                            break;
                                        case 'QuadWash':
                                            logoImage = QuadWashLogo;
                                            break;
                                        case 'SlimSpacePlus':
                                            logoImage = SlimSpacePlusLogo;
                                            break;
                                        case 'SmartDiagnosis':
                                            logoImage = SmartDiagnosisLogo;
                                            break;
                                        case 'SmartInverterCompressor10YearWarranty':
                                            logoImage = SmartInverterCompressor10YearWarrantyLogo;
                                            break;
                                        case 'TrueBalance':
                                            logoImage = TrueBalanceLogo;
                                            break;
                                        case 'TrueSteam':
                                            logoImage = TrueSteamLogo;
                                            break;
                                        case 'TurboSteam':
                                            logoImage = TurboSteamLogo;
                                            break;
                                        case 'TurboWash3D':
                                            logoImage = TurboWash3DLogo;
                                            break;
                                        case 'WashCombo':
                                            logoImage = WashComboLogo;
                                            break;
                                        default:
                                            logoImage = null;
                                    }

                                    return (
                                        <div key={index} className={styles.logo}>
                                            <img
                                                src={logoImage}
                                                alt={selectedLogos}
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </BodyContainer>
        </>

    )
}

export default FeatureLogos;