import styles from "./Tile.module.css"

const Tile = ({ title, data }) => {
    return (
        <div className={styles.statBlockContainer}>
            <div className={styles.textBox}>
                <p className={styles.title}>{title}</p>
                <p className={styles.data}>{data}</p>
            </div>
        </div>
    )
}

export default Tile;