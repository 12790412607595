import styles from "./Graph.module.css"
import GraphBars from "./GraphBars";

const Graph = props => {
    const maxValue = props.dataPoints
        ? Math.max(...props.dataPoints.map(dataPoint => dataPoint.value))
        : 0;


    return (
        <div className={styles.chartContainer}>
            <div className={styles.chartTextBox} >
                <p className={styles.chartTitle}>{props.chartTitle}</p>
                <p className={styles.chartSubtitle}>{props.chartSubtitle}</p>
            </div>
            <div className={styles.chart}>

                {props.dataPoints && props.dataPoints.map(dataPoint =>
                    <GraphBars
                        key={dataPoint.label}
                        value={dataPoint.value}
                        maxValue={maxValue}
                        label={dataPoint.label}
                    />

                )}
            </div>
        </div>
    );
}

export default Graph;
