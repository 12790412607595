import { useContext } from "react";
import { NavLink } from "react-router-dom";

import styles from "../FormPage.styles.module.css"


import SignupForm from "../../Forms/SignupForm/SignupForm";
import { LanguageContext } from "../../../../Context/Language.Context";

const SignUpPage = () => {
    const { translatedText } = useContext(LanguageContext)


    return (
      
            <div className={styles.signUpBackgroundImageWrapper}>
                {/* <div className={styles.pageWrapper}> */}
                    <div className={styles.pageContainer}>
                        <div className={styles.formContainer}>
                            <div className={styles.headTextWrapper}>
                                <p className={styles.titleText}>{translatedText.signUp}</p>
                            </div>
                            <div className={styles.formWrapper}>
                                <SignupForm />
                            </div>
                            <div className={styles.footerTextWrapper}>
                                <p className={styles.footerText}>{translatedText.alreadyRegistered}<NavLink to="/sign-in"> {translatedText.signInNow}</NavLink></p>
                            </div>
                        </div>
                    </div>
                </div>
   


    )
}

export default SignUpPage;