import styles from "../FormPage.styles.module.css"
import { useContext } from "react";
import SigninForm from "../../Forms/SigninForm/SigninForm"

import { NavLink } from "react-router-dom";
import { LanguageContext } from "../../../../Context/Language.Context";


const SignInPage = () => {
    const { translatedText } = useContext(LanguageContext)
    return (
        // <PageTemplate>

        <div className={styles.signInBackgroundImageWrapper}>
            {/* <div className={styles.pageWrapper}> */}
            <div className={styles.pageContainer}>
                {/* <BodyContainer> */}
                    <div className={styles.formContainer}>
                        <div className={styles.headTextWrapper}>
                            <p className={styles.titleText}>{translatedText.signIn}</p>
                        </div>
                        <div className={styles.formWrapper}>
                            <SigninForm />
                        </div>
                        <div className={styles.footerTextWrapper}>
                            <p className={styles.footerText}>{translatedText.notRegistered} <NavLink to="/sign-up">{translatedText.signUpNow}</NavLink></p>
                        </div>
                    </div>
                {/* </BodyContainer> */}
            </div>
            {/* </div> */}
        </div>


        // </PageTemplate>



    )
}

export default SignInPage;