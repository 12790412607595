import { useContext } from "react";

import { RetailerContext } from "../../../../Context/Retailer.Context";
import styles from './PurchaseProPrintScreen.module.css'

import GoogleLogo from "../../../Theme/Product/TechLogos/google-en.svg"
import AmazonLogo from "../../../Theme/Product/TechLogos/amazon-en.svg"
import AsthmaLogo from '../../../Theme/Product/TechLogos/asthma-en.svg'
import NSFLogo from '../../../Theme/Product/TechLogos/nsf-en.svg'
import ThinQLogo from '../../../Theme/Product/TechLogos/thinq-en.svg'
import EnergyStarLogo from '../../../Theme/Product/TechLogos/energystar-en.svg'
import InstaViewLogo from '../../../Theme/Product/TechLogos/instaview-en.svg'
import WashTowerLogo from '../../../Theme/Product/TechLogos/washtower-en.svg'

import LGPrintLogo from '../../../Theme/Branding/LG_3D_CMYK.svg'
import HomeDepotPrintLogo from '../../../Theme/Branding/home_depot_logo.svg'

const EditPurchaseProPrintScreen = ({ products, listDetails, userInfo }) => {
   
    
    const { isHomeDepotApp } = useContext(RetailerContext)

    return (
        <>
            <div className={styles.printScreenContainer}>
                <div className={styles.printHeader}>
                    <div className={styles.logoBox}>
                        {
                           isHomeDepotApp.isHomeDepotActive 
                                ?
                                (
                                    <img src={HomeDepotPrintLogo} />
                                )
                                :
                                (
                                    <img src={LGPrintLogo} />
                                )
                        }
                    </div>
                </div>
                <div className={styles.coverPage}>
                    <div className={styles.coverTextBox}>
                        <p className={styles.subtitleText}>LG Home Appliances</p>
                        <p className={styles.coverTitleText}>Product Guide</p>
                    </div>


                    {listDetails && listDetails &&
                        <>
                            <div className={styles.coverSectionTextBox}>
                                {/* <p className={styles.coverSectionSubtitleText}></p> */}
                                <p className={styles.coverSectionTitleText}>Purchase Pro Summary</p>
                            </div>

                            <div className={styles.listDetailsBox}>

                                <div className={styles.listDetailsTextBox}>
                                    <p className={styles.summaryDetailsTitleText}>Sales Support</p>
                                </div>

                                <div classNam={styles.coverDetailsRow}>
                                    <p className={styles.summaryTitleText}>Retail Outlet<span className={styles.summaryData}>{userInfo?.store ? (userInfo?.store) : null}</span></p>
                                    <p className={styles.summaryTitleText}>Location<span className={styles.summaryData}>{userInfo?.address ? (userInfo?.address) : null}</span></p>
                                </div>
                                <div classNam={styles.listDetailsRow}>
                                    <p className={styles.summaryTitleText}>Sales Rep<span className={styles.summaryData}>{userInfo?.firstName} {userInfo?.lastName ? (userInfo?.lastName) : null}</span></p>
                                </div>
                                <div className={styles.listDetailsTextBox}>
                                    <p className={styles.summaryDetailsTitleText}>Purchase Pro Summary</p>
                                </div>

                                <div classNam={styles.listDetailsRow}>
                                    <p className={styles.summaryTitleText}>
                                        List Name
                                        <span className={styles.summaryData}>
                                            {listDetails && listDetails?.listName}
                                        </span>
                                    </p>
                                    <p className={styles.summaryTitleText}>
                                        Products
                                        <span className={styles.summaryData}>
                                            {products && products?.length}
                                        </span>
                                    </p>
                                    <p className={styles.summaryTitleText}>
                                        List notes
                                        <span className={styles.summaryData}>
                                            {listDetails && listDetails?.listNotes}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </>
                    }

                </div>




                <div className={styles.productContainer}>
                    {products &&
                        products.map((e, idx) => {
                            return (
                                <div key={idx}>
                                    <div className={styles.productWrapper}>

                                        <section className={styles.row1Wrapper}>
                                            <div className={styles.imageBox}>
                                                <div className={styles.imageWrapper}>

                                                    <img src={`${process.env.REACT_APP_AWS_URL}/` + e?.image} alt={e?.title} />
                                                    {/* <img src={`${process.env.REACT_APP_BACKEND_URL}/` + e.image} alt={e.subtitle} /> */}
                                                </div>
                                            </div>
                                            <div className={styles.textBox}>
                                                <p className={styles.titleText}>{e?.title}</p>
                                                <p className={styles.subtitleText}>{e?.subtitleEN}</p>
                                                <div className={styles.upcBox}>

                                                    {e?.colourEN.map((u, idx) => {
                                                        return (

                                                            <p className={styles.upcItem} key={idx}>{u}</p>
                                                        )
                                                    })}
                                                    {/* <p className={styles.upcCode}>{e.upcCodeEN}</p> */}
                                                </div>

                                                <section>
                                                    <div className={styles.techLogosWrapper}>

                                                        {
                                                            e?.logos &&
                                                            e.logos.map((includedTechLogos, idx) => {
                                                                let logoImage;
                                                                switch (includedTechLogos) {
                                                                    case 'Google':
                                                                        logoImage = GoogleLogo;
                                                                        break;
                                                                    case 'Amazon':
                                                                        logoImage = AmazonLogo;
                                                                        break;
                                                                    case 'Asthma':
                                                                        logoImage = AsthmaLogo;
                                                                        break;
                                                                    case 'NSF':
                                                                        logoImage = NSFLogo;
                                                                        break;
                                                                    case 'EnergyStar':
                                                                        // case 'Energy Star':
                                                                        logoImage = EnergyStarLogo;
                                                                        break;
                                                                    case 'ThinQ':
                                                                        logoImage = ThinQLogo;
                                                                        break;
                                                                    case 'InstaView':
                                                                        logoImage = InstaViewLogo;
                                                                        break;
                                                                    case 'WashTower':
                                                                        logoImage = WashTowerLogo;
                                                                        break;

                                                                    // default:
                                                                    //     logoImage = null;
                                                                }
                                                                return (

                                                                    <div className={styles.techLogosBox}>
                                                                        <img key={idx} src={logoImage} alt={e.logosEN} />
                                                                    </div>

                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </section>
                                            </div>


                                        </section>

                                        <section className={styles.row2Wrapper}>
                                            <div className={styles.specWrapper}>
                                                {
                                                    e?.specList1EN &&
                                                    <div className={styles.specListCol}>
                                                        <div className={styles.specTitleBox}>
                                                            <p className={styles.specTitle}>{e?.specTitle1EN}</p>
                                                        </div>
                                                        <div className={styles.specListBox}>
                                                            {
                                                                e?.specList1EN.map((i, idx) => {
                                                                    return (
                                                                        <p key={idx} className={styles.specListItem}>
                                                                            {i}
                                                                        </p>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    e?.specList2EN &&
                                                    <div className={styles.specListCol}>
                                                        <div className={styles.specTitleBox}>
                                                            <p className={styles.specTitle}>{e?.specTitle2EN}</p>
                                                        </div>
                                                        <div className={styles.specListBox}>
                                                            {
                                                                e?.specList2EN.map((i) => {
                                                                    return (
                                                                        <p key={idx} className={styles.specListItem}>
                                                                            {i}
                                                                        </p>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    e?.specList3EN &&
                                                    <div className={styles.specListCol}>
                                                        <div className={styles.specTitleBox}>
                                                            <p className={styles.specTitle}>{e?.specTitle3EN}</p>
                                                        </div>
                                                        <div className={styles.specListBox}>
                                                            {
                                                                e?.specList3EN.map((i) => {
                                                                    return (
                                                                        <p key={idx} className={styles.specListItem}>
                                                                            {i}
                                                                        </p>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    e?.specList4EN &&
                                                    <div className={styles.specListCol}>
                                                        <div className={styles.specTitleBox}>
                                                            <p className={styles.specTitle}>{e?.specTitle4EN}</p>
                                                        </div>
                                                        <div className={styles.specListBox}>
                                                            {
                                                                e?.specList4EN.map((i) => {
                                                                    return (
                                                                        <p key={idx} className={styles.specListItem}>
                                                                            {i}
                                                                        </p>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                        </section>


                                    </div>
                                </div>
                            )
                        })
                    }

                </div>

            </div>
        </>
    )
}

export default EditPurchaseProPrintScreen;