import { motion } from 'framer-motion';
import styles from './SelectInput.module.css';
import Select from 'react-select'

//ANIMATION
const inputVariants = {
  initial: { opacity: 0, x: -20 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 20 },
};

const SelectInput = ({ 
  id, 
  name, 
  value, 
  onChange, 
  options, 
  multiple, 
  labelName, 
  requireAsterix
 }) => (
  <motion.div className={styles.selectRow}
    initial="initial"
    animate="animate"
    exit="exit"
    variants={inputVariants}
  >
    <label className={styles.selectLabel}>
      {labelName} {requireAsterix ? <span className={styles.requiredAsterix}>*</span> : ''}
    </label>
   
    <select className={styles.selectInput} id={id} multiple={multiple} name={name} value={value} onChange={onChange}>
      {options.map((option) => (
        <option className={styles.selectOption} key={option.value} value={option.value} disabled={option.disabled}>
          {option.label}
        </option>
      ))}
    </select>
  </motion.div>
);

export default SelectInput;
