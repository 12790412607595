import styles from "./TheUtilsContainer.module.css"
import { useContext } from "react";
import { AuthContext } from "../../../../Context/Auth.Context";
import { LanguageContext } from "../../../../Context/Language.Context";
import BodyContainer from "../../../Components/UI/BodyContainer/BodyContainer"

const TheUtilsContainer = ({
    title,
    subtitle,
    children,
    count,
    authButtons,
    generalButtons,
}) => {
    const { translatedText } = useContext(LanguageContext)
    const { token } = useContext(AuthContext);

    return (
        <>
           
                <div className={styles.utilityBarContainerWrapper}>
                     <BodyContainer>
                    <section className={styles.utilityBarContainer}>
                        <header className={styles.utilityBarHeader}>
                            <div className={styles.textBox}>
                                <p className={styles.subtitleText}>
                                    {/* {title}  */}
                                    {translatedText.productHeader}
                                    {count ? (<span className={styles.countText}>{count}</span>) : null}
                                </p>
                                <div className={styles.titleTextCountWrapper}>
                                    <h1 className={styles.titleText}>{subtitle}</h1>

                                </div>
                            </div>
                            <div className={styles.buttonsWrapper}>
                                {authButtons && count && token ? authButtons : null}
                                {count && count > 0 ? generalButtons : null}

                            </div>
                        </header>
                        {children ? (<div>{children}</div>) : null}
                    </section>
                      </BodyContainer>
                </div>
          
        </>
    )
}

export default TheUtilsContainer;