import { useContext, useState } from "react";
import { useNavigate, Link } from 'react-router-dom';

import { LanguageContext } from "../../../../Context/Language.Context";
import { AuthContext } from "../../../../Context/Auth.Context";
import { RetailerContext } from "../../../../Context/Retailer.Context";
import { RoutingContext } from "../../../../Context/Routing.Context";
// import { AnalyticsContext } from "../../../../Context/AnalyticsContext";
import { useForm } from '../../../../Hooks/FormHook/form-hook';
import Input from '../../../Components/UI/FormElements/Input/Input';
// import Loader from "../../../Components/Loaders/Loader"
import styles from "../Form.styles.module.css"

import {
  faEye,
  faEyeSlash,
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";

import { VALIDATOR_EMAIL, VALIDATOR_REQUIRE } from '../../../../Utils/validators';
import Modal from "../../../Components/UI/TheModals/TheModal";
import TheButton from "../../../Components/UI/TheButton/TheButton";

const SigninForm = () => {
  const redirect = useNavigate();

  const SFWUser = useContext(AuthContext);

  // const { setUserData, initializeUserData, } = useContext(AnalyticsContext);


  const {isHomeDepotApp} = useContext(RetailerContext);
  const {isRoutingState, setIsRoutingState} = useContext(RoutingContext);
  const { translatedText } = useContext(LanguageContext);


  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  //PASSWORD UI
  const [passwordInputType, setPasswordInputType] = useState("password");
  const togglePasswordVisibility = () => {
    setPasswordInputType((prevState) =>
      prevState === "password" ? "text" : "password"
    );
  };

  const [formState, inputHandler] = useForm({
    email: {
      value: '',
      isValid: false,
    },
    password: {
      value: '',
      isValid: false,
    },
  }, false);

  const signInSubmitHandler = async (event) => {
    event.preventDefault()
    // setIsLoading(true)
    setIsRoutingState(prevState=>({
      ...prevState,
      isLoading:true
    }))

    let errorMessage = [];
    if (!formState.inputs.email.value) errorMessage.push(translatedText.emailMissing);
    if (!formState.inputs.password.value) errorMessage.push('Password missing or invalid.');

    if (!formState.isValid) {
      // setIsLoading(false);
      setIsRoutingState(prevState=>({
        ...prevState,
        isLoading:false
      }))
      setShowModal(true);
      setError(
        {
          title: 'Sign in Failed',
          message: translatedText.emailPasswordMissing
        }
      )
      return;
    }

    try {

      const emailToLowercase = formState.inputs.email.value.toLowerCase();

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/signin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: emailToLowercase,
          password: formState.inputs.password.value,
        })
      })


      if (response.ok) {
        const theUser = await response.json();
        const expirationTime = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
        // const expirationTime = new Date(new Date().getTime() + 1000 * 60 * 60); 1hr
        // const expirationTime = new Date(new Date().getTime() + 1000 * 60); 1 min
        SFWUser.login(theUser.token, expirationTime.toISOString());
        // setUserData(initializeUserData(theUser.trackingData));
        setIsRoutingState(prevState=>({
          ...prevState,
          isLoading:false
        }))
        // setIsLoading(false);
        redirect(`${isHomeDepotApp.isHomeDepotPrefix}/`);
      } else {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          const errorData = await response.json();

          let message = 'An error occurred.';

          switch (response.status) {
            case 401:
              message = "The email or password is incorrect.";
              break;
            case 404:
              message = "Something went wrong. Please contact the administrator.";
              break;
            case 422:
              message = "Invalid credentials.";
              break;
            case 500:
              message = 'Server error. Please try again later.';
              break;
            default:
              message = errorData.message || 'Something went wrong.';
              break;
          }
       
          throw new Error(message);
        } else {
          // If not JSON, then likely HTML or text was returned, which means an unexpected error.
         
          throw new Error("Unexpected server response. Please contact the administrator.");
        }
      }
    } catch (err) {
   
      // setIsLoading(false);
      setIsRoutingState(prevState=>({
        ...prevState,
        isLoading:false
      }))

      let errorMessage = err.message;
  
      if (err.message === "Failed to fetch") {
        errorMessage = "There was a problem reaching the server. Please check your internet connection and try again.";
      }

      setError({
        title: 'Sign In Error',  // You can adjust this title based on your needs.
        message: errorMessage
      });
      setShowModal(true);
    } finally {
      setIsRoutingState(prevState=>({
        ...prevState,
        isLoading:false
      }))
      // setIsLoading(false);
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>

      {/* {isLoading && <Loader />} */}
      <Modal
        icon
        buttonIcon
        iconName="close"
        styleName="lightIconButton"
        iconOnClick={closeModal}
        show={showModal}
        // title="Something went wrong!"
        title={error?.title}
        message={error?.message}
        closeOnBackdropClick={false}
        actionBox={
          <>
         
            <span onClick={closeModal}>
              <TheButton styleName="black" animated="true">
                Try Again
              </TheButton>
            </span>

          </>
        }
      />

      <form
        onSubmit={signInSubmitHandler}
        className={styles.formContainer}
      >
        <Input
          // labelColor="#4A4946"
          id="email"
          element="input"
          type="email"
          label="Email"
          validators={[VALIDATOR_EMAIL()]}
          errorText="Please enter a valid email address."
          onInput={inputHandler}
          icon={faEnvelope}
          // iconColor="gray"
        />
        <Input
          // labelColor="#262626"
          // labelColor="#4A4946"
          id="password"
          element="input"
          // type="password"
          type={passwordInputType}
          label={translatedText.password}
          validators={[VALIDATOR_REQUIRE()]}
          errorText="A password is required."
          onInput={inputHandler}
          icon={passwordInputType === "password" ? faEyeSlash : faEye}
          // iconColor="gray"
          onIconClick={togglePasswordVisibility}
        />
        <div className={styles.passwordResetBox}>
          <Link to={`${isHomeDepotApp.isHomeDepotPrefix}/forgot-password`}>
            <span className={styles.passwordResetText}>
              {translatedText.resetPassword}
            </span>
          </Link>
        </div>
        <div className={styles.buttonBox}>

          <TheButton
            styleName="red"
            animated='true'
          >
            {translatedText.signIn}
          </TheButton>
        </div>
      </form>
    </>
  )
}

export default SigninForm;