import { useContext, useState } from "react"
import { AdminContext } from "../../../Context/Admin.Context"

import DateRangePicker from "../../../Shared/Components/UI/ThePageElements/DatePicker"


import LGAppDataTiles from "../../PortalComponents/DisplayData/LGAppTrafficDataTiles";
import LGAppTrafficTable from "../../PortalComponents/DisplayData/LGAppTrafficTable"
import HDAppTrafficTable from "../../PortalComponents/DisplayData/HDAppTrafficTable"


import HDAppTrafficDataTiles from "../../PortalComponents/DisplayData/HDAppTrafficDataTiles";
import SavedListsDataTiles from "../../PortalComponents/DisplayData/SavedListsDataTiles";

import PopularSavedListProductsTable from "../../PortalComponents/DisplayData/PopularSavedListProductsTable";
// import CustomEmailListsDataTiles from "../../PortalComponents/VisualElements/CustomEmailListsDataTiles";
// import CustomPrintListsDataTiles from "../../PortalComponents/VisualElements/CustomPrintListsDataTiles";

// import CustomSearchDataTiles from "../../PortalComponents/VisualElements/CustomSearchDataTiles";

// import WebsiteTrafficTable from "../../PortalComponents/VisualElements/WebsiteTrafficTable";
import styles from "./AnalyticsPage.module.css"

import TheTableHeader from "../../PortalComponents/TableElements/TheTableHeader";

import ProductInventoryGraphs from "../../PortalComponents/DisplayData/ProductInventoryGraphs";

const AnalyticsPage = () => {

    const { 
       
        startDate, endDate, setStartDate, setEndDate,
        isFilteredGoogleAnalyticsData,
        isPortalDataState 
    } = useContext(AdminContext);
    
    const [showTable, setShowTable] = useState({
        lgTrafficTable: false,
        hdTrafficTable: false,
        popularSavedListTable:false,
    })

    const showLGTableHandler = () => {
        setShowTable(prevState => ({ ...prevState, lgTrafficTable: !prevState.lgTrafficTable }))
    }
    const showHDTableHandler = () => {
        setShowTable(prevState => ({ ...prevState, hdTrafficTable: !prevState.hdTrafficTable }))
    }
    const showPopulartSavedListTableHandler = () => {
        setShowTable(prevState => ({ ...prevState, popularSavedListTable: !prevState.popularSavedListTable }))
    }
    // const dateHourMinutes = isPortalDataState.userData.map(user => user.dateHourMinute);

    // console.log();
    return (

        <div>
            <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                handleStartDateChange={setStartDate}
                handleEndDateChange={setEndDate}
            />
            <TheTableHeader
                title="Analytics"
                subtitle="Product Guide Overview"
                headline="Statistics and Insights on website traffic and user behaviours"
            />
      
            <section>
                <div className={styles.textBox}>
                    <p className={styles.titleText}>LG Product Guide</p>
                    <div onClick={showLGTableHandler}>SHOW TABLE</div>
                </div>
                <LGAppDataTiles />
                {/* <div onClick={showLGTableHandler}>SHOW TABLE</div> */}
                {showTable.lgTrafficTable && <LGAppTrafficTable />}
            </section>
            <section>
                <div className={styles.textBox}>
                    <p className={styles.titleText}>Home Depot Product Guide</p>
                    <div onClick={showHDTableHandler}>SHOW TABLE</div>
                </div>
                <HDAppTrafficDataTiles/>
              
                {showTable.hdTrafficTable &&  <HDAppTrafficTable />}
            </section>
            <section>
                <div className={styles.textBox}>
                    <p className={styles.titleText}>Product List Engagement</p>
                    <div onClick={showPopulartSavedListTableHandler}>SHOW TABLE</div>
                </div>
                <SavedListsDataTiles />
                {/* <CustomPrintListsDataTiles />
                <CustomEmailListsDataTiles />
                <CustomSearchDataTiles /> */}
               
                {showTable.popularSavedListTable &&<PopularSavedListProductsTable />}
            </section>

            <section>
                <div className={styles.textBox}>
                    <p className={styles.titleText}>Product Inventory</p>
                </div>
                <ProductInventoryGraphs/>
                
            </section>

            
            
            <div className={styles.pageHeaderContainer}></div>

        </div>
    )
}

export default AnalyticsPage;