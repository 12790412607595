export const mapProductsByCategory = (products) => {

    const categories = {};
  
    products.forEach((product) => {
  
      const categoryKey = product.categoryEN; // Use the English category as the key
      if (!categories[categoryKey]) {
        categories[categoryKey] = [product];
      } else {
        categories[categoryKey].push(product);
      }
    });
  
    return categories;
  };
  
  
  