import styles from './SearchHeader.module.css'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useContext, useState, useEffect } from "react";

import { SFWProductsContext } from "../../../../Context/CategoryContext";
import { SFWBuilderContext } from "../../../../Context/BuilderContext";
import { LanguageContext } from '../../../../Context/Language.Context';
import SearchInput from "../SearchInput/SearchInput";
import SearchHeaderTextContainer from './SearchHeaderTextContainer';

import EditModeSearchHeaderPreviewContainer from './EditModeSearchHeaderPreviewContainer';
import SearchHeaderPreviewContainer from './SearchHeaderPreviewContainer';
import EditingModeSearchPreview from './EditingModeSearchPreview';

const EditModeSearchHeader = () => {

    const { filteredProducts } = useContext(SFWProductsContext);
    const { translatedText } = useContext(LanguageContext)
    const { initialSavedList } = useContext(SFWBuilderContext);
    const [searchField, setSearchField] = useState('');
    const [editModeBuilderProducts, setEditModeBuilderProducts] = useState([]);
    const [filteredBuilderProducts, setFilteredBuilderProducts] = useState(editModeBuilderProducts);
    const [showSearchPreview, setShowSearchPreview] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);

    useEffect(() => {
        if (filteredProducts.length > 0) {
            setEditModeBuilderProducts(filteredProducts);
        }
    }, [filteredProducts]);


    useEffect(() => {
        if (searchField.length >= 2) {
            const newfilteredProducts = editModeBuilderProducts.filter((product) => {
                // Exclude the product if it is already in builderListItems.
                if (initialSavedList.some(item => item._id === product._id)) {
                    return false;
                }
                // Only include the product if its title matches the search field.

                return product.title && product.title.toLocaleLowerCase().includes(searchField);
            });
            setFilteredBuilderProducts(newfilteredProducts);
            setShowSearchPreview(true);
        } else {
            setFilteredBuilderProducts([]);
            setShowSearchPreview(false);
        }
    }, [editModeBuilderProducts, searchField, initialSavedList]);

    const onSearchChange = (event) => {
        const searchFieldString = event.target.value.toLocaleLowerCase();
        setSearchField(searchFieldString);
        setIsSearchActive(searchFieldString.length > 0);

    };

    return (
        
        <section className={styles.container}>
            <section className={styles.searchContainer}>
                <SearchHeaderTextContainer
                    title="Product List"
                    subtitle="Start updating your list"
                />
                <SearchInput
                    id="search"
                    icon={isSearchActive ? undefined : faSearch}
                    onChange={onSearchChange}
                />
                <div className={styles.searchHeaderPreviewWrapper}>
                    <EditModeSearchHeaderPreviewContainer
                     show={showSearchPreview}
                     results={filteredBuilderProducts}
                    />
                    {/* <EditingModeSearchPreview
                    products={filteredBuilderProducts}
                    /> */}
                    {/* <SearchHeaderPreviewContainer
                        show={showSearchPreview}
                        results={filteredBuilderProducts}

                    /> */}
                    {/* <EditModeSearchHeaderPreviewContainer
                        show={showSearchPreview}
                        results={filteredBuilderProducts}
                    /> */}

                </div>
            </section>
        </section>

    )
}

export default EditModeSearchHeader;