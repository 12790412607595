import styles from './SearchHeader.module.css'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useContext, useState, useEffect } from "react";
import { SFWProductsContext } from "../../../../Context/CategoryContext";
import { SFWBuilderContext } from "../../../../Context/BuilderContext";
import { LanguageContext } from '../../../../Context/Language.Context';
import SearchInput from "../SearchInput/SearchInput"
import SearchHeaderTextContainer from "./SearchHeaderTextContainer";
import SearchHeaderPreviewContainer from "./SearchHeaderPreviewContainer";

const SearchHeader = () => {
    const { filteredProducts } = useContext(SFWProductsContext);
    const { translatedText } = useContext(LanguageContext);
    const { builderListItems } = useContext(SFWBuilderContext);
    const [searchField, setSearchField] = useState('');
    const [builderProducts, setBuilderProducts] = useState([]);
    const [filteredBuilderProducts, setFilteredBuilderProducts] = useState(builderProducts);
    const [showSearchPreview, setShowSearchPreview] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);
    
    useEffect(() => {
        if (filteredProducts.length > 0) {
            setBuilderProducts(filteredProducts);
        }
    }, [filteredProducts]);

  
    useEffect(() => {
        if (searchField.length >= 2) {
            const newfilteredProducts = builderProducts.filter((product) => {
                // Exclude the product if it is already in builderListItems.
                if (builderListItems.some(item => item.title === product.title)) {
                    return false;
                }
                // Only include the product if its title matches the search field.
                return product.title && product.title.toLocaleLowerCase().includes(searchField);
            });
            setFilteredBuilderProducts(newfilteredProducts);
            setShowSearchPreview(true);
        } else {
            setFilteredBuilderProducts([]);
            setShowSearchPreview(false);
        }
    }, [builderProducts, searchField, builderListItems]);

    const onSearchChange = (event) => {
        const searchFieldString = event.target.value.toLocaleLowerCase();
        setSearchField(searchFieldString);
        setIsSearchActive(searchFieldString.length > 0);

    };

    return (
        <>
            <section className={styles.container}>
                <section className={styles.searchContainer}>
                    <SearchHeaderTextContainer
                        // title="Purchase Pro"
                        subtitle={translatedText.pL}
                        title={translatedText.searchPLHA}
                    />
                    <SearchInput
                        id="search"
                        icon={isSearchActive ? undefined : faSearch}
                        onChange={onSearchChange}
                    />
                    <div className={styles.searchHeaderPreviewWrapper}>
                    <SearchHeaderPreviewContainer
                        show={showSearchPreview}
                        results={filteredBuilderProducts}
                    />
                    </div>
                </section>
            </section>
        </>
    );
}

export default SearchHeader;