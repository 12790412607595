

import { useEffect, useContext, useState } from "react";
import { format } from 'date-fns';
import { AdminContext } from "../../../Context/Admin.Context";
import { AuthContext } from "../../../Context/Auth.Context";
import { RoutingContext } from "../../../Context/Routing.Context";


import WelcomeTile from "../../PortalComponents/PortalUi/WelcomeTile/WelcomeTile";
import DateRangePicker from "../../../Shared/Components/UI/ThePageElements/DatePicker";

import DataShowcase from "./DataShowcase/DataShowCase";
const PortalDashboard = () => {

    const { setIsRoutingState } = useContext(RoutingContext)
    const { token } = useContext(AuthContext)
    const {

        startDate,
        endDate,
        setStartDate,
        setEndDate,

        // shouldFetch,
        // setShouldFetch

    } = useContext(AdminContext);

 

    // const handleApply = () => {
    //     setShouldFetch(true);
    // }    
    return (
        !token ? setIsRoutingState.isLoading :

                <div>
                    <WelcomeTile />
                   
                    <DateRangePicker
                        startDate={startDate}
                        endDate={endDate}
                        handleStartDateChange={setStartDate}
                        handleEndDateChange={setEndDate}
                        // onApply={handleApply}
                    />
                    
                    
                    <DataShowcase />
                </div>
    )
}

export default PortalDashboard;