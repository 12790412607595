import { motion } from 'framer-motion';
import { useState, useContext } from 'react';
import { LanguageContext } from '../../../../Context/Language.Context';
import styles from './LanguageSelectionMenu.module.css'

const LanguageSelectionMenu = () => {
    const { language, setLanguage } = useContext(LanguageContext);
    const [isOn, setIsOn] = useState(language === 'FR');
    const toggleSwitch = () => {
        setIsOn(!isOn);
        setLanguage(isOn ? 'EN' : 'FR');
    };
    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 30
    };

    const switchColor = language === 'EN' ? '#262626' : '#a50034';
    const handleAlignment = isOn ? 'right' : 'left';

    return (
        <div className={styles.switch} data-ison={isOn} onClick={toggleSwitch}>
            <motion.div
                className={styles.handle}
                // layout
                transition={spring}
                style={{ backgroundColor: switchColor, textAlign: handleAlignment }}
            >
               <span className={styles.switchText}> {language === 'EN' ? 'EN' : 'FR'}</span>
            </motion.div>
        </div>
    )
}
export default LanguageSelectionMenu;


