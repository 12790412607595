import { useState, useContext } from "react";
import TheModalWrapper from "../../../Components/UI/TheModals/TheModalWrapper";

import { SFWBuilderContext } from "../../../../Context/BuilderContext";
// import { AnalyticsContext } from "../../../../Context/AnalyticsContext";
import { HAPGContext } from "../../../../Context/HAPG.Context";
// import { AuthContext } from "../../../../Context/Auth.Context";
// import { LanguageContext } from "../../../../Context/Language.Context";
// import jwt_decode from "jwt-decode";
import emailjs from "@emailjs/browser";
import TheButton from "../../../Components/UI/TheButton/TheButton";
import { logEvent } from "../../../../Utils/google-analytics";
// import styles from "./TheOnlyEmailListModalStyles.module.css";
import FlashMessageWrapper from "../../../Components/UI/FlashMessage/FlashMessageWrapper";
import TheIcon from "../../../Components/UI/TheIcon/TheIcon";
import { RoutingContext } from "../../../../Context/Routing.Context";

const TheEmailListModal = ({ isEditingMode = false }) => {


    // const form = useRef();
    // const { token } = useContext(AuthContext);
    // const decodedToken = token ? jwt_decode(token) : null;
    // const { translatedText } = useContext(LanguageContext)
    // const { storeButtonInteraction } = useContext(AnalyticsContext);
    const { builderListItems, } = useContext(SFWBuilderContext);
    const { emailListState, setEmailListState } = useContext(HAPGContext)
    
const {setIsRoutingState, isRoutingState}=useContext(RoutingContext)

    const [emailToSend, setEmailToSend] = useState('');
    const [ setIsLoading] = useState(false);

    // const interactionNames = {
    //     successfulEmail: isEditingMode ? 'successful_edit_saved_list_email' : 'successful_saved_list_email',
    //     failedEmail: isEditingMode ? 'failed_edit_saved_list_email' : 'failed_saved_list_email',
    //     attemptEmail: isEditingMode ? 'attempt_edit_saved_list_email' : 'attempt_saved_list_email',
       
    // };

  

    //HANDLERS
    const validateEmail = (email) => {
        // Regular expression for email validation
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    //   EMAIL STRUCTURING
    const productListStringEN = builderListItems.map((item, index) =>
        //     `${index + 1}
        //  ${item.store}
        //  ${item.availability}
        //  ${item.title}
        //  ${item.subtitleEN}
        //  ${item.categoryEN}
        //  ${item?.specTitle1EN}
        //  ${item?.specList1EN}
        //  ${item?.specTitle2EN}
        //  ${item?.specList2EN}
        //  ${item?.specTitle3EN}
        //  ${item?.specList3EN}
        //  ${item?.specTitle4EN}
        //  ${item?.specList4EN}
        //  ${item.upcCodeEN}`
        ` <div>
   <h4>${index + 1}. ${item.title}</h4>
   <img src="${process.env.REACT_APP_AWS_URL}/${item.image}" alt="${item.title}" width="100" style="display:block; margin-bottom:10px;">
  
   <p>${item.subtitleEN}</p>
   <p>${item.categoryEN}</p>
   <p>${item.specTitle1EN || 'N/A'}</p>
   <p>${item.specList1EN || 'N/A'}</p>
   <p>${item.specTitle2EN || 'N/A'}</p>
   <p>${item.specList2EN || 'N/A'}</p>
   <p>${item.specTitle3EN || 'N/A'}</p>
   <p>${item.specList3EN || 'N/A'}</p>
   <p>${item.specTitle4EN || 'N/A'}</p>
   <p>${item.specList4EN || 'N/A'}</p>
   <p>${item.upcCodeEN}</p>
</div>`
    ).join('');
    // const messageEN = `${productListStringEN}`;


    const productListStringFR = builderListItems.map((item, index) =>
    //     `${index + 1}
    //  ${item.store}
    //  ${item.availability}
    //  ${item.title}
    //  ${item.subtitleEN}
    //  ${item.categoryEN}
    //  ${item?.specTitle1EN}
    //  ${item?.specList1EN}
    //  ${item?.specTitle2EN}
    //  ${item?.specList2EN}
    //  ${item?.specTitle3EN}
    //  ${item?.specList3EN}
    //  ${item?.specTitle4EN}
    //  ${item?.specList4EN}
    //  ${item.upcCodeEN}`
    ` <div>
<h4>${index + 1}. ${item.title}</h4>
<img src="${process.env.REACT_APP_AWS_URL}/${item.image}" alt="${item.title}" width="100" style="display:block; margin-bottom:10px;">


<p>${item.subtitleFR}</p>
<p>${item.categoryFR}</p>
<p>${item.specTitle1FR || 'N/A'}</p>
<p>${item.specList1FR || 'N/A'}</p>
<p>${item.specTitle2FR || 'N/A'}</p>
<p>${item.specList2FR || 'N/A'}</p>
<p>${item.specTitle3FR || 'N/A'}</p>
<p>${item.specList3FR || 'N/A'}</p>
<p>${item.specTitle4FR || 'N/A'}</p>
<p>${item.specList4FR || 'N/A'}</p>
<p>${item.upcCodeFR}</p>
</div>`
).join('');
// const message = `${productListStringFR}`;

    const handleEmail = async (e) => {
   
        e.preventDefault();
        // setIsLoading(true);
        setIsRoutingState(prevState=>({...prevState, isLoading:true}))

        if (!validateEmail(emailToSend)) {
            setIsRoutingState(prevState=>({...prevState, isLoading:false}))
            // setIsLoading(false);
            setEmailListState(prevState => ({
                ...prevState,
                isFormValid: false,
                formMessage: "Email address missing or invalid"

            }))
            // setIsEmailFormValid(false);
            // setIsEmailFormMessage('Email address missing or invalid')
            return;
        }


        try {

            const result = await emailjs.send(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                {
                    customer_email: emailToSend,
                    from_name: "LG Purchase Pro",
                    messageEN: productListStringEN,
                    messageFR: productListStringFR,
                },
                process.env.REACT_APP_EMAILJS_USER_ID
            );
            if (result.status === 200) {

                // setIsLoading(false);
                setIsRoutingState(prevState=>({...prevState, isLoading:false}))
                setEmailListState(prevState => ({
                    ...prevState,
                    isSuccess: true,
                    formMessage: 'Email sent successfully'

                }))
                // storeButtonInteraction(interactionNames.successfulEmail);
                if(isEditingMode === true){
                    logEvent('User Interaction', 'Click_Email_Saved_List_Complete', 'Email Saved List Complete Button Click')
                }
                logEvent('User Interaction', 'Click_Email_Complete', 'Email Complete Button Click')
            }

        } catch (error) {
            // setIsLoading(false);
            setIsRoutingState(prevState=>({...prevState, isLoading:false}))
            setEmailListState(prevState => ({
                ...prevState,
                isFailure: true,
                formMessage: error.message
            }))
            // storeButtonInteraction(interactionNames.failedEmail);
        }
    };


    const closeEmailMessageHandler = () => {
        setEmailListState(prevState => ({
            ...prevState,
            formMessage: false,
            isFailure: false,
            isSuccess: false
        }));
    }

    const handleCancelEmailModal = () => {
        setEmailListState(prevState => ({
            ...prevState,
            isShowModal: false,
            formMessage: false,
            isSuccess: false
        }))

    }
    return (
        <>
            <TheModalWrapper
                icon='true'
                show={emailListState.isShowModal}
                iconName="close"
                onCancel={handleCancelEmailModal}
                title="Email List"
                subtitle="Purchase Pro"
                headline="Add the person's email you want to share the list with and hit send."
                // headline={translatedText.emailModalMessage}
                formMessages={
                    emailListState.formMessage && (
                        <div>{emailListState.isSuccess ? (
                            <FlashMessageWrapper
                                message={emailListState.formMessage}
                                onClick={closeEmailMessageHandler}
                                buttonIcons={
                                    <TheButton
                                        icon
                                        buttonIcon
                                        iconName="check"
                                        styleName="successButtonIcon"
                                    />
                                }
                            />
                        ) : emailListState.isFailure ? (

                            <FlashMessageWrapper
                                message={emailListState.formMessage}
                                onClick={closeEmailMessageHandler}
                                buttonIcons={
                                    <TheButton
                                        icon
                                        buttonIcon
                                        iconName="exclamation"
                                        styleName="warningButtonIcon"
                                    />
                                }
                            />
                        ) : !emailListState.isFormValid ? (

                            <FlashMessageWrapper
                                message={emailListState.formMessage}
                                onClick={closeEmailMessageHandler}
                                buttonIcons={
                                    <TheButton
                                        icon
                                        buttonIcon
                                        iconName="exclamation"
                                        styleName="warningButtonIcon"
                                    />
                                }
                            />
                        ) : null}
                        </div>
                    )
                }
                formContent={
                    <>

                        <form onSubmit={handleEmail}>
                            <div>
                                <label >
                                    {/* {translatedText.emailInputLabel}  */}
                                    Share your list
                                </label>
                                <div >


                                    <input
                                        type="email"
                                        name="customer_email"
                                        placeholder="e.g. customer@gmail.com"
                                        value={emailToSend}
                                        onChange={(e) => setEmailToSend(e.target.value)}
                                    />
                                    {/* <div
                                        className={styles.inputIcon}
                                    > */}
                                    <TheIcon
                                        name='email'
                                    />
                                    {/* </div> */}
                                </div>
                            </div>
                            <section>

                                <TheButton
                                    styleName="red"
                                    buttonIcon
                                    buttonText
                                    linkText="Send"
                                    iconName="send"
                                    // buttonTracking
                                    // interactionName="attempt_saved_list_email"
                                    // interactionName={interactionNames.attemptEmail}
                                    animated='true'
                                >Send
                                    {/* {translatedText.send} */}
                                </TheButton>
                                <span onClick={handleCancelEmailModal}><TheButton
                                    animated='true'
                                >
                                    Cancel
                                    {/* {translatedText.send} */}
                                </TheButton>
                                </span>
                            </section>
                        </form>
                    </>
                }

            >


                {/* <form onSubmit={handleEmail}>
                    <div>
                        <label>
                            <span >
                                {translatedText.emailInputLabel}
                            </span>
                        </label>

                        <input
                            type="email"
                            name="customer_email"
                            placeholder="e.g. customer@gmail.com"
                            value={emailToSend}
                            onChange={(e) => setEmailToSend(e.target.value)}
                        />
                        <TheButton
                            styleName="red"
                            buttonIcon
                            buttonText
                            linkText="Send"
                            iconName="email"
                            buttonTracking
                            interactionName="confirm-email"
                        >
                            {translatedText.send}
                        </TheButton>
                    </div>
                </form> */}
            </TheModalWrapper>
        </>
    )
}

export default TheEmailListModal;