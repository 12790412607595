import ReactDOM from "react-dom";
import styles from "./TheModalWrapper.module.css"
import TheBackdrop from "../TheBackdrops/Backdrop";
import TheButton from "../TheButton/TheButton";

const TheModalOverlay = props => {

    const backdropClickHandler = props.closeOnBackdropClick !== false ? props.onCancel : undefined;
    const content = (
        <>
            {/* <TheBackdrop onClick={props.onCancel} /> */}
            <TheBackdrop onClick={backdropClickHandler} />
            <div className={styles.modalContainer}>
                
                <div className={styles.headerIconWrapper}>
                    <span onClick={props.onCancel}>
                        <TheButton
                            icon
                            buttonIcon
                            iconName="close"
                            styleName="lightIconButton"
                            animated='true'
                        />
                    </span>
                </div>
               
                <header className={styles.modalHeaderWrapper}>
                    <div>
                        <p className={styles.subtitleText}>{props.subtitle}</p>
                        <h1 className={styles.titleText}>{props.title}</h1>
                        <p className={styles.headlineText}>{props.headline}</p>
                    </div>
                </header>
                {props.formContent ? <div className={styles.modalFormContainer}>{props.formContent}</div> : null}
                {props.formMessages ? <div className={styles.modalMessageWrapper}>{props.formMessages}</div> : null}
                <div className={styles.modalMessageWrapper}>
                    {props.children}
                </div>
            </div>
        </>
    );

    return ReactDOM.createPortal(content, document.getElementById('The-Save-List-Modal-Hook'))
};

const TheModalWrapper = props => {
    return (
        <>
            {props.show && <TheModalOverlay {...props} />}
        </>
    );
};
export default TheModalWrapper;