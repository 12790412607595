
import ReactGA from 'react-ga4';

export const initializeGA = () => {
    ReactGA.initialize('G-4QM3SEEVKS')
}

export const logPageView = ({location}) => {
    // ReactGA.set({ page: window.location.pathname });
    // ReactGA.pageview(window.location.pathname);
    ReactGA.send({
        hitType: "pageview",
        page:location,
    });
}

export const logEvent = (category, action, label = null, value = null) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
        value: value
    });
}
