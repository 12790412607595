import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { SFWBuilderContext } from '../../../../Context/BuilderContext';



import { RetailerContext } from '../../../../Context/Retailer.Context';
import { RoutingContext } from '../../../../Context/Routing.Context';
import TheButton from '../../UI/TheButton/TheButton';
import { LanguageContext } from '../../../../Context/Language.Context';
import { logEvent } from '../../../../Utils/google-analytics';

import styles from "./Cards.module.css";
import TheIcon from '../../UI/TheIcon/TheIcon';


const ProductCard = ({ product }) => {
    const { setIsRoutingState, setIsFlashMessageState } = useContext(RoutingContext);
    const location = useLocation();
    const currentPage = location.pathname;

    const { title, image } = product;
    // const [flashMessage, setFlashMessage] = useState("");
    const {
        addItemToBuilderList,
        removeItemFromBuilderList,
        builderListItems,

    } = useContext(SFWBuilderContext)

    const { isHomeDepotApp } = useContext(RetailerContext)

    const { language, translatedText } = useContext(LanguageContext);
    const subtitleText = language === "EN" ? product.subtitleEN : product.subtitleFR;

    // HANDLE FLASH MESSAGES 
    //---------------------//
    const handleAddToList = () => {
        const productAlreadyInList = builderListItems.find(item => item._id === product._id);
        let message;

        if (productAlreadyInList) {
            removeItemFromBuilderList(product);
            message = language === "EN" ? `${product.title} removed from list` : `Product ${product.title} retiré de la liste`;
            setIsFlashMessageState(prevState => ({
                ...prevState,
                show: true,
                message: message,
                iconType: 'subtracted',
                styleName: 'subtracted'
            }))
            
            logEvent('User Interaction', 'Remove_From_List', `Product Title: ${product.title}`);
      
            // storeButtonInteraction(`RemoveFromList_${product.title}_${currentPage}`);
            // console.log('bi fire - remove',product.title)
        } else {
            addItemToBuilderList(product);
            message = language === "EN" ? `${product.title} added to list` : `Product ${product.title} ajouté à la liste`;
            setIsFlashMessageState(prevState => ({
                ...prevState,
                show: true,
                message: message,
                iconType: 'checkmark',
                styleName: 'checkmark'
            }))
            // logEvent('User Interaction', 'Added_To_List', `Product Title: ${product.title}`);
            logEvent('User Interaction', 'Add_To_List', {productTitle: product.title});
      
            // storeButtonInteraction(`AddToList_${product.title}_${currentPage}`);
            // console.log('bi fire - added',product.title)

        }

    }

    const handleDetailsClick = () => {
        setIsRoutingState(prevState => ({
            ...prevState,
            isNavLinkedClicked: true,
            isBuilderDropdownOpen: false,
            isMobileBuilderDropdownOpen: false,
            
        }))
        // setIsBuilderDropdownOpen(false);
        // setIsPurchaseProDropdownOpen(false)
        // setIsNavLinkClicked(true)
        // setShowBackdrop(false)



    }

    // !product ? setShowLGLoader(true) : setShowLGLoader(false);

    return (
        <div className={styles.productCardContainer}>
            <div className={styles.productCardImageBox}>
                {/* <img src={`${process.env.REACT_APP_BACKEND_URL}/` + image} alt={title} className={styles.productImage} /> */}
                <img src={`${process.env.REACT_APP_AWS_URL}/` + image} alt={title} className={styles.productImage} />
            </div>
            <div className={styles.productBodyWrapper}>
                <div className={styles.productCardTextBox}>
                    <h2 className={styles.productTitle}>
                        {title}
                    </h2>
                    {/* <p className={styles.productSubtitle}>
                        {subtitleText}
                    </p> */}
                    <p className={styles.productSubtitle}>
                        <span className={styles.clampedSubtitle}>{subtitleText}</span>

                        {subtitleText.length > 500 && '... '}
                        {/* <NavLink to={`/product-details/${product.title}`}>
                                {translatedText.ctaSeeDetails}
                            </NavLink> */}

                    </p>
                </div>

                <div className={styles.productCardButtonBox}>
                    <span onClick={handleAddToList}>
                        <TheButton
                            animated='true'
                            // icon
                            buttonIcon="true"
                            // iconName="plus"
                            // styleName="red"
                            styleName={builderListItems.some(item => item._id === product._id) ?"blackIconButton":"redIconButton"}
                            iconName={builderListItems.some(item => item._id === product._id) ?"subtracted":"add"}
                            // interactionName={
                            //     builderListItems.some(item => item._id === product._id) ?
                            //         `RemoveFromList_${product.title}_WhileOn_${currentPage}` : `AddToList_${product.title}_WhileOn_${currentPage}`

                            // }
                        >
                            {
                                builderListItems.some(item => item._id === product._id) ?
                                    <span>
                                        {translatedText.ctaRemoveFromList}
                                    </span>
                                    :
                                    <span>
                                        {translatedText.ctaAddToList}
                                    </span>
                            }

                        </TheButton></span>

                    <div className={styles.buttonBox} onClick={handleDetailsClick}>
                        <TheButton
                           iconName="forward"
                         buttonIcon="true"
                        styleName="whiteIconButton"
                            animated="true"
                            link={`${isHomeDepotApp.isHomeDepotPrefix}/product-details/${product?.title}`}
                            linkText={translatedText.ctaSeeDetails}
                            // buttonTracking
                            // interactionName={`see_details_${product?.title}`}
                        >
                            {/* <TheIcon name={`scrollRight`}/> */}
                        </TheButton>
                    </div>
                    {/* <NavLink to={`/product-details/${product.title}`}>
                        <Button
                            animated="true"

                            styleName='white'
                            buttonClassType='seeDetailsBtn'
                            
                            onClick={handleDetailsClick}
                        >

                            {translatedText.ctaSeeDetails} <Icon name="forward-arrow" />

                        </Button>  </NavLink> */}
                </div>
            </div>
        </div>
    );
};

export default ProductCard;