
import PasswordResetForm from '../../Forms/PasswordResetForm/PasswordResetForm';


import styles from "../FormPage.styles.module.css"

const PasswordResetPage = () => {

    return (
      
            <div className={styles.resetPasswordBackgroundImageWrapper}>
                {/* <div className={styles.pageWrapper}> */}
                    <div className={styles.pageContainer}>
                        <div className={styles.formContainer}>
                            <div className={styles.headTextWrapper}>
                                <p className={styles.titleText}>Create Password</p>
                            </div>
                            <div className={styles.formWrapper}>
                                <PasswordResetForm />
                            </div>

                        </div>
                    </div>
                </div>
       

    );
};

export default PasswordResetPage;
