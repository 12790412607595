import { useInView } from "react-intersection-observer";

export const useScrollFadeIn = (options = {}) => {
    const [ref, inView] = useInView(options);
    return [ref, inView];
};

export const useSlideInAnimation = ({
    initial = { opacity: 0, x: 50 },
    animate = { opacity: 1, x: 0 },
    exit = { opacity: 0, x: 50 },
    transition = { duration: 0.3, ease: [0.75, 0, 0, 1] },
    exitTransition = { duration: 0.3, ease: [0.75, 0, 0, 1] },
  } = {}) => {
    const animationProps = {
      initial,
      animate,
      exit,
      transition,
      exitTransition,
    };
    return animationProps;
  };
  

  export const usePopUpAnimation = ({
    initial = { opacity: 0, y: 50 },
    animate = { opacity: 1, y: 0 },
    exit = { opacity: 0, y: 50 },
    transition = { duration: 0.3, ease: [0.75, 0, 0, 1] },
    exitTransition = { duration: 0.3, ease: [0.75, 0, 0, 1] },
  } = {}) => {
    const animationProps = {
      initial,
      animate,
      exit,
      transition,
      exitTransition,
    };
    return animationProps;
  };
  