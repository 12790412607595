import ProductHeader from '../../Details/ProductHeader/ProductHeader'
import UPCCodes from "../../Details/UPCCodes/UPCCodes";
import SpecificationColumns from "../../Details/Specifications/SpecificationColumns";
import FeatureLogos from "../../Details/FeatureLogos/FeatureLogos";
import FeatureVideos from "../../Details/FeatureVideo/FeatureVideos";
import FeatureResources from "../../Details/FeatureResources/FeatureResources";
import styles from "./ProductDetails.module.css";
import ProductImage from "../../Details/ProductImage/ProductImage";
import BodyContainer from '../../../UI/BodyContainer/BodyContainer';

const FullProductPage = ({ product }) => {

  return (
    <>
      {/* <div className={styles.mainProductDetailsContainer}> */}
      {/* <BodyContainer> */}
      <div className={styles.templateWrapper}>
        <BodyContainer>
          <section className={styles.productAboveFoldContainer}>
            <div className={styles.productHeadGroupWrapper}>
              <ProductHeader product={product} />
            </div>
            <section className={styles.productBannerGridContainer}>
              <div className={styles.productImageItem}>
                <div className={styles.imageBox}>
                  <ProductImage product={product} />
                </div>
              </div>
            </section>
          </section>
        </BodyContainer>
        <div >

          <div className={styles.specsBox}>
            <BodyContainer>
              <SpecificationColumns product={product} />
            </BodyContainer>
          </div>

          {/* CONDITION LOGIC GOOD */}
          {product.videosEN?.length > 0 && product?.videosEN[0] !== "" && (
            <div className={styles.specsBox}>

              <FeatureVideos product={product} />

            </div>
          )}
          <div className={styles.logosBox}>
            <div className={styles.contentMargins}>
              {/* CONDITION LOGIC GOOD */}
              {product?.logos.length > 0 && product?.logos[0] !== ""
                ? <FeatureLogos product={product} />
                : null}
            </div>
          </div>
          <div className={styles.featureResourcesBox}>
            <div className={styles.contentMargins}>
              {<FeatureResources product={product} />}
            </div>
          </div>

          <div className={styles.resourceLinkBoxWrapper}>
            <div className={styles.upcCodeBox}>
              <div className={styles.contentMargins}>
                <UPCCodes product={product} />
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* </BodyContainer> */}
      {/* </div> */}
    </>
  );
};

export default FullProductPage;


