import { Outlet } from "react-router"
import styles from "./ThePageContent.module.css"
const ThePageContent = () => {
    return (
        <div className={styles.thePageContentContainer}>
            <Outlet />

        </div>
    )
}

export default ThePageContent;