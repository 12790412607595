import TheIcon from "../TheIcon/TheIcon"
import styles from "./MenuRowToggleBox.module.css"

const MenuRowToggleBox = ({ 
    onClick, 
    clickText, 
    condition, 
    themeIcon = false,
    iconName,
    styleName,
}) => {

    const menuStyles = `${styles.menuRowToggleBoxContainer} ${styles[styleName]}`;

    return (
        <div className={menuStyles} onClick={onClick}>
            {themeIcon ?
                <div className={styles.iconContainer}>
                    <TheIcon name={iconName} />
                </div>
                : null}
            <p>{clickText}</p>
            <div className={styles.iconToggler}>
                {
                    condition ?
                        <TheIcon name="caretDown" /> :
                        <TheIcon name="caretRight" />
                }
            </div>

        </div>
    )
}

export default MenuRowToggleBox;