import { createContext, useEffect, useState } from "react";
import { AdminContext } from "./Admin.Context";


export const RoutingContext = createContext({

    isRoutingState: {
        isWelcomeBarShowing: true,
        isNavLinkClicked: false,
        isMobileNavOpen: false,
        isMobileCategoriesMenuOpen: false,
        isMobileResourcesMenuOpen: false,
        isMobileAccountMenuOpen: false,

        isCategoriesMenuOpen: false,
        isResourcesMenuOpen: false,
        isAccountMenuOpen: false,
        isBuilderDropdownOpen: false,
        isMobileBuilderDropdownOpen: false,

        isLoading: false,
        isFlashMessage: false,

        isShowScrollToTopButton: false,

    },
    setIsRoutingState: () => { },

    isFlashMessageState: {
        message: '',
        iconType: '',
        styleName: '',
        show: false,
    },
    setIsFlashMessageState: () => { },

});

export const RoutingProvider = ({ children }) => {

    const initialRoutingState = {
        isWelcomeBarShowing: true,
        isNavLinkClicked: false,
        isMobileNavOpen: false,
        isMobileCategoriesMenuOpen: false,
        isMobileResourcesMenuOpen: false,
        isMobileAccountMenuOpen: false,

        isCategoriesMenuOpen: false,
        isResourcesMenuOpen: false,
        isAccountMenuOpen: false,
        isBuilderDropdownOpen: false,
        isMobileBuilderDropdownOpen: false,

        isLoading: false,
        isFlashMessage: false,

        isShowScrollToTopButton: false,


    }
    const [isRoutingState, setIsRoutingState] = useState(initialRoutingState);


    const initialFlashMessageState = {
        message: '',
        iconType: '',
        styleName: '',
        show: false,
    }
    const [isFlashMessageState, setIsFlashMessageState] = useState(initialFlashMessageState)


    useEffect(() => {
        if (!isRoutingState.isMobileNavOpen) {
            setIsRoutingState(prevState => ({
                ...prevState,
                isMobileCategoriesMenuOpen: false,
                isMobileResourcesMenuOpen: false,
                isMobileAccountMenuOpen: false,
                isMobileBuilderDropdownOpen: false,
            }))


        } else if (isRoutingState.isMobileNavOpen) {
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.body.style.overflow = 'unset';
        }
    }, [isRoutingState.isMobileNavOpen])

    useEffect(() => {
        if (isRoutingState.isBuilderDropdownOpen ||
            isRoutingState.isMobileBuilderDropdownOpen ||
            isRoutingState.isMobileNavOpen
        ) {
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.body.style.overflow = 'unset';
        }

    }, [
        isRoutingState.isBuilderDropdownOpen,
        isRoutingState.isMobileBuilderDropdownOpen,
        isRoutingState.isMobileNavOpen
    ])

    useEffect(() => {
        if (isRoutingState.isBuilderDropdownOpen && isRoutingState.isNavLinkClicked) {
            setIsRoutingState(prevState => ({
                ...prevState,
                isBuilderDropdownOpen: false,
            }))
        } 
    }, [isRoutingState.isNavLinkClicked])


    return (
        <RoutingContext.Provider
            value={{
                isRoutingState,
                setIsRoutingState,
                isFlashMessageState,
                setIsFlashMessageState
            }}
        >
            {children}
        </RoutingContext.Provider>
    );
}
