import { motion } from "framer-motion";
import styles from "./SearchHeaderTextContainer.module.css";

const SearchHeaderTextContainer = ({ title, subtitle }) => {
    
    return (
        <motion.div
        className={styles.searchHeaderTextContainer}
            initial={{ opacity: 0, x: 20 }}
            animate={{
                opacity: 1, x: 0,
                transition: { duration: .3, ease: [0.75, 0, 0, 1], },
            }}
            exit={{opacity: 0, x: 50, transition: { duration: .3, ease: [0.75, 0, 0, 1], },
            }}>
            <p className={styles.subtitleText}>
                {subtitle}
            </p>
            <p className={styles.titleText}>
                {title}
            </p>
        </motion.div>
    );
};

export default SearchHeaderTextContainer