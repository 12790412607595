import styles from "./NavigationLinks.module.css";
import { NavLink } from "react-router-dom"

const NavigationLinks = ({ 
    linkText, 
    to, 
    onClick, 
    key,
    styleName='default', 
    children

}) => {

    const linkStyles = `${styles.navigationLinksContainer} ${styles[styleName]}`;

    return (
        <span className={linkStyles}>
            <NavLink
                key={key}
                onClick={onClick}
                className={({ isActive }) => isActive ? styles.active : undefined}
                to={to}
            >
                {linkText}{children}
            </NavLink>
        </span>
    );
}

export default NavigationLinks;