import { useContext, useRef } from 'react';
import { LanguageContext } from '../../../../../Context/Language.Context';
import styles from './FeatureVideos.module.css';
import TheButton from '../../../UI/TheButton/TheButton';

const FeatureVideos = ({ product }) => {
    const mobileVideoDisplayRef = useRef();
    const scrollMobileLeft = () => {
        mobileVideoDisplayRef.current.scrollLeft += -300;
    };
    const scrollMobileRight = () => {
        mobileVideoDisplayRef.current.scrollLeft += 300;
    };

    const desktopVideoDisplayRef = useRef();
    const scrollDesktopLeft = () => {
        desktopVideoDisplayRef.current.scrollLeft += -500;
    };
    const scrollDesktopRight = () => {
        desktopVideoDisplayRef.current.scrollLeft += 500;
    };

    const { language } = useContext(LanguageContext);

    const featureInnovationVideos = language === 'EN' ? product?.videosEN : product?.videosFR;
    const featureInnovationTitle = language === 'EN' ? 'Feature Innovation' : 'fonctionnalité innovation';

    const getVideoEmbedUrl = (videoUrl) => {
        const isVimeo = videoUrl.includes('vimeo');
        const videoId = videoUrl.split('/').pop();
        return isVimeo
            ? `https://player.vimeo.com/video/${videoId}`
            : `https://www.youtube.com/embed/${videoId}`;
    };

    return (
        <div className={styles.videosSectionWrapper}>
            <section className={styles.videosContainer}>
                {/* Mobile Video Section */}
                <div className={styles.mobileVideoHeadWrapper}>
                    <div className={styles.titleBox}>
                        <p className={styles.titleText}>{featureInnovationTitle}</p>
                    </div>
                    <div className={styles.arrowBox}>
                        <div onClick={scrollMobileLeft}>
                            <TheButton
                                buttonIcon="true"
                                iconName="scrollLeft"
                                styleName="lightIconButton"
                                animated="true"
                            />
                        </div>
                        <div onClick={scrollMobileRight}>
                            <TheButton
                                animated="true"
                                buttonIcon="true"
                                iconName="scrollRight"
                                styleName="lightIconButton"
                            />
                        </div>
                    </div>
                </div>

                {/* Mobile Video Display */}
                <div className={styles.mobileWrapperScroll} ref={mobileVideoDisplayRef}>
                    {featureInnovationVideos.map((selectedVideos, index) => {
                        const videoEmbedUrl = getVideoEmbedUrl(selectedVideos);

                        return (
                            <div key={index} className={styles.mobileVideoContainer}>
                                <iframe
                                    title={`video-${index}`}
                                    width="300"
                                    height="170"
                                    src={videoEmbedUrl}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        );
                    })}
                </div>

                {/* Desktop Video Section */}
                <div className={styles.desktopVideoHeadWrapper}>
                    <div className={styles.titleBox}>
                        <p className={styles.titleText}>{featureInnovationTitle}</p>
                    </div>

                    <div className={styles.arrowBox}>
                        <div onClick={scrollDesktopLeft}>
                            <TheButton
                                animated="true"
                                buttonIcon
                                iconName="scrollLeft"
                                styleName="lightIconButton"
                            />
                        </div>
                        <div onClick={scrollDesktopRight}>
                            <TheButton
                                animated="true"
                                buttonIcon
                                iconName="scrollRight"
                                styleName="lightIconButton"
                            />
                        </div>
                    </div>
                </div>

                {/* Desktop Video Display */}
                <div className={styles.desktopWrapperScroll} ref={desktopVideoDisplayRef}>
                    {featureInnovationVideos.map((selectedVideos, index) => {
                        const videoEmbedUrl = getVideoEmbedUrl(selectedVideos);

                        return (
                            <div key={index} className={styles.desktopVideoContainer}>
                                <iframe
                                    title={`video-${index}`}
                                    width="500"
                                    height="270"
                                    src={videoEmbedUrl}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        );
                    })}
                </div>
            </section>
        </div>
    );
};

export default FeatureVideos;
