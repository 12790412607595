// import styles from "./Cards.module.css"


import { NavHashLink } from 'react-router-hash-link';
import styles from "./CategoryCard.module.css"
import { useContext } from "react";

// import { AnalyticsContext } from "../../../../Context/AnalyticsContext";

import { RetailerContext } from "../../../../Context/Retailer.Context";


const CategoryCard = ({ product, category, subCategory }) => {

    // const { storeButtonInteraction } = useContext(AnalyticsContext);
    const {isHomeDepotApp}=useContext(RetailerContext)
   
    
    return (
        <div className={styles.cardContainer}>
            <NavHashLink
                // onClick={() => storeButtonInteraction(`click_to_${subCategory}_from${category}`)}
                to={`${isHomeDepotApp.isHomeDepotPrefix}/categories/${category}/#${subCategory}`}>
                <div className={styles.categoryCardContainer}>
                    <div className={styles.cardImageWrapper}>
                        {/* <img src={`${process.env.REACT_APP_BACKEND_URL}/` + product.image} alt={product.title} /> */}
                        <img src={`${process.env.REACT_APP_AWS_URL}/` + product.image} alt={product.title} />


                    </div>
                    <div className={styles.title}>
                        <p className={styles.categoryText}>{category}</p>
                        <p className={styles.subcategoryText}>{subCategory}</p>
                        {/* <p className={styles.subcategoryText}>{subCategory === "Dishwasher" || subCategory==="Lave-vaisselle" ? null : subCategory}</p> */}

                    </div>
                </div>
            </NavHashLink>
        </div>
    )
}

export default CategoryCard;