import TheBackdrop from '../TheBackdrops/Backdrop';

const DropdownWrapper = ({children}) => {
  return (
    <>
      <TheBackdrop />
      <div >
        {children}
      </div>
    </>
  );
}

export default DropdownWrapper;
