import styles from "./ListUtilitiesBar.module.css"
import { useContext, useState } from "react";
import { SFWBuilderContext } from "../../../../Context/BuilderContext";
import { LanguageContext } from "../../../../Context/Language.Context";
// import { AuthContext } from "../../../../Context/Auth.Context";
// import { RoutingContext } from "../../../../Context/Routing.Context";
// import { AnalyticsContext } from "../../../../Context/AnalyticsContext";
import { HAPGContext } from "../../../../Context/HAPG.Context";
import Modal from "../../../Components/UI/TheModals/TheModal"
import TheButton from "../../../Components/UI/TheButton/TheButton";
import TheSaveListModal from "../ThePurchasProModals/TheSaveListModal";
import TheEmailListModal from "../ThePurchasProModals/TheEmailListModal";
// import jwt_decode from "jwt-decode";
import TheUtilsContainer from "./TheUtilsContainer";
// import { useNavigate } from "react-router-dom";
import { logEvent } from "../../../../Utils/google-analytics";


const ListUtilitiesBar = () => {
    // const redirect = useNavigate();
    // const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    // const { token } = useContext(AuthContext);
    // const decodedToken = token ? jwt_decode(token) : null;
    const { translatedText } = useContext(LanguageContext);
    // const { storeButtonInteraction } = useContext(AnalyticsContext)
    const { removeAllItemsFromBuilderList, builderListItems } = useContext(SFWBuilderContext)
    const {
        saveListState,
        setSaveListState,
        emailListState,
        setEmailListState,
        setPurchaseProPrintState,
    } = useContext(HAPGContext)

    const handlePrint = () => {
        setPurchaseProPrintState(prevState => ({
            ...prevState,
            isPurchaseProPrintState: true,

        }))
        window.print();
        logEvent('User Interaction', 'Click_Print', 'Print Button Click');

        // storeButtonInteraction('click_printList')
    };


    const handleSaveListClick = () => {
        setSaveListState(prevState => ({
            ...prevState,
            isShowModal: true
        }));
        logEvent('User Interaction', 'Click_Save', 'Save Button Click');

    }
    {/* EMAIL HIDDEN PER PHASE 2 REQUEST */ }
    // const handleEmailListClick = () => {
    //     setEmailListState(prevState => ({
    //         ...prevState,
    //         isShowModal: true
    //     }));
    //     logEvent('User Interaction', 'Click_Email', 'Email Button Click');
    // }

    const defaultModal = {
        show: false,
        title: '',
        message: '',
        errorList: [],
        actionText: 'Close',
        wasSuccessful: false,
        onConfirm: null,
        onCancel: null,
        onClose: null,
        cancelText: '',
        confirmText: '',
    }
    const [modalInfo, setModalInfo] = useState(defaultModal);
   
    const clearListConfirmationHandler = () => {
   
        setModalInfo({
            show: true,
            title: 'Are you sure you want to clear your list?',
            message: 'This action will delete products from your Product List. Please confirm to proceed.',
            // onConfirm: deleteAccount,
            confirmText: "Confirm",
            onConfirm: executeClearListHandler,
            onCancel: () => setModalInfo({ ...modalInfo, show: false }),
            onClose: () => setModalInfo({ ...modalInfo, show: false }),
            cancelText: 'Go back'
        });
      
    }
    const executeClearListHandler = () => {
        removeAllItemsFromBuilderList(builderListItems);
        // storeButtonInteraction('confirm_delete')
    }

    return (
        <>
            {emailListState.isShowModal && <TheEmailListModal />}
            {saveListState.isShowModal && <TheSaveListModal />}

            <TheUtilsContainer
                // title="Your Products"
                subtitle={translatedText.pL}
                // subtitle="Purchase Pro List"
                count={builderListItems.length}
                generalButtons={
                    <>
                        {/* EMAIL HIDDEN PER PHASE 2 REQUEST */}
                        {/* <span
                            className={styles.purchaseProIcon}
                            onClick={handleEmailListClick} >
                            <TheButton
                                styleName="grayButtonIcon"
                                buttonIcon
                                iconName="email"
                                iconText="Email"
                                // buttonTracking
                                // interactionName="click_email"
                                animated='true'
                            />

                        </span> */}
                        <span
                            className={styles.purchaseProIcon}
                            onClick={handlePrint}>
                            <TheButton
                                styleName="grayButtonIcon"
                                buttonIcon ="true"
                                iconName="print"
                                iconText={translatedText.print}
                                // buttonTracking="true"
                                // interactionName="click_print"
                                animated='true'
                            />

                        </span>
                        <span className={styles.purchaseProIcon}
                            onClick={clearListConfirmationHandler}>
                            <TheButton
                                styleName="grayButtonIcon"
                                buttonIcon ="true"
                                iconName="delete"
                                iconText={translatedText.delete}
                                // buttonTracking="true"
                                // interactionName="click_delete"
                                animated='true'
                            />
                        </span>
                    </>
                }
                authButtons={
                    <>
                        <span
                            className={styles.purchaseProIcon}
                            onClick={handleSaveListClick}
                        >
                            <TheButton
                                styleName="grayButtonIcon"
                                buttonIcon="true"
                                iconName="save"
                                iconText="Save"
                                // buttonTracking="true"
                                // interactionName="click_save"
                                animated='true'
                            />
                        </span>
                    </>
                }
            >

                {/* {builderListItems.length > 0 && token &&
                    <span
                        className={styles.purchaseProIcon}
                        onClick={handleSaveListClick}
                    >
                        <TheButton
                            styleName="grayButtonIcon"
                            buttonIcon
                            iconName="save"
                            iconText="Save"
                            buttonTracking
                            interactionName="click_save"
                        />

                    </span>
                } */}
            </TheUtilsContainer>
            <Modal
                icon
                iconName="close"
                styleName="lightIconButton"
                iconOnClick={modalInfo.onClose}
                show={modalInfo.show}
                title={modalInfo.title}
                message={modalInfo.message}
                errorList={modalInfo.errorList}
                closeOnBackdropClick={false}
                actionText={modalInfo.actionText}
                actionBox={
                    <>
                        {modalInfo.onConfirm && (
                            <span onClick={modalInfo.onConfirm}>
                                <TheButton styleName="red" animated="true">
                                    {modalInfo.confirmText}
                                </TheButton>
                            </span>
                        )}
                        {modalInfo.onCancel && (
                            <span onClick={modalInfo.onCancel}>
                                <TheButton styleName="white" animated="true">
                                    {modalInfo.cancelText}
                                </TheButton>
                            </span>
                        )}
                    </>
                }
            />
            {/* 
            <TheModal
                show={showConfirmationModal}
                title={translatedText.areYouSureTitle}
                message={translatedText.clearListMessage}

                onConfirm={() => {
                    removeAllItemsFromBuilderList(builderListItems);
                    setShowConfirmationModal(false);
                    storeButtonInteraction('confirm_delete')
                }}
                onCancel={() => setShowConfirmationModal(false)}
            /> */}

        </>
    )
}

export default ListUtilitiesBar;
