import { useContext } from "react"
import { LanguageContext } from "../../../../Context/Language.Context"
import PageWrapperTemplate from "../../../Components/UI/Templates/PageWrapperTemplate"
import BodyContainer from "../../../Components/UI/BodyContainer/BodyContainer"
import styles from "./Legal.module.css"
import { Link } from "react-router-dom"
import SectionTextContainer from "../../../Components/UI/SectionTextContainer/SectionTextContainer"
const TermsPage = () => {
    const { language, translatedText } = useContext(LanguageContext)

    const termsContentEN = [
        {
            sec1: `Acceptance of the Website Terms and Conditions of Use`,
            sec1Body: `These website terms and conditions of use for www.lgproductguide.ca constitute a legal agreement and are entered into by and between you and LG Electronics Canada, Inc. and its website operator, Match MG Canada Inc. (collectively, "Company," "we," "us," "our"). The following terms and conditions (these "Terms and Conditions"), govern your access to and use of, including any content, functionality, and services offered on or through www.lgproductguide.ca (the "Website").`,
            sec1Body2: [<>BY USING THE WEBSITE, YOU ACCEPT AND AGREE TO BE BOUND AND COMPLY WITH THESE TERMS AND CONDITIONS AND OUR PRIVACY POLICY found at ( <Link to="/privacy">https://www.lgproductguide.ca/privacy</Link> ), INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS OR THE PRIVACY POLICY, YOU MUST NOT ACCESS OR USE THE WEBSITE.</>],

            sec2: `Modifications to the Terms and Conditions and to the Website`,
            sec2Body1Par1: `We reserve the right in our sole discretion to revise and update these terms and conditions from time to time. Any and all such modifications are effective immediately upon posting and apply to all access to and continued use of the Website. You agree to periodically review the terms and conditions in order to be aware of any such modifications and your continued use shall be your acceptance of these.`,
            sec2Body1Par2: `The information and material on this Website may be changed, withdrawn, or terminated at any time in our sole discretion without notice. We will not be liable if, for any reason, all or any part of the Website is restricted to users or unavailable at any time or for any period.`,

            sec3: `Your Use of the Website and Account Set-Up and Security`,
            sec3Body1Par1: `The security of your personal information is very important to us. We use physical, electronic, and administrative measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.`,
            sec3Body1Par2: `The safety and security of your information also depends on you. Users are responsible for obtaining their own access to the Website. Users are required to ensure that all persons who access the Website through a user's internet connection are aware of these Terms and Conditions and comply with them. The Website, including content or areas of the Website, may require user registration. It is a condition of your use of the Website that all the information you provide on the Website is correct, current, and complete.`,
            sec3Body1Par3: `Unfortunately, the transmission of information via the Internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.`,
            sec3Body1Par4: `Your provision of registration information and any submissions you make to the Website through any functionality such as applications, e-mail, or forms (collectively, "Interactive Functions") constitutes your consent to all actions we take with respect to such information consistent with our Privacy Policy. `,
            sec3Body1Par5: `Any username, password, or any other piece of information chosen by you, or provided to you as part of our security procedures, must be treated as confidential, and you must not disclose it to any other person or entity. You must exercise caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information. You understand and agree that should you be provided an account, your account is personal to you and you agree not to provide any other person with access to this Website or portions of it using your username, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your username or password or any other breach of security. You also agree to ensure that you logout from your account at the end of each session. You are responsible for any password misuse or any unauthorized access.`,
            sec3Body1Par5: `We reserve the right at any time and from time to time, to disable or terminate your account, any username, password, or other identifier, whether chosen by you or provided by us, in our sole discretion for any or no reason, including any violation of any provision of these Terms and Conditions.`,
            sec3Body1Par6: `You are prohibited from attempting to circumvent and from violating the security of this Website, including, without limitation: (a) accessing content and data that is not intended for you; (b) attempting to breach or breaching the security and/or authentication measures which are not authorized; (c) restricting, disrupting or disabling service to users, hosts, servers, or networks; (d) illicitly reproducing TCP/IP packet header; (e) disrupting network services and otherwise disrupting Website owner's ability to monitor the Website; (f) using any robot, spider, or other automatic device, process, or means to access the Website for any purpose, including monitoring or copying any of the material on the Website; (g) introducing any viruses, trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful; (h) attacking the Website via a denial-of-service attack, distributed denial-of-service attack, flooding, mailbombing, or crashing; and (i) otherwise attempting to interfere with the proper working of the Website.`,

            sec4: `Intellectual Property Rights and Ownership`,
            sec4Body1Par1: `You understand and agree that the Website and its entire contents, features, and functionality, including, but not limited to, all information, software, code, data text, displays, graphics, photographs, images, video, audio, music, broadcast, design, presentation, website layout, selection, and arrangement, are owned by the Company, its licensors, or other providers of such material and are protected in all forms by intellectual property laws including, without limitation, copyright, trademark, patent, trade secret, and any other proprietary rights. `,
            sec4Body1Par2: `The LG name, LG logo, and all related names, logos, product and service names, designs, images, and slogans are trademarks of LG Electronics Canada, Inc. or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. Other names, logos, product and service names, designs, images, and slogans mentioned, or which appear on this Website are the trademarks of their respective owners. Use of any such property, except as expressly authorized, shall constitute an infringement or violation of the rights of the property owner and may be a violation of federal or other laws and could subject the infringer to legal action.`,
            sec4Body1Par3: `You may only access or use the Website to carry on the legitimate business of Company. You shall not directly or indirectly reproduce, compile for an internal database, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website, in any form or medium whatsoever, except to further the legitimate and authorized business purposes of Company.`,
            sec4Body1Par4: `You are not permitted to modify copies of any materials from this Website nor delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site.`,
            sec4Body1Par5: `If you print off, copy, or download any part of our Website in breach of these Terms and Conditions, your right to use the Website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. You have no right, title, or interest in or to the Website or to any content on the Website, and all rights not expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms and Conditions is a breach of these Terms and Conditions and may infringe or violate copyright, trademark, and other intellectual property or other proprietary laws.`,

            sec5: `Conditions of Use and User Submissions and Site Content Standards`,
            sec5Body1Par1: `As a condition of your access and use of the Website, you agree that you may use the Website only for lawful purposes and in accordance with these Terms and Conditions. `,
            sec5Body1Par2: `The following site content standards apply to any and all content, material, and information a user submits or transmits (collectively, "submit") to the website or to other users or persons (collectively, "User Submissions") and any and all Interactive Functions. Any and all User Submissions must also comply with all applicable federal, provincial, local, and international laws, regulations, and terms of service. `,

            sec5BodyListTitle1: `Without limiting the foregoing, you warrant and agree that your use of the Website and any User Submissions shall not:`,
            sec5BodyListItems1: [
                `In any manner violate any applicable federal, provincial, local, or international law or regulation including, without limitation, any laws regarding the export of data or software, patent, trademark, trade secret, copyright, or other intellectual property, legal rights (including the rights of publicity and privacy of others) or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms and Conditions and our Privacy Policy.`,
                `In any manner violate the terms of use of any third-party website that is linked to the Website, including but not limited to, any third-party social media website.
                `,
                `Include or contain any material that is exploitive, obscene, harmful, threatening, abusive, harassing, hateful, defamatory, sexually explicit or pornographic, violent, inflammatory, or discriminatory based on race, sex, religion, nationality, disability, sexual orientation, or age or other such legally prohibited ground or be otherwise objectionable, such determination to be made in Company's sole discretion.
                `,
                `Involve stalking, attempting to exploit or harm any individual (including minors) in any way by exposing them to inappropriate content or otherwise or ask for personal information as prohibited under applicable laws, regulations, or code.
                `,
                `Involve, provide, or contribute any false, inaccurate, or misleading information. 
                `,
                `Include sending, knowingly receiving, uploading, downloading, using, or reusing any material that does not comply with the Conditions of Use and User Submissions and Site Content Standards. 
                `,
                `Impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity (including, without limitation, by using email addresses or screen names associated with any of the foregoing).
                `,
                `Transmit, or procure the sending of, any advertisements or promotions [without our prior written consent], sales, or encourage any other commercial activities, including, without limitation, any "spam", "junk mail", "chain letter", contests, sweepstakes and other sales promotions, barter, or advertising or any other similar solicitation. `,
                `Encourage any other conduct that restricts or inhibits anyone's use or enjoyment of the Website, or which, as determined by us, may harm the Company or users of the Website or expose them to liability.`,
                `Cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, or alarm any other person.`,
                `Promote any illegal activity, or advocate, promote, or assist any unlawful act.`,
                `Give the impression that they originate from or are endorsed by us or any other person or entity, if this is not the case.`

            ],




            sec6: `User Submissions: Grant of Licence`,


            sec6Body1Par1: `The Website may contain Interactive Functions allowing User Submissions on or through the Website.
            `,

            sec6Body1Par2: `None of the User Submissions you submit to the Website will be subject to any confidentiality by the Company. By providing any User Submission to the Website, you grant us and each of our affiliates and service providers, and each of their and our respective licensees, successors, and assigns the right to a world-wide, royalty free, perpetual, irrevocable, non-exclusive licence to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such material for any purpose and according to your account settings and/or incorporate such material into any form, medium or technology throughout the world without compensation to you. You further waive any moral rights or other rights of authorship as a condition of submitting any User Submission.
            `,


            sec6Body1Par3: `By submitting the User Submissions, you declare and warrant that you own or have the necessary rights to submit the User Submissions and have the right to grant the licence hereof to us and each of our affiliates and service providers, and each of their and our respective licensees, successors, and assigns to the User Submissions and comply with these Terms and Conditions. You represent and warrant that all User Submissions comply with applicable laws and regulations and the Conditions of Use and User Submissions and Site Content Standards set out in these Terms and Conditions.
            `,
            sec6Body1Par4: `You understand and agree that you, not us, nor each of our parents, subsidiaries, and affiliates, nor each of their and our respective directors, officers, employees, agents, service providers, contractors, licensors, suppliers, or successors, are fully responsible for any User Submissions you submit or contribute, and you are fully responsible and legally liable, including to any third party, for such content and its accuracy. We are not responsible or legally liable to any third party for the content or accuracy of any User Submissions submitted by you or any other user of the Website.`,

            sec7: `Site Monitoring and Enforcement, Suspension, and Termination`,

            sec7Body1Par1: `We have the right, without provision of notice to:`,


            sec7BodyListItems1: [
                `Remove or refuse to post on the Website any User Submissions for any or no reason in our sole discretion.
                `,
                `At all times, take such actions with respect to any User Submission deemed necessary or appropriate in our sole discretion, including, without limitation, for violating these Terms and Conditions.`,
                `Take appropriate legal action, including, without limitation, referral to law enforcement or regulatory authority, or notifying the harmed party of any illegal or unauthorized use of the Website. Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Website.`,
                `Terminate or suspend your access to all or part of the Website for any or no reason, including, without limitation, any violation of these Terms and Conditions.`,
            ],
            sec7Body2Par1: `YOU WAIVE AND HOLD HARMLESS LG ELECTRONICS CANADA, INC., MATCH MG CANADA INC.  AND EACH OF OUR PARENTS, SUBSIDIARIES, AND AFFILIATES, AND EACH OF THEIR AND OUR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, AND SUCCESSORS, FROM ANY AND ALL CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY AND ANY OF THE FOREGOING PARTIES RELATING TO ANY, INVESTIGATIONS BY EITHER THE COMPANY OR SUCH PARTIES OR BY LAW ENFORCEMENT AUTHORITIES.`,
            sec7Body2Par2: `We have no obligation, nor any responsibility to any party to monitor the Website or its use, and do not and cannot undertake to review material that you or other users submit to the Website. We cannot ensure prompt removal of objectionable material after it has been posted and we have no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party, subject to applicable laws.`,


            sec8: `No Reliance `,

            sec8Body1Par1: `The content on our Website is provided for general information purposes only. It is not intended to amount to advice on which you should rely. You must obtain more specific or professional advice before taking, or refraining from, any action or inaction on the basis of the content on our site.`,

            sec8Body1Par2: `Although we make reasonable efforts to update the information on our Website, we make no representations, warranties, or guarantees, whether express or implied, that the content on our Website is accurate, complete, or up to date. Your use of the Website is at your own risk and neither LG Electronics Canada, Inc. nor Match MG Canada Inc., nor each of its respective parents, subsidiaries, and affiliates, nor each of their or our respective directors, officers, employees, agents, service providers, contractors, licensors, licensees, suppliers, or successors have any responsibility or liability whatsoever for your use of this Website.
            `,


            sec8Body1Par3: `This Website includes content provided by third parties, including from other users and third-party licensors. All statements and/or opinions expressed in any such third-party content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. Such materials do not necessarily reflect the opinion of the Company. Neither LG Electronics Canada Inc. nor Match MG Canada Inc., nor each of its parents, subsidiaries, and affiliates, nor each of their or our respective directors, officers, employees, agents, service providers, contractors, licensors, licensees, suppliers, or successors have any responsibility or liability whatsoever to you, or any third party, for the content or accuracy of any third-party materials.`,


            sec9: `Privacy`,

            sec9Body1Par1: `By submitting your personal information and using our Website, you consent to the collection, use, reproduction, hosting, transmission, and disclosure of any such user content submissions in compliance with our Privacy Policy, as we deem necessary for use of the Website and provision of services.`,
            sec9Body1Par2: [<>By using this Website you are consenting to the use of cookies which allow a server to recall previous requests or registration and/or IP addresses to analyze website use patterns. You can set your browser to notify you before you receive a cookie, giving you the chance to decide whether to accept it. You can also set your browser to turn off cookies. If you do, however, some areas of the Website may not function adequately. For more information on this automated information gathering practices, see <Link to="/privacy">{translatedText.privacy}</Link> .</>],

            sec10: `Third-Party Websites `,

            sec10Body1Par1: `For your convenience, this Website may provide links or pointers to third-party sites. We make no representations about any other websites that may be accessed from this Website. If you choose to access any such sites, you do so at your own risk. We have no control over the contents of any such third-party sites and accept no responsibility for such sites or for any loss or damage that may arise from your use of them. You are subject to any terms and conditions of such third-party sites. `,

            sec11: `Geographic Restrictions`,

            sec11Body1Par1: `The owner of the Website is based in Ontario in Canada. We provide this Website for use only by persons located in Canada. This Website is not intended for use in any jurisdiction where its use is not permitted. If you access the Website from outside Canada, you do so at your own risk and you are responsible for compliance with local laws of your jurisdiction.`,


            sec12: `Disclaimer of Warranties`,

            sec12Body1Par1: `YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.`,
            sec12Body1Par2: `NEITHER LG ELECTRONICS CANADA, INC. NOR MATCH MG CANADA INC., NOR EACH OF ITS PARENT, SUBSIDIARIES, AND AFFILIATES, NOR EACH OF THEIR OR OUR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS MAKE ANY WARRANTY, REPRESENTATION, OR ENDORSEMENT WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY, OR AVAILABILITY OF THE WEBSITE OR ITS CONTENTS. WITHOUT LIMITING THE FOREGOING, NEITHER LG ELECTRONICS CANADA, INC. NOR MATCH MG CANADA INC. NOR EACH OF ITS PARENT, SUBSIDIARIES, AND AFFILIATES OR EACH OF THEIR OR OUR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS REPRESENT OR WARRANT THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.`,
            sec12Body1Par3: `WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR DATA AVAILABLE FOR DOWNLOADING FROM THE INTERNET OR THE WEBSITE WILL BE FREE OF VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY AND ENTIRELY RESPONSIBLE FOR YOUR USE OF THE WEBSITE AND YOUR COMPUTER, INTERNET, AND DATA SECURITY. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY DENIAL-OF-SERVICE ATTACK, DISTRIBUTED DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING, MAILBOMBING, OR CRASHING, VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.`,

            sec13: `Limitation on Liability`,


            sec13Body1Par1: `EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER NO CIRCUMSTANCE WILL LG ELECTRONICS CANADA, INC. NOR MATCH MG CANADA INC. NOR EACH OF ITS PARENT, SUBSIDIARIES, AND AFFILIATES, NOR EACH OF THEIR OR OUR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS BE LIABLE FOR NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT MISREPRESENTATION, FUNDAMENTAL BREACH, DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT, BREACH OF CONTRACT, BREACH OF PRIVACY, OR OTHERWISE, EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, OR RELIANCE ON, THE WEBSITE, ANY LINKED WEBSITES OR SUCH OTHER THIRD-PARTY WEBSITES, NOR ANY WEBSITE CONTENT, MATERIALS, POSTING, OR INFORMATION THEREON.
            `,

            sec14: `Indemnification`,
            sec14Body1Par1: `To the maximum extent permitted by applicable law, you agree to defend, indemnify, and hold harmless LG Electronics Canada, Inc., Match MG Canada Inc., and each of its parent, subsidiaries, and affiliates, and each of their or our respective directors, officers, employees, agents, service providers, contractors, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable legal fees) arising out of or relating to your breach of these Terms and Conditions or your use of the Website, including, but not limited to, your User Submissions, third-party sites, any use of the Website's content, services, and products other than as expressly authorized in these Terms and Conditions.`,


            sec15: `Governing Law and Choice of Forum`,
            sec15Body1Par1: `The Website and these Terms and Conditions will be governed by and construed in accordance with the laws of the Province of Ontario and the federal laws of Canada applicable therein, without giving effect to any choice or conflict of law provision, principle, or rule (whether of the laws of the Province of Ontario or any other jurisdiction) and notwithstanding your domicile, residence, or physical location.  `,
            sec15Body1Par2: `Any action or proceeding arising out of or relating to this Website and under these Terms and Conditions will be instituted in the courts of the Province of Ontario and/or the Federal Court of Canada, and each party irrevocably submits to the exclusive jurisdiction of such courts in any such action or proceeding. You waive any and all objections to the exercise of jurisdiction over you by such courts and to the venue of such courts.`,

            sec16: `Waiver`,
            sec16Body1Par1: `No failure to exercise, or delay in exercising, any right, remedy, power, or privilege arising from these Terms and Conditions operates, or may be construed, as a waiver thereof; and no single or partial exercise of any right, remedy, power, or privilege hereunder precludes any other or further exercise thereof or the exercise of any other right, remedy, power, or privilege.`,

            sec17: `Severability`,
            sec17Body1Par1: `If any term or provision of these Terms and Conditions is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of these Terms and Conditions or invalidate or render unenforceable such term or provision in any other jurisdiction.`,
            sec18: `Entire Agreement`,
            sec18Body1Par1: `The Terms and Conditions and our Privacy Policy constitute the sole and entire agreement between you and Match MG Canada Inc. regarding the Website and supersedes all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, regarding such subject matter. `,
            sec19: `Reporting and Contact`,
            sec19Body1Par1: `This website is operated by Match MG Canada Inc., 5250 Satellite Drive, Unit 2, Mississauga, ON, L4W 5G5.`,
            sec19Body1Par2: `Should you become aware of misuse of the website, or have feedback, comments, requests for technical support, and other communications relating to the Website, please email us at productguide@teamlg.ca.`,

        }
    ]

    const termsContentFR = [
        {
            sec1: `Acceptation des Conditions d'utilisation du site web `,
            sec1Body: `Ces conditions d'utilisation du site web pour www.lgproductguide.ca constituent un accord légal et sont conclues par et entre vous et LG Electronics Canada, Inc. et son opérateur de site web, Match MG Canada Inc. (collectivement, "Société", "nous", "notre"). Les termes et conditions suivants (ces "Termes et Conditions") régissent votre accès et votre utilisation, y compris tout contenu, fonctionnalité et services offerts sur ou via www.lgproductguide.ca (le "Site web"). `,
            sec1Body2: [`EN UTILISANT LE SITE WEB, VOUS ACCEPTEZ ET CONVENEZ D'ÊTRE LIÉ ET DE RESPECTER CES TERMES ET CONDITIONS ET NOTRE POLITIQUE DE CONFIDENTIALITÉ (trouvée à http://www.lgproductguide.ca/privacy), INCORPORÉE ICI PAR RÉFÉRENCE. SI VOUS N'ACCEPTEZ PAS CES TERMES ET CONDITIONS OU LA POLITIQUE DE CONFIDENTIALITÉ, VOUS NE DEVEZ PAS ACCÉDER NI UTILISER LE SITE WEB.`],

            sec2: `Modifications des Termes et Conditions et du Site web`,
            sec2Body1Par1: `Nous nous réservons le droit, à notre seule discrétion, de réviser et de mettre à jour ces termes et conditions de temps à autre. Toutes ces modifications entrent en vigueur immédiatement après leur publication et s'appliquent à tout accès et utilisation continue du Site web. Vous acceptez de revoir périodiquement les termes et conditions pour être informé de telles modifications, et votre utilisation continue signifie votre acceptation de celles-ci.`,
            sec2Body1Par2: `Les informations et le matériel sur ce Site web peuvent être modifiés, retirés ou terminés à tout moment à notre seule discrétion sans préavis. Nous ne serons pas responsables si, pour une raison quelconque, tout ou partie du Site web est restreint à des utilisateurs ou indisponible à tout moment ou pendant une période quelconque.`,



            sec3: `Votre utilisation du Site web et Configuration et sécurité du compte`,
            sec3Body1Par1: `La sécurité de vos informations personnelles est très importante pour nous. Nous utilisons des mesures physiques, électroniques et administratives conçues pour sécuriser vos informations personnelles contre la perte accidentelle et contre l'accès, l'utilisation, la modification et la divulgation non autorisés.             `,
            sec3Body1Par2: `La sécurité et la protection de vos informations dépendent également de vous. Les utilisateurs sont responsables de leur propre accès au Site web. Les utilisateurs doivent s'assurer que toutes les personnes qui accèdent au Site web via la connexion Internet de l'utilisateur sont informées de ces Termes et Conditions et les respectent. Le Site web, y compris le contenu ou les zones du Site web, peut nécessiter une inscription utilisateur. Il est condition de votre utilisation du Site web que toutes les informations que vous fournissez sur le Site web soient correctes, actuelles et complètes.`,


            sec3Body1Par3: `Malheureusement, la transmission d'informations via Internet n'est pas complètement sécurisée. Bien que nous fassions de notre mieux pour protéger vos informations personnelles, nous ne pouvons garantir la sécurité de vos informations personnelles transmises à notre Site web. Toute transmission d'informations personnelles est à vos propres risques. Nous ne sommes pas responsables de la contournement de tout paramètre de confidentialité ou mesure de sécurité contenue sur le Site web. Malheureusement, la transmission d'informations via Internet n'est pas complètement sécurisée. Bien que nous fassions de notre mieux pour protéger vos informations personnelles, nous ne pouvons garantir la sécurité de vos informations personnelles transmises à notre Site web. Toute transmission d'informations personnelles est à vos propres risques. Nous ne sommes pas responsables de la contournement de tout paramètre de confidentialité ou mesure de sécurité contenue sur le Site web.`,
            sec3Body1Par4: `Votre utilisation du Site web et Configuration et sécurité du compte. La sécurité de vos informations personnelles est très importante pour nous. Nous utilisons des mesures physiques, électroniques et administratives conçues pour sécuriser vos informations personnelles contre la perte accidentelle et contre l'accès, l'utilisation, la modification et la divulgation non autorisés. La sécurité et la protection de vos informations dépendent également de vous. Les utilisateurs sont responsables de leur propre accès au Site web. Les utilisateurs doivent s'assurer que toutes les personnes qui accèdent au Site web via la connexion Internet de l'utilisateur sont informées de ces Termes et Conditions et les respectent. Le Site web, y compris le contenu ou les zones du Site web, peut nécessiter une inscription utilisateur. Il est condition de votre utilisation du Site web que toutes les informations que vous fournissez sur le Site web soient correctes, actuelles et complètes. `,
            sec3Body1Par5: `Tout nom d'utilisateur, mot de passe ou toute autre information choisie par vous, ou fournie à vous dans le cadre de nos procédures de sécurité, doit être traitée comme confidentielle, et vous ne devez pas la divulguer à une autre personne ou entité. Vous devez faire preuve de prudence lorsque vous accédez à votre compte depuis un ordinateur public ou partagé afin que d'autres ne puissent pas voir ou enregistrer votre mot de passe ou d'autres informations personnelles. Vous comprenez et acceptez que si un compte vous est fourni, votre compte est personnel pour vous et vous acceptez de ne pas fournir à une autre personne l'accès à ce Site web ou à des parties de celui-ci en utilisant votre nom d'utilisateur, mot de passe ou autres informations de sécurité. Vous acceptez de nous informer immédiatement de tout accès non autorisé ou utilisation de votre nom d'utilisateur ou mot de passe ou de toute autre violation de la sécurité. Vous acceptez également de vous assurer de vous déconnecter de votre compte à la fin de chaque session. Vous êtes responsable de toute utilisation abusive de votre mot de passe ou de tout accès non autorisé. Nous nous réservons le droit, à tout moment et de temps à autre, de désactiver ou de résilier votre compte, tout nom d'utilisateur, mot de passe ou autre identifiant, qu'il soit choisi par vous ou fourni par nous, à notre seule discrétion pour une raison quelconque ou sans raison, y compris toute violation de toute disposition de ces Termes et Conditions.`,

            sec3Body1Par6: `Il vous est interdit de tenter de contourner et de violer la sécurité de ce Site web, notamment : (a) accéder à du contenu et des données qui ne vous sont pas destinés ; (b) tenter de violer ou de violer les mesures de sécurité et/ou d'authentification non autorisées ; (c) restreindre, perturber ou désactiver le service aux utilisateurs, hôtes, serveurs ou réseaux ; (d) reproduire illicitement l'en-tête du paquet TCP/IP ; (e) perturber les services réseau et perturber autrement la capacité du propriétaire du Site web à surveiller le Site web ; (f) utiliser un robot, une araignée ou tout autre dispositif, processus ou moyen automatique pour accéder au Site web à des fins quelconques, y compris pour surveiller ou copier tout matériel sur le Site web ; (g) introduire des virus, des chevaux de Troie, des vers, des bombes logiques ou d'autres matériaux malveillants ou technologiquement nuisibles ; (h) attaquer le Site web via une attaque par déni de service, une attaque par déni de service distribué, une inondation, un bombardement de courrier ou un crash ; et (i) tenter autrement d'interférer avec le bon fonctionnement du Site web.`,

            sec4: `Droits de propriété intellectuelle et propriété`,
            sec4Body1Par1: `Vous comprenez et acceptez que le Site web et l'ensemble de son contenu, ses fonctionnalités et sa fonctionnalité, y compris, mais sans s'y limiter, toutes les informations, logiciels, codes, données, textes, affichages, graphiques, photographies, images, vidéos, audios, musique, diffusion, conception, présentation, mise en page du site web, sélection et arrangement, sont la propriété de la Société, de ses concédants ou d'autres fournisseurs de ce matériel et sont protégés sous toutes leurs formes par les lois sur la propriété intellectuelle, y compris, sans limitation, le droit d'auteur, la marque de commerce, le brevet, le secret commercial et tous les autres droits de propriété.`,
            sec4Body1Par2: `Le nom LG, le logo LG et tous les noms, logos, noms de produits et de services, designs, images et slogans associés sont des marques de commerce de LG Electronics Canada, Inc. ou de ses filiales ou concédants de licence. Vous ne devez pas utiliser ces marques sans l'autorisation écrite préalable de la Société. D'autres noms, logos, noms de produits et de services, designs, images et slogans mentionnés ou apparaissant sur ce Site web sont les marques de commerce de leurs propriétaires respectifs. L'utilisation de toute propriété, sauf autorisation expresse, constitue une contrefaçon ou une violation des droits du propriétaire de la propriété et peut être une violation des lois fédérales ou d'autres lois et pourrait exposer le contrefacteur à des poursuites judiciaires.`,
            sec4Body1Par3: `Vous ne pouvez accéder ou utiliser le Site web que pour mener les affaires légitimes de la Société. Vous ne devez pas directement ou indirectement reproduire, compiler pour une base de données interne, distribuer, modifier, créer des œuvres dérivées, afficher publiquement, exécuter publiquement, republier, télécharger, stocker ou transmettre tout matériel sur notre Site web, sous quelque forme ou moyen que ce soit, sauf pour poursuivre les objectifs commerciaux légitimes et autorisés de la Société.`,
            sec4Body1Par4: `Il ne vous est pas permis de modifier les copies de tout matériel de ce Site web, ni de supprimer ou d'altérer les avis de droit d'auteur, de marque de commerce ou d'autres droits de propriété des copies des matériaux de ce site.`,
            sec4Body1Par5: `Si vous imprimez, copiez ou téléchargez une partie de notre Site web en violation de ces Termes et Conditions, votre droit d'utiliser le Site web cessera immédiatement et vous devrez, à notre choix, retourner ou détruire toute copie des matériaux que vous avez faite. Vous n'avez aucun droit, titre ou intérêt sur le Site web ou sur tout contenu du Site web, et tous les droits non expressément accordés sont réservés par la Société. Toute utilisation du Site web non expressément autorisée par ces Termes et Conditions est une violation de ces Termes et Conditions et peut enfreindre ou violer le droit d'auteur, la marque de commerce et d'autres droits de propriété intellectuelle ou d'autres droits de propriété.`,






            sec5: `Conditions d'utilisation et Soumissions d'utilisateurs et Normes de contenu du site`,
            sec5Body1Par1: `En tant que condition de votre accès et de votre utilisation du Site web, vous acceptez que vous pouvez utiliser le Site web uniquement à des fins légales et conformément à ces Termes et Conditions.`,
            sec5Body1Par2: `Les normes de contenu du site suivantes s'appliquent à tout contenu, matériel et information qu'un utilisateur soumet ou transmet (collectivement, "soumet") au site web ou à d'autres utilisateurs ou personnes (collectivement, "Soumissions d'utilisateurs") et à toutes les Fonctions interactives. Toutes les Soumissions d'utilisateurs doivent également être conformes à toutes les lois, réglementations et conditions de service fédérales, provinciales, locales et internationales applicables.`,

            sec5BodyListTitle1: `Sans limiter ce qui précède, vous garantissez et acceptez que votre utilisation du Site web et toute Soumission d'utilisateur ne doit pas :`,
            sec5BodyListItems1: [
                `(a) Enfreindre de quelque manière que ce soit toute loi ou réglementation fédérale, provinciale, locale ou internationale applicable, y compris, sans limitation, les lois concernant l'exportation de données ou de logiciels, les brevets, les marques de commerce, les secrets commerciaux, les droits d'auteur ou d'autres droits de propriété intellectuelle, les droits légaux (y compris les droits à la publicité et à la vie privée d'autrui) ou contenir tout matériel qui pourrait donner lieu à une responsabilité civile ou pénale en vertu des lois ou réglementations applicables ou qui pourrait être en conflit avec ces Termes et Conditions et notre Politique de confidentialité. `,
                `(b) Enfreindre de quelque manière que ce soit les conditions d'utilisation de tout site web tiers qui est lié au Site web, y compris, mais sans s'y limiter, tout site web de médias sociaux tiers.`,

                `(c) Inclure ou contenir tout matériel qui est exploiteur, obscène, nuisible, menaçant, abusif, harcelant, haineux, diffamatoire, sexuellement explicite ou pornographique, violent, inflammatoire ou discriminatoire sur la base de la race, du sexe, de la religion, de la nationalité, du handicap, de l'orientation sexuelle ou de l'âge ou d'autres motifs légalement interdits, cette détermination étant faite à la seule discrétion de la Société. 
                `,
                `(d) Impliquer le harcèlement, tenter d'exploiter ou de nuire à un individu (y compris les mineurs) de quelque manière que ce soit en les exposant à un contenu inapproprié ou autrement ou en leur demandant des informations personnelles interdites en vertu des lois, réglementations ou codes applicables.
                `,
                `(e) Impliquer, fournir ou contribuer à toute information fausse, inexacte ou trompeuse. 

                `,
                `(f) Inclure l'envoi, la réception, le téléchargement, l'utilisation ou la réutilisation de tout matériel qui ne respecte pas les Conditions d'utilisation et les Soumissions d'utilisateurs et les Normes de contenu du site.
                `,
                `(g) Usurper l'identité ou tenter d'usurper l'identité de la Société, d'un employé de la Société, d'un autre utilisateur ou de toute autre personne ou entité (y compris, sans limitation, en utilisant des adresses électroniques ou des noms d'écran associés à l'une des personnes ou entités susmentionnées). 

                `,
                `(h) Transmettre ou obtenir l'envoi de publicités ou de promotions [sans notre consentement écrit préalable], de ventes ou encourager toute autre activité commerciale, y compris, sans limitation, tout "spam", "courrier indésirable", "lettre en chaîne", concours, tirages au sort et autres promotions, troc ou publicité ou toute autre sollicitation similaire.  `,
                `(i) Encourager toute autre conduite qui restreint ou empêche l'utilisation ou la jouissance du Site web par quiconque, ou qui, selon nous, pourrait nuire à la Société ou aux utilisateurs du Site web ou les exposer à une responsabilité. `,
                `(j) Provoquer de l'ennui, de l'inconvénient ou de l'anxiété inutile ou être susceptible de contrarier, d'embarrasser ou d'alarmer toute autre personne. 
                `,
                `(k) Promouvoir toute activité illégale ou plaider, promouvoir ou aider tout acte illégal. 
                `,
                `(l) Donner l'impression qu'ils proviennent de nous ou sont approuvés par nous ou par toute autre personne ou entité, si ce n'est pas le cas.`

            ],




            sec6: `Soumissions d'utilisateurs : Octroi de licence `,


            sec6Body1Par1: `Le Site web peut contenir des Fonctions interactives permettant des Soumissions d'utilisateurs sur ou via le Site web..
            `,

            sec6Body1Par2: `Aucune des Soumissions d'utilisateurs que vous soumettez au Site web ne sera soumise à aucune confidentialité de la part de la Société. En fournissant toute Soumission d'utilisateur au Site web, vous nous accordez à nous et à chacun de nos affiliés et prestataires de services, ainsi qu'à chacun de leurs et nos successeurs et ayants droit respectifs, le droit à une licence mondiale, libre de droits, perpétuelle, irrévocable et non exclusive d'utiliser, reproduire, modifier, exécuter, afficher, distribuer et divulguer à des tiers tout ce matériel à quelque fin que ce soit et conformément aux paramètres de votre compte et/ou d'incorporer ce matériel dans toute forme, moyen ou technologie dans le monde entier sans compensation pour vous. Vous renoncez en outre à tout droit moral ou autre droit d'auteur en tant que condition de soumission de toute Soumission d'utilisateur.`,


            sec6Body1Par3: `En soumettant les Soumissions d'utilisateurs, vous déclarez et garantissez que vous possédez ou avez les droits nécessaires pour soumettre les Soumissions d'utilisateurs et avez le droit d'accorder la licence ci-dessus à nous et à chacun de nos affiliés et prestataires de services, ainsi qu'à chacun de leurs et nos successeurs et ayants droit respectifs, aux Soumissions d'utilisateurs et de vous conformer à ces Termes et Conditions. Vous déclarez et garantissez que toutes les Soumissions d'utilisateurs sont conformes aux lois et réglementations applicables et aux Conditions d'utilisation et aux Soumissions d'utilisateurs et aux Normes de contenu du site énoncées dans ces Termes et Conditions.
            `,

            sec6Body1Par4: `Vous comprenez et acceptez que vous, et non nous, ni chacun de nos parents, filiales et affiliés, ni chacun de leurs et nos administrateurs, dirigeants, employés, agents, prestataires de services, entrepreneurs, concédants de licence, fournisseurs ou successeurs respectifs, êtes entièrement responsables de toute Soumission d'utilisateur que vous soumettez ou contribuez, et vous êtes entièrement responsable et légalement responsable, y compris envers un tiers, de ce contenu et de son exactitude. Nous ne sommes pas responsables ni légalement responsables envers un tiers du contenu ou de l'exactitude de toute Soumission d'utilisateur soumise par vous ou par tout autre utilisateur du Site web.`,

            sec7: `Surveillance et application du site, suspension et résiliation`,

            sec7Body1Par1: `Nous avons le droit, sans préavis :`,


            sec7BodyListItems1: [
                "De retirer ou de refuser de publier sur le Site web toute Soumission d'utilisateur pour une raison quelconque ou sans raison à notre seule discrétion.",
                "À tout moment, de prendre les mesures que nous jugeons nécessaires ou appropriées à notre seule discrétion, y compris, sans limitation, pour avoir violé ces Termes et Conditions. ",
                "De prendre les mesures légales appropriées, y compris, sans limitation, le renvoi aux autorités policières ou réglementaires, ou de signaler à la partie lésée toute utilisation illégale ou non autorisée du Site web. Sans limiter ce qui précède, nous avons le droit de coopérer pleinement avec toute autorité policière ou toute ordonnance du tribunal nous demandant ou nous ordonnant de divulguer l'identité ou d'autres informations d'une personne publiant du matériel sur ou via le Site web. ",

            ],
            sec7Body2Par1: `VOUS RENONCEZ ET DÉCHARGEZ LA SOCIÉTÉ ET CHACUN DE SES PARENTS, FILIALES ET AFFILIÉS, ET CHACUN DE LEURS ET NOS ADMINISTRATEURS, DIRIGEANTS, EMPLOYÉS, AGENTS, PRESTATAIRES DE SERVICES, ENTREPRENEURS, CONCÉDANTS DE LICENCE, FOURNISSEURS OU SUCCESSEURS RESPECTIFS DE TOUTES LES RÉCLAMATIONS RÉSULTANT DE TOUTE ACTION PRISE PAR L'UNE DES PARTIES PRÉCÉDENTES PENDANT OU À LA SUITE DE NOS ENQUÊTES ET DE TOUTE ACTION PRISE EN CONSÉQUENCE DES ENQUÊTES PAR L'UNE DES PARTIES PRÉCÉDENTES OU PAR LES AUTORITÉS POLICIÈRES.`,
            sec7Body2Par2: `Nous ne pouvons pas, et ne tentons pas, de revoir tout le matériel avant qu'il ne soit publié sur le Site web et ne pouvons pas garantir une réponse rapide à des informations inappropriées ou inexactes. Par conséquent, nous n'assumons aucune responsabilité ou obligation pour toute action ou inaction concernant les transmissions, communications ou contenu fournis par un utilisateur ou un tiers. Nous n'avons aucune responsabilité ou obligation envers quiconque pour la performance ou la non-performance des activités décrites dans cette section.`,

            sec7a: `Droits que vous nous accordez `,
            sec7aBody1: `En accédant ou en utilisant le Site web, vous garantissez que toute information que vous nous fournissez est vraie, exacte, actuelle et complète, que vous êtes autorisé à nous fournir cette information et que nous pouvons utiliser cette information conformément à notre Politique de confidentialité.`,
            sec8: `Reliance sur les informations publiées`,

            sec8Body1Par1: `Reliance sur les informations publiées `,
            sec8Body1Par2: `Les informations présentées sur ou via le Site web sont mises à disposition uniquement à titre d'information générale. Nous ne garantissons pas l'exactitude, l'exhaustivité ou l'utilité de ces informations. Toute confiance que vous accordez à ces informations est strictement à vos propres risques. Nous déclinons toute responsabilité et obligation découlant de toute confiance placée en ces matières par vous ou par tout autre visiteur du Site web, ou par toute personne qui pourrait être informée de son contenu.`,
            sec8Body1Par3: `Ce Site web peut inclure du contenu fourni par des tiers, y compris des matériaux fournis par d'autres utilisateurs, des blogueurs et des fournisseurs tiers, des concédants de licence, des syndicats et/ou des organismes de reporting. Toutes les déclarations et/ou opinions exprimées dans ces matériaux, et tous les articles et réponses aux questions et autres contenus, autres que le contenu fourni par la Société, sont uniquement les opinions et la responsabilité de la personne ou de l'entité fournissant ces matériaux. Ces matériaux ne reflètent pas nécessairement l'opinion de la Société. Nous ne sommes pas responsables du contenu ou de l'exactitude de tout matériel fourni par un tiers. `,





            sec9: `Modifications du site web `,

            sec9Body1Par1: `Nous pouvons mettre à jour le contenu de ce Site web de temps à autre, mais son contenu n'est pas nécessairement complet ou à jour. Tout le matériel sur le Site web est fourni "tel quel" et "tel que disponible" pour votre utilisation. Vous acceptez que nous ne sommes pas responsables de la mise à jour ou de la correction de tout ou partie du Site web.`,

            sec10: `Informations sur vous et vos visites sur le site web `,

            sec10Body1Par1: `Toutes les informations que nous recueillons sur ce Site web sont soumises à notre Politique de confidentialité. En utilisant le Site web, vous consentez à toutes les actions prises par nous concernant vos informations conformément à la Politique de confidentialité.`,

            sec11: `Liens vers le site web et les fonctionnalités des médias sociaux `,

            sec11Body1Par1: `Vous pouvez créer un lien vers notre page d'accueil, à condition que vous le fassiez d'une manière équitable et légale et que cela ne porte pas atteinte à notre réputation ou n'en tire pas profit, mais vous ne devez pas établir de lien d'une manière qui suggère une forme d'association, d'approbation ou d'approbation de notre part sans notre consentement écrit exprès. `,

            sec11a: `Ce Site web peut fournir certaines fonctionnalités de médias sociaux qui vous permettent de : `,
            sec11aList: [
                `Lier à partir de votre propre ou de certains sites web tiers vers certaines pages de ce Site web.`,
                `Envoyer des e-mails ou d'autres communications avec certains contenus, ou des liens vers certains contenus, de ce Site web.`,
                `Faire que des parties limitées de contenu de ce Site web soient affichées ou semblent être affichées sur votre propre site web ou sur certains sites web tiers.`,
            ],
            sec11b: `Vous pouvez utiliser ces fonctionnalités uniquement comme elles sont fournies par nous et uniquement en ce qui concerne le contenu qu'elles affichent et autrement conformément à tous les termes et conditions supplémentaires que nous fournissons en ce qui concerne ces fonctionnalités. Sous réserve de ce qui précède, vous ne devez pas :`,
            sec11bList: [
                `Établir un lien vers une partie du Site web autre que la page d'accueil`,
                `Faire que le Site web ou une partie de celui-ci soit affiché, ou semble être affiché par, par exemple, le cadrage, le deep linking ou le cadrage en ligne sur toute autre page.`,
                `Lier à une partie du Site web autre que la page d'accueil.`,
                `Sinon prendre des mesures concernant les matériaux de ce Site web qui sont incompatibles avec toute autre disposition de ces Termes et Conditions.`,
            ],
            sec11c: `Vous acceptez de coopérer avec nous pour faire cesser immédiatement toute cadrage ou lien non autorisé. Nous nous réservons le droit de retirer la permission de liaison sans préavis. `,
            sec11d: `Nous pouvons désactiver toutes les fonctionnalités de médias sociaux et tous les liens à tout moment sans préavis à notre seule discrétion.`,
            sec11e: `Liens vers des sites web tiers et des services de tiers`,
            sec11f: `Si le Site web contient des liens vers d'autres sites et ressources fournis par des tiers, ces liens sont fournis pour votre commodité uniquement. Cela inclut les liens contenus dans les publicités, y compris les bannières publicitaires et les liens sponsorisés. Nous n'avons aucun contrôle sur le contenu de ces sites ou ressources et n'acceptons aucune responsabilité pour eux ou pour toute perte ou dommage qui pourrait résulter de votre utilisation d'eux. Si vous décidez d'accéder à l'un des sites web tiers liés à ce Site web, vous le faites entièrement à vos propres risques et sous réserve des conditions d'utilisation de ces sites web.`,
            sec12: `Avertissement de garantie`,

            sec12Body1Par1: `VOUS COMPRENEZ QUE NOUS NE POUVONS PAS ET NE GARANTISSONS PAS QUE LES FICHIERS DISPONIBLES EN TÉLÉCHARGEMENT DEPUIS INTERNET OU LE SITE WEB SERONT EXEMPTS DE VIRUS OU D'AUTRES CODES DESTRUCTEURS. VOUS ÊTES RESPONSABLE DE LA MISE EN PLACE DE PROCÉDURES ET DE POINTS DE CONTRÔLE SUFFISANTS POUR SATISFAIRE À VOS EXIGENCES PARTICULIÈRES EN MATIÈRE D'EXACTITUDE DES DONNÉES ET DE SORTIE, ET D'UN MOYEN EXTERNE À NOTRE SITE POUR TOUTE RECONSTITUTION DE TOUTE DONNÉE PERDUE. NOUS NE SERONS PAS RESPONSABLES ENVERS VOUS OU TOUTE AUTRE PERSONNE DE TOUTE PERTE OU DOMMAGE CAUSÉ PAR UNE ATTAQUE PAR DÉNI DE SERVICE, DES VIRUS OU D'AUTRES MATÉRIAUX TECHNOLOGIQUEMENT NUISIBLES QUI POURRAIENT INFECTER VOTRE ÉQUIPEMENT INFORMATIQUE, VOS PROGRAMMES INFORMATIQUES, VOS DONNÉES OU D'AUTRES MATÉRIAUX PROPRIÉTAIRES EN RAISON DE VOTRE UTILISATION DU SITE WEB OU DE TOUT SERVICE OU ARTICLE OBTENU PAR L'INTERMÉDIAIRE DU SITE WEB OU DE VOTRE TÉLÉCHARGEMENT DE TOUT MATÉRIEL PUBLIÉ SUR CELUI-CI, OU SUR TOUT SITE WEB LIÉ À CELUI-CI.`,
            sec12Body1Par2: `VOTRE UTILISATION DU SITE WEB, DE SON CONTENU ET DE TOUT SERVICE OU ARTICLE OBTENU PAR L'INTERMÉDIAIRE DU SITE WEB EST À VOS PROPRES RISQUES. LE SITE WEB, SON CONTENU ET TOUT SERVICE OU ARTICLE OBTENU PAR L'INTERMÉDIAIRE DU SITE WEB SONT FOURNIS "TEL QUEL" ET "TEL QUE DISPONIBLE", SANS AUCUNE GARANTIE DE QUELQUE NATURE QUE CE SOIT, EXPRESSE OU IMPLICITE. NI LA SOCIÉTÉ NI AUCUNE PERSONNE ASSOCIÉE À LA SOCIÉTÉ NE FAIT AUCUNE DÉCLARATION OU GARANTIE QUANT À L'EXHAUSTIVITÉ, LA SÉCURITÉ, LA FIABILITÉ, LA QUALITÉ, L'EXACTITUDE OU LA DISPONIBILITÉ DU SITE WEB. SANS LIMITER CE QUI PRÉCÈDE, NI LA SOCIÉTÉ NI AUCUNE PERSONNE ASSOCIÉE À LA SOCIÉTÉ NE REPRÉSENTE OU GARANTIT QUE LE SITE WEB, SON CONTENU OU TOUT SERVICE OU ARTICLE OBTENU PAR L'INTERMÉDIAIRE DU SITE WEB SERA EXACT, FIABLE, SANS ERREUR OU INTERRUPTION, QUE LES DÉFAUTS SERONT CORRIGÉS, QUE NOTRE SITE OU LE SERVEUR QUI LE MET À DISPOSITION SONT EXEMPTS DE VIRUS OU D'AUTRES COMPOSANTS NUISIBLES OU QUE LE SITE WEB OU TOUT SERVICE OU ARTICLE OBTENU PAR L'INTERMÉDIAIRE DU SITE WEB RÉPONDRA À VOS BESOINS OU ATTENTES.`,
            sec12Body1Par3: `LA SOCIÉTÉ DÉCLINE TOUTE GARANTIE, EXPRESSE OU IMPLICITE, Y COMPRIS TOUTE GARANTIE D'EXACTITUDE, DE NON-CONTREFAÇON, DE COMMERCIABILITÉ ET D'ADÉQUATION À UN USAGE PARTICULIER. LA SOCIÉTÉ DÉCLINE TOUTE RESPONSABILITÉ POUR LES ACTES, LES OMISSIONS ET LA CONDUITE DE TOUT UTILISATEUR OU TIERS EN RELATION AVEC OU LIÉS À VOTRE UTILISATION DU SITE WEB, DE TOUT SERVICE OU ARTICLE. VOUS ASSUMEZ LA RESPONSABILITÉ TOTALE DE VOTRE UTILISATION DU SITE WEB ET DE TOUT SITE WEB LIÉ. VOTRE SEUL RECOURS CONTRE LA SOCIÉTÉ POUR MÉCONTENTEMENT AVEC LE SITE WEB OU SON CONTENU EST DE CESSER D'UTILISER LE SITE WEB OU CE CONTENU. CETTE LIMITATION DE RECOURS FAIT PARTIE DE L'ACCORD ENTRE LES PARTIES. Les dispositions ci-dessus ne s'appliquent pas si elles sont interdites par la loi. Veuillez consulter vos lois locales pour les restrictions ou les limitations concernant l'exclusion des garanties implicites.  `,

            sec13: `Limitation de responsabilité `,


            sec13Body1Par1: `DANS LA MESURE MAXIMALE PERMISE PAR LA LOI APPLICABLE, EN AUCUN CAS LA SOCIÉTÉ, SES FILIALES OU SES CONCÉDANTS DE LICENCE, PRESTATAIRES DE SERVICES, EMPLOYÉS, AGENTS, DIRIGEANTS OU ADMINISTRATEURS NE SERONT RESPONSABLES DES DOMMAGES DE QUELQUE NATURE QUE CE SOIT, SOUS QUELQUE THÉORIE JURIDIQUE QUE CE SOIT, DÉCOULANT DE OU EN RELATION AVEC VOTRE UTILISATION, OU VOTRE INCAPACITÉ À UTILISER, LE SITE WEB, TOUT SITE WEB LIÉ À CELUI-CI, TOUT CONTENU DU SITE WEB OU DES SITES WEB LIÉS OU TOUT SERVICE OU ARTICLE OBTENU PAR L'INTERMÉDIAIRE DU SITE WEB OU DES SITES WEB LIÉS, Y COMPRIS TOUT DOMMAGE DIRECT, INDIRECT, SPÉCIAL, INCIDENT, CONSÉCUTIF OU PUNITIF, Y COMPRIS, MAIS SANS S'Y LIMITER, LES BLESSURES PERSONNELLES, LA DOULEUR ET LA SOUFFRANCE, LE STRESS ÉMOTIONNEL, LA PERTE DE REVENU, LA PERTE DE PROFITS, LA PERTE D'ACTIVITÉ OU D'ÉCONOMIES ANTICIPÉES, LA PERTE D'UTILISATION, LA PERTE DE CLIENTÈLE, LA PERTE DE DONNÉES, ET QUE CE SOIT CAUSÉ PAR UN ACTE DÉLICTUEUX (Y COMPRIS LA NÉGLIGENCE), LA VIOLATION DU CONTRAT OU AUTREMENT, MÊME SI PRÉVISIBLE. LA LIMITATION DE RESPONSABILITÉ ÉNONCÉE CI-DESSUS NE S'APPLIQUE PAS SI ELLE EST INTERDITE PAR LA LOI. VEUILLEZ CONSULTER VOS LOIS LOCALES POUR LES RESTRICTIONS OU LES LIMITATIONS CONCERNANT LA LIMITATION DE RESPONSABILITÉ.`,

            sec14: `Indemnisation`,
            sec14Body1Par1: `Vous acceptez de défendre, d'indemniser et de dégager de toute responsabilité la Société, ses affiliés, concédants de licence et prestataires de services, et ses et leurs dirigeants, administrateurs, employés, entrepreneurs, agents, concédants de licence, fournisseurs, successeurs et ayants droit respectifs de et contre toute réclamation, responsabilité, dommage, jugement, prix, perte, coût, dépense ou frais (y compris les honoraires d'avocats) découlant de ou en relation avec votre violation de ces Termes et Conditions ou votre utilisation du Site web, y compris, mais sans s'y limiter, votre utilisation du contenu du Site web, des services et des produits autres que comme expressément autorisé dans ces Termes et Conditions, ou votre utilisation de toute information obtenue à partir du Site web.`,


            sec15: `Divers`,
            sec15Body1Par1: `Ces Termes et Conditions, ainsi que notre Politique de confidentialité, constituent l'intégralité de l'accord entre vous et la Société en ce qui concerne le Site web et remplacent tous les accords, ententes et déclarations antérieurs ou contemporains, écrits ou oraux, entre vous et la Société en ce qui concerne le Site web. `,
            sec15Body1Par2: `Aucune renonciation à ces Termes et Conditions par la Société ne doit être considérée comme une renonciation ultérieure ou continue à ces termes ou à toute autre condition, et toute omission de la Société d'exercer ou d'exécuter tout droit ou disposition en vertu de ces Termes et Conditions ne constituera pas une renonciation à ce droit ou à cette disposition.`,
            sec15Body1Par3: `Si une disposition de ces Termes et Conditions est jugée invalide, illégale ou inapplicable pour quelque raison que ce soit par un tribunal compétent, cette disposition sera éliminée ou limitée dans la mesure minimale nécessaire, de sorte que les autres dispositions des Termes et Conditions continueront à être pleinement en vigueur.`,


            sec19: `Votre commentaire et vos préoccupations`,
            sec19Body1Par1: `Ce site web est exploité par Match MG Canada Inc.`,
            sec19Body1Par2: `Toutes les autres réactions, commentaires, demandes de support technique et autres communications relatives au Site web doivent être adressées à : productguide@teamlg.ca.`,

        }
    ]

    const TermsPageContent = language === "EN" ? termsContentEN : termsContentFR;


    return (
        <div className={styles.legalBackground}>
            <PageWrapperTemplate>
                <BodyContainer>
                    {TermsPageContent?.map((x, idx) => (
                        <>
                            <section key={idx}>
                                <SectionTextContainer
                                    titleText={translatedText.terms}
                                />
                                <div className={styles.sectionContainer}>
                                    <div className={styles.sectionGroup}>

                                        <div className={styles.titleBox}>
                                            <p className={styles.title}>{x.sec1}</p>
                                        </div>
                                        <div className={styles.textBox}>
                                            <p className={styles.bodyText}>{x.sec1Body}</p>
                                            <p className={styles.bodyText}>{x.sec1Body2}</p>
                                            {/* {x.sec1Body2 ?
                                                x.sec1Body2.map((p, idx) => (
                                                    <p key={idx} className={styles.bodyText}>{p}</p>
                                                ))
                                                : null
                                        } */}

                                        </div>
                                    </div>
                                </div>
                                <div className={styles.sectionContainer}>
                                    <div className={styles.sectionGroup}>
                                        <div className={styles.titleBox}>
                                            <p className={styles.title}>{x.sec2}</p>
                                        </div>

                                        <div className={styles.textBox}>
                                            <p className={styles.bodyText}>{x.sec2Body1Par1}</p>
                                            <p className={styles.bodyText}>{x.sec2Body1Par2}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.sectionContainer}>
                                    <div className={styles.sectionGroup}>
                                        <div className={styles.titleBox}>
                                            <p className={styles.title}>{x.sec3}</p>
                                        </div>

                                        <div className={styles.textBox}>
                                            <p className={styles.bodyText}>{x.sec3Body1Par1}</p>
                                            <p className={styles.bodyText}>{x.sec3Body1Par2}</p>
                                            <p className={styles.bodyText}>{x.sec3Body1Par3}</p>
                                            <p className={styles.bodyText}>{x.sec3Body1Par4}</p>
                                            <p className={styles.bodyText}>{x.sec3Body1Par5}</p>
                                            <p className={styles.bodyText}>{x.sec3Body1Par6}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.sectionContainer}>
                                    <div className={styles.sectionGroup}>
                                        <div className={styles.titleBox}>
                                            <p className={styles.title}>{x.sec4}</p>
                                        </div>

                                        <div className={styles.textBox}>
                                            <p className={styles.bodyText}>{x.sec4Body1Par1}</p>
                                            <p className={styles.bodyText}>{x.sec4Body1Par2}</p>
                                            <p className={styles.bodyText}>{x.sec4Body1Par3}</p>
                                            <p className={styles.bodyText}>{x.sec4Body1Par4}</p>
                                            <p className={styles.bodyText}>{x.sec4Body1Par5}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.sectionContainer}>
                                    <div className={styles.sectionGroup}>
                                        <div className={styles.titleBox}>
                                            <p className={styles.title}>{x.sec5}</p>
                                        </div>

                                        <div className={styles.textBox}>
                                            <p className={styles.bodyText}>{x.sec5Body1Par1}</p>
                                            <p className={styles.bodyText}>{x.sec5Body1Par2}</p>
                                        </div>
                                        <div className={styles.groupWrapper}>
                                            <div className={styles.listTitleBox}>
                                                <p className={styles.listTitle}>{x.sec5BodyListTitle1}</p>
                                            </div>
                                            <div className={styles.listBox}>
                                                <ul className={styles.listWrapper}>{x.sec5BodyListItems1?.map((i => <li className={styles.listItem}>{i}</li>))}</ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.sectionContainer}>
                                    <div className={styles.sectionGroup}>
                                        <div className={styles.titleBox}>
                                            <p className={styles.title}>{x.sec6}</p>
                                        </div>

                                        <div className={styles.textBox}>
                                            <p className={styles.bodyText}>{x.sec6Body1Par1}</p>
                                            <p className={styles.bodyText}>{x.sec6Body1Par2}</p>
                                            <p className={styles.bodyText}>{x.sec6Body1Par3}</p>
                                            <p className={styles.bodyText}>{x.sec6Body1Par4}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.sectionContainer}>
                                    <div className={styles.sectionGroup}>
                                        <div className={styles.titleBox}>
                                            <p className={styles.title}>{x.sec7}</p>
                                        </div>

                                        <div className={styles.groupWrapper}>
                                            <div className={styles.listTitleBox}>
                                                <p className={styles.listTitle}>{x.sec7Body1Par1}</p>
                                            </div>
                                            <div className={styles.listBox}>
                                                <ul className={styles.listWrapper}>{x.sec7BodyListItems1?.map((i => <li className={styles.listItem}>{i}</li>))}</ul>
                                            </div>
                                        </div>
                                        <div className={styles.textBox}>
                                            <p className={styles.bodyText}>{x.sec7Body2Par1}</p>
                                            <p className={styles.bodyText}>{x.sec7Body2Par2}</p>
                                        </div>
                                        <div className={styles.titleBox}>
                                            <p className={styles.title}>{x.sec7a}</p>
                                        </div>
                                        <div className={styles.textBox}>
                                            <p className={styles.bodyText}>{x.sec7aBody1}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.sectionContainer}>
                                    <div className={styles.sectionGroup}>
                                        <div className={styles.titleBox}>
                                            <p className={styles.title}>{x.sec8}</p>
                                        </div>

                                        <div className={styles.textBox}>
                                            <p className={styles.bodyText}>{x.sec8Body1Par1}</p>
                                            <p className={styles.bodyText}>{x.sec8Body1Par2}</p>
                                            <p className={styles.bodyText}>{x.sec8Body1Par3}</p>

                                        </div>
                                    </div>
                                </div>
                                <div className={styles.sectionContainer}>
                                    <div className={styles.sectionGroup}>
                                        <div className={styles.titleBox}>
                                            <p className={styles.title}>{x.sec9}</p>
                                        </div>

                                        <div className={styles.textWrapper}>
                                            <p className={styles.bodyText}>{x.sec9Body1Par1}</p>
                                            <p className={styles.bodyText}>{x.sec9Body1Par2}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.sectionContainer}>
                                    <div className={styles.sectionGroup}>
                                        <div className={styles.titleBox}>
                                            <p className={styles.title}>{x.sec10}</p>
                                        </div>

                                        <div className={styles.textBox}>
                                            <p className={styles.bodyText}>{x.sec10Body1Par1}</p>
                                            <p className={styles.bodyText}>{x.sec10Body1Par2}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.sectionContainer}>
                                    <div className={styles.sectionGroup}>
                                        <div className={styles.titleBox}>
                                            <p className={styles.title}>{x.sec11}</p>
                                        </div>

                                        <div className={styles.textBox}>
                                            <p className={styles.bodyText}>{x.sec11Body1Par1}</p>
                                        </div>
                                        <div className={styles.sectionGroup}>
                                            <div className={styles.listTitleBox}>
                                                <p className={styles.listTitle}>{x.sec11a}</p>
                                            </div>
                                            <div className={styles.listBox}>
                                                <ul className={styles.listWrapper}>
                                                    {x?.sec11aList ? x.sec11aList.map((i => <li className={styles.listItem}>{i}</li>)) : null}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={styles.sectionGroup}>
                                            <div className={styles.listTitleBox}>
                                                <p className={styles.listTitle}>{x.sec11b}</p>
                                            </div>
                                            <div className={styles.listBox}>
                                                <ul className={styles.listWrapper}>
                                                    {x?.sec11bList ? x.sec11bList.map((i => <li className={styles.listItem}>{i}</li>)) : null}
                                                </ul>
                                            </div>
                                            <div className={styles.textBox}>
                                                <p className={styles.bodyText}>{x.sec11c}</p>
                                            </div>
                                            <div className={styles.textBox}>
                                                <p className={styles.bodyText}>{x.sec11d}</p>
                                            </div>
                                            <div className={styles.textBox}>
                                                <p className={styles.listTitle}>{x.sec11e}</p>
                                            </div>
                                            <div className={styles.textBox}>
                                                <p className={styles.bodyText}>{x.sec11f}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className={styles.sectionContainer}>
                                    <div className={styles.sectionGroup}>
                                        <div className={styles.titleBox}>
                                            <p className={styles.title}>{x.sec12}</p>
                                        </div>

                                        <div className={styles.textBox}>
                                            <p className={styles.bodyText}>{x.sec12Body1Par1}</p>
                                            <p className={styles.bodyText}>{x.sec12Body1Par2}</p>
                                            <p className={styles.bodyText}>{x.sec12Body1Par3}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.sectionContainer}>
                                    <div className={styles.sectionGroup}>
                                        <div className={styles.titleBox}>
                                            <p className={styles.title}>{x.sec13}</p>
                                        </div>

                                        <div className={styles.textBox}>
                                            <p className={styles.bodyText}>{x.sec13Body1Par1}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.sectionContainer}>
                                    <div className={styles.sectionGroup}>
                                        <div className={styles.titleBox}>
                                            <p className={styles.title}>{x.sec14}</p>
                                        </div>

                                        <div className={styles.textBox}>
                                            <p className={styles.bodyText}>{x.sec14Body1Par1}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.sectionContainer}>
                                    <div className={styles.sectionGroup}>
                                        <div className={styles.titleBox}>
                                            <p className={styles.title}>{x.sec15}</p>
                                        </div>

                                        <div className={styles.textBox}>
                                            <p className={styles.bodyText}>{x.sec15Body1Par1}</p>
                                            <p className={styles.bodyText}>{x.sec15Body1Par2}</p>
                                            <p className={styles.bodyText}>{x.sec15Body1Par3}</p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    x.sec16 &&
                                    <div className={styles.sectionContainer}>
                                        <div className={styles.sectionGroup}>
                                            <div className={styles.titleBox}>
                                                <p className={styles.title}>{x.sec16}</p>
                                            </div>
                                            <div className={styles.textBox}>
                                                <p className={styles.bodyText}>{x.sec16Body1Par1}</p>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {x.sec17 &&
                                    <div className={styles.sectionContainer}>
                                        <div className={styles.sectionGroup}>
                                            <div className={styles.titleBox}>
                                                <p className={styles.title}>{x.sec17}</p>
                                            </div>

                                            <div className={styles.textBox}>
                                                <p className={styles.bodyText}>{x.sec17Body1Par1}</p>

                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    x.sec18 &&
                                    <div className={styles.sectionContainer}>
                                        <div className={styles.sectionGroup}>

                                            <div className={styles.titleBox}>
                                                <p className={styles.title}>{x.sec18}</p>
                                            </div>
                                            <div className={styles.textBox}>
                                                <p className={styles.bodyText}>{x.sec18Body1Par1}</p>
                                            </div>


                                        </div>
                                    </div>
                                }
                                <div className={styles.sectionContainer}>
                                    <div className={styles.sectionGroup}>
                                        <div className={styles.titleBox}>
                                            <p className={styles.title}>{x.sec19}</p>
                                        </div>

                                        <div className={styles.textBox}>
                                            <p className={styles.bodyText}>{x.sec19Body1Par1}</p>
                                            <p className={styles.bodyText}>{x.sec19Body1Par2}</p>
                                        </div>
                                    </div>
                                </div>

                            </section>
                        </>
                    ))}

                </BodyContainer>
            </PageWrapperTemplate>
        </div>

    )
}

export default TermsPage