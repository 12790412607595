import { format } from 'date-fns';
import jwt_decode from "jwt-decode";
import { useContext, useState, useEffect } from "react";
import Modal from '../../../Shared/Components/UI/TheModals/TheModal';

import { AuthContext } from '../../../Context/Auth.Context';
// import { AnalyticsContext } from '../../Context/AnalyticsContext';
// import { useHttpClient } from '../../Hooks/HttpHook/http-hook';
import { LanguageContext } from '../../../Context/Language.Context';
import styles from "./UserSavedListsTablePage.module.css"
// import Icon from '../../Shared/Components/IconElements/Icon';
import NavigationLinks from '../../../Shared/Components/UI/NavigationElements/NavigationLinks';
import TheTableActionBox from '../../PortalComponents/TableElements/TheTableActionBox';
import TheTableHeader from '../../PortalComponents/TableElements/TheTableHeader';
import TheTable from '../../PortalComponents/TableElements/TheTable';
import TheTablePagination from '../../PortalComponents/TableElements/TheTablePagination';
import PageWrapperTemplate from '../../../Shared/Components/UI/Templates/PageWrapperTemplate';
import BodyContainer from "../../../Shared/Components/UI/BodyContainer/BodyContainer"
import TheIcon from '../../../Shared/Components/UI/TheIcon/TheIcon';

const UserSavedListsTablePage = () => {

    const { token } = useContext(AuthContext);
    const decodedToken = token ? jwt_decode(token) : null;

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [selectedListForDeletion, setSelectedListForDeletion] = useState(null);

    // const { sendRequest, isLoading } = useHttpClient();
    const { translatedText } = useContext(LanguageContext);
    // const{storeButtonInteraction}=useContext(AnalyticsContext)

    const [userLists, setUserLists] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;


    const columns = [
        { key: 'count', title: '#' },
        { key: 'listName', title: translatedText.listName },
        { key: 'listEmail', title: 'Email' },
        { key: 'products', title: translatedText.productHeader },

        { key: 'createdAt', title: translatedText.createdAt },
        { key: 'updatedAt', title: translatedText.lastUpdated },
        {
            key: 'actions', title: 'Actions', render: row => (

                <TheTableActionBox>
                    <span
                    // onClick={() => storeButtonInteraction('select_edit_view_saved_list_from_table')} 
                    >
                        <NavigationLinks
                            linkText="View / Edit"
                            to={`../edit-list/${row._id}`}
                        />
                    </span>
                    <span onClick={() => {
                        setSelectedListForDeletion(row._id);
                        setShowConfirmationModal(true);
                    }}
                    >
                        <TheIcon name="delete" />

                    </span>
                </TheTableActionBox>

            )
        },
    ];

    //FETCH SAVED LIST FROM API
    useEffect(() => {
        const fetchRequest = async () => {
            // try {
            //     const data = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/user-lists/${decodedToken.userId}`,
            // //     { headers: { 'Authorization': `Bearer ${token}` }
            // // }

            //     );
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-lists/${decodedToken.userId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                })
                if (!response) {
                    // console.log({ "failed fetch response": response })
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();


                setUserLists(data.userLists);

            } catch (err) { }
        };
        fetchRequest();
    }, [token])
    // }, [sendRequest,])

    //DELETE SAVED LIST FROM API
    const handleDeleteList = async (listId) => {

        try {
            // await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/delete-list/${listId}`,'DELETE',{'Authorization': `Bearer ${token}`});
            // await fetch(`${process.env.REACT_APP_BACKEND_URL}/delete-list/${listId}`,'DELETE',{'Authorization': `Bearer ${token}`});
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/delete-list/${listId}`, {
                method: 'DELETE',
                headers: { 'Authorization': `Bearer ${token}` }
            })
            if (!response) {

                throw new Error('Failed to fetch data');
            }
            setUserLists(prevLists => prevLists.filter(list => list._id !== listId));
        } catch (err) {
            console.error(err);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const theUserSavedList = userLists.slice(indexOfFirstItem, indexOfLastItem);
    const formattedLists = theUserSavedList.map((list, index) => {
        return {
            ...list,
            count: index + 1 + ((currentPage - 1) * itemsPerPage),
            products: list.builderList.length, // changed from list.products.length
            createdAt: format(new Date(list.createdAt), 'dd/MM/yyyy'),
            updatedAt: format(new Date(list.updatedAt), 'dd/MM/yyyy'),
        };
    });

    return (
        <>
            <Modal
                show={showConfirmationModal}
                title="You sure about this?"
                message="This action will delete this product. Are you sure you want to proceed?"
                onConfirm={(e) => {
                    e.preventDefault();
                    handleDeleteList(selectedListForDeletion);
                    setShowConfirmationModal(false);
                    setSelectedListForDeletion(null); // reset the selected product
                    // storeButtonInteraction('click_delete_saved_list_from_table');
                }}
                onCancel={() => {
                    setShowConfirmationModal(false);
                    setSelectedListForDeletion(null); // reset the selected product
                }}

            />

            {/* <PageWrapperTemplate> */}
            <div className={styles.templateWrapper}>
                <BodyContainer>
                    <div className={styles.pageContainer}>
                        <TheTableHeader
                            title=" Your Saved Lists"
                            subtitle="Purchase Pro"
                            headline="Find all of your personalized Purchase Pro lists."
                        />
                        <TheTable
                            columns={columns}
                            data={formattedLists}
                        />

                        {/* <TheTablePagination
                        itemsPerPage={itemsPerPage}
                        tableData={formattedLists}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                    /> */}
                        <TheTablePagination
                            itemsPerPage={itemsPerPage}
                            tableData={userLists}  // pass the entire list
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                        />
                    </div>
                </BodyContainer>
            {/* </PageWrapperTemplate> */}
</div>
        </>
    )
}

export default UserSavedListsTablePage;