import { useContext } from "react";
import { AdminContext } from "../../../Context/Admin.Context";
// import DataTile from "../DataElements/DataTile";

import TileContainer from "./DisplayDataElements/Tiles/TileContainer";
import Tile from "./DisplayDataElements/Tiles/Tile";

const SavedListsDataTiles = () => {
    const { isPortalDataState } = useContext(AdminContext)

    const allSavedListData = isPortalDataState.allSavedList.map(data => data);

    const savedListCount = allSavedListData.length;


    //This creates a list that contains the total of each saved list
    const productsAcrossAllSavedListCount = allSavedListData.map(list => list.builderList.length);
    const sumOfAllProductsInList = productsAcrossAllSavedListCount.reduce((accumulator, currentElement) => accumulator + currentElement, 0)

    //LIST WITH MORE THAN ONE PRODUCT
    const listsMoreThanOneProduct = allSavedListData.filter(item => item.builderList.length > 1)
    const listMinOneProduct = allSavedListData.filter(item => item.builderList.length >= 1)

    //AVERAGE LIST SIZE
    const avgListSizeOfListAtLeastOneProduct = sumOfAllProductsInList / listMinOneProduct.length;
    const roundedAvgListSize = Math.round(avgListSizeOfListAtLeastOneProduct);

    // Extract unique userIds from the lists with products
    const uniqueUsersWithProducts = new Set(listMinOneProduct.map(entry => entry.userId));

    //BIGGEST LIST
    const largestSavedList = Math.max(...productsAcrossAllSavedListCount)


    return (
     <TileContainer>
            <Tile
                title="All Saved Lists"
                data={savedListCount}
            />
           <Tile
                data={sumOfAllProductsInList}
                title="Products in all list"
            />
           <Tile
                data={roundedAvgListSize}
                title="Avg list size"
            />
          <Tile
                data={uniqueUsersWithProducts.size}
                title="Users with a List"
            />
         <Tile
                data={largestSavedList}
                title="Biggest list"
            />
          <Tile
                  data={listsMoreThanOneProduct.length}
                  title="Lists with > 1 product"
            />
            
            <Tile
                data={listMinOneProduct.length}
                title="List with at least 1 product"
            />
      </TileContainer>

    )
}

export default SavedListsDataTiles;