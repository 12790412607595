import { motion } from "framer-motion";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../../../Context/Language.Context";
import { RetailerContext } from "../../../../Context/Retailer.Context";
import { SFWProductsContext } from "../../../../Context/CategoryContext";



import rangeImg from "../../../Theme/Product/SubcategoryImages/subcategory-cooking-gas-1.webp"
import dishImg from '../../../Theme/Product/CategoryImages/dish-2.webp';
import laundryImg from '../../../Theme/Product/CategoryImages/laundry-2.webp';
import lgStudioImg from '../../../Theme/Product/SubcategoryImages/subcategory-lg-studio-cooking-3.webp';
import fridgeImg from '../../../Theme/Product/SubcategoryImages/subcategory-fridge-french-1.webp';
import styles from './Categories.module.css'

import CategoryPreview from "../../../Components/ProductElements/previews/CategoryPreview"
import PageWrapperTemplate from "../../../Components/UI/Templates/PageWrapperTemplate";
import BodyContainer from "../../../Components/UI/BodyContainer/BodyContainer"

const categoryBackgroundImages = {
    "Cuisson": rangeImg,
    "Cooking": rangeImg,
    "Dishwasher": dishImg,
    "Lave-vaisselle": dishImg,
    "LG Studio": lgStudioImg,
    "Laundry": laundryImg,
    "Lessive": laundryImg,
    "Refrigeration": fridgeImg,
    "Réfrigérateur": fridgeImg,
}
const categoryBackgroundPositions = {
    "LG Studio": 'bottom',
    "Laundry": 'center',
    "Cooking": 'top',
    "Dishwasher": 'center',
    "Refrigeration": 'center',
};

const getBackgroundPosition = (category) => {
    return categoryBackgroundPositions[category] || 'center';
}
const getEnglishCategoryName = (category) => {
    return categoryNamesInEnglish[category] || category;
};

const categoryDescriptionEN = {
    "Cooking": `The star of the show, LG kitchen ranges & ovens offer precision and performance unlike any others in the market. With a variety of innovative features, our ranges can help you create dishes that will leave your guests demanding an encore.`,
    "Dishwasher": `LG’s sleek and powerful dishwashers will make your dishes shine. With intuitive controls, adjustable racks, and a large load capacity LG dishwashers make kitchen clean up a breeze.`,
    "LG Studio": `Say hello to the LG Studio Kitchen. With innovative freestanding and built-in appliances that perform flawlessly and look awesome, you may never want to leave your home again.`,
    "Laundry": `Whether you’re looking for side-by-side laundry machines, stackable washers and dryers, or all-in-one laundry appliances that offer powerful washing and drying in a single, space-saving machine, LG offers a wide range of reliable, innovative solutions.`,
    "Refrigeration": `Make the most out of life. Whether you’re replacing a kitchen appliance, adding a laundry room or furnishing your whole house, look to LG for stylish and innovative appliances that fit your lifestyle and match your home’s décor.`,
};
const categoryNamesInEnglish = {
    "Dishwasher": "Dishwasher",
    "Cooking": "Cooking",
    "Laundry": "Laundry",
    "Refrigeration": "Refrigeration",
    "LG Studio": "LG Studio"
};
const subcategoryNamesInEnglish = {
    "Cooking": [
        // "Electric Range", "Gas Range", "Built-in Wall Oven", "Built-in Cooktop", "Over-the-Range Microwave", "Countertop Microwave"
        "Electric Slide-in Ranges", "Electric Free-standing Ranges", 
        "Induction Ranges", "Gas Ranges", 
        "Wall Ovens", "Cooktops","Over-the-Range Microwaves","Countertop Microwaves"
    ],
    "Laundry": [
        "Front Load", "WashTower", "Top Load", "Specialty Laundry",
    ],
    "LG Studio": [
        // "Laundry", "Refrigeration", "Cooking", "Dishwasher",  
        "Laundry", "Refrigeration", "Built-in Cooking", "Free-standing Cooking", "Ventilation", "Dishwashers",
    ],
    "Dishwasher": [
        "Built-in"
    ],
    "Refrigeration": [
        // "French Door, Side-by-Side", "Top and Bottom Mount", "Column", "Kimchi"	
        "36\" French Door Standard Depth", "36\" French Door Counter Depth",
        "33\" French Door Standard Depth", "33\" French Door Counter Depth",
        "30\" French Door", "Top and Bottom Mount", "Side-by-Side", "Column", "Kimchi"
    ],
}
const categoryNamesInFrench = {
    "Cooking": "Cuisson",
    "Dishwasher": "Lave-vaisselle",
    "Laundry": "Lessive",
    "Refrigeration": "Réfrigérateur",
    "LG Studio": "LG Studio"
};
const categoryDescriptionFR = {
    "Cooking": `Que vous recherchiez des appareils de lavage côte à côte, des laveuses et des sécheuses empilables, ou des appareils tout-en-un qui offrent un lavage et un séchage puissants dans une seule unité compacte, LG offre une vaste gamme de solutions fiables et innovantes.`,
    "Dishwasher": `Élégants et puissants, les lave-vaisselle de LG feront briller votre vaisselle. Avec des commandes intuitives, des paniers ajustables et une grande capacité, les lave-vaisselle de LG peuvent désencombrer votre cuisine en un tour de main.`,
    "LG Studio": `Dites bonjour à la cuisine LG Studio. Grâce à ces appareils innovateurs non encastrés et intégrés qui ont fière allure et fonctionnent parfaitement, vous ne voudrez plus jamais sortir de votre maison.`,
    "Laundry": `Que vous recherchiez des appareils de lavage côte à côte, des laveuses et des sécheuses empilables, ou des appareils tout-en-un qui offrent un lavage et un séchage puissants dans une seule unité compacte, LG offre une vaste gamme de solutions fiables et innovantes.`,
    "Refrigeration": `Profitez pleinement de la vie. Que ce soit pour remplacer un électroménager de cuisine, ajouter une salle de lavage ou meubler votre maison, vous pouvez compter sur LG pour trouver des électroménagers élégants et novateurs qui correspondent à votre style de vie et s’harmonisent avec le décor de votre maison.`,
};


const CategoriesPage = () => {

    const { language, translatedText } = useContext(LanguageContext);
    const { isHomeDepotApp } = useContext(RetailerContext)
    const { filteredProductsByCategory } = useContext(SFWProductsContext);

    const categoriesOrder = [
        'Laundry',
        'Refrigeration',
        'Cooking',
        'Dishwasher',
        'LG Studio',
    ];

    // useEffect(() => {
    //     if (!filteredProductsByCategory || !categoriesOrder || !language) {
    //         setShowLGLoader(true);
    //     }
    // }, [filteredProductsByCategory, categoriesOrder, language]);

    return (
        <>
            {/* <PageTemplate> */}
            <PageWrapperTemplate>
                <BodyContainer>
                    <section className={styles.categoryBannerWrapper}>
                        {categoriesOrder.map((category, idx) => {
                            // {Object.keys(filteredProductsByCategory).map((category, idx) => {
                            if (!filteredProductsByCategory[category]) {
                                return null;
                            }
                            // const products = productsByCategory[category];
                            const backgroundImage = categoryBackgroundImages[category];
                            const subCategory = language === "EN" ? subcategoryNamesInEnglish[category] : subcategoryNamesInEnglish[category]
                            const categoryName = language === "EN" ? categoryNamesInEnglish[category] : categoryNamesInFrench[category];
                            const description = language === "EN" ? categoryDescriptionEN[category] : categoryDescriptionFR[category];


                            return (
                                <section className={styles.categorySectionContainer} key={categoryName}>
                                    <motion.section

                                        id={categoryName}
                                        className={styles.categorySectionWrapper} style={{
                                            marginTop: idx !== 0 ? "5rem" : "0rem",
                                            backgroundPosition: getBackgroundPosition(getEnglishCategoryName(category)),
                                            backgroundImage:
                                                `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.314)),
                                                linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.166)),
                                                url(${backgroundImage})`
                                        }}>
                                        <div className={styles.categoryBox}>
                                            <Link to={`${isHomeDepotApp.isHomeDepotPrefix}/categories/${category}`}>
                                                <motion.p
                                                    className={styles.sectionSubtitleText}
                                                    initial={{ opacity: 0, x: 20 }}
                                                    animate={{
                                                        opacity: 1,
                                                        x: 0,
                                                        transition: {
                                                            duration: .3,
                                                            ease: [0.75, 0, 0, 1],
                                                        },
                                                    }}
                                                    exit={{
                                                        opacity: 0,
                                                        x: 50,
                                                        transition: {
                                                            duration: .3,
                                                            ease: [0.75, 0, 0, 1],
                                                        },
                                                    }}
                                                >
                                                    {translatedText.homeAppliances}
                                                </motion.p>
                                                <motion.p className={styles.sectionTitleText}
                                                    initial={{ opacity: 0, x: 20 }}
                                                    animate={{
                                                        opacity: 1,
                                                        x: 0,
                                                        transition: {
                                                            duration: .3,
                                                            ease: [0.75, 0, 0, 1],
                                                        },
                                                    }}
                                                    exit={{
                                                        opacity: 0,
                                                        x: 50,
                                                        transition: {
                                                            duration: .3,
                                                            ease: [0.75, 0, 0, 1],
                                                        },
                                                    }}
                                                >
                                                    {categoryName}
                                                </motion.p>
                                            </Link>
                                        </div>
                                    </motion.section>
                                    <>
                                        {
                                            subCategory &&
                                            <CategoryPreview
                                                categoryDescription={description}
                                                categoryName={categoryName}
                                                SubCategory={subCategory}
                                            />
                                        }
                                    </>
                                </section>
                            )
                        })}

                    </section>
                </BodyContainer>
            </PageWrapperTemplate>


            {/* </PageTemplate> */}
        </>
    )
}

export default CategoriesPage;