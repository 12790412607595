// import Button from '../../Buttons/Buttons';
import CustomIcon from '../../IconElements/CustomIcons';
import styles from './CustomModal.module.css'

const CustomModal = ({ show, closeHandler, title, message, errorList }) => {
    if (!show) {
        return null;
    }
    return (
        <div>
            <div className={styles.backdrop} onClick={closeHandler} />
            <div className={styles.modal}>
                <header className={styles.header}>
                    <h2>{title}</h2>
                </header>
                <div className={styles.content}>
                    <p>{message}</p>
                    {errorList ? (
                        <>
                            
                                <ul className={styles.errorList}>
                                    {errorList.map((error, index) => {
                                        return (
                                            <li key={index}>{error}</li>
                                        )
                                    }


                                    )}
                                </ul>
                            
                        </>
                    ) : null}

                </div>
                <footer className={styles.actions}>
                    <CustomIcon
                        iconName="close"
                        iconStyle="circularGray05Icon"
                        // iconColor="black"
                        onClick={closeHandler}
                    />
                    {/* <Button styleName='' onClick={closeHandler}>Close</Button> */}
                </footer>
            </div>
        </div>
    )
}

export default CustomModal;