import styles from "../Form.styles.module.css"
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Input from '../../../Components/UI/FormElements/Input/Input';

import { useForm } from '../../../../Hooks/FormHook/form-hook';

import { RoutingContext } from "../../../../Context/Routing.Context";
import { VALIDATOR_EMAIL } from '../../../../Utils/validators';

import { RetailerContext } from "../../../../Context/Retailer.Context";
import TheButton from "../../../Components/UI/TheButton/TheButton";
import Modal from "../../../Components/UI/TheModals/TheModal";


const ForgotPasswordForm = () => {
    const redirect = useNavigate();
    const { isHomeDepotApp } = useContext(RetailerContext);
    const { setIsRoutingState } = useContext(RoutingContext);




    const [showModal, setShowModal] = useState(false);
    const [feedback, setFeedback] = useState();
    const [showResponseModal, setShowResponseModal] = useState(false);

    const [formState, inputHandler] = useForm({
        email: {
            value: '',
            isValid: false,
        },
    }, false);
    const handleSubmit = async (event) => {
        event.preventDefault();

        // setIsLoading(true);
        setIsRoutingState(prevState => ({
            ...prevState,
            isLoading: true
        }))
        if (!formState.isValid) {
            setIsRoutingState(prevState => ({
                ...prevState,
                isLoading: false
            }))
            setShowModal(true);
            setFeedback({
                title: 'Something went wrong',
                message: 'Please provide a valid email address. '
            })
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/reset`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify({
                    email: formState.inputs.email.value,
                }),
            });

            // setIsLoading(false);
            setIsRoutingState(prevState => ({
                ...prevState,
                isLoading: false
            }))
            if (!response.ok) {
                setShowModal(true);
                setFeedback({
                    title: 'We could not send your link',
                    message: 'Please try again. '
                })
                return;
            }
            if (response.ok) {
                setIsRoutingState(prevState => ({
                    ...prevState,
                    isLoading: false
                }))
                setShowResponseModal(true)
                setFeedback({
                    title: 'Success!',
                    message: 'Check your inbox for the password reset email. It will be active for 1hr. '
                })
                // setEnteredEmail('');
                // redirect(`${homeDepotPrefix}/sign-in`);
            }
        } catch (error) {
            setIsRoutingState(prevState => ({
                ...prevState,
                isLoading: false
            }))
            // setIsLoading(false);
            setShowModal(true);
            setFeedback({
                title: 'Error',
                message: 'Something went wrong. Please try again. '
            })
        }
    };

    const closeModal = () => {
        setShowModal(false);
    };
    const closeResponseModal = () => {
        setShowResponseModal(false);
        redirect(`${isHomeDepotApp.isHomeDepotPrefix}/sign-in`);
    };

    return (
        <>
            <Modal
                icon="true"
                iconName="close"
                styleName="lightIconButton"
                iconOnClick={closeModal}
                show={showModal}
                // title="Something went wrong!"
                title={feedback?.title}
                message={feedback?.message}
                closeOnBackdropClick={false}
                actionBox={
                    <>

                        <span onClick={closeModal}>
                            <TheButton 
                            animated="true"
                            styleName="black"
                            >
                                Try Again
                            </TheButton>
                        </span>

                    </>
                }
            />
            <Modal
                icon="true"
                iconName="close"
                styleName="lightIconButton"
                iconOnClick={closeResponseModal}
                show={showResponseModal}
                // title="Something went wrong!"
                title={feedback?.title}
                message={feedback?.message}
                closeOnBackdropClick={false}

            />
            {/* {isLoading
                && <Loader />} */}
            {/* {showModal && (
                // <CustomModal
                //     show={showModal}
                //     title={feedback.title}
                //     message={feedback.message}
                //     closeHandler={closeModal}
                // />
            )} */}
            {/* <PageTemplate> */}
            {/* <HeroHeader imgUrl={heroImage}/> */}

            <form
                // title="Reset Your Password"
                onSubmit={handleSubmit}>
                <Input
                    // labelColor="#4A4946"
                    id="email"
                    element="input"
                    type="email"
                    label="Email"
                    validators={[VALIDATOR_EMAIL()]}
                    errorText="Please enter a valid email address."
                    onInput={inputHandler}
                    icon={faEnvelope}
                // iconColor="gray"
                />
                <div className={styles.buttonBox}>
                    <TheButton animated="true" styleName="red">
                        Send Recovery Link
                    </TheButton>
                    {/* <Button
                        type="submit"
                        styleName="black"
                        animated="true"
                    ></Button> */}
                </div>
            </form>




            {/* </PageTemplate> */}

        </>
    );
};

export default ForgotPasswordForm;
