import styles from "./ListUtilitiesBar.module.css"
import { useContext, useState, 
    // useRef, useEffect 
} from "react";

import { SFWBuilderContext } from "../../../../Context/BuilderContext";
// import { LanguageContext } from "../../../../Context/Language.Context";
// import { AuthContext } from "../../../../Context/Auth.Context";

import { HAPGContext } from "../../../../Context/HAPG.Context";
import TheEmailListModal from "../ThePurchasProModals/TheEmailListModal";
import TheSaveListModal from "../ThePurchasProModals/TheSaveListModal";
// import jwt_decode from "jwt-decode";
import TheUtilsContainer from "./TheUtilsContainer";
import Modal from "../../../Components/UI/TheModals/TheModal";
import TheButton from "../../../Components/UI/TheButton/TheButton";

import { logEvent } from "../../../../Utils/google-analytics";

const EditModeListUtilitiesBar = ({ children, handleListUpdateFromParent }) => {

    // const [showConfirmationModal, setShowConfirmationModal] = useState(false)
//    const { translatedText } = useContext(LanguageContext)
//        const decodedToken = token ? jwt_decode(token) : null;
//     const { token } = useContext(AuthContext);

    const { initialSavedList, removeAllItemsFromSavedList } = useContext(SFWBuilderContext);
 

    const {
        saveListState,
        // setSaveListState,
        emailListState,
        setEmailListState,
    } = useContext(HAPGContext)


    const defaultModal = {
        show: false,
        title: '',
        message: '',
        errorList: [],
        actionText: 'Close',
        wasSuccessful: false,
        onConfirm: null,
        onCancel: null,
        onClose: null,
        cancelText: '',
        confirmText: '',
    }
    const [modalInfo, setModalInfo] = useState(defaultModal);

    const handleClearList = () => {
        setModalInfo({
            show: true,
            title: 'Are you sure want to clear your list?',
            message: 'You are about to remove all products from your list. Please confirm to proceed.',
            errorList: [],
            confirmText: 'Confirm',
            cancelText: 'Go back',
            onCancel: () => setModalInfo({ ...modalInfo, show: false }),
            onConfirm: () => removeAllItemsFromSavedList(initialSavedList),
            onClose: () => setModalInfo({ ...modalInfo, show: false })
        });
    }
//EMAIL HANDLER - NOT NEEDED PER PHASE 2 REQUEST
    const handleEmailListClick = () => {
        setEmailListState(prevState => ({
            ...prevState,
            isShowModal: true
        }));
        logEvent('User Interaction', 'Click_Email_Saved_List', 'Email Saved List Button Click');
    }

    const handlePrint = () => {
        logEvent('User Interaction', 'Click_Print_Saved_List', 'Print Saved List Button Click');
        window.print();
        
    };
    return (
        <>
            <Modal
                icon
                iconName="close"
                styleName="baseIconButton"
                iconOnClick={modalInfo.onClose}
                show={modalInfo.show}
                title={modalInfo.title}
                message={modalInfo.message}
                errorList={modalInfo.errorList}
                closeOnBackdropClick={false}
                actionText={modalInfo.actionText}
                actionBox={
                    <>
                        {modalInfo.onConfirm && (
                            <span onClick={modalInfo.onConfirm}>
                                <TheButton styleName="red" animated="true">
                                    {modalInfo.confirmText}
                                </TheButton>
                            </span>
                        )}
                        {modalInfo.onCancel && (
                            <span onClick={modalInfo.onCancel}>
                                <TheButton styleName="black" animated="true">
                                    {modalInfo.cancelText}
                                </TheButton>
                            </span>
                        )}
                    </>
                }
            />
            {emailListState.isShowModal && <TheEmailListModal isEditingMode={true} />}
            {/* {saveListState.isShowModal && <TheSaveListModal  />} */}
            {saveListState.isShowModal && <TheSaveListModal isEditingMode={true}  />}

            <TheUtilsContainer

                title="Your Saved List"
                subtitle="Update your list"
                count={initialSavedList.length}
                generalButtons={
                    <>
                        {/* START EMAIL HIDDEN -PHASE 2 REQUEST */}
                        <span
                            className={styles.purchaseProIcon}
                            onClick={handleEmailListClick} >
                            <TheButton
                                styleName="grayButtonIcon"
                                buttonIcon="true"
                                iconName="email"
                                iconText="Email"
                                // buttonTracking
                                // interactionName="click_email_saved_list"
                                animated='true'
                            />
                        </span>
                        {/* END EMAIL HIDDEN -PHASE 2 REQUEST */}
                        <span
                            className={styles.purchaseProIcon}
                            onClick={handlePrint}>
                            <TheButton
                                styleName="lightButtonIcon"
                                buttonIcon="true"
                                iconName="print"
                                iconText="Print"
                                // buttonTracking
                                // interactionName="click_print_saved_list"
                                animated='true'
                            />

                        </span>
                        <span className={styles.purchaseProIcon}
                            onClick={handleClearList}>
                            <TheButton
                                styleName="grayButtonIcon"
                                buttonIcon="true"
                                iconName="delete"
                                iconText="Delete"
                                // buttonTracking
                                // interactionName="click_delete_saved_list"
                                animated='true'
                            />
                        </span>
                    </>
                }
                authButtons={
                    <>
                        <span
                            className={styles.purchaseProIcon}
                            onClick={handleListUpdateFromParent}
                        >
                            <TheButton
                                styleName="grayButtonIcon"
                                buttonIcon
                                iconName="save"
                                iconText="Update"
                                buttonTracking
                                interactionName="click_update_saved_list"
                                animated='true'
                            />
                        </span>
                    </>
                }


            >
                {children}
            </TheUtilsContainer>
         
        </>
    )


}

export default EditModeListUtilitiesBar;
