import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useState, useContext } from "react";
import TheModalWrapper from "../../../Components/UI/TheModals/TheModalWrapper";
import { useNavigate } from "react-router";


import { useForm } from "../../../../Hooks/FormHook/form-hook";
import { SFWBuilderContext } from "../../../../Context/BuilderContext";
import { HAPGContext } from "../../../../Context/HAPG.Context";
import { AuthContext } from "../../../../Context/Auth.Context";
// import { AnalyticsContext } from "../../../../Context/AnalyticsContext";

import { RoutingContext } from "../../../../Context/Routing.Context";
import { RetailerContext } from "../../../../Context/Retailer.Context";

import jwt_decode from "jwt-decode";
import { VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE, VALIDATOR_EMAIL } from "../../../../Utils/validators";
import TheButton from "../../../Components/UI/TheButton/TheButton";
import Input from "../../../Components/UI/FormElements/Input/Input";

import Modal from "../../../Components/UI/TheModals/TheModal";
import { logEvent } from "../../../../Utils/google-analytics";

const TheSaveListModal = (  isEditingMode = false) => {
    const redirect = useNavigate();
    const {isHomeDepotApp} =useContext(RetailerContext);
    const { setIsRoutingState } = useContext(RoutingContext);

    const { token } = useContext(AuthContext);
    const decodedToken = token ? jwt_decode(token) : null;
    // const { storeButtonInteraction } = useContext(AnalyticsContext);

    const { builderListItems } = useContext(SFWBuilderContext);
    const { saveListState, setSaveListState } = useContext(HAPGContext);


    const [formState, inputHandler] = useForm({
        listName: {
            value: '',
            isValid: false,
        },
        listEmail: {
            value: '',
            isValid: false,
        },

        listNotes: {
            value: '',
            isValid: false,
        },

    }, false);

    const defaultModal = {
        show: false,
        title: '',
        message: '',
        errorList: [],
        actionText: 'Close',
        wasSuccessful: false,
        onConfirm: null,
        onCancel: null,
        onClose: null,
        cancelText: '',
        confirmText: '',
    }
    const [modalInfo, setModalInfo] = useState(defaultModal);

    const confirmModalSuccessHanlder = () => {
        setSaveListState(prevState => ({
            ...prevState,
            isShowModal: false,
            formMessage: false,
            isSuccess: false
        }));
        redirect(`${isHomeDepotApp.isHomeDepotPrefix}/user-lists/${decodedToken?.userId}`)
    }

    const cancelModalSuccessHanlder = () => {
        setSaveListState(prevState => ({
            ...prevState,
            isShowModal: false,
            formMessage: false,
            isSuccess: false
        }));

    }

    const handleSubmitSaveList = async (e) => {

        e.preventDefault();
        // setIsLoading(true);
        // setIsRoutingState(prevState => ({ ...prevState, isLoading: true }))

        let errorMessage = [];
        if (!formState.inputs.listName.value) errorMessage.push(`List name missing or invalid. `);
        if (!formState.inputs.listEmail.value) errorMessage.push('List email missing or invalid. ');
        if (!formState.inputs.listNotes.value) errorMessage.push('List notes missing or invalid.');

        if (!formState.isValid || errorMessage.length > 0) {
            // setIsLoading(false);
            // setSaveListState(prevState => ({
            //     ...prevState,
            //     isFormValid: false,
            //     formMessage: errorMessage
            // }));
            setModalInfo({
                show: true,
                title: 'Errors',
                message: 'Please fix errors below to continue:.',
                errorList: errorMessage,
                cancelText: "Close",
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
                onCancel: () => setModalInfo({ ...modalInfo, show: false }),
            });
            // console.log('fstate', errorMessage)
            return;
        }


        const url = `${process.env.REACT_APP_BACKEND_URL}/save-list/${decodedToken.userId}`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({

                listName: formState.inputs.listName.value,
                listEmail: formState.inputs.listEmail.value,
                listNotes: formState.inputs.listNotes.value,
                builderList: builderListItems,
                userId: decodedToken.userId
            }),
        };

        try {
            setIsRoutingState(prevState => ({ ...prevState, isLoading: true }))
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.customErrorMessage);
            }
            if (response.ok) {
                // setIsLoading(false);
                // setSaveListState(prevState => ({
                //     ...prevState,
                //     isShowModal: false,
                //     formMessage: false,
                //     isSuccess: false
                // }));
                setModalInfo({
                    show: true,
                    title: 'List saved',
                    message: 'Your list has been saved successfully! View your all saved lists. Go back to the Product List.',
                    errorList: [],
                    cancelText: "Go back",
                    confirmText: 'View all',
                    wasSuccessful: true,
                    onConfirm: confirmModalSuccessHanlder,
                    onCancel: cancelModalSuccessHanlder,
                    onClose: () => setModalInfo({ ...modalInfo, show: false }),
                });
                // setSaveListState(prevState => ({
                //     ...prevState,
                //     isSuccess: true,
                //     formMessage: 'List saved successfully'
                // }));
                // setIsSaveSuccessful(true);
                // setIsSaveFormMessage('List saved successfully')
                // storeButtonInteraction('successful_save')
                if(isEditingMode === true){
                    logEvent('User Interaction', 'Click_Update_Complete', 'Update Complete Button Click')
                }
        
                logEvent('User Interaction', 'Click_Save_Complete', 'Save Complete Button Click');
            }
        } catch (error) {
            setModalInfo({
                show: true,
                title: 'Something went wrong',
                message: 'The list did not save. Please contact an administrator for assistance.',
                errorList: error,
                confirmText: 'Try again',
                onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
            });
            // setIsLoading(false);
            // setSaveListState(prevState => ({
            //     ...prevState,
            //     isFailure: true,
            //     formMessage: error.message
            // }));
        } finally {
            setIsRoutingState(prevState => ({ ...prevState, isLoading: false }))
        }
    }

    const cancelSaveHandler = () => {
        setSaveListState(prevState => ({
            ...prevState,
            isShowModal: false,
            formMessage: false,
            isSuccess: false
        }));

    }

    // const closeSaveMessageHandler = () => {
    //     setSaveListState(prevState => ({
    //         ...prevState,
    //         formMessage: false,
    //         isFailure: false,
    //         isSuccess: false
    //     }));
    // }

    // console.log({
    //     'save message': saveListState.formMessage,
    //     'save valid': saveListState.isFormValid,
    //     'save faile': saveListState.isFailure,
    //     'saved good': saveListState.isSuccess,
    //     'show modal': saveListState.isShowModal
    // })
    return (
        <>
            {/* {isLoading && <Loader />} */}

            <Modal
                icon="true"
                iconName="close"
                styleName="lightIconButton"
                iconOnClick={modalInfo.onClose}
                show={modalInfo.show}
                title={modalInfo.title}
                message={modalInfo.message}
                errorList={modalInfo.errorList}
                closeOnBackdropClick={false}
                actionText={modalInfo.actionText}
                actionBox={
                    <>
                        {modalInfo.onConfirm && (
                            <span onClick={modalInfo.onConfirm}>
                                <TheButton styleName="red" animated='true'>
                                    {modalInfo.confirmText}
                                </TheButton>
                            </span>
                        )}
                        {modalInfo.onCancel && (
                            <span onClick={modalInfo.onCancel}>
                                <TheButton styleName="white" animated='true'>
                                    {modalInfo.cancelText}
                                </TheButton>
                            </span>
                        )}
                    </>
                }
            />
            <TheModalWrapper
                icon
                closeOnBackdropClick={false}
                show={saveListState.isShowModal}
                iconName="close"
                onCancel={cancelSaveHandler}
                title="Save Your List"
                subtitle="Product List Pro"
                headline="Fill out the form to save your list."

                // formMessages={
                //     saveListState.formMessage && (
                //         <div>
                //             {saveListState.isSuccess ? (
                //                 <FlashMessageWrapper
                //                     message={saveListState.formMessage}
                //                     onClick={closeSaveMessageHandler}
                //                     buttonIcons={
                //                         <TheButton
                //                             icon
                //                             buttonIcon
                //                             iconName="check"
                //                             styleName="successButtonIcon"
                //                         />
                //                     }
                //                 />

                //             ) : saveListState.isFailure ? (
                //                 <FlashMessageWrapper
                //                     message={saveListState.formMessage}
                //                     onClick={closeSaveMessageHandler}
                //                     buttonIcons={
                //                         <TheButton
                //                             icon
                //                             buttonIcon
                //                             iconName="exclamation"
                //                             styleName="warningButtonIcon"
                //                         />
                //                     }
                //                 />
                //             ) : !saveListState.isFormValid ? (
                //                 <FlashMessageWrapper
                //                     message={saveListState.formMessage}
                //                     onClick={closeSaveMessageHandler}
                //                     buttonIcons={
                //                         <TheButton
                //                             icon
                //                             buttonIcon
                //                             iconName="exclamation"
                //                             styleName="warningButtonIcon"
                //                         />
                //                     }
                //                 />
                //             ) : null}
                //         </div>
                //     )}

                formContent={
                    <form onSubmit={handleSubmitSaveList}>
                        <Input
                            id="listName"
                            label="Name your list"
                            element="input"
                            type="text"
                            validators={[VALIDATOR_MINLENGTH(3)]}
                            errorText="3 character in minimum."
                            onInput={inputHandler}
                            requireAsterix
                        />
                        <Input
                            id="listEmail"
                            label="Add email address to send to"
                            element="input"
                            type="email"
                            validators={[VALIDATOR_EMAIL()]}
                            errorText="Please enter a valid email address."
                            onInput={inputHandler}
                            requireAsterix
                            icon={faEnvelope}
                        // iconColor="gray"
                        />
                        <Input
                            id="listNotes"
                            label="Notes"
                            element="textarea"
                            rows={3}
                            type="text"
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="List notes must not be empty."
                            onInput={inputHandler}
                        />
                        <section>
                            <TheButton
                                styleName="red"

                                linkText="Save"

                                // buttonTracking
                                // interactionName="confirm-save"
                                animated='true'

                            >
                                Save
                            </TheButton>
                            <span onClick={cancelSaveHandler}>
                                <TheButton
                                    animated='true'
                                >
                                    Cancel
                                    {/* {translatedText.send} */}
                                </TheButton>
                            </span>
                        </section>
                    </form>
                }

            >





            </TheModalWrapper>
        </>
    )
}
export default TheSaveListModal;