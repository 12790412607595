import LGLogoSVG from "../../../Theme/Branding/LG_3D_CMYK.svg"
// import LGLogoSVG from "../../../Theme/Branding/LG-Product-Guide-Logo.svg";
import styles from "../BrandElement.styles.module.css"

const LGLogo = () => {
    return(
        <div className={styles.logoContainer}>
            <img
            src={LGLogoSVG}
            />
        </div>
    )
}

export default LGLogo;