
import UserDataTiles from "../../../PortalComponents/DisplayData/UserDataTiles";

import ProductGuideCityUsageLeaderboard from "../../../PortalComponents/DisplayData/ProductGuideCityUsageLeaderBoard"




import CustomDataTileGroup from "../../../PortalComponents/DisplayData/CustomDataTileGroup";

import styles from "./DataShowcase.module.css"
import { useContext } from "react";
import { AdminContext } from "../../../../Context/Admin.Context";

import ProductGuideSummaryTiles from "../../../PortalComponents/DisplayData/ProductGuideSummaryTiles";
import ProductInventoryGraphs from "../../../PortalComponents/DisplayData/ProductInventoryGraphs";

import ProductGuideTrafficCompare from "../../../PortalComponents/DisplayData/ProductGuideTrafficCompare";
import LGAppTrafficTables from "../../../PortalComponents/DisplayData/LGAppTrafficTable";
import HDAppTrafficTables from "../../../PortalComponents/DisplayData/HDAppTrafficTable";
import ProductGuideTrafficAcquisitionTable from "../../../PortalComponents/DisplayData/ProductGuideTrafficAcquisitionTable";


const DataShowcase = () => {
  const { isFilteredGoogleAnalyticsData, isPortalDataState } = useContext(AdminContext);

  console.log('data', isPortalDataState, 'fa', isFilteredGoogleAnalyticsData)

  return (
    <>
      <section className={styles.dataSection}>
        {/* <div className={styles.textBox}>
          <p className={styles.subtitle}>Key Metrics</p>
          <p className={styles.title}>Summary</p>
          <p className={styles.title}>Product Guide Traffic</p>
        </div> */}
        
{/* 
<ActiveUsersTable/> */}
{/* <ProductGuideSummaryTiles/> */}
        <UserDataTiles />
     
        <ProductGuideCityUsageLeaderboard />
        {/* <ProductGuideTrafficAcquisitionTable/>
        <CustomDataTileGroup />
       */}
        <ProductGuideTrafficCompare/>
        {/* <ProductInventoryGraphs/> */}
        {/* <LGAppTrafficTables/>
        <HDAppTrafficTables/> */}


      </section>



      {/* <section className={styles.dataSection}>
        <PopularSavedListProductsLeaderboard />
      </section> */}
      {/* 
        <DataSection
        title="Overview"
        statBarData={<UserDataTiles/>}
        featureChart={<ProductGuideCityUsageLeaderboard/>}
        sideChart={ <CustomDataTileGroup/>}
       
        >
          
          <ProductGuideTrafficCompare/>
          <PopularSavedListProductsLeaderboard/>
          <MemberSideBar/>
          <CustomDataActivityGraph/>
          <CustomDeviceUsageGraph/>
        </DataSection> */}
    </>
  )
}

export default DataShowcase;