import { useEffect, useState } from "react";
import styles from "./Clock.module.css"

const Clock = () => {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(interval); // This clears the interval when the component unmounts
    }, []);

    const formatDate = (date) => {
        // Format the day and month
        const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };

        const dateString = date.toLocaleDateString(undefined, dateOptions);
        const timeString = date.toLocaleTimeString(undefined, timeOptions);

        return `${dateString} ${timeString}`;
    };

    return (
        <div className={styles.clockContainer}>
            <p className={styles.clockText}>{formatDate(currentTime)}</p>
        </div>
    )
}

export default Clock;
