import styles from './ListIcon.module.css';
import { motion } from 'framer-motion';
import { useContext } from 'react';
import { SFWBuilderContext } from '../../../../../Context/BuilderContext';
import { RoutingContext } from '../../../../../Context/Routing.Context';
// import { usePopUpAnimation } from '../../../../../Hooks/AnimationHook/UseAnimiationHook';

import TheIcon from '../../../../Components/UI/TheIcon/TheIcon';
const ListIcon = () => {
    // const popUpAnimationProps = usePopUpAnimation();
    const { builderListItems } = useContext(SFWBuilderContext);
    const {setIsRoutingState, isRoutingState}=useContext(RoutingContext)
  
    const totalNumberOfProductsInList = builderListItems.length;
    let itemCountStyle = totalNumberOfProductsInList < 10 ? styles.itemCountSingleDigit : styles.itemCountDoubleDigit;

    const handleListToggle = () => {
        if (!isRoutingState.isBuilderDropdownOpen){
            setIsRoutingState(prevState => ({
                ...prevState, 
                isMobileBuilderDropdownOpen:false,
                isBuilderDropdownOpen:true,
            }))
        } 
        if (isRoutingState.isBuilderDropdownOpen){
            setIsRoutingState(prevState => ({
                ...prevState, 
                isBuilderDropdownOpen:false,
            }))
        }
    };

    return (
        <div className={styles.builderIconContainer} onClick={handleListToggle}>
            {/* <TheIcon name="cart"/> */}
            {/* <TheIcon name="productList"/> */}
            <TheIcon name="checkList"/>
            {totalNumberOfProductsInList > 0 && 
                <motion.span
                className={itemCountStyle}
                //    {...usePopUpAnimation}
                initial={{ opacity: 0, x: 50 }}
                animate={{
                    opacity: 1,
                    x: 0,
                    transition: {
                        duration: .3,
                        ease: [0.75, 0, 0, 1],
                    },
                }}
                exit={{
                    opacity: 0,
                    x: 50,
                    transition: {
                        duration: .3,
                        ease: [0.75, 0, 0, 1],
                    },
                }}
                >
                    {totalNumberOfProductsInList}
                </motion.span>
            }
        </div>
    );
}

export default ListIcon;
