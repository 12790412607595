import { useContext } from "react"
import { LanguageContext } from "../../../../Context/Language.Context"
import PageWrapperTemplate from "../../../Components/UI/Templates/PageWrapperTemplate"
import BodyContainer from "../../../Components/UI/BodyContainer/BodyContainer"

import styles from "./Legal.module.css"
import SectionTextContainer from "../../../Components/UI/SectionTextContainer/SectionTextContainer"
const PrivacyPage = () => {
    const { language, translatedText } = useContext(LanguageContext)

    const privacyContentEN = [
        {
            sec1: `INTRODUCTION`,
            sec1Body: `LG Electronics Canada, Inc. and our affiliates, subsidiaries, and agencies ("Company" or "We") respect your privacy and are committed to protecting it by complying with this policy.`,
            sec1BodyListTitle1: `This policy describes:`,
            sec1BodyListItems1: [
                `How we collect, use, disclose, and protect the personal information of our customers and website users ("you").`,
                `Describes the types of information we may collect from you or that you may provide when you visit the website www.lgproductguide.ca (our "Website").`,
                `Our practices for collecting, using, maintaining, protecting, and disclosing that information.`
            ],
            sec1Body2Par1: `We will only use your personal information in accordance with this policy unless otherwise required by applicable law. We take steps to help ensure that the personal information that we collect about you is adequate, relevant, not excessive, and used for limited purposes.`,
            sec1Body2Par2: `Privacy laws in Canada generally define "personal information" as any information about an identifiable individual, which includes information that can be used on its own or with other information to identify, contact, or locate a single person. Personal information does not include business contact information, including your name, title, or business contact information.`,


            sec1Body2Par3: `This policy applies to information we collect, use, or disclose about you on this Website, or in email, text, and other electronic messages between you and this Website.`,


            sec1Body2Par4: `The Website may include links to third-party websites, plug-ins, services, social networks, or applications. Clicking on those links or enabling those connections may allow the third party to collect or share data about you. If you follow a link to a third-party website or engage a third-party plugin, please note that these third parties have their own privacy policies and we do not accept any responsibility or liability for these policies. We do not control these third-party websites, and we encourage you to read the privacy policy of every website you visit.`,


            sec1Body2ListTitle2: `This policy DOES NOT apply to information that:`,



            sec1Body2ListItems2: [
                `We collect offline or through any other means, including on any other Company or third-party website (including our affiliates and subsidiaries).`,
                `You provide to or is collected by any third party (including our affiliates and subsidiaries), through any application or content (including advertising) that may link to or be accessible from or on the Website.`
            ],


            sec1Body2Par3: `Please read this policy carefully to understand our policies and practices for collecting, processing, and storing your information. If you do not agree with our policies and practices, your choice is not to use our Website. By accessing or using this Website, you indicate that you understand, accept, and consent to the practices described in this policy. This policy may change from time to time (see Changes to Our Privacy Policy). Your continued use of this Website after we make changes indicates that you accept and consent to those changes, so please check the policy periodically for updates.`,


            sec2: `INFORMATION WE COLLECT ABOUT YOU`,


            sec2Body2ListTitle1: `We collect and use several types of information from and about you, including:`,

            sec2Body2ListItems1: [
                `Personal information, that we can reasonably use to directly or indirectly identify you, such as your name, e-mail address, telephone number, employer, employer’s address, Internet protocol (IP) address used to connect your computer to the Internet and any other identifier we may use to contact you ("personal information"). 
            We provide an opportunity for any user to remove any personal information collected by our LG Product Guide website by accessing the Website and deleting the applicable user profile.`,
                `Non-personal information, that does not directly or indirectly reveal your identity or directly relate to an identifiable individual, such as demographic information, or statistical or aggregated information. Statistical or aggregated data does not directly identify a specific person, but we may derive non-personal statistical or aggregated data from personal information. For example, we may aggregate personal information to calculate the percentage of users accessing a specific Website feature.`,
                `Technical information, including your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform, or information about your internet connection, the equipment you use to access our Website, and usage details.`,
                `Non-personal details about your Website interactions, including the full Uniform Resource Locators (URLs), clickstream to, through and from our Website (including date and time), products you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page, or any phone number used to call our customer service number.`
            ],


            sec3: `HOW WE COLLECT INFORMATION ABOUT YOU`,


            sec3BodyListTitle1: `We use different methods to collect your information, including through:`,


            sec3BodyListItems1: [
                `Direct interactions with you when you provide it to us, for example, by filling in forms or corresponding with us by phone, email, or otherwise.`,
                `Automated technologies or interactions, as you navigate through our Website. Information collected automatically may include usage details, IP addresses, and information collected through cookies, Flash cookies, web beacons, tags, and other tracking technologies.`,
            ],


            sec3SubSec1: `Information You Provide to Us`,

            sec3SubSec1BodyListTitle1: `The information we collect directly from you on or through our Website may include:`,

            sec3SubSec1BodyListItems1: [
                `Information that you provide by filling in forms on our Website. This includes information provided at the time of registering to use our Website. We may also ask you for information when you report a problem with our Website.`,
                `Records and copies of your correspondence (including email addresses) if you contact us.`,
                `Your search queries on the Website.`,
                `Information gathered from customer prospects, from whom you have obtained separate consent.`
            ],

            sec3SubSec2: `Information We Collect Through Cookies and Other Automatic Data Collection Technologies`,


            sec3SubSec2BodyPar1: `As you navigate through and interact with our Website, we may use cookies or other automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:`,


            sec3SubSec2BodyListItems1: [
                `Details of your visits to our Website, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Website.`,
                `Information about your computer and internet connection, including your IP address, operating system, and browser type.`
            ],




            sec3SubSec2BodyPar2: `The information we collect automatically is statistical data and does not include personal information, and we may maintain it or associate it with personal information we collect that you provide to us. It helps us to improve our Website and to deliver a better and more personalized service, including by enabling us to:`,



            sec3SubSec2BodyListItems2: [
                `Estimate our audience size and usage patterns.`,
                `Store information about your preferences, allowing us to customize our Website according to your individual interests.`,
                `Speed up your searches.`,
                `Recognize you when you return to our Website.`
            ],


            sec3SubSec2BodyPar3: `The technologies we use for this automatic data collection may include:`,



            // sec3SubSec2BodyListItems3: [
            //     `Cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website.`,
            //     `Flash Cookies. Certain features of our Website may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our Website. Flash cookies are not managed by the same browser settings that are used for browser cookies. For information about managing your privacy and security settings for Flash cookies, see Choices About How We Use and Disclose Your Information.`, `Web Beacons. Pages of our Website and our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).`
            //         `Web Beacons. Pages of our Website and our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).`
            // ],


            sec4: `HOW WE USE YOUR INFORMATION`,



            sec4BodyListTitle1: `We use information that we collect about you or that you provide to us, including any personal information:`,

            sec4BodyListItems1: [
                `To present our Website and its contents to you.`,
                `To provide you with information, products, or services that you request from us.`,
                `To fulfill the purposes for which you provided the information or that were described when it was collected, or any other purpose for which you provide it.`,
                `To provide you with notices about your account.`,
                `To carry out our obligations and enforce our rights arising from any contracts with you, including to comply with legal requirements.`,
                `To notify you about changes to our Website or any products or services we offer or provide though it.`,
                `To improve our Website, products or services, marketing, or customer relationships and experiences.`,
                `In any other way we may describe when you provide the information.`,
                `For any other purpose with your consent.`
            ],


            sec5: `DISCLOSURE OF YOUR INFORMATION`,
            sec5Body1Par1: `We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.`,
            sec5BodyListTitle1: `We may disclose personal information that we collect or you provide as described in this privacy policy:`,
            sec5BodyListItems1: [
                `To our subsidiaries and affiliates.`,
                `In accordance with applicable law, to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of LG Electronics Canada, Inc.'s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by LG Electronics Canada, Inc. about our customers and users is among the assets transferred.`,
                `To contractors, service providers, and other third parties we use to support our business (such as analytics and search engine providers that assist us with Website improvement and optimization) and who are contractually obligated to keep personal information confidential, use it only for the purposes for which we disclose it to them, and to process the personal information with the same standards set out in this policy.`,
                `To fulfill the purpose for which you provide it.`,
                `For any other purpose disclosed by us when you provide the information.`,
                `With your consent.`
            ],
            sec5BodyListTitle2: `We may also disclose your personal information:`,
            sec5BodyListItems2: [
                `To comply with any court order, law, or legal process, including to respond to any government or regulatory request, in accordance with applicable law.`,
                `To enforce or apply our Terms and Conditions of Use and other agreements, including for billing and collection purposes.`,
                `If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of LG Electronics Canada, Inc., our customers, our vendors, or others.`
            ],



            sec6: `TRANSFERRING YOUR PERSONAL INFORMATION`,


            sec6Body1Par1: `We may transfer personal information that we collect or that you provide as described in this policy to contractors, service providers, and other third parties we use to support our business (such as analytics and search engine providers that assist us with Website improvement and optimization) and who are contractually obligated to keep personal information confidential, use it only for the purposes for which we disclose it to them, and to process the personal information with the same standards set out in this policy.`,

            sec6Body1Par2: `We may process, store, and transfer your personal information in and to a foreign country, with different privacy laws that may or may not be as comprehensive as Canadian law. In these circumstances, the governments, courts, law enforcement, or regulatory agencies of that country may be able to obtain access to your personal information through the laws of the foreign country. Whenever we engage a service provider, we require that its privacy and security standards adhere to this policy and applicable Canadian privacy legislation.`,


            sec6Body1Par3: `By submitting your personal information or engaging with the Website, you consent to this transfer, storage, or processing.`,


            sec7: `CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION`,

            sec7Body1Par1: `We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:`,

            sec7SubSec1Par1: `Tracking Technologies and Advertising. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe's website. If you disable or refuse cookies, please note that some parts of this Website may not be accessible or may not function properly. For more information about tracking technologies, see Information We Collect Through Cookies and Other Automatic Data Collection Technologies.`,


            sec8: `DATA SECURITY`,

            sec8Body1Par1: `The security of your personal information is very important to us. We use physical, electronic, and administrative measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. We store all information you provide to us behind firewalls on our secure servers.`,

            sec8Body1Par2: `The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.`,


            sec8Body1Par3: `Unfortunately, the transmission of information via the Internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.`,


            sec9: `DATA RETENTION`,

            sec9Body1Par1: `Except as otherwise permitted or required by applicable law or regulation, we will only retain your personal information for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. Under some circumstances we may anonymize your personal information so that it can no longer be associated with you. We reserve the right to use such anonymous and de-identified data for any legitimate business purpose without further notice to you or your consent.`,

            sec10: `CHILDREN UNDER THE AGE OF 13`,

            sec10Body1Par1: `Our Website is not intended for children under 13 years of age. No one under age 13 may provide any information to the Website. We do not knowingly collect personal information from children under 13. If you are under 13, do not use or provide any information on this Website or on or through any of its features/register on the Website, or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you may use. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13, please contact us at productguideprivacy@teamlg.ca.`,

            sec11: `ACCESSING AND CORRECTING YOUR PERSONAL INFORMATION`,

            sec11Body1Par1: `It is important that the personal information we hold about you is accurate and current. Please keep us informed if your personal information changes. By law you have the right to request access to and to correct the personal information that we hold about you.`,

            sec11Body1Par2: `You can review, change and delete your personal information by logging into the Website and visiting your account profile page.`,


            sec11Body1Par3: `We may request specific information from you to help us confirm your identity and your right to access, and to provide you with the personal information that we hold about you or make your requested changes.`,


            sec11Body1Par4: `Applicable law may allow or require us to refuse to provide you with access to some or all of the personal information that we hold about you, or we may have destroyed, erased, or made your personal information anonymous in accordance with our record retention obligations and practices. If we cannot provide you with access to your personal information, we will inform you of the reasons why, subject to any legal or regulatory restrictions. If you are concerned about our response, you may contact us at productguideprivacy@teamlg.ca.`,


            sec12: `WITHDRAWING YOUR CONSENT`,

            sec12Body1Par1: `Where you have provided your consent to the collection, use, and transfer of your personal information, you may have the legal right to withdraw your consent under certain circumstances. To withdraw your consent, you may access the Website and delete the applicable user profile, or contact us at productguideprivacy@teamlg.ca. Please note that if you withdraw your consent we may not be able to provide you with a particular product or service. We will explain the impact to you at the time to help you with your decision.`,


            sec13: `CHANGES TO OUR PRIVACY POLICY`,


            sec13Body1Par1: `It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users' personal information, we will notify you by email to the email address specified in your account or through a notice on the Website home page.`,


            sec13Body1Par2: `We include the date the privacy policy was last revised at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you, and for periodically visiting our Website and this privacy policy to check for any changes.`,


            sec14: `CONTACT INFORMATION AND CHALLENGING COMPLIANCE`,


            sec14Body1Par1: `We welcome your questions, comments, and requests regarding this privacy policy and our privacy practices. Please contact us at:`,


            sec14Body1AddressList: [
                `Product Guide Privacy`,
                `LG Electronics Canada Inc.`,
                `20 Norelco Drive, North York,`,
                `ON  M9L 2X6`
            ],


            sec14Body1Email: `productguideprivacy@teamlg.ca`,


            sec14Body1Par2: `We have procedures in place to receive and respond to complaints or inquiries about our handling of personal information, our compliance with this policy, and with applicable privacy laws. To discuss our compliance with this policy please contact us using the contact information listed above.`,


        }
    ]

    const privacyContentFR = [
        {

            sec1: `INTRODUCTION`,
            sec1Body: `LG Electronics Canada, Inc. et nos affiliés, filiales et agences ("Société" ou "Nous") respectent votre vie privée et s'engagent à la protéger en se conformant à cette politique.`,
            sec1BodyListTitle1: `Cette politique décrit:`,

            sec1BodyListItems1: [
                `Comment nous collectons, utilisons, divulguons et protégeons les informations personnelles de nos clients et utilisateurs du site web ("vous").`,
                `Décrit les types d'informations que nous pouvons collecter auprès de vous ou que vous pouvez fournir lorsque vous visitez le site web www.lgproductguide.ca (notre "Site Web").`,
                `Nos pratiques pour collecter, utiliser, maintenir, protéger et divulguer ces informations. `
            ],
            sec1Body2Par1: `Nous n'utiliserons vos informations personnelles qu'en conformité avec cette politique, sauf si la loi applicable l'exige autrement. Nous prenons des mesures pour garantir que les informations personnelles que nous collectons sur vous sont adéquates, pertinentes, non excessives et utilisées à des fins limitées.`,



            sec1Body2Par2: `Les lois sur la vie privée au Canada définissent généralement les "informations personnelles" comme toute information concernant un individu identifiable, ce qui comprend les informations pouvant être utilisées seules ou avec d'autres informations pour identifier, contacter ou localiser une seule personne. Les informations personnelles ne comprennent pas les coordonnées professionnelles, y compris votre nom, titre ou coordonnées professionnelles.`,


            sec1Body2Par3: `Cette politique s'applique aux informations que nous collectons, utilisons ou divulguons à votre sujet sur ce Site Web, ou dans des courriels, des textos et d'autres messages électroniques entre vous et ce Site Web.`,


            sec1Body2Par4: `Le site Web peut inclure des liens vers des sites Web tiers, des plugins, des services, des réseaux sociaux ou des applications. Cliquer sur ces liens ou activer ces connexions peut permettre au tiers de collecter ou de partager des données vous concernant. Si vous suivez un lien vers un site web tiers ou activez un plugin tiers, veuillez noter que ces tiers ont leurs propres politiques de confidentialité et nous déclinons toute responsabilité quant à ces politiques. Nous ne contrôlons pas ces sites web tiers et nous vous encourageons à lire la politique de confidentialité de chaque site que vous visitez.`,


            sec1BodyListTitle2: `Cette politique NE S'APPLIQUE PAS aux informations que :`,




            sec1BodyListItems2: [
                `Nous collectons hors ligne ou par d'autres moyens, y compris sur un autre site web de la Société ou d'un tiers (y compris nos affiliés et filiales).`,
                `Vous fournissez ou qui sont collectées par un tiers (y compris nos affiliés et filiales), via une application ou un contenu (y compris la publicité) pouvant être lié ou accessible depuis ou sur le Site Web.`
            ],


            sec1BodyPar3: `Veuillez lire attentivement cette politique pour comprendre nos politiques et pratiques concernant la collecte, le traitement et le stockage de vos informations. Si vous n'êtes pas d'accord avec nos politiques et pratiques, votre choix est de ne pas utiliser notre Site Web. En accédant ou en utilisant ce Site Web, vous indiquez que vous comprenez, acceptez et consentez aux pratiques décrites dans cette politique. Cette politique peut changer de temps en temps (voir Modifications de notre politique de confidentialité). Votre utilisation continue de ce Site Web après que nous ayons apporté des modifications indique que vous acceptez et consentez à ces changements, alors veuillez vérifier la politique régulièrement pour des mises à jour.`,


            sec2: `INFORMATIONS QUE NOUS COLLECTONS À VOTRE SUJET`,


            sec2BodyListTitle1: `Nous collectons et utilisons plusieurs types d'informations vous concernant, y compris :`,


            sec2BodyListItems1: [
                `Des informations personnelles, que nous pouvons raisonnablement utiliser pour vous identifier directement ou indirectement, telles que votre nom, adresse courriel, numéro de téléphone, employeur, adresse de l'employeur, adresse IP utilisée pour connecter votre ordinateur à Internet et tout autre identifiant que nous pourrions utiliser pour vous contacter ("informations personnelles"). Nous offrons la possibilité à tout utilisateur de supprimer toutes les informations personnelles collectées par notre site web LG Product Guide en accédant au Site Web et en supprimant le profil utilisateur concerné.`,
                `Des informations non personnelles, qui ne révèlent pas directement ou indirectement votre identité ou ne se rapportent pas directement à un individu identifiable, telles que des informations démographiques ou des informations statistiques ou agrégées. Les données statistiques ou agrégées ne permettent pas d'identifier directement une personne spécifique, mais nous pouvons obtenir des données statistiques ou agrégées non personnelles à partir d'informations personnelles. Par exemple, nous pourrions agréger des informations personnelles pour calculer le pourcentage d'utilisateurs accédant à une fonctionnalité spécifique du site Web.`,
                `Des informations techniques, incluant vos informations de connexion, le type et la version de votre navigateur, le paramétrage de votre fuseau horaire, les types et versions des plugins de votre navigateur, votre système d'exploitation et votre plateforme, ou des informations sur votre connexion Internet, l'équipement que vous utilisez pour accéder à notre Site Web et les détails de votre utilisation.`,
                `Des détails non personnels concernant vos interactions sur le Site Web, incluant les Uniform Resource Locators (URL) complets, le flux de clics vers, à travers et depuis notre Site Web (y compris la date et l'heure), les produits que vous avez consultés ou recherchés, les temps de réponse des pages, les erreurs de téléchargement, la durée des visites sur certaines pages, les informations sur l'interaction avec les pages (telles que le défilement, les clics et les survols de la souris), les méthodes utilisées pour quitter la page, ou tout numéro de téléphone utilisé pour appeler notre numéro de service client.`
            ],


            sec3: `COMMENT NOUS COLLECTONS DES INFORMATIONS À VOTRE SUJET `,


            sec3BodyListTitle1: `Nous utilisons différentes méthodes pour collecter vos informations, notamment à travers :`,


            sec3BodyListItems1: [
                `Des interactions directes avec vous lorsque vous nous les fournissez, par exemple, en remplissant des formulaires ou en correspondant avec nous par téléphone, courriel ou autrement.`,
                `Des technologies automatisées ou interactions, lorsque vous naviguez sur notre Site Web. Les informations collectées automatiquement peuvent inclure des détails d'utilisation, des adresses IP, et des informations collectées à travers les cookies, les Flash cookies, les balises web, les tags et d'autres technologies de suivi.`,
            ],


            sec3SubSec1: `Informations que vous nous fournissez 
            `,


            sec3SubSec1BodyListTitle1: `Les informations que nous collectons directement auprès de vous sur ou via notre Site Web peuvent inclure : 
            `,


            sec3SubSec1BodyListItems1: [
                `Informations que vous fournissez en remplissant des formulaires sur notre Site Web. Cela inclut les informations fournies lors de l'inscription pour utiliser notre Site Web, la souscription à nos services, la publication de matériel, ou la demande de services supplémentaires. Nous pouvons également vous demander des informations lorsque vous signalez un problème avec notre Site Web.`,
                `Des enregistrements et copies de votre correspondance (y compris l'adresse courriel), si vous nous contactez.`,
                `Vos réponses à des enquêtes que nous pourrions vous demander de remplir à des fins de recherche, bien que vous n'ayez aucune obligation de répondre à ces enquêtes.`,
                `Des détails sur les transactions que vous effectuez à travers notre Site Web et sur l'exécution de vos commandes.Vos recherches sur le Site Web.`
            ],

            sec3SubSec2: `Informations que nous collectons à travers des technologies automatisées`,



            sec3SubSec2BodyPar1: `Comme vous naviguez et interagissez avec notre Site Web, nous pouvons utiliser des technologies de collecte de données automatiques pour collecter certaines informations sur votre équipement, vos actions de navigation et vos modèles, notamment : 
            `,


            sec3SubSec2BodyListItems1: [
                `Des détails de vos visites sur notre Site Web, incluant, mais sans s'y limiter, les données de trafic, les données de localisation, les journaux et d'autres données de communication, ainsi que les ressources auxquelles vous accédez et utilisez sur le Site Web. 
                `,
                `Des informations sur votre ordinateur et votre connexion Internet, y compris votre adresse IP, votre système d'exploitation et le type de navigateur. 
                `
            ],



            sec3SubSec2BodyPar2: `Les informations que nous collectons automatiquement peuvent inclure des informations personnelles, ou nous pouvons maintenir ces informations ou les associer à des informations personnelles que nous collectons d'autres manières ou que nous recevons d'autres sources. Cela nous aide à améliorer notre Site Web et à offrir un service meilleur et plus personnalisé, notamment en nous permettant de : `,



            sec3SubSec2BodyListItems2: [
                `Estimer la taille et les habitudes d'utilisation de notre public. `,
                `Conserver des informations sur vos préférences, nous permettant ainsi de personnaliser notre Site Web selon vos intérêts individuels.`,
                `Accélérer vos recherches.`,
                `Vous reconnaître lorsque vous revenez sur notre Site Web.`
            ],


            sec3SubSec2BodyPar3: `Les technologies que nous utilisons pour cette collecte automatique de données peuvent inclure :`,



            sec3SubSec2BodyListItems3: [
                `Les cookies (ou cookies de navigateur). Un cookie est un petit fichier déposé sur le disque dur de votre ordinateur. Vous pouvez refuser d'accepter les cookies de navigateur en activant le paramètre approprié sur votre navigateur. Cependant, si vous sélectionnez ce paramètre, vous pourriez ne pas être en mesure d'accéder à certaines parties de notre Site Web. À moins que vous n'ayez réglé les paramètres de votre navigateur pour qu'il refuse les cookies, notre système émettra des cookies lorsque vous dirigerez votre navigateur vers notre Site Web.`,
                `Les Flash cookies. Certains aspects de notre Site Web peuvent utiliser des objets stockés localement (ou Flash cookies) pour collecter et stocker des informations sur vos préférences et votre navigation vers, depuis et sur notre Site Web. Les Flash cookies ne sont pas gérés par les mêmes paramètres de navigateur que sont utilisés pour les cookies de navigateur. Pour plus d'informations sur la gestion de vos paramètres de confidentialité et de sécurité pour les Flash cookies, vous pouvez consulter les Outils d'aide de Flash Player disponibles sur le site Web d'Adobe.`,
                `Les balises web. Les pages de notre Site Web et nos courriels peuvent contenir de petits fichiers électroniques appelés balises web (également appelées pixels espions, pixels invisibles et GIF à pixel unique) qui permettent à la Société, par exemple, de compter les utilisateurs qui ont visité ces pages ou ouvert un courriel et pour d'autres statistiques de sites Web associées (par exemple, enregistrer la popularité de certains contenus du site Web et vérifier l'intégrité du système et du serveur). Nous n'utilisons pas de suivi automatique pour recueillir des informations personnelles sur vous sans votre consentement. Certains navigateurs envoient des signaux "Ne pas suivre" à des sites web. Notre Site Web ne répond pas actuellement à ces signaux.`
            ],

            // sec4: `COMMENT NOUS UTILISONS VOS INFORMATIONS`,
            // sec4BodyListTitle1: `Nous pouvons divulguer des informations agrégées sur nos utilisateurs, et des informations qui ne permettent pas d'identifier un individu, sans restriction. Nous pouvons divulguer des informations personnelles que nous collectons ou que vous fournissez, tel que décrit dans cette politique de confidentialité : `,
            // sec4BodyListItems1: [
            //     "À nos filiales et affiliés.",
            //     "À des entrepreneurs, prestataires de services et autres tiers que nous utilisons pour soutenir notre entreprise et qui sont liés par des obligations contractuelles de garder les informations personnelles confidentielles et de les utiliser uniquement aux fins pour lesquelles nous les divulguons.",
            //     "Pour répondre à une assignation à comparaître, une ordonnance du tribunal ou un processus juridique similaire, y compris à des organismes d'application de la loi ou d'autres organismes gouvernementaux.",
            //     "Afin d'appliquer ou d'appliquer nos conditions d'utilisation et autres accords, y compris pour la facturation et la collecte de paiements.",
            //     "Pour protéger les droits, la propriété ou la sécurité de la Société, de nos clients ou d'autres, y compris pour éviter ou réduire le risque de fraude ou de crédit.",
            //     "Dans le cadre d'une fusion, cession de biens, financement, acquisition, dissolution, transaction ou dans le cas où tout ou une partie de notre entreprise est vendue ou acquise par une autre société, que ce soit en tant que continuation ou en tant qu'entité distincte, et les informations des utilisateurs peuvent être l'un des actifs transférés.",
            //     "Avec votre consentement."
            // ],




            sec5:'COMMENT NOUS UTILISONS VOS INFORMATIONS ',
            sec5Body1Par1: `Nous pouvons divulguer des informations agrégées sur nos utilisateurs, et des informations qui ne permettent pas d'identifier un individu, sans restriction.`,
            sec5BodyListTitle1: `Nous pouvons divulguer des informations personnelles que nous collectons ou que vous fournissez, tel que décrit dans cette politique de confidentialité :`,
            sec5BodyListItems1: [
                "À nos filiales et affiliés.",
                "À des entrepreneurs, prestataires de services et autres tiers que nous utilisons pour soutenir notre entreprise et qui sont liés par des obligations contractuelles de garder les informations personnelles confidentielles et de les utiliser uniquement aux fins pour lesquelles nous les divulguons.",
                "Pour répondre à une assignation à comparaître, une ordonnance du tribunal ou un processus juridique similaire, y compris à des organismes d'application de la loi ou d'autres organismes gouvernementaux.",
                "Afin d'appliquer ou d'appliquer nos conditions d'utilisation et autres accords, y compris pour la facturation et la collecte de paiements.",
                "Pour protéger les droits, la propriété ou la sécurité de la Société, de nos clients ou d'autres, y compris pour éviter ou réduire le risque de fraude ou de crédit.",
                "Dans le cadre d'une fusion, cession de biens, financement, acquisition, dissolution, transaction ou dans le cas où tout ou une partie de notre entreprise est vendue ou acquise par une autre société, que ce soit en tant que continuation ou en tant qu'entité distincte, et les informations des utilisateurs peuvent être l'un des actifs transférés.",
                "Avec votre consentement."
            ],
            sec5BodyListTitle2: `Nous pourrions également divulguer vos informations personnelles :`,
            sec5BodyListItems2: [
                `Pour se conformer à une ordonnance du tribunal, à une loi ou à une procédure légale, y compris pour répondre à toute demande du gouvernement ou d'une autorité réglementaire, conformément à la loi applicable. `,
                `Pour faire respecter ou appliquer nos Termes et Conditions d'Utilisation et autres accords, y compris à des fins de facturation et de recouvrement.`,
                `Si nous pensons que la divulgation est nécessaire ou appropriée pour protéger les droits, la propriété ou la sécurité de LG Electronics Canada, Inc., nos clients, nos fournisseurs ou d'autres. `
            ],




            sec6: `TRANSFERT DE VOS INFORMATIONS PERSONNELLES`,


            sec6Body1Par1: `Nous pouvons transférer les informations personnelles que nous recueillons ou que vous fournissez, telles que décrites dans cette politique, à des entrepreneurs, des prestataires de services et à d'autres tiers avec lesquels nous collaborons pour soutenir notre entreprise (tels que des fournisseurs d'analyse et de moteurs de recherche qui nous aident à améliorer et optimiser notre site Web). Ces parties sont contractuellement tenues de garder les informations personnelles confidentielles, de les utiliser uniquement aux fins pour lesquelles nous les leur avons divulguées et de traiter ces informations conformément aux normes établies dans cette politique.`,


            sec6Body1Par2: `Nous pouvons traiter, stocker et transférer vos informations personnelles vers et dans un pays étranger, avec des lois sur la confidentialité différentes qui peuvent être plus ou moins complètes que la loi canadienne. Dans ces circonstances, les gouvernements, les tribunaux, les organismes d'application de la loi ou les agences réglementaires de ce pays peuvent obtenir l'accès à vos informations personnelles à travers les lois du pays étranger. Lorsque nous faisons appel à un prestataire de services, nous exigeons que ses normes en matière de confidentialité et de sécurité soient conformes à cette politique et à la législation canadienne sur la confidentialité.`,


            sec6Body1Par3: `En soumettant vos informations personnelles ou en interagissant avec le site Web, vous consentez à ce transfert, stockage ou traitement.`,



            sec7: `CHOIX CONCERNANT LA FAÇON DONT NOUS UTILISONS ET DIVULGUONS VOS INFORMATIONS `,


            sec7Body1Par1: `Nous nous efforçons de vous fournir des choix concernant les informations personnelles que vous nous fournissez. Nous avons créé des mécanismes pour vous offrir le contrôle suivant sur vos informations :`,


            sec7SubSec1Par1: `Technologies de suivi et publicité. Vous pouvez configurer votre navigateur pour refuser tous les cookies ou certains d'entre eux, ou pour vous alerter lors de l'envoi de cookies. Pour savoir comment gérer vos paramètres de cookies Flash, visitez la page des paramètres du lecteur Flash sur le site Web d'Adobe. Si vous désactivez ou refusez les cookies, veuillez noter que certaines parties de ce site Web peuvent ne pas être accessibles ou peuvent ne pas fonctionner correctement. Pour plus d'informations sur les technologies de suivi, consultez les Informations que nous collectons via les cookies et autres technologies de collecte de données automatique.`,


            sec8: `SÉCURITÉ DES DONNÉES `,


            sec8Body1Par1: `La sécurité de vos informations personnelles est très importante pour nous. Nous utilisons des mesures physiques, électroniques et administratives conçues pour protéger vos informations personnelles contre la perte accidentelle et contre l'accès, l'utilisation, l'altération et la divulgation non autorisés. Nous stockons toutes les informations que vous nous fournissez derrière des pare-feu sur nos serveurs sécurisés.`,


            sec8Body1Par2: `La sécurité de vos informations dépend également de vous. Lorsque nous vous avons donné (ou lorsque vous avez choisi) un mot de passe pour accéder à certaines parties de notre site Web, vous êtes responsable de la confidentialité de ce mot de passe. Nous vous demandons de ne pas partager votre mot de passe avec quiconque.`,


            sec8Body1Par3: `Malheureusement, la transmission d'informations via Internet n'est pas complètement sécurisée. Bien que nous fassions de notre mieux pour protéger vos informations personnelles, nous ne pouvons garantir la sécurité de vos informations personnelles transmises à notre site Web. Toute transmission d'informations personnelles est à vos propres risques. Nous ne sommes pas responsables de la contournement de tout paramètre de confidentialité ou mesure de sécurité contenue sur le site Web.`,


            sec9: `CONSERVATION DES DONNÉES`,


            sec9Body1Par1: `Sauf autorisation ou exigence contraire de la loi ou du règlement applicable, nous conserverons vos informations personnelles uniquement le temps nécessaire pour remplir les objectifs pour lesquels nous les avons collectées, y compris pour satisfaire à toute exigence légale, comptable ou de rapport. Dans certaines circonstances, nous pouvons anonymiser vos informations personnelles afin qu'elles ne puissent plus être associées à vous. Nous nous réservons le droit d'utiliser ces données anonymes et désidentifiées à toute fin commerciale légitime sans autre notification ni votre consentement.`,


            sec10: `ENFANTS DE MOINS DE 13 ANS`,


            sec10Body1Par1: `Notre site Web n'est pas destiné aux enfants de moins de 13 ans. Personne de moins de 13 ans ne peut fournir d'informations au site Web. Nous ne collectons pas sciemment d'informations personnelles d'enfants de moins de 13 ans. Si vous avez moins de 13 ans, ne pas utiliser ni fournir d'informations sur ce site Web ni via l'une de ses fonctionnalités/ne pas vous inscrire sur le site Web, ni fournir d'informations vous concernant, y compris votre nom, adresse, numéro de téléphone, adresse courriel ou tout nom d'écran ou nom d'utilisateur que vous pourriez utiliser. Si nous apprenons que nous avons collecté ou reçu des informations personnelles d'un enfant de moins de 13 ans sans vérification du consentement parental, nous supprimerons ces informations. Si vous pensez que nous pourrions avoir des informations concernant un enfant de moins de 13 ans, veuillez nous contacter à productguideprivacy@teamlg.ca`,


            sec11: `ACCÈS ET CORRECTION DE VOS INFORMATIONS PERSONNELLES`,


            sec11Body1Par1: `Il est important que les informations personnelles que nous détenons à votre sujet soient exactes et à jour. Veuillez nous informer si vos informations personnelles changent. Par la loi, vous avez le droit de demander l'accès et la correction des informations personnelles que nous détenons à votre sujet. `,


            sec11Body1Par2: `Vous pouvez consulter, modifier et supprimer vos informations personnelles en vous connectant au site Web et en visitant la page de profil de votre compte.  `,


            sec11Body1Par3: `Nous pourrions vous demander des informations spécifiques pour nous aider à confirmer votre identité et votre droit d'accès, et pour vous fournir les informations personnelles que nous détenons à votre sujet ou effectuer vos modifications demandées.`,


            sec11Body1Par4: `La loi applicable peut nous permettre ou nous obliger à refuser de vous fournir l'accès à tout ou partie des informations personnelles que nous détenons à votre sujet, ou nous pourrions avoir détruit, effacé ou rendu anonymes vos informations personnelles conformément à nos obligations et pratiques de conservation des dossiers. Si nous ne pouvons pas vous fournir l'accès à vos informations personnelles, nous vous informerons des raisons, sous réserve de toute restriction légale ou réglementaire. Si vous avez des préoccupations concernant notre réponse, vous pouvez nous contacter à productguideprivacy@teamlg.ca.`,



            sec12: `RÉVOCATION DE VOTRE CONSENTEMENT `,


            sec12Body1Par1: `Lorsque vous avez donné votre consentement à la collecte, l'utilisation et le transfert de vos informations personnelles, vous pourriez avoir le droit légal de révoquer votre consentement dans certaines circonstances. Pour révoquer votre consentement, vous pouvez accéder au site Web et supprimer le profil utilisateur concerné, ou nous contacter à productguideprivacy@teamlg.ca. Veuillez noter que si vous révoquez votre consentement, nous pourrions ne pas être en mesure de vous fournir un produit ou service particulier. Nous vous expliquerons les conséquences à ce moment-là pour vous aider dans votre décision.`,


            sec13: `MODIFICATIONS DE NOTRE POLITIQUE DE CONFIDENTIALITÉ `,


            sec13Body1Par1: `Notre politique est de publier tout changement que nous apportons à notre politique de confidentialité sur cette page. Si nous apportons des modifications substantielles à la façon dont nous traitons les informations personnelles de nos utilisateurs, nous vous en informerons par courriel à l'adresse courriel spécifiée dans votre compte ou par un avis sur la page d'accueil du site Web.`,


            sec13Body1Par2: `Nous indiquons la date de la dernière révision de la politique de confidentialité en haut de la page. Vous êtes responsable de veiller à ce que nous ayons une adresse courriel valide, active et livrable pour vous, et de visiter régulièrement notre site Web et cette politique de confidentialité pour vérifier les éventuels changements.`,


            sec14: `COORDONNÉES ET MISE EN CAUSE DE LA CONFORMITÉ `,


            sec14Body1Par1: `Nous accueillons vos questions, commentaires et demandes concernant cette politique de confidentialité et nos pratiques en matière de confidentialité. Veuillez nous contacter à : `,



            sec14Body1AddressList: [
                `Guide de Confidentialité des Produits`,
                `LG Electronics Canada Inc.`,
                `20 Norelco Drive, North York,`,
                `ON  M9L 2X6`,
                `productguideprivacy@teamlg.ca`
            ],
            sec14Body1Par2: `Nous avons des procédures en place pour recevoir et répondre aux plaintes ou demandes concernant notre traitement des informations personnelles, notre conformité avec cette politique et avec les lois sur la confidentialité applicables. Pour discuter de notre conformité à cette politique, veuillez nous contacter en utilisant les coordonnées ci-dessus. `



        }
    ]
    const PrivacyPageContent = language === "EN" ? privacyContentEN : privacyContentFR;
    return (
        <PageWrapperTemplate>
            <BodyContainer>
                {PrivacyPageContent?.map((x, idx) => (
                    <section key={idx}>
                        <SectionTextContainer
                            titleText={translatedText.privacy}
                        />

                        <div className={styles.sectionContainer}>
                            <div className={styles.sectionGroup}>
                                <div className={styles.titleBox}>
                                    <p className={styles.title}>{x.sec1}</p>
                                </div>
                                <div className={styles.textBox}>
                                    <p className={styles.bodyText}>{x.sec1Body}</p>
                                </div>

                                <div className={styles.groupWrapper}>
                                    <div className={styles.listTitleBox}>
                                        <p className={styles.listTitle}>{x.sec1BodyListTitle1}</p>
                                    </div>
                                    <div className={styles.listBox}>
                                        <ul className={styles.listWrapper}>
                                            {x.sec1BodyListItems1.map((i => <li className={styles.listItem}>{i}</li>))}
                                        </ul>
                                    </div>
                                    <div className={styles.textBox}>
                                        <p className={styles.bodyText}>{x.sec1Body2Par1}</p>
                                        <p className={styles.bodyText}>{x.sec1Body2Par2}</p>
                                        <p className={styles.bodyText}>{x.sec1Body2Par3}</p>
                                        <p className={styles.bodyText}>{x.sec1Body2Par4}</p>
                                    </div>
                                </div>

                                <div className={styles.groupWrapper}>
                                    <div className={styles.listTitleBox}>
                                        <p className={styles.listTitle}>{x.sec1Body2ListTitle2}</p>
                                    </div>
                                    <div className={styles.listBox}>
                                        <ul className={styles.listWrapper}>{x.sec1Body2ListItems2?.map((i => <li className={styles.listItem}>{i}</li>))}</ul>
                                    </div>
                                    <div className={styles.textBox}>
                                        <p className={styles.bodyText}>{x.sec1Body2Par3}</p>
                                    </div>
                                </div>
                                <div className={styles.groupWrapper}>
                                    <div className={styles.listTitleBox}>
                                        <p className={styles.listTitle}>{x.sec2Body2ListTitle1}</p>
                                    </div>
                                    <div className={styles.listBox}>
                                        <ul className={styles.listWrapper}>{x.sec2Body2ListItems1?.map((i => <li className={styles.listItem}>{i}</li>))}</ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className={styles.sectionContainer}>
                            <div className={styles.sectionGroup}>
                                <div className={styles.titleBox}>
                                    <p className={styles.title}>{x.sec3}</p>
                                </div>

                                <div className={styles.groupWrapper}>

                                    <div className={styles.listTitleBox}>
                                        <p className={styles.listTitle}>{x.sec3BodyListTitle1}</p>
                                    </div>
                                    <div className={styles.listBox}>
                                        <ul className={styles.listWrapper}>{x.sec3BodyListItems1?.map((i => <li className={styles.listItem}>{i}</li>))}</ul>
                                    </div>
                                </div>
                                <div className={styles.nestedGroupWrapper}>
                                    <div className={styles.titleBox}>
                                        <p className={styles.nestedTitle}>{x.sec3SubSec1}</p>
                                    </div>
                                    <div className={styles.groupWrapper}>
                                        <div className={styles.listTitleBox}>
                                            <p className={styles.listTitle}>{x.sec3SubSec1BodyListTitle1}</p>
                                        </div>
                                        <div className={styles.listBox}>
                                            <ul className={styles.listWrapper}>{x.sec3SubSec1BodyListItems1?.map((i => <li className={styles.listItem}>{i}</li>))}</ul>
                                        </div>
                                    </div>


                                    <div className={styles.groupWrapper}>
                                        <div className={styles.listTitleBox}>
                                            <p className={styles.listTitle}>{x.sec3SubSec2}</p>
                                        </div>
                                        <div className={styles.textWrapper}>


                                            <p className={styles.listTitle}>{x.sec3SubSec2BodyPar1}</p>
                                        </div>
                                        <div className={styles.listBox}>
                                            <ul className={styles.listWrapper}>{x.sec3SubSec2BodyListItems1?.map((i => <li className={styles.listItem}>{i}</li>))}</ul>
                                        </div>
                                        <div className={styles.textWrapper}>
                                            <p className={styles.listTitle}>{x.sec3SubSec2BodyPar2}</p>
                                        </div>
                                        <div className={styles.listBox}>
                                            <ul className={styles.listWrapper}>{x.sec3SubSec2BodyListItems2?.map((i => <li className={styles.listItem}>{i}</li>))}</ul>
                                        </div>
                                        <p className={styles.bodyText}>{x.sec3SubSec2BodyPar3}</p>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className={styles.sectionContainer}>
                            <div className={styles.sectionGroup}>
                                <div className={styles.titleBox}>
                                    <p className={styles.title}>{x.sec4}</p>
                                </div>

                                <div className={styles.groupWrapper}>
                                    <div className={styles.listTitleBox}>
                                        <p className={styles.listTitle}>{x.sec4BodyListTitle1}</p>
                                    </div>

                                    <div className={styles.listBox}>
                                        <ul className={styles.listWrapper}>{x.sec4BodyListItems1?.map((i => <li className={styles.listItem}>{i}</li>))}</ul>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className={styles.sectionContainer}>
                            <div className={styles.sectionGroup}>
                                <div className={styles.titleBox}>
                                    <p className={styles.title}>{x.sec5}</p>
                                </div>

                                <div className={styles.groupWrapper}>
                                    <div className={styles.textBox}>
                                        <p className={styles.bodyText}>{x.sec5Body1Par1}</p>
                                    </div>

                                    <div className={styles.listTitleBox}>
                                        <p className={styles.listTitle}>{x.sec5BodyListTitle1}</p>
                                    </div>

                                    <div className={styles.listBox}>
                                        <ul className={styles.listWrapper}>{x.sec5BodyListItems1?.map((i => <li className={styles.listItem}>{i}</li>))}</ul>
                                    </div>
                                    <div className={styles.listTitleBox}>
                                        <p className={styles.listTitle}>{x.sec5BodyListTitle2}</p>
                                    </div>

                                    <div className={styles.listBox}>
                                        <ul className={styles.listWrapper}>{x.sec5BodyListItems2?.map((i => <li className={styles.listItem}>{i}</li>))}</ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.sectionContainer}>
                            <div className={styles.sectionGroup}>
                                <div className={styles.titleBox}>
                                    <p className={styles.title}>{x.sec6}</p>
                                </div>

                                <div className={styles.textBox}>
                                    <p className={styles.bodyText}>{x.sec6Body1Par1}</p>
                                    <p className={styles.bodyText}>{x.sec6Body1Par2}</p>
                                    <p className={styles.bodyText}>{x.sec6Body1Par3}</p>

                                </div>
                            </div>
                        </div>
                        <div className={styles.sectionContainer}>
                            <div className={styles.sectionGroup}>
                                <div className={styles.titleBox}>
                                    <p className={styles.title}>{x.sec7}</p>
                                </div>

                                <div className={styles.textBox}>
                                    <p className={styles.bodyText}>{x.sec7Body1Par1}</p>
                                    <p className={styles.bodyText}>{x.sec7SubSec1Par1}</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.sectionContainer}>
                            <div className={styles.sectionGroup}>
                                <div className={styles.titleBox}>
                                    <p className={styles.title}>{x.sec8}</p>
                                </div>

                                <div className={styles.textBox}>
                                    <p className={styles.bodyText}>{x.sec8Body1Par1}</p>
                                    <p className={styles.bodyText}>{x.sec8Body1Par2}</p>
                                    <p className={styles.bodyText}>{x.sec8Body1Par3}</p>

                                </div>
                            </div>
                        </div>
                        <div className={styles.sectionContainer}>
                            <div className={styles.sectionGroup}>
                                <div className={styles.titleBox}>
                                    <p className={styles.title}>{x.sec9}</p>
                                </div>

                                <div className={styles.textBox}>
                                    <p className={styles.bodyText}>{x.sec9Body1Par1}</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.sectionContainer}>
                            <div className={styles.sectionGroup}>
                                <div className={styles.titleBox}>
                                    <p className={styles.title}>{x.sec10}</p>
                                </div>
                                <div className={styles.textBox}>
                                    <p className={styles.bodyText}>{x.sec10Body1Par1}</p>
                                </div>
                            </div>
                        </div>

                        <div className={styles.sectionContainer}>
                            <div className={styles.sectionGroup}>
                                <div className={styles.titleBox}>
                                    <p className={styles.title}>{x.sec11}</p>
                                </div>
                                <div className={styles.textBox}>
                                    <p className={styles.bodyText}>{x.sec11Body1Par1}</p>
                                    <p className={styles.bodyText}>{x.sec11Body1Par2}</p>
                                    <p className={styles.bodyText}>{x.sec11Body1Par3}</p>
                                    <p className={styles.bodyText}>{x.sec11Body1Par4}</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.sectionContainer}>
                            <div className={styles.sectionGroup}>
                                <div className={styles.titleBox}>
                                    <p className={styles.title}>{x.sec12}</p>
                                </div>
                                <div className={styles.textBox}>
                                    <p className={styles.bodyText}>{x.sec12Body1Par1}</p>
                                    <p className={styles.bodyText}>{x.sec12Body1Par2}</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.sectionContainer}>
                            <div className={styles.sectionGroup}>
                                <div className={styles.titleBox}>
                                    <p className={styles.title}>{x.sec13}</p>
                                </div>
                                <div className={styles.textBox}>
                                    <p className={styles.bodyText}>{x.sec13Body1Par1}</p>
                                    <p className={styles.bodyText}>{x.sec13Body1Par2}</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.sectionContainer}>
                            <div className={styles.sectionGroup}>
                                <div className={styles.titleBox}>
                                    <p className={styles.title}>{x.sec14}</p>
                                </div>
                                <div className={styles.groupWrapper}>
                                    <div className={styles.textBox}>
                                        <p className={styles.bodyText}>{x.sec14Body1Par1}</p>
                                    </div>
                                    <div className={styles.listBox}>
                                        <ul className={styles.listWrapper}>{x.sec14Body1AddressList?.map((i => <li className={styles.listItem}>{i}</li>))}</ul>
                                    </div>
                                     
                                    <div className={styles.textBox}>
                                        <p className={styles.bodyText}>{x.sec14Body1Par2}</p>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>

                    </section>
                ))}

            </BodyContainer>
        </PageWrapperTemplate>

    )
}

export default PrivacyPage