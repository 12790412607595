import { useContext } from "react";
import { AdminContext } from "../../../Context/Admin.Context";

import Tile from "./DisplayDataElements/Tiles/Tile";
import TileContainer from "./DisplayDataElements/Tiles/TileContainer";


const UserDataTiles = () => {
    const { isFilteredGoogleAnalyticsData, isFilteredCustomDataState } = useContext(AdminContext)

    const totalActiveUsers = isFilteredGoogleAnalyticsData?.pageDataMetrics?.reduce((sum, entry) => sum + parseInt(entry.activeUsers, 10), 0);
    const totalNewUsers = isFilteredGoogleAnalyticsData?.pageDataMetrics?.reduce((sum, entry) => sum + parseInt(entry.newUsers, 10), 0);
    const totalSessions = isFilteredGoogleAnalyticsData?.pageDataMetrics?.reduce((sum, entry) => sum + parseInt(entry.sessions, 10), 0);
    const totalScreenPageViews = isFilteredGoogleAnalyticsData?.pageDataMetrics?.reduce((sum, entry) => sum + parseInt(entry.screenPageViews, 10), 0);
    const totalUserEngagementTimeInSeconds = isFilteredGoogleAnalyticsData?.pageDataMetrics?.reduce((sum, entry) => sum + parseInt(entry.userEngagementDuration, 10), 0);
    const totalUserEngagementTime = (totalUserEngagementTimeInSeconds / 60).toFixed(2);

    const totalUsersPendingApproval = isFilteredCustomDataState?.allUsersData?.filter(users => users.status === "pending")?.length;
    // const totalUsersPendingApproval = isFilteredCustomDataState?.allUsersData?.filter(users => users.status === "not approved")?.length;
    // const allApprovedUsers = isFilteredUserDataState.approvedUsersCount;
    // const allUsersPendingApproval = isFilteredUserDataState.notApprovedUsersCount;

    return (
        <TileContainer>
            {/* <StatBlock
                title="All Visitors"
                data={totalActiveUsers}
            /> */}
              <Tile
                title="New Visitors"
                data={totalNewUsers}
            />
              <Tile
                title="Pending Approvals"
                data={totalUsersPendingApproval}
            />
            {/* <StatBlock
                title="All Approved Users"
                data={allApprovedUsers}
            />
            <StatBlock
                title="All Users Pending Approval"
                data={allUsersPendingApproval}
            /> */}
               <Tile
                title="Sessions"
                data={totalSessions}
            />
              <Tile
                title="Page Views"
                data={totalScreenPageViews}
            />
            <Tile
                title="Time on Page (mins)"
                data={totalUserEngagementTime}
            />
        </TileContainer>

    )
}

export default UserDataTiles;