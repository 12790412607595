import styles from "./SectionTextContainer.module.css";
import { motion } from "framer-motion";
import { useScrollFadeIn } from "../../../../Hooks/AnimationHook/UseAnimiationHook";
import BodyContainer from "../BodyContainer/BodyContainer";
const SectionTextContainer = ({
    topTitleText = false,
    mainTitleText = false,
    titleText,
    subtitleText,
    headlineText,
    descriptionText,
    descriptionText2,
    descriptionText3,
    studioExclusion = '',
    buttonElement
}) => {
    const [cardRef0, cardInView0] = useScrollFadeIn({
        threshold: 0.2,
        triggerOnce: true,
    });
    const [cardRef1, cardInView1] = useScrollFadeIn({
        threshold: 0.2,
        triggerOnce: false,
    });
    return (
        <div className={styles.sectionTextContainer}>
            {/* <BodyContainer> */}
            <div className={styles.textBox}>
                <motion.div
                    ref={cardRef0}
                    animate={{ opacity: cardInView0 ? 1 : 0, y: cardInView0 ? 0 : 50 }}
                    transition={{ duration: 0.2 }}
                >
                    {topTitleText ? <p className={styles.topTitleText} >{topTitleText}</p>:null}
                    {mainTitleText ? <p className={styles.mainTitleText} >{mainTitleText}</p>:null}
                    <p className={styles.subtitleText} >{subtitleText}</p>
                    <p className={styles.titleText}>{titleText}</p>
                    <p className={styles.headlineText}>{headlineText}</p>
                </motion.div>
                <motion.div
                    ref={cardRef1}
                    animate={{ opacity: cardInView1 ? 1 : 0, y: cardInView1 ? 0 : 50 }}
                    transition={{ duration: 0.2 }}
                    className={styles.textBox}
                >
                    <p className={styles.descriptionText}>{descriptionText}</p>
                    <p className={styles.descriptionText}>{descriptionText2}</p>
                    <p className={styles.descriptionText}>{descriptionText3}</p>
                </motion.div>
            </div>
            {buttonElement && studioExclusion !== 'LG Studio'  ? 
                <div className={styles.buttonContainer}>
                    {buttonElement}
                </div>
                : null
            }

{/* </BodyContainer> */}
        </div>
    )
}
export default SectionTextContainer;