import styles from "../Form.styles.module.css"
import {useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Input from '../../../Components/UI/FormElements/Input/Input';
// import Button from '../../../Components/Buttons/Buttons';
import { LanguageContext } from "../../../../Context/Language.Context";


import { useForm } from '../../../../Hooks/FormHook/form-hook';
import { VALIDATOR_MINLENGTH } from '../../../../Utils/validators';


import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import TheButton from "../../../Components/UI/TheButton/TheButton";
import { RoutingContext } from "../../../../Context/Routing.Context";
import { RetailerContext } from "../../../../Context/Retailer.Context";
import Modal from "../../../Components/UI/TheModals/TheModal";

const PasswordResetForm = () => {
 
  const redirect = useNavigate();
    const { token } = useParams();
 
    const {isHomeDepotApp} = useContext(RetailerContext);
    const {setIsRoutingState} = useContext(RoutingContext);

const {translatedText}=useContext(LanguageContext)
  // const [isLoading, setIsLoading] = useState(false);
  const [feedback, setFeedback] = useState()
  const [showModal, setShowModal] = useState(false);
  const [showResponseModal, setShowResponseModal]=useState(false)

  const [formState, inputHandler] = useForm({
    password: {
      value: '',
      isValid: false,
    },
    confirmPassword: {
      value: '',
      isValid: false,
    },
  }, false);

  const [passwordInputType, setPasswordInputType] = useState("password");
  const [confirmPasswordInputType, setConfirmPasswordInputType] = useState("password");

  const togglePasswordVisibility = () => {
    setPasswordInputType(prevState => prevState === "password" ? "text" : "password");
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordInputType(prevState => prevState === "password" ? "text" : "password");
  };

  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/reset/${token}`);
        const data = await response.json();

        if (data.error) {
          throw new Error(data.error);
        }
        if (!response.ok) {
          throw new Error(response.statusText);
        }

        setUserId(data.userId);

      } catch (error) {
        // setShowModal(true);
        setFeedback('Error: Server unable to send password reset email');
      }
    };
    fetchUserId();
  }, [token]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // setIsLoading(true);
    setIsRoutingState(prevState=>({
      ...prevState,
      isLoading:true
  }));
    if (!formState.isValid) {
      // setIsLoading(false);
      setIsRoutingState(prevState=>({
        ...prevState,
        isLoading:false
    }))
      setShowModal(true);
      // alert('Reset Failed: Form is not valid');
      setFeedback({ title: 'Try again', message: 'Your password must be at least 8 characters in length.' });
      return;
    }
    if (formState.inputs.confirmPassword.value !== formState.inputs.password.value) {
      setIsRoutingState(prevState=>({
        ...prevState,
        isLoading:false
    }))
      // setIsLoading(false);
      
      setShowModal(true);
      // alert('Reset Failed: passwords do not match');
      setFeedback({ title: 'Try again', message: 'Your passwords do not match.' });
      return;
    }
   
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/new-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userId,
          password: formState.inputs.password.value,
          passwordToken: token
        }),
      });

      const data = await response.json();

      if (data.error) {
        throw new Error(data.error);
      }

      if (!response.ok) {
        throw new Error('Could not reset password.');
      }
      // alert('Reset Failed: Form is not valid');
      setShowResponseModal(true);
      setFeedback({ title: 'Success!', message: 'Your new password was created. Visit the sign in page to log in.' });
      
    } catch (error) {
      setIsRoutingState(prevState=>({
        ...prevState,
        isLoading:false
    }))
      // setIsLoading(false);
      setShowModal(true);
      // setFeedback('Error: ' + error.message);
      setFeedback({ title: 'Something went wrong', message: 'There appears to be an error. Please contact the administrator.' });
    } finally {
      setIsRoutingState(prevState=>({
        ...prevState,
        isLoading:false
    }))
    }
  };
  const closeModal = () => {
    setShowModal(false);

  };
  const closeResponseModal = () => {
    setShowResponseModal(false);
    redirect(`${isHomeDepotApp.isHomeDepotPrefix}/sign-in`);

  };

  return (
    <>
     <Modal
         icon="true"
         iconName="close"
         styleName="lightIconButton"
         iconOnClick={closeModal}
         show={showModal}
         // title="Something went wrong!"
         title={feedback?.title}
         message={feedback?.message}
         closeOnBackdropClick={false}
        //  actionBox={
        //    <>
          
        //      <span onClick={closeModal}>
        //        <TheButton animated="true">
        //          Try Again
        //        </TheButton>
        //      </span>
 
        //    </>
        //  }
        />
          <Modal
         icon="true"
         iconName="close"
         styleName="lightIconButton"
         iconOnClick={closeResponseModal}
         show={showResponseModal}
         // title="Something went wrong!"
         title={feedback?.title}
         message={feedback?.message}
         closeOnBackdropClick={false}
        
        />
      {/* {isLoading && <Loader />}
      {showModal && (
        <CustomModal
          show={showModal}
          title={feedback.title}
          message={feedback.message}
          closeHandler={closeModal}
        />
      )} */}

 
     
        
       
          <form 
          onSubmit={handleSubmit}
          className={styles.formContainer}
          >
            <Input
              // requireAsterix
              // labelColor="#4A4946"
              id="password"
              element="input"
              type={passwordInputType}
              label={translatedText.newPassword}
              validators={[VALIDATOR_MINLENGTH(8)]}
              errorText="Minimum of 8 characters."
              onInput={inputHandler}
              icon={passwordInputType === "password" ? faEyeSlash : faEye}
              // iconColor="gray"
              onIconClick={togglePasswordVisibility}
            />
            <Input
              // requireAsterix
              // labelColor="#4A4946"
              id="confirmPassword"
              element="input"
              type={confirmPasswordInputType}
              label={translatedText.confirmNewPassword}
              validators={[VALIDATOR_MINLENGTH(8)]}
              errorText="Minimum of 8 characters."
              onInput={inputHandler}
              icon={confirmPasswordInputType === "password" ? faEyeSlash : faEye}
              // iconColor="gray"
              onIconClick={toggleConfirmPasswordVisibility}
            />
             <div className={styles.buttonBox}>
              <TheButton
              animated='true'
              styleName="red"
              >
                 Update
              </TheButton>
              {/* <Button
                type="submit"
                styleName="black"
                animated="true"
              >
               
              </Button> */}
            </div>
          </form>
    </>
  );
};

export default PasswordResetForm;
