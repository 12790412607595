import  ReactDOM  from "react-dom";
import { usePopUpAnimation } from "../../../../Hooks/AnimationHook/UseAnimiationHook";
import { motion } from "framer-motion";
import styles from "./TheFullBackdrop.module.css";

const TheLoaderBackdrop = props => { 
    const animationProps = usePopUpAnimation();
    return ReactDOM.createPortal(
  
        <motion.div 
        // {...animationProps} 
        
        className={styles.loaderBackdropContainer} onClick={props.onClick}></motion.div>,
        document.getElementById('The-Backdrop-Hook')
    );
   
   
};

export default TheLoaderBackdrop;