import styles from "./TheIcon.module.css"
import {
    AiOutlinePrinter,
    AiOutlineMail,
    AiOutlineSave,
    AiOutlineDelete,
    AiOutlinePlus,
    AiOutlineMinus,
    AiOutlineRight,
    AiOutlineLeft,
    AiOutlineCheck,
    AiOutlineExclamation,
    AiOutlineWarning,
    AiOutlineSearch,
    AiOutlineSend,
    AiOutlineClose,
    AiOutlineMenu,
    AiOutlineUser,
    AiOutlineExpandAlt,

    AiOutlineDown,
    AiOutlineUp,

    AiOutlineShoppingCart,
    AiOutlineAppstore,
    AiOutlineTeam,
    AiOutlineHome,



} from "react-icons/ai";
import { RxDashboard } from "react-icons/rx";
import { GoSignOut, GoLinkExternal, GoGear } from "react-icons/go";
import {
    MdOutlineAdminPanelSettings, MdOutlineInventory

} from "react-icons/md";
import { BsArrowUpRight, BsJournal, BsCardChecklist } from "react-icons/bs";
import { CiShoppingTag, CiCircleList, CiSquareAlert, } from "react-icons/ci";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
// import { GrSubtractCircle, GrCheckmark } from "react-icons/gr";
import { IoCheckmarkCircleOutline, IoRemoveCircleOutline, IoAdd, IoChevronForward } from "react-icons/io5";
const TheIcon = ({ name }) => {
    switch (name) {

        //IOS-5
        case 'checkmark':
            return <  IoCheckmarkCircleOutline className={styles.checkmarkIcon} />;
        case 'subtracted':
            return < IoRemoveCircleOutline className={styles.subtractedIcon} />;
            case 'add':
                return < IoAdd className={styles.addIcon} />;
                case 'forward':
                    return < IoChevronForward className={styles.forwardIcon} />;
        //RX
        case 'rxdash':
            return <  RxDashboard className={styles.icon} />;
        //GO
        // case 'subtracted':
        //     return <  GrSubtractCircle className={styles.icon} />;
        //GO
        case 'settings':
            return <  GoGear className={styles.icon} />;
        // case 'checkmark':
        //     return <   GrCheckmark className={styles.checkmarkIcon} />;
        case 'signout':
            return <  GoSignOut className={styles.icon} />;
        //GO
        case 'externalLink':
            return <  GoLinkExternal className={styles.icon} />;
        //TB

        case 'dashboard':
            return <  TbDeviceDesktopAnalytics className={styles.icon} />;

        case "home":
            return < AiOutlineHome className={styles.icon} />;
        case 'users':
            return < AiOutlineTeam className={styles.icon} />;

        //MD
        case 'inventory':
            return <      MdOutlineInventory className={styles.icon} />;
        case 'admin':
            return <  MdOutlineAdminPanelSettings className={styles.icon} />;
        //CI
        case 'shoppingTag':
            return < CiShoppingTag className={styles.icon} />;
        case 'compareBoard':
            return < CiCircleList className={styles.icon} />;
        case 'squareInfo':
            return < CiSquareAlert className={styles.icon} />;
        //Ai
        case 'expand':
            return <  AiOutlineExpandAlt className={styles.icon} />;
        case 'products':
            return < AiOutlineAppstore className={styles.icon} />;

        case 'send':
            return < AiOutlineSend className={styles.icon} />;
        case 'search':
            return < AiOutlineSearch className={styles.icon} />;
        case 'close':
            return < AiOutlineClose className={styles.icon} />;
        case 'print':
            return < AiOutlinePrinter className={styles.icon} />;

        case 'email':
            return <  AiOutlineMail className={styles.icon} />;
        case 'save':
            return < AiOutlineSave className={styles.icon} />;
        case 'delete':
            return <  AiOutlineDelete className={styles.icon} />;
        case 'plus':
            return <AiOutlinePlus className={styles.icon} />;
        case 'minus':
            return <AiOutlineMinus className={styles.icon} />;
        case 'check':
            return <AiOutlineCheck className={styles.icon} />;
        case 'exclamation':
            return <AiOutlineExclamation className={styles.icon} />;
        case 'warning':
            return <AiOutlineWarning className={styles.icon} />;
        case 'scrollRight':
            return < AiOutlineRight className={styles.icon} />;
        case 'scrollLeft':
            return < AiOutlineLeft className={styles.icon} />;
        case 'bars':
            return < AiOutlineMenu className={styles.icon} />;
        case 'user':
            return < AiOutlineUser className={styles.icon} />;
        case 'cart':
            return < AiOutlineShoppingCart className={styles.icon} />;
        case 'caretRight':
            return < AiOutlineRight className={styles.icon} />;
        case 'caretLeft':
            return < AiOutlineLeft className={styles.icon} />;
        case 'caretDown':
            return <  AiOutlineDown className={styles.icon} />;
        case 'caretUp':
            return <  AiOutlineUp className={styles.icon} />;
        case 'upArrowRight':
            return <  BsArrowUpRight className={styles.icon} />;
        case 'productList':
            return <  BsJournal className={styles.icon} />;
        case 'checkList':
            return <  BsCardChecklist className={styles.icon} />;

        default:
            return null;
    }
};

export default TheIcon;