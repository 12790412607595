// DONE
import { useContext } from 'react';
import { LanguageContext } from '../../../../../Context/Language.Context';
// import { AnalyticsContext } from '../../../../../Context/AnalyticsContext';
import { RoutingContext } from '../../../../../Context/Routing.Context';
import { SFWBuilderContext } from '../../../../../Context/BuilderContext';
import styles from './ProductHeader.module.css'
import ColorLegend from '../ColorLegend/ColorLegend';
import TheButton from '../../../UI/TheButton/TheButton';
import { logEvent } from '../../../../../Utils/google-analytics';

const ProductHeader = ({ product, styleName }) => {
    const { language } = useContext(LanguageContext);
    const { setIsRoutingState, setIsFlashMessageState } = useContext(RoutingContext);
    // const { storeButtonInteraction } = useContext(AnalyticsContext)
    const { addItemToBuilderList, removeItemFromBuilderList, builderListItems } = useContext(SFWBuilderContext);
    const { title } = product;
    // const categoryText = language === "EN" ? product.categoryEN : product.categoryFR;
    const subtitleText = language === "EN" ? product.subtitleEN : product.subtitleFR;
  let availText;
    if(language === "EN"){
        availText = product.availability; 
    } else {
        availText = "Disponible"; 
        availText = product.availability; 
    }
   


    const handleAddToList = () => {
        const productAlreadyInList = builderListItems.find(item => item._id === product._id);
        let message;
        if (productAlreadyInList) {
            removeItemFromBuilderList(product);
            message = language === "EN" ? `Product ${product.title} removed from list` : `Product ${product.title} retiré de la liste!`;
            setIsFlashMessageState(prevState => ({
                ...prevState,
                show: true,
                message: message,
                iconType: 'subtracted',
                styleName:'subtracted'
            }))

            // storeButtonInteraction(`click_removed_${product.title}`);
            logEvent('User Interaction', 'Product_Removed_On_Details_Page', `Product Title: ${product.title}`);
        } else {
            addItemToBuilderList(product);
            message = language === "EN" ? `Product ${product.title} added to list` : `Product ${product.title} ajouté à la liste!`;
            setIsFlashMessageState(prevState => ({
                ...prevState,
                show: true,
                message: message,
                iconType: 'checkmark',
                styleName:'checkmark'
            }))
        }
        setIsRoutingState(prevState => ({ ...prevState, isFlashMessage: message }))
        logEvent('User Interaction', 'Product_Added_On_Details_Page', `Product Title: ${product.title}`);
    }



    const printStyles = `${styles.productHeaderContainer} ${styles[styleName]}`;
    return (
        <div className={printStyles}>
            <div className={styles.productHeaderTextWrapper}>
                <p className={styles.availabilityText}>{availText}</p>
                <p className={styles.titleText}>{title}</p>
                <p className={styles.subtitleText}>{subtitleText}</p>
            </div>
            <div className={styles.colorLegendWrapper}>
                {/* CONDITION LOGIC GOOD */}
                {product.colourEN.length > 0 ? <ColorLegend product={product} /> : null}
            </div>


            <div className={styles.buttonBoxWrapper} onClick={handleAddToList}>
                {builderListItems.some(item => item._id === product._id) ?
                    <TheButton
                    styleName="blackIconButton"
                        animated="true"
                        // buttonTracking
                        // interactionName={`detailsPage_remove_${product.title}`}
                        buttonIcon="true"
                        iconText={language === "EN" ? "Remove" : "Supprimer"}
                        iconName="subtracted"
                    />
                    :
                    <TheButton
                        animated="true"
                        // buttonTracking
                        // interactionName={`detailsPage_add_${product.title}`}
                        buttonIcon
                        iconText={language === "EN" ? "Add to List" : "Ajouter à la liste"}
                        iconName="add"
                        styleName="redIconButton"
                    />
                }
            </div>
        </div>
    )
}

export default ProductHeader;

