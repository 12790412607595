
import Graph from "../Graphs/Graph";

const ProductCategoriesGraph = ({ products, chartTitle, chartSubtitle }) => {
// console.log(products)
    let productArray = products;

    let categoryCounts = productArray.reduce((acc, product) => {

        if (!acc[product.categoryEN]) {
            acc[product.categoryEN] = 1;
        }
        else {
            acc[product.categoryEN] += 1;
        }
        return acc;
    }, {});
 

    let dataPoints = Object.keys(categoryCounts).map(category => {
        return {
            label: category,
            value: categoryCounts[category]
        };
    });

    return (
        <div>
            <Graph
                chartTitle={chartTitle}
                chartSubtitle={chartSubtitle}
                dataPoints={dataPoints}
            />

        </div>
    )

}
export default ProductCategoriesGraph;