import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useContext, useState } from 'react';
import { RoutingContext } from '../../../../Context/Routing.Context';
import { SFWBuilderContext } from '../../../../Context/BuilderContext';
import { LanguageContext } from '../../../../Context/Language.Context';
import styles from './SearchResult.module.css'
import Icon from '../../../Components/IconElements/Icon';
import { AnimatePresence, motion } from 'framer-motion';
import { RetailerContext } from '../../../../Context/Retailer.Context';
import TheButton from '../../../Components/UI/TheButton/TheButton';
import { NavLink } from 'react-router-dom';
import BodyContainer from '../../../Components/UI/BodyContainer/BodyContainer';
import TheIcon from '../../../Components/UI/TheIcon/TheIcon';


const SearchResult = ({ product }) => {
    const [showSpecColumn, setShowSpecColumn] = useState({});
    const { isHomeDepotApp } = useContext(RetailerContext)

    const { title } = product;
    const { language, translatedText } = useContext(LanguageContext);
    const { setIsFlashMessageState } = useContext(RoutingContext);
    const category = language === "EN" ? product.categoryEN : product.categoryFR;
    const subcategory = language === "EN" ? product.subcategoryEN : product.subcategoryFR

    const subtitleText = language === "EN" ? product.subtitleEN : product.subtitleFR;
    const specListGroup1 = language === "EN" ? product.specList1EN : product.specList1FR;
    const specTitle1 = language === "EN" ? product.specTitle1EN : product.specTitle1FR;
    const specListGroup2 = language === "EN" ? product.specList2EN : product.specList2FR;
    const specTitle2 = language === "EN" ? product.specTitle2EN : product.specTitle2FR;
    const specListGroup3 = language === "EN" ? product.specList3EN : product.specList3FR;
    const specTitle3 = language === "EN" ? product.specTitle3EN : product.specTitle3FR;
    const specListGroup4 = language === "EN" ? product.specList4EN : product.specList4FR;
    const specTitle4 = language === "EN" ? product.specTitle4EN : product.specTitle4FR;

    // const upcCodeText = language === "EN" ? product.upcCodeEN : product.upcCodeFR;

    const availabilityText = product.availability === "Available" ? translatedText.avail : product.availability


    const { removeItemFromBuilderList, builderListItems } = useContext(SFWBuilderContext)

    const removeItemFromListHandler = () => {
        const productAlreadyInList = builderListItems.find(item => item._id === product._id);
        let message;
        if (productAlreadyInList) {
            removeItemFromBuilderList(product);
            message = language === "EN" ? `Product ${product.title} removed from list!` : `Product ${product.title} retiré de la liste!`;
            setIsFlashMessageState(prevState => ({
                ...prevState,
                show: true,
                message: message,
                iconType: 'subtracted',
                styleName: 'subtracted'
            }))

        }
    }
    return (
        // <main className={styles.productContainer}>
        <BodyContainer>
            <div className={styles.productWrapper}>
                <section onClick={removeItemFromListHandler} className={classNames(styles.iconBox, styles.itemTrash)}>
                    {/* <span className={styles.circleCloseIcon} onClick={() => removeItemFromBuilderList(product)}> */}
                    <TheButton
                        icon="true"
                        buttonIcon
                        iconName="close"
                        styleName="lightIconButton"
                        animated='true'
                    />
                    {/* <Icon name="close" /> */}
                    {/* </span> */}
                </section>
                <section className={classNames(styles.productHeaderContainer, styles.itemHead)}>
                    <div className={styles.productTitleBox}>
                        {/* <p className={styles.availabilityText}>{product.availability ? (product.availability) : ('Available')}</p> */}
                        <p className={styles.availabilityText}>{availabilityText}</p>




                        <p className={styles.productTitleText}>  <NavLink className={styles.linkTagStyles} to={`${isHomeDepotApp.isHomeDepotPrefix}/product-details/${title}`}>{title}</NavLink> </p>


                        <p className={styles.categoryText}>{category} | {subcategory}</p>
                        <p className={styles.subtitleText}>
                            <NavLink className={styles.linkTagStyles} to={`${isHomeDepotApp.isHomeDepotPrefix}/product-details/${title}`}>
                                <span>{subtitleText}</span>
                            </NavLink></p>
                    </div>
                </section>
                <div className={classNames(styles.productImageContainerAII, styles.itemImage)}>
                    <Link className={styles.linkTagStyles} to={`${isHomeDepotApp.isHomeDepotPrefix}/product-details/${title}`}>
                        {/* <img src={`${process.env.REACT_APP_BACKEND_URL}/` + product.image} alt={product.subtitle} /> */}
                        <img src={`${process.env.REACT_APP_AWS_URL}/` + product.image} alt={product.title} />
                    </Link>
                </div>
                <div className={styles.itemSpecsBox}>

                    {/* SECTION - SPEC 1 */}
                    {specListGroup1 && specListGroup1.length > 1 && (
                        <section className={classNames(styles.specificationsColoumn, styles.itemspecCol1)}>
                            <motion.div
                                initial={{ opacity: 0, x: 20 }}
                                animate={{
                                    opacity: 1,
                                    x: 0,
                                    transition: {
                                        duration: .3,
                                        ease: [0.75, 0, 0, 1],
                                    },
                                }}
                                exit={{
                                    opacity: 0,
                                    x: 50,
                                    transition: {
                                        duration: .3,
                                        ease: [0.75, 0, 0, 1],
                                    },
                                }}
                                className={styles.specTitleToolTipGroup}>
                                <p onClick={() => setShowSpecColumn(prevState => ({
                                    ...prevState,
                                    spec1: !prevState.spec1
                                }))} className={styles.specListTitleText}>{specTitle1}</p>
                                <span
                                    className={styles.expandIcon}
                                    onClick={() => setShowSpecColumn(prevState => ({
                                        ...prevState,
                                        spec1: !prevState.spec1
                                    }))}>
                                    {showSpecColumn.spec1 ? <TheIcon name="close" /> : <TheIcon name="expand" />}
                                </span>
                            </motion.div>
                            <div
                                className={styles.specListItemBox}>
                                <AnimatePresence>
                                    {showSpecColumn.spec1 && specListGroup1.map((item, index) => (
                                        <motion.p
                                            initial={{ opacity: 0, x: 20 }}
                                            animate={{
                                                opacity: 1,
                                                x: 0,
                                                transition: {
                                                    duration: .3,
                                                    ease: [0.75, 0, 0, 1],
                                                },
                                            }}
                                            exit={{
                                                opacity: 0,
                                                x: 50,
                                                transition: {
                                                    duration: .3,
                                                    ease: [0.75, 0, 0, 1],
                                                },
                                            }}
                                            className={styles.specListItemText} key={index}>{item}</motion.p>
                                    ))}
                                </AnimatePresence>
                            </div>
                        </section>
                    )}
                    {/* SECTION - SPEC 2 */}
                    {specListGroup2 && specListGroup2.length > 1 && (
                        <section className={classNames(styles.specificationsColoumn, styles.itemspecCol2)}>
                            <div className={styles.specTitleToolTipGroup}>
                                <p onClick={() => setShowSpecColumn(prevState => ({
                                    ...prevState,
                                    spec2: !prevState.spec2
                                }))} className={styles.specListTitleText}>{specTitle2}</p>
                                <span
                                    className={styles.expandIcon}
                                    onClick={() => setShowSpecColumn(prevState => ({
                                        ...prevState,
                                        spec2: !prevState.spec2
                                    }))}>
                                    {showSpecColumn.spec2 ? <Icon name="close" /> : <Icon name="expand" />}
                                </span>
                            </div>
                            <div className={styles.specListItemBox}>
                                <AnimatePresence>
                                    {showSpecColumn.spec2 && specListGroup2.map((item, index) => (
                                        <motion.p
                                            initial={{ opacity: 0, x: 20 }}
                                            animate={{
                                                opacity: 1,
                                                x: 0,
                                                transition: {
                                                    duration: .3,
                                                    ease: [0.75, 0, 0, 1],
                                                },
                                            }}
                                            exit={{
                                                opacity: 0,
                                                x: 50,
                                                transition: {
                                                    duration: .3,
                                                    ease: [0.75, 0, 0, 1],
                                                },
                                            }}
                                            className={styles.specListItemText} key={index}>{item}</motion.p>
                                    ))}
                                </AnimatePresence>
                            </div>
                        </section>
                    )}
                    {/* SECTION - SPEC 3 */}
                    {specListGroup3 && specListGroup3.length > 1 && (
                        <section className={classNames(styles.specificationsColoumn, styles.itemspecCol3)}>
                            <div className={styles.specTitleToolTipGroup}>
                                <p onClick={() => setShowSpecColumn(prevState => ({
                                    ...prevState,
                                    spec3: !prevState.spec3
                                }))} className={styles.specListTitleText}>{specTitle3}</p>
                                <span
                                    className={styles.expandIcon}
                                    onClick={() => setShowSpecColumn(prevState => ({
                                        ...prevState,
                                        spec3: !prevState.spec3
                                    }))}>
                                    {showSpecColumn.spec3 ? <Icon name="close" /> : <Icon name="expand" />}
                                </span>
                            </div>
                            <div className={styles.specListItemBox}>
                                <AnimatePresence>
                                    {showSpecColumn.spec3 && specListGroup3.map((item, index) => (
                                        <motion.p
                                            initial={{ opacity: 0, x: 20 }}
                                            animate={{
                                                opacity: 1,
                                                x: 0,
                                                transition: {
                                                    duration: .3,
                                                    ease: [0.75, 0, 0, 1],
                                                },
                                            }}
                                            exit={{
                                                opacity: 0,
                                                x: 50,
                                                transition: {
                                                    duration: .3,
                                                    ease: [0.75, 0, 0, 1],
                                                },
                                            }}
                                            className={styles.specListItemText} key={index}>{item}</motion.p>
                                    ))}
                                </AnimatePresence>
                            </div>
                        </section>
                    )}
                    {/* SECTION - SPEC 4 */}
                    {specListGroup4 && specListGroup4.length > 1 && (
                        <section

                            className={classNames(styles.specificationsColoumn, styles.itemspecCol4)}>
                            <div className={styles.specTitleToolTipGroup}>
                                <p onClick={() => setShowSpecColumn(prevState => ({
                                    ...prevState,
                                    spec4: !prevState.spec4
                                }))} className={styles.specListTitleText}>{specTitle4}</p>
                                <span
                                    className={styles.expandIcon}
                                    onClick={() => setShowSpecColumn(prevState => ({
                                        ...prevState,
                                        spec4: !prevState.spec4
                                    }))}>
                                    {showSpecColumn.spec4 ? <Icon name="close" /> : <Icon name="expand" />}
                                </span>
                            </div>
                            <div

                                className={styles.specListItemBox}>
                                <AnimatePresence>
                                    {showSpecColumn.spec4 && specListGroup4.map((item, index) => (
                                        <motion.p
                                            initial={{ opacity: 0, x: 20 }}
                                            animate={{
                                                opacity: 1,
                                                x: 0,
                                                transition: {
                                                    duration: .3,
                                                    ease: [0.75, 0, 0, 1],
                                                },
                                            }}
                                            exit={{
                                                opacity: 0,
                                                x: 50,
                                                transition: {
                                                    duration: .3,
                                                    ease: [0.75, 0, 0, 1],
                                                },
                                            }}
                                            className={styles.specListItemText} key={index}>{item}
                                        </motion.p>
                                    ))}
                                </AnimatePresence>
                            </div>
                        </section>
                    )}
                </div>

            </div>


        </BodyContainer>


    );
};

export default SearchResult;
