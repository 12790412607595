import LGLogoSVG from "./LG-Product-Guide-Logo.svg"
import styles from "./BrandLogos.module.css"


const LGLogo = () => {
    return (
        <div className={styles.logoContainer}>
            <img alt="LG Product Guide Logo" src={LGLogoSVG} />
        </div>
    );
}

export default LGLogo;