import styles from './Loader.module.css'
import SpinningLogo from "../../../Theme/Gifs/LG_Logo_White_Spinning.gif"
import TheLoaderBackdrop from '../TheBackdrops/TheLoaderBackdrop';


const Loader = () => {
  return (
    <>
    <TheLoaderBackdrop/>
      <div className={styles.loaderContainer}>
        <div className={styles.logoSpinnerContainer}>
            <img src={SpinningLogo} className={styles.gif} alt="loading gif" />
          </div>
          <div className={styles.loader} />
      </div>
    </>
  );
}

export default Loader;
