

import styles from './SearchPreview.module.css'
import { motion, AnimatePresence } from 'framer-motion';
import { useContext } from 'react';
import { SFWBuilderContext } from '../../../../Context/BuilderContext';
import { LanguageContext } from '../../../../Context/Language.Context';

import { logEvent } from '../../../../Utils/google-analytics';


import TheButton from '../../../Components/UI/TheButton/TheButton';


const EditingModeSearchPreview = ({ products }) => {
    const { addItemToSavedList } = useContext(SFWBuilderContext);
    const { language } = useContext(LanguageContext);

    // const handleAddProductToSavedList = (product) => {

    //     addItemToSavedList(product)
    // }
    return (
        <div className={styles.searchPreviewContainer}>
            <div className={styles.searchPreviewDropdown}>

                <AnimatePresence>
                    {
                        products &&
                        products.map((product) => {
                            const categoryText = language === "EN" ? product.categoryEN : product.categoryFR;
                            const availabilityText = language === "EN" ? product.availabilityEN : product.availabilityFR;
                            const subtitleText = language === "EN" ? product.subtitleEN : product.subtitleFR;
                            return (
                                <motion.section
                                    key={product._id}
                                    className={styles.searchItem}
                                    initial={{ opacity: 0, x: 50 }}
                                    animate={{
                                        opacity: 1,
                                        x: 0,
                                        transition: {
                                            duration: .3,
                                            ease: [0.75, 0, 0, 1],
                                        },
                                    }}
                                    exit={{
                                        opacity: 0,
                                        x: 50,
                                        transition: {
                                            duration: .3,
                                            ease: [0.75, 0, 0, 1],
                                        },
                                    }}

                                >

                                    <div onClick={() => { 
                                        addItemToSavedList(product) 
                                        logEvent('User Interaction', 'Product_Added_To_Saved_List_From_Search', `Product Title: ${product.title}`);
                                        }} className={styles.searchPreviewImageBox}>
                                 
                                        <img src={`${process.env.REACT_APP_AWS_URL}/` + product.image} alt={product.title} />
                                    
                                    </div>
                                    <div onClick={() => { 
                                        addItemToSavedList(product) 
                                        logEvent('User Interaction', 'Product_Added_To_Saved_List_From_Search', `Product Title: ${product.title}`);
                                        }} className={styles.searchPreviewTextBox}>

                                        <p className={styles.previewCategoryText}>{categoryText}</p>
                                        <p className={styles.previewTitleText} >{product.title}</p>
                                        <p className={styles.previewSubtitleText} >{subtitleText}</p>

                                        <p className={styles.previewAvailabilityText} >{availabilityText}</p>
                                    </div>
                                    <div className={styles.searchPreviewBtnBox}>
                                        <span onClick={() => {
                                            addItemToSavedList(product)
                                            logEvent('User Interaction', 'Product_Added_To_Saved_List_From_Search', `Product Title: ${product.title}`);
                                        }}>
                                            <TheButton
                                                icon
                                                buttonIcon="true"
                                                iconName="plus"
                                                styleName="lightIconButton"
                                                // buttonTracking
                                                // interactionName={`search_addToList_${product.title}`}
                                            />

                                        </span>

                                    </div>
                                </motion.section>
                            )
                        })}
                </AnimatePresence>
            </div>
        </div>
    )
}

export default EditingModeSearchPreview;