import styles from "./NotFoundPage.module.css"
import logoAppearing from "../../Theme/Gifs/ha-look.gif"

// import PageWrapperTemplate from "../../Components/UI/Templates/PageWrapperTemplate";
import BodyContainer from "../../Components/UI/BodyContainer/BodyContainer";

const NotFoundPage = () => {
    return (
        // <PageWrapperTemplate>

        <div className={styles.container}>
            <BodyContainer>
                <div className={styles.contentWrapper}>
                    <div className={styles.imageBox}>
                        <img src={logoAppearing} />
                    </div>
                    <h1 className={styles.titleText}>Sorry... 404 page not found.</h1>
                </div>
            </BodyContainer>
        </div>

        // </PageWrapperTemplate>
    );
};

export default NotFoundPage;
