import styles from "./ScrollableBodyContainer.module.css"
const ScrollableBodyContainer = ({ children }) => {
    return (
        <div className={styles.bodyContainer}>
            {/* <div className={styles.gutter1}/> */}
            <div className={styles.mainContent}>
                 {children}
            </div>
           
            {/* <div className={styles.gutter1}/> */}
        </div>
    )
}
export default ScrollableBodyContainer;