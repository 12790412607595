
import Graph from "./DisplayDataElements/Graphs/Graph";
import { useContext } from "react";
import { AdminContext } from "../../../Context/Admin.Context";

import GraphContainer from "./DisplayDataElements/Graphs/GraphContainer";
const ProductGuideTrafficCompare = () => {

    const { isFilteredGoogleAnalyticsData } = useContext(AdminContext)

    //FILTER PRODUCT GUIDES
    const hdTraffic = isFilteredGoogleAnalyticsData?.pageDataMetrics?.filter(item => item.pagePath.includes('/home-depot/')) || [];
    const lgTraffic = isFilteredGoogleAnalyticsData?.pageDataMetrics?.filter(item => !item.pagePath.includes('/home-depot/')) || [];

    //SUM TOTAL PAGE VIEWS
    const totalHdPageViews = hdTraffic.reduce((acc, item) => {
        return acc + (Number(item.screenPageViews) || 0);
    }, 0);

    const totalLgPageViews = lgTraffic.reduce((acc, item) => {
        return acc + (Number(item.screenPageViews) || 0);
    }, 0);


    const pageViewsDataPoints = [
        { label: "LG Page Views", value: totalLgPageViews },
        { label: "HD Page Views", value: totalHdPageViews }
    ];
    //SUM TOTAL ACTIVE USERS
    const totalHdActiveUsers = hdTraffic.reduce((acc, item) => {
        return acc + (Number(item.activeUsers) || 0);
    }, 0);

    const totalLgActiveUsers = lgTraffic.reduce((acc, item) => {
        return acc + (Number(item.activeUsers) || 0);
    }, 0);

    const activeUsersDataPoints = [
        { label: "LG Active Users", value: totalLgActiveUsers },
        { label: "HD Active Users", value: totalHdActiveUsers }
    ];


    //SUM TOTAL TIME ON PAGE

    const totalHdTimeOnPage = hdTraffic.reduce((acc, item) => {
        return acc + (Number(item.userEngagementDuration) || 0);
    }, 0);

    const totalLGTimeOnPage = lgTraffic.reduce((acc, item) => {
        return acc + (Number(item.userEngagementDuration) || 0);
    }, 0);

    const timeOnPageDataPoints = [
        { label: "LG Active Users", value: totalLGTimeOnPage },
        { label: "HD Active Users", value: totalHdTimeOnPage }
    ];



    return (

        <GraphContainer>

            <Graph
                chartTitle="Page Views"
                chartSubtitle="Product Guide Traffic"
                dataPoints={pageViewsDataPoints}
            />
            <Graph
                chartTitle="Active Users"
                chartSubtitle="Product Guide Traffic"
                dataPoints={activeUsersDataPoints}
            />
            <Graph
                chartTitle="Time on Page"
                chartSubtitle="Product Guide Traffic"
                dataPoints={timeOnPageDataPoints}
            />

        </GraphContainer>

    )

}

export default ProductGuideTrafficCompare;