import { useContext, useState, useMemo } from "react";
import { AdminContext } from "../../../Context/Admin.Context";
import { SFWProductsContext } from "../../../Context/CategoryContext";
import TheTable from "../TableElements/TheTable";
import TheTablePagination from "../TableElements/TheTablePagination";

const PopularSavedListProductsTable = () => {
    const { isPortalDataState } = useContext(AdminContext);
    const { products } = useContext(SFWProductsContext);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const allSavedListData = isPortalDataState.allSavedList.map(data => data);

    // Flatten product lists and count product frequencies
    const productFrequencies = allSavedListData.reduce((acc, entry) => {
        (entry.builderList || []).forEach(product => {
            const id = product._id;
            acc[id] = (acc[id] || 0) + 1;
        });
        return acc;
    }, {});

    const sortedProductsByPopularity = Object.entries(productFrequencies)
        .sort((a, b) => b[1] - a[1]);

    const numberedData = useMemo(() => {
        return sortedProductsByPopularity.map(([productId, count], index) => {
            const product = products.find(p => p._id === productId);
            return {
                productId,
                productName: product ? product.title : "N/A",
                category: product ? product.categoryEN : "N/A",  // Assuming 'category' and 'subcategory' are fields on the product object
                subcategory: product ? product.subcategoryEN : "N/A",
                count,
                rowNumber: index + 1
            };
        });
    }, [sortedProductsByPopularity, products]);

    // TABLE DATA
    const columns = [
        { key: 'rowNumber', title: '#' },
        { key: 'productName', title: 'Product Name' },
        { key: 'productId', title: 'Product ID' },
        { key: 'category', title: 'Category' },
        { key: 'subcategory', title: 'Subcategory' },
        { key: 'count', title: 'Count' }
    ];

    while (numberedData.length < itemsPerPage) {
        numberedData.push({
            productName: '',
            productId: '',
            category: '',
            subcategory: '',
            count: '',
            rowNumber: ''
        });
    }

    return (
        <div>
            <TheTable columns={columns} data={numberedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)} />
            <TheTablePagination
                itemsPerPage={itemsPerPage}
                tableData={numberedData}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
            />
        </div>
    );
}

export default PopularSavedListProductsTable;
