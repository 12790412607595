import { createContext, useState } from "react";

export const HAPGContext = createContext({

    //---------------------
    //SAVE LIST CONTEXT
    //---------------------
    saveListState: {
        isShowModal: false,
        isFormValid: false,
        isSuccess: false,
        isFailure: false,
        formMessage: ''
    },
    setSaveListState: () => { },

    //---------------------
    //EMAIL LIST CONTEXT
    //---------------------
    emailListState: {
        isShowModal: false,
        isFormValid: false,
        isSuccess: false,
        isFailure: false,
        formMessage: ''
    },
    setEmailListState: () => { },

    purchaseProPrintState:{
        isPurchaseProPrintState:false,
    },
    setPurchaseProPrintState: ()=>{},
});

export const HAPGProvider = ({ children }) => {

    //---------------------
    //SAVE LIST STATE
    //---------------------
    const initialSaveListState = {
        isShowModal: false,
        isFormValid: false,
        isSuccess: false,
        isFailure: false,
        formMessage: '',
    };

    const [saveListState, setSaveListState] = useState(initialSaveListState);

    //---------------------
    //EMAIL LIST STATE
    //---------------------
    const initialEmailListState = {
        isShowModal: false,
        isFormValid: false,
        isSuccess: false,
        isFailure: false,
        formMessage: '',
    };
    const [emailListState, setEmailListState] = useState(initialEmailListState);

   


     //---------------------
    //PRINT LIST STATE
    //---------------------
    const initialPrintListState = {
        isPurchaseProPrintState: false,
       
    };
    const [purchaseProPrintState, setPurchaseProPrintState] = useState(initialPrintListState);
   



    return (
        <HAPGContext.Provider
            value={{
                //---------------------
                //SAVE LIST VALUE
                //---------------------
                saveListState,
                setSaveListState,

                //---------------------
                //EMAIL LIST VALUE
                //---------------------
                emailListState,
                setEmailListState,

                //---------------------
                //PRINT LIST VALUE
                //---------------------
                purchaseProPrintState,
                setPurchaseProPrintState
            }}
        >
            {children}
        </HAPGContext.Provider>
    );
}
