import ProductCard from '../../Cards/ProductCard'
import { motion } from 'framer-motion'
import { useRef, useEffect, useState } from 'react'

import styles from './ScrollableRow.module.css'
import TheButton from '../../../UI/TheButton/TheButton'

const ScrollableRow = ({ products, categoryName, productCount }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const categoryDisplayRef = useRef();
    const scrollLeft = () => {
        categoryDisplayRef.current.scrollLeft += -1000;
    }
    const scrollRight = () => {
        categoryDisplayRef.current.scrollLeft += 1000;
    }

    return (
        <>
            {/* {!products &&<Loader />} */}

            <div className={styles.container} id={products}>
                <div className={styles.arrowBox}>
                    <div onClick={scrollLeft}>

                        <TheButton
                            icon
                            buttonIcon
                            iconName="scrollLeft"
                            styleName="lightIconButton"
                            animated
                        />
                    </div>
                    <div onClick={scrollRight}>
                        <TheButton
                            icon
                            buttonIcon
                            iconName="scrollRight"
                            styleName="lightIconButton"
                            animated
                        />
                    </div>

                </div>

                <section className={styles.wrapperScroll} ref={categoryDisplayRef}>
                    {/* {
                        products.map((product, idx) => {
                            return (
                                <motion.div
                                    whileHover={isMobile ? {} : { scale: 1.05, transition: { duration: 1 } }}
                                    className={styles.card}
                                >
                                    <ProductCard key={idx} product={product} />
                                </motion.div>
                            )
                        })
                    } */}
                    {
                        products.sort((a, b) => (b.msrp || 0) - (a.msrp || 0))
                            .map((product, idx) => {

                                return (
                                    <motion.div
                                    key={idx}
                                        whileHover={
                                            isMobile ? {} : 
                                            { scale: 1.02,
                                                transition:{duration: .2,},
                                                
                                               
                                            }} 
                                            transition={{ duration: 0.7 }}
                                            
                                        //     whileTap={{
                                        //         scale:1,
                                        //        transition:{
                                        //            duration:.3,
                                        //        }
                                        //    }}
                                        //     transition={{ duration: 0.3 }}
                                        className={styles.card}
                                    >
                                        <ProductCard  product={product} />
                                    </motion.div>
                                )
                            })
                    }

                    {/* {!products ? (<Loader />) : (
                        products.map((product, index) => {
                            return (
                                <motion.div
                                    whileHover={isMobile ? {} : { scale: 1.05, transition: { duration: 1 } }}
                                    className={styles.card}
                                >
                                    <ProductCard key={index} product={product} />
                                </motion.div>
                            )
                        }))} */}



                </section>
            </div>
            {categoryName &&
                // <h1>{categoryName} ({productCount})</h1>
                <div className={styles.previewFooter}>
                    <h1>Browse All {categoryName} Products </h1>
                </div>

            }

        </>
    )
}

export default ScrollableRow;