import styles from "../Form.styles.module.css"
import { useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from "../../../../Context/Language.Context";
import { RoutingContext } from "../../../../Context/Routing.Context";
import { RetailerContext } from "../../../../Context/Retailer.Context";
import { useForm } from "../../../../Hooks/FormHook/form-hook";

import Input from '../../../Components/UI/FormElements/Input/Input';

import TheButton from "../../../Components/UI/TheButton/TheButton";


import Modal from "../../../Components/UI/TheModals/TheModal";
import {
  faEye,
  faEyeSlash,
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";

import { VALIDATOR_EMAIL, VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH } from '../../../../Utils/validators';





const SignupForm = () => {
  const redirect = useNavigate();

  const defaultModal = {
    show: false,
    title: '',
    message: '',
    errorList: [],
    actionText: 'Close',
    wasSuccessful: false
  }

  const [modalInfo, setModalInfo] = useState(defaultModal);

  const [termsChecked, setTermsChecked] = useState(false);

  const { translatedText } = useContext(LanguageContext)
  const { setIsRoutingState } = useContext(RoutingContext)
  const { isHomeDepotApp } = useContext(RetailerContext)

  const handleTermsChange = event => {
    setTermsChecked(event.target.checked);
  };
  const openTermsInNewWindow = (event) => {
    event.preventDefault();  // To prevent default link behavior

    const width = 600;  // Window width in pixels
    const height = 400; // Window height in pixels
    const left = (window.innerWidth / 2) - (width / 2); 
    const top = (window.innerHeight / 2) - (height / 2); 

    window.open('/terms', 'TermsWindow', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`);
};
  const [passwordInputType, setPasswordInputType] = useState("password");
  // const [confirmPasswordInputType, setConfirmPasswordInputType] = useState("password");
  const togglePasswordVisibility = () => {
    setPasswordInputType((prevState) =>
      prevState === "password" ? "text" : "password"
    );
  };
  // const toggleConfirmPasswordVisibility = () => {
  //   setConfirmPasswordInputType((prevState) =>
  //     prevState === "password" ? "text" : "password"
  //   );
  // };
  const [formState, inputHandler] = useForm({

    firstName: {
      value: '',
      isValid: false,
    },
    lastName: {
      value: '',
      isValid: false,
    },
    store: {
      value: '',
      isValid: false,
    },
    email: {
      value: '',
      isValid: false,
    },
    password: {
      value: '',
      isValid: false,
    },
    // confirmPassword: {
    //   value: '',
    //   isValid: false,
    // },
  }, false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsRoutingState(prevState => ({
      ...prevState,
      isLoading: true
    }))

    let errorMessage = [];
    if (!formState.inputs.firstName.value) errorMessage.push('First name missing or invalid.');
    if (!formState.inputs.lastName.value) errorMessage.push('Last name missing or invalid.');
    if (!formState.inputs.email.value) errorMessage.push('Email missing or invalid.');
    if (!formState.inputs.store.value) errorMessage.push('Retailer missing or invalid.');
    if (!formState.inputs.password.value) errorMessage.push('Password missing or invalid.');
    if (!termsChecked) errorMessage.push('You must accept the terms and conditions.');

    if (errorMessage.length > 0) {
      setModalInfo({
        show: true,
        title: 'Signup Failed',
        message: 'Please fix the following errors',
        errorList: errorMessage,
        actionText: 'Try Again'
      });

      setIsRoutingState(prevState => ({
        ...prevState,
        isLoading: false
      }));

      return;
    }

    if (!formState.isValid) {
      setModalInfo({
        show: true,
        title: 'Signup Failed',
        message: 'Please ensure your information is valid.',
        errorList: [
          'First and last name cannot be less than 2 characters.',
          'Retailer name is required.',
          'Only valid email formats allowed.',
          'Password cannot be less than 8 characters'
        ],
        actionText: 'Try Again'
      });

      setIsRoutingState(prevState => ({
        ...prevState,
        isLoading: false
      }));

      return;
    }


    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: formState.inputs.email.value,
          // username: formState.inputs.username.value,
          firstName: formState.inputs.firstName.value,
          lastName: formState.inputs.lastName.value,
          password: formState.inputs.password.value,
          store: formState.inputs.store.value,
          // sessionData: session, 
          // Include session data in the request body
        })
      });

      if (response.ok) {
        setModalInfo({
          show: true,
          title: 'Success',
          message: 'Your Sign up request has been sent. Check your inbox for confirmation and next steps.',
          errorList: [],
          actionText: 'Close',
          wasSuccessful: true
        });
        setIsRoutingState(prevState => ({
          ...prevState,
          isLoading: false
        }));
      } else if (response.status === 422) {
        setModalInfo({
          show: true,
          title: 'Signup Failed',
          message: "That email is not available.",
          errorList: [],
          actionText: 'Try Again'
        });
      } else if (response.status === 500) {
        setModalInfo({
          show: true,
          title: 'Signup Failed',
          message: "Something went wrong. Your request could not be sent. Please contact the administrator.",
          errorList: [],
          actionText: 'Try Again'
        });
      } else {
        const errorData = await response.json();
        throw new Error(errorData.customErrorMessage);
      }

    } catch (error) {
      setModalInfo({
        show: true,
        title: 'Signup Failed',
        message: error.customErrorMessage,
        errorList: [],
        actionText: 'Try Again'
      });

    } finally {
      setIsRoutingState(prevState => ({
        ...prevState,
        isLoading: false
      }))
    }
  };


  const closeModal = () => {
    setModalInfo(defaultModal);
    if (modalInfo.wasSuccessful) {
      redirect(`${isHomeDepotApp.isHomeDepotPrefix}/`);
    }

  };

  return (
    <>
      <Modal
        icon
        iconName="close"
        styleName="lightIconButton"
        iconOnClick={closeModal}
        show={modalInfo.show}
        title={modalInfo.title}
        message={modalInfo.message}
        errorList={modalInfo.errorList}
        closeOnBackdropClick={false}
        actionBox={
          <>
            <span onClick={closeModal}>
              <TheButton styleName="white" animated>
                {modalInfo.actionText}
              </TheButton>
            </span>
          </>
        }
      />


      <section className={styles.container}>
        <form onSubmit={handleSubmit}>
          <div className={styles.formRow}>

          </div>

          <div className={styles.formRow}>
            <Input
              requireAsterix
              id="firstName"
              element="input"
              label={translatedText.firstName}
              type='text'
              validators={[VALIDATOR_MINLENGTH(2)]}
              errorText="Minimum 2 characters required."
              onInput={inputHandler}
            />

          </div>
          <div className={styles.formRow}>
            <Input
              requireAsterix
              id="lastName"
              element="input"
              label={translatedText.lastName}
              type='text'
              validators={[VALIDATOR_MINLENGTH(2)]}
              errorText="Minimum 2 characters required."
              onInput={inputHandler}
            />

          </div>


          <div className={styles.formRow}>
            <Input
              requireAsterix
              id="store"
              element="input"
              label={translatedText.retailer}
              type='text'
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Retailer name required."
              onInput={inputHandler}
            />

          </div>
          <div className={styles.formRow}>
            <Input
              requireAsterix
              id="email"
              element="input"
              type="email"
              label="Email"
              validators={[VALIDATOR_EMAIL()]}
              errorText="Please enter a valid email address."
              onInput={inputHandler}
              icon={faEnvelope}
            />
          </div>
          <div className={styles.formRow}>
            <Input
              requireAsterix
             
              id="password"
              element="input"
             
              type={passwordInputType}
              label={translatedText.password}
              validators={[VALIDATOR_MINLENGTH(8)]}
              errorText="Minimum of 8 characters."
              onInput={inputHandler}
              icon={passwordInputType === "password" ? faEyeSlash : faEye}
             
              onIconClick={togglePasswordVisibility}
            />
            {/* <Input
              requireAsterix
              labelColor="white"
              id="confirmPassword"
              element="input"
             
              type={confirmPasswordInputType}
              label="Confirm Password"
              validators={[VALIDATOR_MINLENGTH(5)]}
              errorText="Please enter password of at least 5 characters in length."
              onInput={inputHandler}
              icon={confirmPasswordInputType === "password" ? faEyeSlash : faEye}
              iconColor="gray"
              onIconClick={toggleConfirmPasswordVisibility}
            /> */}
          </div>
          <div className={styles.formCheckRow}>
            <label className={styles.termsLabelText}  htmlFor="terms">By signing up you are accepting our <a href="/terms" onClick={openTermsInNewWindow}>terms and conditions.</a></label>
         
            <div>
              <input
                
                type="checkbox"
                id="terms"
                checked={termsChecked}
                onChange={handleTermsChange}
              />
            </div>

          </div>

          <div className={styles.buttonBox}>
            <span onClick={closeModal}>
              <TheButton type="submit" styleName="red"
                animated="true">{translatedText.signUp}</TheButton>
            </span>

          </div>
        </form>
        {/* </FormTemplate> */}

      </section>

    </>
  );
};

export default SignupForm;