import { useContext } from "react";
import { RoutingContext } from "../../../../Context/Routing.Context";
import ListDropdown from "./ListDropdown/ListDropdown";
import ListIcon from "./ListIcon/ListIcon";

const NavListElements = () => {
     const { isRoutingState } = useContext(RoutingContext);
    return <><ListIcon />{ isRoutingState.isBuilderDropdownOpen && <ListDropdown />}</>
}

export default NavListElements;

