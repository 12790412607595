import { useContext } from "react";
import ForgotPasswordForm from "../../Forms/ForgotPasswordForm/ForgotPasswordForm";
import NavigationLinks from "../../../Components/UI/NavigationElements/NavigationLinks"
import { RetailerContext } from "../../../../Context/Retailer.Context";
import styles from "../FormPage.styles.module.css"
// import PasswordResetForm from "../../Forms/PasswordResetForm/PasswordResetForm";

const ForgotPasswordPage = () => {
    const { isHomeDepotApp } = useContext(RetailerContext)
    return (

        <div className={styles.forgotPasswordBackgroundImageWrapper}>
            {/* <div className={styles.pageWrapper}> */}
            <div className={styles.pageContainer}>
                <div className={styles.formContainer}>
                    <div className={styles.headTextWrapper}>
                        <p className={styles.titleText}>Need to reset your password?</p>
                    </div>
                    <p className={styles.subtitleText}>Get a recovery link sent to the email associated with your account.</p>
                    <div className={styles.formWrapper}>
                        <ForgotPasswordForm />
                        {/* <PasswordResetForm/> */}
                    </div>
                    <div className={styles.formLinksBox}>
                        <NavigationLinks
                            linkText="Try to log in"
                            to={`${isHomeDepotApp.isHomeDepotPrefix}/sign-in`}
                        />
                    
                    </div>
                </div>
            </div>
        </div>





    )
}

export default ForgotPasswordPage;