
import { AnimatePresence } from "framer-motion";
import { useContext } from "react"
import { SFWBuilderContext } from "../../../../../Context/BuilderContext";
import { RoutingContext } from "../../../../../Context/Routing.Context";

import ProductCard from "../../../../Components/ProductElements/Cards/ProductCard";

import ListModal from "../ListModal/ListModal";

const ListDropdown = () => {
    const { builderListItems } = useContext(SFWBuilderContext);
    const { setIsRoutingState } = useContext(RoutingContext);
   
    return (
      <ListModal>
            <AnimatePresence>
                {builderListItems.map(item => <ProductCard key={item.title} product={item} />)}
            </AnimatePresence>
           
        </ListModal>
    );
}

export default ListDropdown;