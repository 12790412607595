import styles from './PurchaseProPrintScreen.module.css'
import { useContext } from "react";
import { SFWBuilderContext } from "../../../../Context/BuilderContext";
import { LanguageContext } from "../../../../Context/Language.Context";
import { RetailerContext } from "../../../../Context/Retailer.Context";
import ProductHeader from "../../../Components/ProductElements/Details/ProductHeader/ProductHeader";
import ProductImage from "../../../Components/ProductElements/Details/ProductImage/ProductImage";
import LGLogo from "../../../Components/BrandElements/LGLogo/LGLogo";
import HomeDepotLogo from "../../../Components/BrandElements/HomeDepotLogo/HomeDepotLogo";
import FeatureLogos from "../../../Components/ProductElements/Details/FeatureLogos/FeatureLogos";
import SpecificationColumns from "../../../Components/ProductElements/Details/Specifications/SpecificationColumns"
import QRCodeImage from "../../../Components/ProductElements/Details/QRCodeImage/QRCodeImage";


const PurchaseProPrintScreen = () => {
    const { builderListItems } = useContext(SFWBuilderContext)
const {translatedText}=useContext(LanguageContext)

    const { isHomeDepotApp } = useContext(RetailerContext)

    return (
        <div className={styles.printScreenContainer}>
            <div className={styles.coverPage}>
                <div className={styles.logoBox}>
                    {isHomeDepotApp.isHomeDepotActive ? <HomeDepotLogo /> : <LGLogo />}
                </div>
                <div className={styles.coverTextBox}>
                    <p className={styles.coverTitleText}>{translatedText.lgPg}</p>
                    <p className={styles.subtitleText}>{translatedText.homeAppliances} {translatedText.pL} </p>
                </div>
            </div>
            <div className={styles.productContainer}>
                {builderListItems && builderListItems?.map((e, idx) => {
                    return (
                        <div className={styles.productItem} key={idx}>
                            <ProductHeader styleName="printStyles" product={e} />
                            <ProductImage 
                            // styleName="printImage" 
                            product={e} />
                            <SpecificationColumns product={e} />
                            {/* <ColorLegend product={e} /> */} <QRCodeImage styleName="printImage" QRCodeImage={e} />
                            <FeatureLogos product={e} />
                            {/* <div className={styles.productWrapper}>
                            </div> */}
                        </div>
                    )})}
            </div>
        </div>
    )
}

export default PurchaseProPrintScreen;