import { useContext } from "react";

import { LanguageContext } from "../../../../../Context/Language.Context";
import { Link } from "react-router-dom";
// import Icon from "../../../IconElements/Icon";
// import Button from "../../../Buttons/Buttons";
import TheButton from "../../../UI/TheButton/TheButton";

// import SpecSheetLink from '../../../../Themes/Icons/clipboard-text.svg';
// import ManualLink from '../../../../Themes/Icons/book-open.svg';
// import InstallLink from '../../../../Themes/Icons/tool.svg';
import styles from './FeatureResources.module.css'
import TheIcon from "../../../UI/TheIcon/TheIcon";
import BodyContainer from "../../../UI/BodyContainer/BodyContainer";

const FeatureResources = ({ product }) => {
    console.log(product.specSheetLinkEN)
    const { language } = useContext(LanguageContext);

    let specLink, specLinkText, manualLink, manualLinkText, installGuideLink, installGuideLinkText;

    if (!product.specSheetLinkEN && !product.specSheetLinkFR) {
        specLink = null;
        specLinkText = null;
    } else {
        // specLink = language === "EN" ? product.specSheetLinkEN : product.specSheetLinkFR;
        specLink = (language === "EN" && product.specSheetLinkEN) ? product.specSheetLinkEN.trim() : (product.specSheetLinkFR ? product.specSheetLinkFR.trim() : "");
        specLinkText = language === "EN" ? "Specification Sheet" : "Fiche Technique";
    }

    if (!product.ownerManualLinkEN && !product.ownerManualLinkFR) {
        manualLink = null;
        manualLinkText = null;
    } else {
        manualLink = (language === "EN" && product.ownerManualLinkEN) ? product.ownerManualLinkEN.trim() : (product.ownerManualLinkFR ? product.ownerManualLinkFR.trim() : "");

        // manualLink = language === "EN" ? product.ownerManualLinkEN : product.ownerManualLinkFR;
        manualLinkText = language === "EN" ? `Owner's Manual` : `Le manuel du propriétaire`;
    }

    if (!product.installGuideLinkEN && !product.installGuideLinkFR) {
        installGuideLink = null;
        installGuideLinkText = null;
    } else {
        installGuideLink = (language === "EN" && product.installGuideLinkEN) ? product.installGuideLinkEN.trim() : (product.installGuideLinkFR ? product.installGuideLinkFR.trim() : "");
        // installGuideLink = language === "EN" ? product.installGuideLinkEN : product.installGuideLinkFR;
        installGuideLinkText = language === "EN" ? `Installation Guide` : `Guide d'installation`;
    }


    return (

        !manualLink?.length > 0 && !specLink?.length > 0 && !installGuideLink?.length > 0 ? null :
            (
                <div className={styles.resourcesSectionWrapper}>
                    <BodyContainer>
                        <div className={styles.resourcesContainer}>
                            <div className={styles.titleBox}>
                                <p className={styles.titleText}>Resources</p>
                            </div>
                            <div className={styles.resourceLinksWrapper} >
                                {
                                    manualLink && manualLink.length > 0 ?
                                        <a href={`${manualLink}`} target="_blank" rel="noopener noreferrer" className={styles.resourceLink}>
                                            {/* <Link className={styles.resourceLink} to={`${manualLink}`}> */}
                                            <TheButton
                                                animated="true"
                                                icon
                                                buttonIcon
                                                iconName="externalLink"
                                                iconText={manualLinkText}
                                                styleName="lightButtonIcon"

                                            />
                                            {/* </Link>  */}
                                        </a>
                                        : null
                                }
                                {
                                    specLink && specLink.length > 0 ?

                                        <a href={`${specLink}`} target="_blank" rel="noopener noreferrer" className={styles.resourceLink}>
                                            <TheButton
                                                animated="true"
                                                icon
                                                buttonIcon
                                                iconName="externalLink"
                                                iconText={specLinkText}
                                                styleName="lightButtonIcon"
                                            />
                                        </a>

                                        : null
                                }
                                {

                                    installGuideLink && installGuideLink.length > 0 ?

                                        <a href={`${installGuideLink}`} target="_blank" rel="noopener noreferrer" className={styles.resourceLink}>
                                            <TheButton
                                                animated="true"
                                                icon
                                                buttonIcon
                                                iconName="externalLink"
                                                styleName="lightButtonIcon"
                                                iconText={installGuideLinkText}
                                            />
                                        </a>
                                        : null
                                }
                            </div>
                        </div>
                    </BodyContainer>
                </div>
            )
    );
};

export default FeatureResources;