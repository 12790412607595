// import styles from "./WelcomeBar.module.css";
// import { useContext } from "react";

// import TextBar from "../UI/TextBar";

// import { AuthContext } from "../../../Context/Auth.Context"
// import { RoutingContext } from "../../../Context/Routing.Context";
// import { LanguageContext } from "../../../Context/Language.Context"
// import { motion } from "framer-motion";
// import { usePopUpAnimation } from "../../../Hooks/AnimationHook/UseAnimiationHook";

// import TheIcon from "../UI/TheIcon";

// const WelcomeBar = () => {
//     const popUpAnimationProps = usePopUpAnimation();
//     const { currentUser } = useContext(AuthContext)
//     const { translatedText } = useContext(LanguageContext)
//     const { isRoutingState, setIsRoutingState } = useContext(RoutingContext);

//     const handleWelcomeBar = () => {
//         setIsRoutingState(prevState => ({
//             ...prevState,
//             isWelcomeBarShowing: false,
//         }))
//     }

//     return (
//         <>
//             {!currentUser && isRoutingState.isWelcomeBarShowing &&
//                 <TextBar onClick={handleWelcomeBar}>
//                     <div className={styles.welcomeBar}  >
//                         <div className={styles.welcomeBarContent}>
//                         <motion.p  {...popUpAnimationProps} > {translatedText.welcomeBannerLong}</motion.p>
//                         </div>
//                         <motion.span  {...popUpAnimationProps}><TheIcon name="close" /></motion.span>
//                     </div>
//                 </TextBar>
//             }
//         </>
//     )
// };

import styles from "./WelcomeBar.module.css";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { RoutingContext } from "../../../../Context/Routing.Context";
import { RetailerContext } from "../../../../Context/Retailer.Context";
import { AuthContext } from "../../../../Context/Auth.Context"
import { LanguageContext } from "../../../../Context/Language.Context"
import { motion } from "framer-motion";

import TheButton from "../TheButton/TheButton";
import { usePopUpAnimation } from "../../../../Hooks/AnimationHook/UseAnimiationHook";

import TheIcon from "../TheIcon/TheIcon";
import BodyContainer from "../BodyContainer/BodyContainer";

const WelcomeBar = () => {

    const { currentUser, token } = useContext(AuthContext)
    const { translatedText } = useContext(LanguageContext)
    const { isHomeDepotApp } = useContext(RetailerContext)
    const { setIsRoutingState, isRoutingState } = useContext(RoutingContext)
 

    const handleShowing = () => {
        // setShowWelcomeBar(false)
        setIsRoutingState(prevState => ({ ...prevState, isWelcomeBarShowing: false }))
    }
    const popUpAnimationProps = usePopUpAnimation();
    return (
        <>
            {/* {!currentUser && showWelcomeBar && */}
            {!token && isRoutingState.isWelcomeBarShowing &&
                // {isRoutingState.isWelcomeBarShowing &&
                <div className={styles.welcomeBar}>
                    <BodyContainer>
                    <div className={styles.welcomeBarWrapper} >
                        <div className={styles.shortWelcome}>
                            <p className={styles.subtitleText}>
                                <span>{translatedText.introPL}. </span>
                                {/* <NavLink to={`${isHomeDepotApp.isHomeDepotPrefix}/purchase-pro`}> */}
                                <NavLink to={`${isHomeDepotApp.isHomeDepotPrefix}/product-list`}>
                                 <span> {translatedText.tryItNow}</span>
                                </NavLink>
                                {/* {translatedText.welcomeBannerShort} */}
                                {/* <NavLink to={`/sign-up`}>
                                {translatedText.signUp}
                                </NavLink>
                            {translatedText.welcomeBannerShort} */}
                            </p>
                            <span
                                className={styles.welcomeCloseBtn}
                                onClick={handleShowing}
                            >
                                <TheIcon name="close" />
                            </span>
                        </div>
                        <motion.div
                            className={styles.longWelcome}
                            {...popUpAnimationProps}
                        >
                            <p className={styles.subtitleText}>
                                <span>{translatedText.welcomeBarTextLong} </span>
                                {/* <NavLink to={`${isHomeDepotApp.isHomeDepotPrefix}/purchase-pro`}> */}
                                <NavLink to={`${isHomeDepotApp.isHomeDepotPrefix}/product-list`}>
                                    <span>{translatedText.tryItNow}</span>
                                </NavLink>
                                {/* {translatedText.welcomeBannerLong}
                            <NavLink to='./sign-up'>
                                {translatedText.signUpNow}.
                            </NavLink> */}
                                {/* {translatedText.welcomeBannerLong}
                            <NavLink to='./sign-up'>
                                {translatedText.signUpNow}.
                            </NavLink> */}
                            </p>
                            <span
                                className={styles.welcomeCloseBtn}
                                onClick={handleShowing}
                            >
                                <TheButton
                                    icon
                                    buttonIcon
                                    iconName="close"
                                    styleName="transIconButton"
                                    buttonTracking
                                    animated="true"
                                />
                                {/* <Icon name="close" /> */}
                            </span>
                        </motion.div>
                    </div>
                    </BodyContainer>
                </div>
            }
        </>
    )
};


export default WelcomeBar;