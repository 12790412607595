import { useContext, useEffect } from "react";
import { RoutingContext } from "../../../../Context/Routing.Context";

import styles from "./FlashMessage.module.css"
import TheIcon from "../TheIcon/TheIcon";
const FlashMessage = () => {
    const { isFlashMessageState, setIsFlashMessageState } = useContext(RoutingContext);





    useEffect(() => {
        let timeoutId
        if (isFlashMessageState.show) {
            timeoutId = setTimeout(() => {
                setIsFlashMessageState(prevState => ({
                    ...prevState,
                    message: "",
                    show: false,
                    iconType: '',
                    styleName: ''
                }))
                }, 1000)
            // }, 99000)
        }
        return () => clearTimeout(timeoutId);

    }, [isFlashMessageState.show])


    return (

        isFlashMessageState.show === true &&
        <div className={styles.flashMessageContainerWrapper}
        onClick={()=>setIsFlashMessageState(prevState =>({...prevState, show:false}))}
        >
            <div className={styles.flashMessageContainer}>
                {isFlashMessageState.iconType === "checkmark" ?
                    <div className={styles.checkmark}>
                        <TheIcon name={isFlashMessageState.iconType} />
                    </div> :
                    isFlashMessageState.iconType === "subtracted" ?
                        <div className={styles.subtracted}>
                            <TheIcon name={isFlashMessageState.iconType} />
                        </div> : null

                }


                <div className={styles.flashIconWrapper}>
                    <p className={styles.messageText}>{isFlashMessageState.message}</p>
                </div>

            </div>



        </div>
    )
}

export default FlashMessage;