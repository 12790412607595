import styles from './Footer.module.css';
import { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import LGLogo from '../../Shared/Theme/Branding/LGLogo';

import HomeDepotLogo from '../../Shared/Theme/Branding/HomeDepotLogo';
import { SFWProductsContext } from '../../Context/CategoryContext';
import { LanguageContext } from '../../Context/Language.Context';
import { RetailerContext } from '../../Context/Retailer.Context'
import LanguageSelectionMenu from '../../Shared/Components/UI/LanguageSelectionMenu/LanguageSelectionMenu';
import CustomIcon from '../../Shared/Components/IconElements/CustomIcons';
import PrintBlock from '../../Shared/PurchasePro/Components/PrintScreen/PrintBlock';

import NavigationLinks from '../../Shared/Components/UI/NavigationElements/NavigationLinks.jsx';
import BodyContainer from '../../Shared/Components/UI/BodyContainer/BodyContainer';

const Footer = () => {
    // const { productsByCategory } = useContext(SFWProductsContext);

    const { translatedText } = useContext(LanguageContext)
    const { isHomeDepotApp } = useContext(RetailerContext)

    // HANDLING HOME DEPOT SWAP
    // const location = useLocation();
    // const isHomeDepot = location.pathname.startsWith('/home-depot');
    // const homeDepotPrefix = isHomeDepot ? '/home-depot' : '';



    return (
        <>
            {/* <PrintBlock> */}

            <footer className={styles.mainFooterContainer}>
                <BodyContainer>
                    {/* FOOTER ROW 1 */}
                    <div className={styles.footerLogoLanguageWrapper}>
                        <div className={styles.footerLogoBox}>
                            {isHomeDepotApp.isHomeDepotActive ?
                                (
                                    <div className={styles.logoBox}>
                                        <NavLink to={isHomeDepotApp.isHomeDepotPrefix ? '/home-depot' : '/'}>
                                            <HomeDepotLogo />
                                        </NavLink>
                                    </div>
                                )
                                :
                                (
                                    <div className={styles.logoBox}>
                                        <NavLink to={isHomeDepotApp.isHomeDepotPrefix ? '/home-depot' : '/'}>
                                            <LGLogo />
                                        </NavLink>
                                    </div>
                                )}
                        </div>
                    </div>
                    <section className={styles.container}>
                        <div className={styles.containerItem}>
                            <div>
                                <p className={styles.footerTitle}>
                                    {/* {translatedText.products} */}
                                    {translatedText.homeAppliances}
                                </p>
                            </div>
                            <div className={styles.footerCol}>
                                <NavigationLinks

                                    linkText={translatedText.laundry}
                                    to={`${isHomeDepotApp.isHomeDepotPrefix}/categories/Laundry`}
                                />

                            </div>
                            <div className={styles.footerCol}>
                                <NavigationLinks
                                    linkText={translatedText.refrigeration}
                                    to={`${isHomeDepotApp.isHomeDepotPrefix}/categories/Refrigeration`}
                                />
                            </div>
                            <div className={styles.footerCol}>
                                <NavigationLinks
                                    linkText={translatedText.cooking}
                                    to={`${isHomeDepotApp.isHomeDepotPrefix}/categories/Cooking`}
                                />

                            </div>
                            <div className={styles.footerCol}>
                                <NavigationLinks
                                    linkText={translatedText.dishwasher}
                                    to={`${isHomeDepotApp.isHomeDepotPrefix}/categories/Dishwasher`}
                                />
                            </div>
                            <div className={styles.footerCol}>
                                <NavigationLinks
                                    linkText={translatedText.lgStudio}
                                    to={`${isHomeDepotApp.isHomeDepotPrefix}/categories/LG Studio`}
                                />

                            </div>
                            <div className={styles.footerCol}>
                                <NavigationLinks
                                    linkText={translatedText.allCategories}
                                    to={`${isHomeDepotApp.isHomeDepotPrefix}/categories/`}
                                />
                            </div>
                        </div>


                        <div className={styles.containerItem}>
                            <div>
                                <p className={styles.footerTitle}>
                                    {translatedText.stepUpChart}
                                </p>
                            </div>
                            <div className={styles.footerCol}>
                                <NavigationLinks
                                    linkText={translatedText.laundry}
                                    to={`${isHomeDepotApp.isHomeDepotPrefix}/compare/Laundry`}
                                />

                            </div>
                            <div className={styles.footerCol}>
                                <NavigationLinks
                                    linkText={translatedText.refrigeration}
                                    to={`${isHomeDepotApp.isHomeDepotPrefix}/compare/Refrigeration`}
                                />
                            </div>
                            <div className={styles.footerCol}>
                                <NavigationLinks
                                    linkText={translatedText.cooking}
                                    to={`${isHomeDepotApp.isHomeDepotPrefix}/compare/Cooking`}
                                />


                            </div>

                            <div className={styles.footerCol}>
                                <NavigationLinks
                                    linkText={translatedText.dishwasher}
                                    to={`${isHomeDepotApp.isHomeDepotPrefix}/compare/Dishwasher`}
                                />
                            </div>
                        </div>
                        <div className={styles.containerItem}>
                            <div>
                                <p className={styles.footerTitle}>{translatedText.resources}</p>
                            </div>

                            <div className={styles.footerCol}>
                                <NavigationLinks
                                    linkText={translatedText.featureDefinitions}
                                    to={`${isHomeDepotApp.isHomeDepotPrefix}/resources/definitions`}
                                />
                            </div>
                            <div className={styles.footerCol}>
                                <NavigationLinks
                                    linkText={translatedText.limitedWarranties}
                                    to={`${isHomeDepotApp.isHomeDepotPrefix}/resources/warranties`}
                                />

                            </div>
                            <div className={styles.footerCol}>
                                <NavigationLinks
                                    linkText={translatedText.modelTransitions}
                                    to={`${isHomeDepotApp.isHomeDepotPrefix}/resources/transitions`}
                                />
                            </div>
                        </div>
                        <div className={styles.containerItem}>
                            <div>
                                <p className={styles.footerTitle}>{translatedText.exclusiveOffers}</p>
                            </div>

                            <div className={styles.footerCol}>

                                <NavigationLinks
                                    linkText={translatedText.pL}
                                    to={`${isHomeDepotApp.isHomeDepotPrefix}/product-list`}
                                // to={`${isHomeDepotApp.isHomeDepotPrefix}/purchase-pro`}
                                />
                            </div>
                        </div>
                        <div className={styles.containerItem}>
                            <div>
                                <p className={styles.footerTitle}>
                                    {/* Join */}
                                    {translatedText.support}
                                </p>
                            </div>

                            <div className={styles.footerCol}>
                                <NavigationLinks linkText="productguide@teamlg.ca" to={`mailto:productguide@teamlg.ca`} />
                            </div>
                            <div className={styles.footerCol}>
                                <NavigationLinks linkText={translatedText.terms} to={`${isHomeDepotApp.isHomeDepotPrefix}/terms`} />
                            </div>

                            <div className={styles.footerCol}>
                                <NavigationLinks linkText={translatedText.privacy} to={`${isHomeDepotApp.isHomeDepotPrefix}/privacy`} />
                            </div>

                            <div className={styles.footerCol}>
                                <LanguageSelectionMenu />
                            </div>
                            {/* HIDDEN PER PHASE 2 REQUEST */}
                            {/*    <div className={styles.footerCol}><NavigationLinks linkText={translatedText.signIn} to={`${isHomeDepotApp.isHomeDepotPrefix}/sign-in`}/> </div>
                                <NavigationLinks linkText={translatedText.createAccount} to={`${isHomeDepotApp.isHomeDepotPrefix}/sign-up`} /> */}

                            {/*  <div className={styles.footerCol}> <NavigationLinks linkText="Email productguide@teamlg.ca" to={`mailto:productguide@teamlg.ca`}/> </div>*/}


                        </div>
                    </section>
                </BodyContainer>
            </footer>
            <div className={styles.footerRightsBoxWrapper} >
                <div className={styles.footerRightsBox}>
                    <BodyContainer>
                        {/* <div className={styles.fP}> */}
                        <p>{translatedText.allRightsReserved}</p>
                        {/* </div> */}
                    </BodyContainer>
                </div>
            </div>
            {/* </PrintBlock > */}
        </>


    )
}

export default Footer;