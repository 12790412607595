import { createContext, useState } from 'react';

import { translatedText } from '../Shared/Data/Language/translated-text';

export const LanguageContext = createContext({
    language: '',
    setLanguage: () => {},
    translatedText:{},
});

export const LanguageProvider = ({ children }) => {
    
    const [language, setLanguage] = useState('EN');
    
 
    const value = {
        language,
        setLanguage,
        translatedText: translatedText[language],
    };

    return (
        <LanguageContext.Provider value={value}>
            {children}
        </LanguageContext.Provider>
    );
};