import jwt_decode from "jwt-decode"

import { createContext, useState, useEffect } from "react";

import { useNavigate } from "react-router";


let logoutTimer;

export const AuthContext = createContext({
    token: '',
    currentUser: null, // isLoggedIn: false, 
    
    login: (token) => { },
    logout: () => { },
    
    loggedInUser: null, // currentUser
    setLoggedInUser: () => null,
    

    registeredUser: null,
    setRegisteredUser: () => { },  
     // myUserDetails: null, // user?
    // setMyUserDetails: () => { }
})

// -----------------------------------------------------
// LOGIC TO CREATE TOOL: TOKEN EXPIRATION 
// -----------------------------------------------------

//configure dates for token expiration
const calculateRemainingTime = (expirationTime) => {
    const currentTime = new Date().getTime();
    const adjExpirationTime = new Date(expirationTime).getTime();
    const remainingDuration = adjExpirationTime - currentTime;
    return remainingDuration;
};

const getUserIdFromToken = (token) => {
    const decodedToken = token ? jwt_decode(token) : null;
    return decodedToken?.userId;
}

const retrieveStoredToken = () => {
    const storedToken = localStorage.getItem('TOKEN-LOGIN-HANDLER');
    const storedExpirationDate = localStorage.getItem('EXP-LOGIN-HANDLER');
    // const storedUserDetails = JSON.parse(localStorage.getItem('USER-LOGIN-HANDLER'));

    const remainingTime = calculateRemainingTime(storedExpirationDate);

    // if (remainingTime <= 1800000) {
        if (remainingTime <= 0) {
        localStorage.removeItem('TOKEN-LOGIN-HANDLER');
        localStorage.removeItem('EXP-LOGIN-HANDLER');
        const currentUserIdFromToken = getUserIdFromToken(storedToken);
        if (currentUserIdFromToken) {
            localStorage.removeItem(`USER_DATA_${currentUserIdFromToken}`);
        }
        return null;

    }

    return { //this is tokenData
        token: storedToken,
        duration: remainingTime,
        // userDetails: storedUserDetails 
        // Include user information
    }
};




// -----------------------------------------------------
// PROVIDER WRAPPING APP AND PROVIDING VALUES TO CHILD COMPONENTS
// -----------------------------------------------------
export const AuthProvider = ({ children }) => {

    // const [user, setUser] = useState(null);

    const tokenData = retrieveStoredToken();

    let initialToken;
    
    if (tokenData) { initialToken = tokenData.token; }
    const [token, setToken] = useState(initialToken);
    const myUserIsLoggedIn = !!token;
    // const initialUserDetails = JSON.parse(localStorage.getItem('USER-LOGIN-HANDLER'));

    // const [myUserDetails, setMyUserDetails] = useState(initialUserDetails);


    // const [registeredUser, setRegisteredUser] = useState({});


    //without token
    const [loggedInUser, setLoggedInUser] = useState(null);

    //---------------------------------------
    //---LOGGING USERS OUT
    //---------------------------------------
    const redirect = useNavigate();
    const currentUserId = getUserIdFromToken(token);

    const logoutHandler = () => {
        setToken(null);
        // setMyUserDetails(null);
        // setRegisteredUser(null)
        // setUser(null)
        localStorage.removeItem('TOKEN-LOGIN-HANDLER');
        localStorage.removeItem('EXP-LOGIN-HANDLER');
        // localStorage.removeItem('USER_DATA');
        // localStorage.removeItem('USER-LOGIN-HANDLER');

        if (currentUserId) {
            localStorage.removeItem(`USER_DATA_${currentUserId}`);
        }

        if (logoutTimer) {
            clearTimeout(logoutTimer);
            redirect('/');
        }
    };

    //---------------------------------------
    //---LOGGING USERS IN
    //---------------------------------------
    const loginHandler = (token, expirationTime) => {
        setToken(token);
        // setMyUserDetails(user);



        localStorage.setItem('TOKEN-LOGIN-HANDLER', token);//what we se in local storage
        localStorage.setItem('EXP-LOGIN-HANDLER', expirationTime);//what we se in local storage
        // localStorage.setItem('USER-LOGIN-HANDLER', JSON.stringify(user));//what we se in local storage
        const remainingTime = calculateRemainingTime(expirationTime);
        //  logoutTimer = setTimeout(logoutHandler, 9000); // testing - log you out in 3 seconds
        logoutTimer = setTimeout(logoutHandler, remainingTime); // testing - log you out in 3 seconds
      
    };


    useEffect(() => {
        if (tokenData) {
          
            logoutTimer = setTimeout(logoutHandler, tokenData.duration);
        }
    }, [tokenData]);
    // console.log(token)





    const value = {
        token: token,
        currentUser: myUserIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler,
        // myUserDetails,
        loggedInUser,
        setLoggedInUser,
        // registeredUser,
        // setRegisteredUser,


    }
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>


}