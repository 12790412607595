import styles from "./PortalBody.module.css"
import { useContext, useEffect } from "react";
import { AdminContext } from "../../../../Context/Admin.Context";
import { AuthContext } from "../../../../Context/Auth.Context";


import PortalDashboard from "../../../PortalPages/PortalDashboard/PortalDashboard";
import jwt_decode from "jwt-decode";
import AllProductsTablePage from "../../../PortalPages/AllProducts/AllProductsTablePage";
import AllUsersTablePage from "../../../PortalPages/AllUsers/AllUsersTablePage";
import AllAdminUsersTablePage from "../../../PortalPages/AllAdminUsers/AllAdminUsersTablePage";
import CreateAdminPortalFormPage from "../../../PortalPages/CreateAdmin/CreateAdminPortalFormPage";
import CreateProductPortalFormPage from "../../../PortalPages/CreateProduct/CreateProductPortalFormPage";
import UpdateProductPortalFormPage from "../../../PortalPages/UpdateProduct/UpdateProductPortalFormPage";
import PortalUpdateUserRoleFormPage from "../../../PortalPages/UpdateUserRole/PortalUpdateUserRoleFormPage";
import PortalUpdateUserStatusFormPage from "../../../PortalPages/UpdateUserStatus/PortalUpdateUserStatusFormPage";
import AnalyticsPage from "../../../PortalPages/Analytics/AnalyticsPage";

const PortalBody = () => {

     const { token } = useContext(AuthContext);
     const decodedToken = token ? jwt_decode(token) : null;
     const { isActiveSection } = useContext(AdminContext)

     // const handleSectionClick = (sectionNumber) => {
     //     setActiveSection(sectionNumber);
     // }

     // useEffect(() => {
     //     // setIsEditingProduct(null);
     //     // setIsPortalUser(null)
     //     // setActiveSection(1);
     //     // console.log('new portal refresh on mount ')
     // }, []);




     return (
        
               <div className={styles.portalBodyContainer}>
                    <div className={styles.portalContentWrapper}>
                         {
                              isActiveSection === 1 && <PortalDashboard />
                         }
                         {
                              isActiveSection === 3 && <CreateProductPortalFormPage />
                         }
                         {
                              isActiveSection === 4 && <UpdateProductPortalFormPage />
                         }
                         {
                              isActiveSection === 2 && <AllProductsTablePage />
                         }
                         {
                              isActiveSection === 5 && <AllUsersTablePage />
                         }
                         {
                              isActiveSection === 6 && <AllAdminUsersTablePage />
                         }
                         {
                              isActiveSection === 7 && <PortalUpdateUserRoleFormPage />
                         }
                         {
                              isActiveSection === 8 && <CreateAdminPortalFormPage />
                         }
                         {
                              isActiveSection === 9 && <PortalUpdateUserStatusFormPage />
                         }
                         {
                              isActiveSection === 10 && <AnalyticsPage />
                         }

                    </div>

               </div>
     
     );
};

export default PortalBody;