import { motion } from "framer-motion";
import styles from './Checkbox.module.css';

const CheckboxInput = ({ id, type,  name,  value, checked, label, onChange }) => {
   
    const handleSelectionChange = event => {
        onChange(event.target.value, event.target.checked);
    }

    const inputVariants = {
        initial: { opacity: 0, x: -20 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 20 },
    };

    return (
        <motion.div className={styles.checkboxRow}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={inputVariants}
        >
            <label
            className={styles.checkboxLabel}
            htmlFor={id}>
                   {label}
            </label>
            <input
            className={styles.checkboxInput}
                id={id}
                type={type}
                name={name}
                value={value}
                checked={checked}
                isValid
                onChange={handleSelectionChange}
            />
        </motion.div>
    );
};

export default CheckboxInput;
