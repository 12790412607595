import styles from "./PortalSectionTextBox.module.css"

const PortalSectionTextBox = ({
    subtitle,
    title,
    headline,
    // headlineColor = "#716F6A"
}) => {
    return (
        <div className={styles.portalSectionTextBoxContainer}>
            <p className={styles.subtitleText}>{subtitle}</p>
            <p className={styles.titleText}>{title}</p>
            <p className={styles.headlineText}>
            {/* <p className={styles.headlineText} style={{ color: headlineColor }} */}
            {headline}</p>
        </div>

    )
}

export default PortalSectionTextBox;