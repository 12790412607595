import "react-datepicker/dist/react-datepicker.css";
import React from 'react';
import DatePicker from "react-datepicker";
import styles from "./DatePicker.module.css"
import TheButton from "../TheButton/TheButton"
// import { AdminContext } from "../../../../Context/Admin.Context";
// import { useContext } from "react";

function DateRangePicker({
    startDate,
    endDate,
    handleStartDateChange,
    handleEndDateChange,
    // onApply
}) {



    // const{endDate, startDate, setEndDate, setStartDate}=useContext(AdminContext)
    return (
        <div className={styles.datePickerContainer}>
            <div className={styles.dateBox}>
                {/* <p className={styles.dateText}>Start Date</p> */}
                <DatePicker
                    closeOnScroll={true}
                    selected={startDate}
                    onChange={date => handleStartDateChange(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                />
            </div>
            <div className={styles.dateBox}>
                {/* <p className={styles.dateText}>End Date</p> */}
                <DatePicker
                    closeOnScroll={true}
                    selected={endDate}
                    onChange={date => handleEndDateChange(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                />
            </div>
            {/* <div className={styles.dateButton}>
               <span onClick={onApply}> 
               <TheButton
                    animated="true"
                    styleName="black"
                >
                    Apply
                </TheButton></span>
            </div> */}


        </div>
    );
}

export default DateRangePicker;
