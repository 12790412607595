import { useContext } from 'react';

import { LanguageContext } from '../../../../../Context/Language.Context';
import styles from './ColorLegend.module.css';

const ColorLegend = ({ product }) => {
    const { language } = useContext(LanguageContext);
    
    const theColours = language === "EN" ? product.colourEN : product.colourFR;
    const colourWhite = language === "EN" ? "White" : "Blanc";
    const colourSmoothWhite = language === "EN" ? "Smooth White" : "Blanc lisse";
    const colourSmudgeResistantMatteBlack = language === "EN" ? "Smudge Resistant Matte Black" : "Noir mat résistant aux taches";
    const colourSmoothBlack = language === "EN" ? "Smooth Black" : "Noir lisse";
    const colourBlackStainlessSteel = language === "EN" ? "Black Stainless Steel" : "Acier inoxydable noir";
    const colourBlackSteel = language === "EN" ? "Black Steel" : "Acier noir";
    const colourGraphiteSteel = language === "EN" ? "Graphite Steel" : "Graphite";
    const colourStainlessSteel = language === "EN" ? "Stainless Steel" : "Acier inoxydable";
    const colourSmudgeResistantBlackStainlessSteel = language === "EN" ? "Smudge Resistant Black Stainless Steel" : "Acier inoxydable noir résistant aux taches";
    const colourSmudgeResistantStainlessSteel = language === "EN" ? "Smudge Resistant Stainless Steel" : "Acier inoxydable résistant aux taches";
    // NEW
const colourPlatinumSilver = language === "EN" ? "Platinum Silver" : "Argent platine";
// const colourPanelReady = language === "EN" ? "Panel Ready" : "Prêt pour panneau";
// const colourColorChangingLEDPanels  = language === "EN" ? "Color Changing LED Panels" : "Panneau DEL à couleur changeable";
const colourGlassBeige= language === "EN" ? "Glass Beige" : "Verre beige";
const colorNatureGreen= language === "EN" ? "Nature Green" : "Vert nature";
const colorCandyAppleRed= language === "EN" ? "Candy Apple Red" : " Rouge bonbon pomme";
const colorEssenceWhite= language === "EN" ? "Essence White" : " Blanc essence";
const colorNobleSteel= language === "EN" ? "Noble Steel" : "Acier noble";
const colorMonochromeGrey= language === "EN" ? "Monochrome grey" : " Gris monochrome";
const colorMirror= language === "EN" ? "Mirror" : "Miroir";
    const colorMapping = {
        [colourWhite]: "#FFFFFF",
        [colourSmoothWhite]: "#FFFAFA",
        [colourSmudgeResistantMatteBlack]: "#28282B",
        [colourSmoothBlack]: "#0D1115",
        [colourBlackStainlessSteel]: "#0D1115",
        [colourBlackSteel]: "#0D1115",
        [colourGraphiteSteel]: "#383428",
        [colourStainlessSteel]: "#CFD4D9",
        [colourSmudgeResistantBlackStainlessSteel]: "#0D1115",
        [colourSmudgeResistantStainlessSteel]: "#CFD4D9",

        // 
        [colourPlatinumSilver]: "#E5E4E2",
        [colourGlassBeige]:"#F5F5DC",
        [colorNatureGreen]:"#4CAF50",
        [colorCandyAppleRed]:"#FF0800",

        [colorEssenceWhite]:"#F2F2F2",
        [colorNobleSteel]:"#949A9E",
        [colorMonochromeGrey]:"#7F7F7F",
        [colorMirror]:"#E5E5E5",
    };

    return (
        <div className={styles.colorLegendContainer}>
           
                <div className={styles.colorLegendWrapper}>
                    
                    {
                    theColours && 
                    theColours.map((selectedColor, index) => {
                        let swatchColor = colorMapping[selectedColor] || "#000000";
                        return (
                            <div className={styles.colorRow} key={index}>
                               <div className={styles.swatchColor}
                                    style={{ backgroundColor: swatchColor }}
                                />
                                <p className={styles.selectedColorText}>
                                    {selectedColor}
                                </p>
                                
                            </div>
                        );
                    })}
                </div>
           
        </div>
    );
};

export default ColorLegend;

// import { useContext } from 'react';
// import { LanguageContext } from '../../../Context/Language.Context';
// import styles from './ColorLegend.module.css';
// const ColorLegend = ({ product }) => {

//     const { language } = useContext(LanguageContext);

//     const theColours = language === "EN" ? product.colourEN : product.colourFR;
//     console.log('Product:', product);
// console.log('The Colours:', theColours);

//     const colourWhite = language === "EN" ? "White" : "blanc";
//     const colourSmoothWhite = language === "EN" ? "Smooth White" : "Blanc lisse";
//     const colourSmudgeResistantMatteBlack = language === "EN" ? "Smudge Resistant Matte Black" : "Noir mat résistant aux taches";
//     const colourSmoothBlack = language === "EN" ? "Smooth Black" : "Noir lisse";
//     const colourBlackStainlessSteel = language === "EN" ? "Black Stainless Steel" : "Acier inoxydable noir";
//     const colourBlackSteel = language === "EN" ? "Black Steel" : "Acier noir";
//     const colourGraphiteSteel = language === "EN" ? "Graphite Steel" : "Acier graphite";
//     const colourStainlessSteel = language === "EN" ? "Stainless Steel" : "Acier inoxydable";
//     const colourSmudgeResistantBlackStainlessSteel = language === "EN" ? "Smudge Resistant Black Stainless Steel" : `Acier inoxydable noir résistant aux taches`;
//     const colourSmudgeResistantStainlessSteel = language === "EN" ? "Smudge Resistant Stainless Steel" : "Acier inoxydable résistant aux taches";
//     const colorMapping = {
//         [colourWhite]: "#FFFFFF",
//         [colourSmoothWhite]: "#FFFAFA",
//         [colourSmudgeResistantMatteBlack]: "#28282B",
//         [colourSmoothBlack]: "#0D1115",
//         [colourBlackStainlessSteel]: "#0D1115",
//         [colourBlackSteel]: "#0D1115",
//         [colourGraphiteSteel]: "#383428",
//         [colourStainlessSteel]: "#CFD4D9",
//         [colourSmudgeResistantBlackStainlessSteel]: "#0D1115",
//         [colourSmudgeResistantStainlessSteel]: "#CFD4D9",
//       };
      
//       swatchColor = colorMapping[selectedColor] || "#000000";
//     return (
//         <div className={styles.colorLegendContainer}>
//             <div className="legendContainer">
//                 <div className={styles.colorRow}>
//                     {theColours.map((selectedColor, index) => {
//   let swatchColor;
//   swatchColor = colorMapping[selectedColor] || "#000000";
//                         // let swatchColor;
//                         // switch (selectedColor) {
//                         //     case colourWhite:
//                         //         swatchColor = "#FFFFFF";
//                         //         break;
//                         //     case colourBlackSteel:
//                         //         swatchColor = "#0D1115";
//                         //         break;
//                         //     case colourGraphiteSteel:
//                         //         swatchColor = "#383428";
//                         //         break;
//                         //     case colourStainlessSteel:
//                         //         swatchColor = "#CFD4D9";
//                         //         break;
//                         //     case colourSmudgeResistantStainlessSteel:
//                         //         swatchColor = "#CFD4D9";
//                         //         break;
//                         //     case colourSmudgeResistantBlackStainlessSteel:
//                         //         swatchColor = "#0D1115";
//                         //         break;
//                         //     case colourBlackStainlessSteel:
//                         //         swatchColor = "#0D1115";
//                         //         break;
//                         //     case colourSmoothBlack:
//                         //         swatchColor = "#0D1115";
//                         //         break;
//                         //     case colourSmudgeResistantMatteBlack:
//                         //         swatchColor = "#28282B";
//                         //         break;
//                         //     case colourSmoothWhite:
//                         //         swatchColor = "#FFFAFA";
//                         //         break;
//                         //     default:
//                         //         swatchColor = "#000000";
//                         // }
//                         return (
//                             <div className={styles.colorBox} key={index}>

//                                 <p className={styles.selectedColor}>
//                                     {selectedColor}
//                                 </p>
//                                 <div

//                                     className={styles.swatchColor}
//                                     style={{ backgroundColor: swatchColor }}
//                                 />


//                             </div>

//                         );
//                     })}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default ColorLegend;