import SearchPreview from "./SearchPreview"
import NoSearchResultsTextContainer from "./NoSearchResultsTextContainer"
import styles from "./SearchHeaderPreviewContainer.module.css"
import EditingModeSearchPreview from "./EditingModeSearchPreview";

const EditModeSearchHeaderPreviewContainer = ({ show, results }) => {
    return (
        <div className={styles.searchHeaderPreviewWrapper}>
            {
                show &&
                (
                    results?.length > 0 ?
                        (
                        
                        <EditingModeSearchPreview products={results} />
                        )
                        : results?.length === 0 ?
                            (<NoSearchResultsTextContainer />)
                            : null
                )
            }
        </div>
    );
};

export default EditModeSearchHeaderPreviewContainer