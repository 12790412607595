import {

    // NAV
    IoReorderThreeOutline,
    IoCaretDown,
    IoCaretUp,
    IoCaretForward,
    IoCartOutline,
    IoLanguageOutline,

    // ACTIONS
    IoCloseCircleOutline,
    IoCloseOutline,
    IoAddOutline,
    IoAddCircleOutline,

    // DIRECTION

    IoArrowForwardOutline,
    IoArrowBackOutline,
    IoArrowBackCircleOutline,
    IoArrowForwardCircleOutline,

    // ADMIN
    IoHomeOutline,

    // GENERAL
    IoCalendarOutline,
    IoRibbonOutline,
    IoReceiptOutline,


} from "react-icons/io5";


import {
    MdOutlineAdminPanelSettings, MdOutlineLockPerson, MdOutlineVerifiedUser, MdOutlineInventory

} from "react-icons/md";

import {
    AiOutlineUserAdd,
    AiOutlineTeam,
    AiOutlineAppstoreAdd,
    AiOutlineAppstore,
    AiOutlineEdit,
    AiOutlineDelete,
    AiOutlinePrinter,
    AiOutlineMail,
    AiOutlineSave,
    AiOutlineUser,
    AiOutlineExpandAlt,
    AiOutlineArrowUp,
    AiOutlineMinus,
    AiOutlineRight,
    AiOutlineLeft,
    AiOutlineDown,
    AiOutlineCheckCircle,
    AiOutlineInfoCircle,
    AiOutlineEye,
    AiOutlinePause,
    AiOutlineFileSearch,
    AiOutlineShopping,
    AiOutlineTag,
    AiOutlineTool,
    AiOutlineUnorderedList,
    AiOutlineLock,
    AiOutlineHome,
    AiOutlineDashboard

} from "react-icons/ai";

import { VscAccount, VscClearAll, VscInfo, VscArrowUp, VscBook, VscMenu } from "react-icons/vsc";

import {
    BsArrowUpRight,
    BsArrowRight,
    BsArrowLeft,
    BsPlay

} from "react-icons/bs";

import { TbDeviceDesktopAnalytics, TbUserSearch, TbUsers, TbFridge } from "react-icons/tb";
import { CiUser, CiShoppingTag, CiShoppingCart, CiShoppingBasket, CiSquareAlert, CiViewList, CiCircleList } from "react-icons/ci";



const Icon = ({ name, className }) => {
    switch (name) {
        //CI
        case 'shoppingTag':
            return < CiShoppingTag className={className} />;
        case 'compareBoard':
            return < CiCircleList className={className} />;
        case 'viewList':
            return < CiViewList className={className} />;
        case 'squareInfo':
            return < CiSquareAlert className={className} />;
        case 'thinCart':
            return < CiShoppingCart className={className} />;
        case 'thinBag':
            return < CiShoppingBasket className={className} />;

        case 'thinUser':
            return <  CiUser className={className} />;
        //BS
        case 'play':
            return <   BsPlay className={className} />;
        case 'upArrowRight':
            return <  BsArrowUpRight className={className} />;
        case 'arrowRight':
            return <   BsArrowRight className={className} />;
        case 'arrowLeft':
            return <  BsArrowLeft className={className} />;
        //AI
        case "guage":
            return < AiOutlineDashboard className={className} />
        case "home":
            return < AiOutlineHome className={className} />;
        case 'tool':
            return < AiOutlineTool className={className} />;
        case 'outlineCircle':
            return <AiOutlineInfoCircle className={className} />;
        case 'list':
            return < AiOutlineUnorderedList className={className} />;
        case 'shopping':
            return < AiOutlineShopping className={className} />;
        case 'tag':
            return < AiOutlineTag className={className} />;
        case 'searchFile':
            return <  AiOutlineFileSearch className={className} />;
        case 'view':
            return <  AiOutlineEye className={className} />;
        case 'pause':
            return <  AiOutlinePause className={className} />;
        case 'lock':
            return <  AiOutlineLock className={className} />;

        case 'checkMarkCircleOutline':
            return <  AiOutlineCheckCircle className={className} />;
        case 'caretRight':
            return < AiOutlineRight className={className} />;
        case 'caretLeft':
            return < AiOutlineLeft className={className} />;
        case 'caretDown':
            return <  AiOutlineDown className={className} />;
        //MD
        case 'admin':
            return <  MdOutlineAdminPanelSettings className={className} />;
        case 'accountApproved':
            return < MdOutlineVerifiedUser className={className} />;

        case 'accountLocked':
            return <      MdOutlineLockPerson
                className={className} />;
        case 'inventory':
            return <      MdOutlineInventory
                className={className} />;
        //TB
        case 'dashboard':
            return <  TbDeviceDesktopAnalytics className={className} />;
        case 'fridge':
            return <  TbFridge className={className} />;
        case 'users':
            return <  TbUserSearch className={className} />;
        case 'multipleUsers':
            return < TbUsers className={className} />;

        // FA*
        case 'user-account':
            return <  AiOutlineUser className={className} />;
        case 'expand':
            return <  AiOutlineExpandAlt className={className} />;
        // VSC*
        case 'thinBurgerBars':
            return < VscMenu className={className} />;
        case 'book':
            return < VscBook className={className} />;
        case 'tool-tip':
            return < VscInfo className={className} />;
        case 'account':
            return < VscAccount className={className} />;
        case 'clear-all':
            return < VscClearAll className={className} />;
        case 'arrow-up':
            return <VscArrowUp className={className} />;
        // AI*
        case 'minus':
            return < AiOutlineMinus className={className} />;
        case 'save':
            return < AiOutlineSave className={className} />;
        case 'print':
            return < AiOutlinePrinter className={className} />;
        case 'email':
            return < AiOutlineMail className={className} />;
        case 'add-user':
            return < AiOutlineUserAdd className={className} />;
        case 'users':
            return < AiOutlineTeam className={className} />;
        case 'add-product':
            return < AiOutlineAppstoreAdd className={className} />;
        case 'products':
            return < AiOutlineAppstore className={className} />;
        case 'edit':
            return < AiOutlineEdit className={className} />;
        case 'delete':
            return <  AiOutlineDelete className={className} />;

        //  GENERAL
        case 'language':
            return <IoLanguageOutline className={className} />;
        case 'calendar':
            return <IoCalendarOutline className={className} />;

        case 'ribbon':
            return < IoRibbonOutline className={className} />;

        case 'receipt':
            return < IoReceiptOutline className={className} />;
        // NAV
        case 'cart':
            return <IoCartOutline className={className} />;
        case 'menu-bars':
            return < IoReorderThreeOutline className={className} />;
        case 'caret-down':
            return < IoCaretDown className={className} />;
        case 'caret-up':
            return < IoCaretUp className={className} />;
        case 'caret-forward':
            return < IoCaretForward className={className} />;

        // ACTIONS
        case 'add':
            return <IoAddOutline className={className} />;
        case 'circle-add':
            return <IoAddCircleOutline className={className} />;

        case 'circle-close':
            return <IoCloseCircleOutline className={className} />;
        case 'close':
            return <IoCloseOutline className={className} />;

        // DIRECTION
        case 'forward-arrow':
            return <IoArrowForwardOutline className={className} />;
        case 'back-arrow':
            return <IoArrowBackOutline className={className} />;

        case 'circle-forward-arrow':
            return <IoArrowForwardCircleOutline className={className} />;
        case 'circle-back-arrow':
            return <IoArrowBackCircleOutline className={className} />;

        // DIRECTION
        case 'home':
            return <IoHomeOutline className={className} />;
        default:
            return null;
    }
};

export default Icon;