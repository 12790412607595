import { createContext, useEffect, useState, useContext } from "react";
import { mapProductsByCategory } from "../Utils/category-map";
import { LanguageContext } from "./Language.Context";
import { useHttpClient } from "../Hooks/HttpHook/http-hook";
import { useLocation } from "react-router-dom";

export const SFWProductsContext = createContext({
  products: [],
  productsByCategory: {},
  filteredProducts: [], // <-- new state for filtered products
  filteredProductsByCategory: {}, // <-- add new state
  setProducts: () => { },
  editingProduct: null,
  setEditingProduct: () => { },
  setActiveSection: () => { },
  activeSection: []

});

export const SFWProductsProvider = ({ children }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [products, setProducts] = useState([]);
  const [productsByCategory, setProductsByCategory] = useState({});
  const [filteredProducts, setFilteredProducts] = useState([]); // <-- initialize new state
  const [filteredProductsByCategory, setFilteredProductsByCategory] = useState({}); // <-- initialize new state
 
  const { language } = useContext(LanguageContext);
  const location = useLocation(); // <-- get current location
 const [editingProduct, setEditingProduct] = useState(null)
  const [activeSection, setActiveSection] = useState(1)
  useEffect(() => {
    const fetchRequest = async () => {
      try {
        const data = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/products`
          // `${process.env.REACT_APP_BACKEND_URL}/'products`
        );
        setProducts(data.allProducts);
      } catch (err) { }
    };
    fetchRequest();
  }, [sendRequest]);


  useEffect(() => {
    setProductsByCategory(mapProductsByCategory(products, language));
  }, [language, products]);

  useEffect(() => {
    // Determine if current path starts with /home-depot
    const isHomeDepot = location.pathname.startsWith('/home-depot');

    // Filter products in each category based on the store
    let filteredProductsByCategory = {};
    for (let category in productsByCategory) {
      if (Array.isArray(productsByCategory[category])) {
        // Include both 'LG' and 'HD' products if isHomeDepot is true, only 'LG' products otherwise
        filteredProductsByCategory[category] = productsByCategory[category].filter(product => isHomeDepot ? true : product.store === 'LG');
      }
    }

    // Filter products array based on the store
    let filteredProducts = products.filter(product => isHomeDepot ? true : product.store === 'LG'); // <-- filter products array

    setFilteredProducts(filteredProducts); // <-- update the filtered products
    setFilteredProductsByCategory(filteredProductsByCategory); // update the filtered products by category
  }, [products, productsByCategory, location.pathname]);



  const value = {
    products,
    setProducts,
    
    editingProduct,
    setEditingProduct,

    activeSection,
    setActiveSection,

    productsByCategory,
    filteredProducts, // <-- pass the new state down the context
    filteredProductsByCategory, // <-- pass the new state down the context
    
    isLoading,
    error,
    clearError
  };

  return (
    <SFWProductsContext.Provider value={value}>
      {children}
    </SFWProductsContext.Provider>
  );
};